import gql from 'graphql-tag'

export const DISCLAIMER_MAIL = gql`
  mutation disclaimerMail {
    disclaimerMail
  }
`
export const UPDATE_KYC = gql`
  mutation updateKyc(
    $userId: Int
    $panCard: Upload
    $aadharCard: Upload
    $passportSize: Upload
    $signature: Upload
    $cancelledChequeSnap: Upload
    $bankStatement: Upload
    $aadharCardBack: Upload
    $incomeProof: Upload
    $panHolderName: String
  ) {
    updateKyc(
      where: { userId: $userId }
      data: {
        panCard: $panCard
        aadharCard: $aadharCard
        passportSize: $passportSize
        signature: $signature
        cancelledChequeSnap: $cancelledChequeSnap
        bankStatement: $bankStatement
        aadharCardBack: $aadharCardBack
        incomeProof: $incomeProof
        panHolderName: $panHolderName
      }
    ) {
      id
      panCard
      aadharCard
      passportSize
      signature
      cancelledChequeSnap
      bankStatement
      aadharCardBack
      incomeProof
      panHolderName
    }
  }
`

export const GIVE_ANSWER = gql`
  mutation giveAnswer($data: AnswerCreateInputWithAnnualIncome) {
    giveAnswer(data: $data)
  }
`
export const INVESTMENT_PLANNING_MAIL = gql`
  mutation investmentPlanningMail($where: InvestmentPlanningMailInput) {
    investmentPlanningMail(where: $where)
  }
`
export const RISK_ASSESSMENT_MAIL = gql`
  mutation riskAssessmentMail {
    riskAssessmentMail
  }
`
export const FETCH_CVL_STATUS = gql`
  mutation fetchCvlStatus {
    fetchCvlStatus
  }
`
export const CREATE_MANDATE = gql`
  mutation createMandate($data: mandateCreateInput!) {
    createMandate(data: $data) {
      ifscCode
    }
  }
`
export const CONSENT_DATA = gql`
  mutation consentData {
    consentData
  }
`
export const COMPLETE_STEP = gql`
  mutation completeStep($data: completeStepInput!) {
    completeStep(data: $data) {
      id
      step
      from
    }
  }
`

export const SENDING_ASSESSMENT_MAILS = gql`
  mutation sendingRiskAssessmentMails {
    sendingRiskAssessmentMails
  }
`

export const VERIFY_UPI = gql`
  mutation verifyUpiId($data: verifyUpiIdInput) {
    verifyUpiId(data: $data)
  }
`

export const PAYMENT = gql`
  mutation PaymentAmc($data: paymentInput) {
    PaymentAmc(data: $data)
  }
`

export const ENCRYPT_UPI_ID = gql`
  mutation encryptUpi($data: verifyUpiIdInput) {
    encryptUpi(data: $data)
  }
`

export const CREATE_TRANSACTION_VIA_UPI = gql`
  mutation createTransactionViaUpi($data: upiTransactionInput) {
    createTransactionViaUpi(data: $data)
  }
`

export const UPDATE_PARTNER_DETAILS = gql`
  mutation updatePartnerDetails($data: [InputForUpdatePartnerDetails!]!) {
    updatePartnerDetails(data: $data)
  }
`

export const CREATE_BOOK_ADVISORY = gql`
  mutation CreateBookAdvisory($data: BookAdvisoryInput) {
    createBookAdvisory(data: $data) {
      id
      userId
      advisoryDate
      isAdvisoryBooked
      isClientLogin
      isClientAcceptAdvisory
      advisorySendDateAndTime
      advisoryAcceptDateAndTime
      isTermAndServiceAccepted
      isPendingAdvisoryAccepted
      advisorySendType
      isCancelAdvisory
      paymentType
      amcAmount
      advisoryType
      createdAt
      updatedAt
      clientId {
        firstName
        lastName
        clientCode
        id
        email
        userName
      }
      advisorySubmittedUser {
        firstName
        lastName
        role
        email
        id
      }
      advisoryCancelByUser {
        firstName
        lastName
        role
        email
        id
      }
      advisoryUpdateByUser {
        firstName
        lastName
        role
        email
        id
      }
    }
  }
`
export const SEND_PENDING_ADVISORY_TERMS_MAIL = gql`
  mutation sendTermsAndServiceMail($data: sendTermsAndServiceMailInput) {
    sendTermsAndServiceMail(data: $data)
  }
`

export const UPDATE_BOOK_ADVISORY = gql`
  mutation UpdateBookAdvisory(
    $where: BookAdvisoryWhereInput!
    $data: BookAdvisoryInput
  ) {
    updateBookAdvisory(where: $where, data: $data) {
      id
      userId
      advisoryDate
      isAdvisoryBooked
      clientId {
        firstName
        lastName
        clientCode
        id
        email
        userName
      }
      isClientLogin
      isClientAcceptAdvisory
      advisorySendDateAndTime
      createdAt
      updatedAt
      advisoryAcceptDateAndTime
      isTermAndServiceAccepted
      advisorySendType
      isCancelAdvisory
      advisoryType
      paymentType
      amcAmount
      isPendingAdvisoryAccepted
      advisorySubmittedUser {
        firstName
        lastName
        role
        email
        id
      }
      advisoryCancelByUser {
        firstName
        lastName
        role
        email
        id
      }
      advisoryUpdateByUser {
        firstName
        lastName
        role
        email
        id
      }
    }
  }
`

export const GENERATE_ADVISORY = gql`
  mutation Mutation($data: generateNewAdvisoryData) {
    generateNewAdvisory(data: $data)
  }
`
