import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import DownloadReport from '../DownloadReport'
import Loader from 'components/loaders/Loader'
import PerformanceTable from './PerformanceTable'
import { GET_PERFORMANCE_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const { state: { currentUser: { clientCode } } } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_PERFORMANCE_REPORT, { variables: { where: { clientCode, date: null } } })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spPerformanceReport')) return <NoData />
  const { spPerformanceReport: [spPerformanceReport] } = data

  return (
    <>
      <DownloadReport data={spPerformanceReport.filter((report, key) => key !== 0)} fileName='performance-report' id='PERFORMANCE-REPORT-PDF' />
      <div className='report-table-wrapper' id='PERFORMANCE-REPORT-PDF'>
        <PerformanceTable reportData={spPerformanceReport.filter((report, key) => key !== 0)} />
      </div>
    </>
  )
}
