import gql from 'graphql-tag'

export const CREATE_SECTION = gql`
mutation createSection($data: SectionCreateInput){
  createSection(data: $data) {
    id
  }
}
`

export const UPDATE_SECTION = gql`
mutation updateSection($data: SectionUpdateInput, $id: ID!){
  updateSection(data: $data, where: { id: $id }){
    id
  }
}
`

export const DELETE_SECTION = gql`
mutation deleteSection($id: ID!){
  deleteSection(where: { id: $id }) {
    id
  }
}
`

export const CREATE_QUESTION = gql`
mutation createQuestion($data: QuestionCreateInput){
  createQuestion(data: $data) {
    id
  }
}
`

export const DELETE_QUESTION = gql`
mutation deleteQuestion($id: ID!){
  deleteQuestion(where: { id: $id }) {
    id
  }
}
`
export const UPDATE_QUESTION = gql`
mutation updateQuestion($data: QuestionUpdateInput, $id: ID!){
  updateQuestion(data: $data, where: { id: $id }){
    id
  }
}
`

export const DELETE_OPTION = gql`
mutation deleteOption($id: ID!){
  deleteOption(where:{id:$id}){
    id
  }
}
`
