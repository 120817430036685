import { get, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Form, Row, Col, Select, Button } from 'antd'

import { DownloadOutlined } from '@ant-design/icons'
import '../index.css'
import { getName } from 'utils/User'
import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import {
  GET_CAPITAL_GAIN,
  GET_FINANCIAL_YEAR,
  GET_TAXATION_REPORT_PDF,
} from '../graphql/Queries'
import client from 'apollo'
import './index.css'

const { Option } = Select

export default function IltsTaxationReport() {
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({})
  const [selectedFinancialYear, setSelectedFinancialYear] = useState({})

  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_CAPITAL_GAIN, {
    variables: filters,
    fetchPolicy: 'network-only',
  })
  const { data: financialYearData, loading: financialYearLoading } = useQuery(
    GET_FINANCIAL_YEAR,
    { fetchPolicy: 'network-only' }
  )

  const { data: allClient, loading: userLoading } = useQuery(
    GET_CLIENT_USERS_FOR_STAFF,
    {
      variables: { where: {}, limit: 10000 },
    }
  )

  let profitLossData = []
  let descriptionData = []
  let finalProfitLoss, finalFuture, expenses, totalProfitLoss

  if (!loading && get(data, 'capitalGain')) {
    profitLossData = data.capitalGain.filter((entry) =>
      entry.hasOwnProperty('Particular')
    )
    descriptionData = data.capitalGain.filter((entry) =>
      entry.hasOwnProperty('Description')
    )
    const profitLoss = profitLossData?.filter(
      (item) => item?.Particular === 'PROFIT/LOSS'
    )

    finalProfitLoss = profitLoss[0]?.CALL + profitLoss[0]?.PUT
    finalFuture = profitLoss[0]?.FUTURE
    expenses = descriptionData?.find(
      (item) => item?.Description === 'Total'
    )?.Value
    totalProfitLoss = finalProfitLoss + expenses
  }

  const onFinish = (values) => {
    const { clientCode, selectYear } = values
    const financialYear = financialYearData.getFinancialYear.find(
      (f) => f.FA_YEAR_ID === selectYear
    )
    setSelectedFinancialYear(financialYear)
    const openingDate = financialYear.OPENING_DATE
    const startDate = financialYear.START_DATE
    const endDate = financialYear.END_DATE
    setFilters({
      where: {
        openingDate,
        clientCode,
        startDate,
        endDate,
      },
    })
  }

  const downloadTaxationReport = () => {
    client
      .query({
        query: GET_TAXATION_REPORT_PDF,
        variables: { ...filters },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        window.open(res?.data?.getTaxationReportPdf, '_blank')
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      <Row gutter={16}>
        <Col span={24} className='taxation_report_main_div'>
          <p className='taxation_report_main_div_header'>
            F&O Derivative P&L Report
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Form form={form} onFinish={onFinish} name='advanced_search'>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  name='clientCode'
                  label='Select Users'
                  rules={[{ required: true, message: 'Please select users!' }]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={userLoading}
                    optionFilterProp='children'
                    style={{ width: '100%' }}
                    placeholder='Please select users'
                    filterOption={(input, option) =>
                      (option?.children[0] ?? '')
                        .toLowerCase()
                        .includes(input) ||
                      (option?.children[2] ?? '').toLowerCase().includes(input)
                    }
                  >
                    {!userLoading &&
                      allClient &&
                      allClient?.getClientUsersForStaff?.users.map(
                        (user, key) => {
                          return (
                            <Option
                              key={key}
                              value={user.clientCode}
                              title={getName(user)}
                            >
                              {user.clientCode}-{getName(user)}
                            </Option>
                          )
                        }
                      )}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={7}
                lg={7}
                xl={7}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  name='selectYear'
                  label='Select Year'
                  rules={[{ required: true, message: 'Please select year!' }]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={financialYearLoading}
                    style={{ width: '100%' }}
                    placeholder='Select Year'
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {!financialYearLoading &&
                      financialYearData &&
                      financialYearData.getFinancialYear.map((financial) => {
                        return (
                          <Option value={financial.FA_YEAR_ID}>
                            {financial.FA_YEAR}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <Button type='primary' htmlType='submit'>
                  View
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFilters({})
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Page404 />
      ) : !get(data, 'capitalGain') || isEmpty(filters) ? (
        <NoData />
      ) : (
        <div className='taxation_report_display'>
          <Row gutter={16}>
            <Col span={24}>
              <p className='taxation_report_header'>
                Summary of F&O Derivative P&L Report{' '}
                {selectedFinancialYear?.START_DATE} To{' '}
                {selectedFinancialYear?.END_DATE}
              </p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}
              className='taxation_report_main_div'
            >
              <div
                className='taxation_report_div_1'
                style={{ border: '1px solid #000', marginLeft: '10px' }}
              >
                <div className='taxation_report_div_1_text'>
                  <span> Profit(+) / Loss(-) In Futures</span>
                </div>
                <div className='taxation_report_div_1_value'>
                  <span>{parseFloat(finalFuture).toFixed()}</span>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={2} lg={2} xl={2}></Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}
              className='taxation_report_main_div'
            >
              <div className='taxation_report_div_2'>
                <div className='taxation_report_div_1_text'>
                  <span> Profit(+) / Loss(-) In Options</span>
                </div>
                <div className='taxation_report_div_1_value'>
                  <span>{parseFloat(finalProfitLoss).toFixed()}</span>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}
              className='taxation_report_main_div'
            >
              <div className='taxation_report_div_3'>
                <div className='taxation_report_div_1_text'>
                  <span> Expenses</span>
                </div>
                <div className='taxation_report_div_1_value'>
                  <span>{parseFloat(expenses).toFixed()}</span>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={2} lg={2} xl={2}></Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}
              className='taxation_report_main_div'
            >
              <div className='taxation_report_div_4'>
                <div className='taxation_report_div_1_text'>
                  <span>Total Profit(+) / Loss(-)</span>
                </div>
                <div className='taxation_report_div_1_value'>
                  <span>{parseFloat(totalProfitLoss).toFixed()}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={16} className='taxation_report_download_div'>
            <Col span={24}>
              <div
                className='taxation_report_download_div_1'
                onClick={downloadTaxationReport}
              >
                <span className='taxation_report_download_link'>
                  <DownloadOutlined /> Download PDF F&O Derivative P&L Report
                </span>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}
