import React, { useContext, useState } from 'react'
import { Form, Input, Select, Button, Checkbox, Row, Col, Tooltip } from 'antd'
import { get, isEmpty } from 'lodash'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import client from 'apollo'

import './verification.css'
import {
  CHECK_EXIST_CLIENT_CODE,
  GET_USERS_FOR_CLIENT,
} from 'modules/Users/graphql/Queries'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import { COMPLETE_STEP, FETCH_CVL_STATUS } from './graphql/Mutations'
import ClientProfileModel1 from './Model/ClientProfile1'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import { handleInputChange } from 'utils/utilities'

const FormItem = Form.Item
const { Option } = Select

export default function ({ next, reEvaluate }) {
  const [form] = Form.useForm()
  const [showFormModal, setShowFormModal] = useState(false)
  const [formValue, setFormValue] = useState({})
  const [dataEmail, setDataEmail] = useState(true)
  const [reEvaluateValue, setReEvaluateValue] = useState(reEvaluate)
  const [loadings, setLoadings] = useState(false)
  const [clientCodeExist, setClientCodeExist] = useState('')
  const { dispatch, state } = useContext(AppContext)
  const {
    firstName,
    lastName,
    userName,
    email,
    createdBy,
    phone,
    panCardNo,
    clientCode,
    loginType,
  } = state.currentUser

  const { data, loading, error } = useQuery(GET_USERS_FOR_CLIENT, {
    fetchPolicy: 'network-only',
  })

  const { data: existClientCode, loading: clientCodeLoading } = useQuery(
    CHECK_EXIST_CLIENT_CODE,
    {
      variables: { where: { clientCode: clientCodeExist } },
      fetchPolicy: 'network-only',
    }
  )

  if (error) return <Page404 error={error} />

  let getUsers = []
  if (!loading && get(data, 'getUsersForClient.users')) {
    getUsers = data.getUsersForClient.users
  }

  const onFinish = (values) => {
    values.clientEmail = values.clientEmail.toLowerCase()
    values.firstName =
      values.firstName.trim().charAt(0).toUpperCase() +
      values.firstName.trim().slice(1).toLowerCase()
    values.lastName =
      values.lastName.trim().charAt(0).toUpperCase() +
      values.lastName.trim().slice(1).toLowerCase()
    if (reEvaluateValue === true) {
      onNext()
    } else {
      setFormValue(values)
      setShowFormModal(true)
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  }

  const prefixSelector = (
    <Form.Item name='prefixMobile' noStyle>
      <Select disabled>
        <Option value='91'>+91</Option>
      </Select>
    </Form.Item>
  )

  let ReferenceBy = ''
  if (createdBy) {
    ReferenceBy = createdBy.id
  }

  const handleHideFormModal = () => {
    setShowFormModal(false)
  }

  const editAccessFunc = () => {
    setReEvaluateValue(false)
  }

  const onNext = () => {
    setLoadings(true)
    const { id } = state.currentUser

    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id,
          data: {
            completedSteps: 'CLIENT_PROFILE_1',
          },
        },
      })
      .then((res) => {
        client.mutate({ mutation: FETCH_CVL_STATUS })
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'CLIENT_PROFILE_1',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        openNotification('success', 'Client Basic Details Updated Successfully')
        setLoadings(false)
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadings(false))
  }

  return (
    <>
      <Row>
        <Col span={22} style={{ textAlign: 'center' }}>
          <h1>Client Basic Details</h1>
        </Col>
        <Col span={2} style={{ textAlign: 'end' }}>
          {reEvaluateValue && (
            <Button type='primary' onClick={editAccessFunc}>
              Edit
            </Button>
          )}
        </Col>
      </Row>
      <div className='gx-app-login-wrap title-verification'>
        <CustomMeta title='Client Profile 1' />
        <div className='gx-app-login-content verification'>
          <Form
            {...formItemLayout}
            form={form}
            name='register'
            onFinish={onFinish}
            initialValues={{
              prefixMobile: '91',
              firstName,
              lastName,
              userName,
              clientCode,
              email,
              clientEmail: email || '',
              check: true,
              referenceBy: ReferenceBy || undefined,
              phone,
              panCardNo,
            }}
            labelAlign='left'
            scrollToFirstError
          >
            <div>
              <FormItem
                name='firstName'
                label='FirstName'
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/\b([A-Za-z]+[ ]*)+/),
                    message: 'First Name is Required',
                  },
                ]}
              >
                <Input
                  placeholder='FirstName'
                  autoFocus={true}
                  style={{ textTransform: 'capitalize' }}
                  disabled={!reEvaluateValue ? false : true}
                  onChange={(e) => handleInputChange(form, 'firstName', e)}
                />
              </FormItem>
              <FormItem
                name='lastName'
                label='LastName'
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/\b([A-Za-z]+[ ]*)+/),
                    message: 'Last Name is Required',
                  },
                ]}
              >
                <Input
                  placeholder='LastName'
                  style={{ textTransform: 'capitalize' }}
                  disabled={!reEvaluateValue ? false : true}
                  onChange={(e) => handleInputChange(form, 'lastName', e)}
                />
              </FormItem>
              <span style={{ color: '#ff4d4f', fontSize: '14px' }}>
                {!clientCodeLoading &&
                !existClientCode?.checkExistClientCode &&
                !isEmpty(clientCodeExist)
                  ? 'client code already exist'
                  : ''}
              </span>
              <FormItem
                name='clientCode'
                initialValues={clientCode}
                label={
                  <span>
                    Client Code&nbsp;{' '}
                    <Tooltip
                      title='Input the trading
                account code where you intend to take the trades for Index Long Term
                Strategy. Not sure?? Then leave the field blank'
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message:
                      !clientCodeLoading &&
                      !existClientCode?.checkExistClientCode &&
                      !isEmpty(clientCodeExist)
                        ? 'client code already exist'
                        : '',
                  },
                ]}
              >
                <Input
                  placeholder='Client Code'
                  disabled={!reEvaluateValue ? false : true}
                  onChange={(e) => setClientCodeExist(e.target.value)}
                />
              </FormItem>
              {/* <span style={{ color: '#ff4d4f', fontSize: '14px' }}>
                {!clientCodeLoading &&
                !existClientCode?.checkExistClientCode &&
                !isEmpty(clientCodeExist)
                  ? 'client code already exist'
                  : ''}
              </span> */}

              <FormItem
                name='userName'
                label='User Name'
                rules={[{ required: true }]}
              >
                <Input placeholder='User Name' disabled />
              </FormItem>

              <FormItem
                name='email'
                label='E-mail'
                rules={[
                  { type: 'email', message: 'The input is not valid E-mail!' },
                  { required: true, message: 'Please input your E-mail!' },
                ]}
              >
                <Input placeholder='Email' disabled={!email ? false : true} />
              </FormItem>

              <FormItem
                name='check'
                valuePropName='checked'
                className='checkedEmail'
                rules={[
                  ({ getFieldValue, setFieldsValue }) => ({
                    validator(_, value) {
                      if (value) {
                        setFieldsValue({ clientEmail: getFieldValue('email') })
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Checkbox disabled={!reEvaluateValue ? false : true}>
                  Same as Above E-mail
                </Checkbox>
              </FormItem>
              <br />
              <FormItem
                name='clientEmail'
                label='Client E-mail'
                rules={[
                  {
                    required: true,
                    message: 'Please input your E-mail!!!!',
                  },
                  ({ getFieldValue, setFieldsValue }) => ({
                    validator(_, value) {
                      const isSame = getFieldValue('email') === value
                      if (value) {
                        setFieldsValue({ check: isSame })
                      }
                      if (value) {
                        if (!isSame && dataEmail) {
                          setDataEmail(false)
                        } else if (isSame && !dataEmail) {
                          setDataEmail(true)
                        }
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  placeholder='Client Email'
                  style={{ textTransform: 'lowercase' }}
                  disabled={!email ? false : true}
                />
              </FormItem>

              {reEvaluate === true
                ? ' '
                : loginType !== 'GOOGLE' && (
                    <>
                      <FormItem
                        name='password'
                        label='Password'
                        rules={[
                          {
                            required: true,
                            message:
                              'password must be between 8 and 16 characters!',
                            min: 8,
                            max: 16,
                          },
                        ]}
                        hasFeedback
                      >
                        <Input
                          type='password'
                          placeholder='Password'
                          disabled={!reEvaluateValue ? false : true}
                        />
                      </FormItem>

                      <FormItem
                        name='passwordConfirm'
                        label='Confirm Password'
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve()
                              }
                              return Promise.reject(
                                new Error(
                                  'The two passwords that you entered do not match!'
                                )
                              )
                            },
                          }),
                        ]}
                      >
                        <Input
                          type='password'
                          placeholder='Password'
                          disabled={!reEvaluateValue ? false : true}
                        />
                      </FormItem>
                    </>
                  )}

              <FormItem
                name='phone'
                label='Mobile Number'
                rules={[
                  {
                    required: true,
                    message: 'Please input your mobile number!',
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{
                    width: '100%',
                  }}
                  placeholder='Mobile number'
                  maxLength='10'
                  disabled={phone ? true : false}
                />
              </FormItem>

              <FormItem
                name='panCardNo'
                label='PAN No'
                rules={[
                  {
                    required: true,
                    message: 'Please input your valid Pan number!',
                    pattern: new RegExp(
                      /[A-Za-z]{3}[ABCFGHLJPTFabcfghjpltf]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}/g
                    ),
                  },
                ]}
              >
                <Input
                  placeholder='PAN No'
                  maxLength='10'
                  style={{ textTransform: 'uppercase' }}
                  disabled={!reEvaluateValue ? false : true}
                />
              </FormItem>

              {/* <FormItem
              name="referenceBy"
              label="Reference By"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Reference By"
                style={{ width: '100%' }}
              >
                {getUsers.length && getUsers.map(user => {
                  return (
                    <Option value={user.id}>{`${user.firstName} ${user.lastName}`}</Option>
                  )
                })}
              </Select>
            </FormItem> */}
            </div>
            <div>
              {/* {!existClientCode?.checkExistClientCode &&
              !isEmpty(clientCodeExist) ? ( */}
              <Button
                disabled={
                  !existClientCode?.checkExistClientCode &&
                  !isEmpty(clientCodeExist)
                    ? true
                    : false
                }
                type='primary'
                htmlType='submit'
                loading={loadings}
              >
                Verify And Next
              </Button>
              {/* ) : (
                <Button type='primary' htmlType='submit' loading={loadings}>
                  Verify And Next
                </Button>
              )} */}
              {showFormModal && (
                <ClientProfileModel1
                  next={next}
                  formValue={formValue}
                  getUsers={getUsers}
                  visible={showFormModal}
                  prefixSelector={prefixSelector}
                  onCancel={() => handleHideFormModal()}
                  reEvaluate={reEvaluate}
                />
              )}
              {/* {otpModal &&
                <VerificationModal
                  visible={otpModal}
                  onNext={onNext}
                />
              } */}
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}
