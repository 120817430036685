import React, { useContext } from 'react'
import { Steps } from 'antd'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import { NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { UserAddOutlined } from '@ant-design/icons'

import { getName } from 'utils/User'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext'
import { GET_LEAD_USERS } from 'modules/Users/graphql/Queries'

const { Step } = Steps

export default function LeadUsersChain({ userId }) {
  const {
    state: { isAssociate },
  } = useContext(AppContext)
  const { data, loading } = useQuery(GET_LEAD_USERS, {
    variables: { userId: parseInt(userId) },
    fetchPolicy: 'network-only',
  })

  if (loading || !_get(data, 'getLeadUsers.length')) return <></>

  const leadUsers = _sortBy(_get(data, 'getLeadUsers'), ['level'])

  return (
    <Widget>
      <Steps
        type='navigation'
        current={_get(data, 'getLeadUsers.length')}
        className='lead-users-steps'
      >
        {_map(leadUsers, (user, key) => {
          const { email, otherMailIds } = user
          return (
            <Step
              key={key}
              icon={<UserAddOutlined />}
              description={`${
                user?.userType
                  ? user?.userType.toUpperCase()
                  : leadUsers?.length - 1 === key
                  ? 'ASSOCIATE'
                  : 'MASTER ASSOCIATE'
              } \n ${email} \n ${
                otherMailIds?.length ? `(${otherMailIds?.join(',')})` : ''
              }`}
              title={
                isAssociate ? (
                  getName(user)
                ) : (
                  <NavLink to={`/users/${user.id}`}>{getName(user)}</NavLink>
                )
              }
            />
          )
        })}
      </Steps>
    </Widget>
  )
}
