import { Table } from 'antd'
import React from 'react'
// import { Table } from 'antd'
import { NavLink } from 'react-router-dom'
import { convertNumberToCommaSeparate } from 'utils/utilities'

export default function ({ reportData, clientData }) {
  const { ClientCode, ClientName } = clientData
  const columns = [
    {
      title: (
        <NavLink
          to='/reports/position-eq'
          className='reports-position-name'
          style={{
            color: '#000',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '20px',
          }}
        >
          Stock Holding Report
        </NavLink>
      ),
      className: 'report-title main-title',
      children: [
        {
          title: (
            <div className='hidden-client-data'>
              Client Code:{' '}
              <span className='hidden-client-data'>{ClientCode}</span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Script',
              dataIndex: 'Script',
              key: 'Script',
              width: 140,
              className: 'report-text',
            },
            {
              title: 'Quantity',
              dataIndex: 'TotalQty',
              key: 'TotalQty',
              width: 110,
              className: 'report-numeric',
              render: (record) => convertNumberToCommaSeparate(record),
            },
            {
              title: 'Trade Rate',
              dataIndex: 'TradedRate',
              key: 'TradedRate',
              width: 110,
              className: 'report-numeric',
              render: (record) => convertNumberToCommaSeparate(record, true),
            },
          ],
        },
        {
          title: (
            <div className='hidden-client-data'>
              Client Name:{' '}
              <span className='hidden-client-data'>{ClientName}</span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'CMP',
              dataIndex: 'CMP',
              key: 'CMP',
              width: 110,
              className: 'report-numeric',
              render: (record) => convertNumberToCommaSeparate(record, true),
            },
            {
              title: 'Value',
              dataIndex: 'Value',
              key: 'Value',
              width: 110,
              className: 'report-numeric',
              render: (record) => convertNumberToCommaSeparate(record),
            },
            {
              title: 'Profit / Loss',
              dataIndex: 'Pnl',
              key: 'Pnl',
              width: 110,
              className: 'report-numeric',
              render: (record) => convertNumberToCommaSeparate(record),
            },
          ],
        },
      ],
    },
  ]

  const data = reportData.map((report, key) => {
    return { key, ...report }
  })

  return (
    <Table
      className='stock-options'
      dataSource={data}
      columns={columns}
      bordered
      // scroll={{ x: true, y: 240 }}
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    />
    // <>
    //   <div
    //     style={{ border: '2px solid #535353', borderRadius: '5px', padding: 6 }}
    //   >
    //     <NavLink
    //       to='/reports/position-eq'
    //       className='reports-position-name'
    //       style={{
    //         display: 'flex',
    //         justifyContent: 'center',
    //         fontSize: '20px',
    //       }}
    //     >
    //       <b>Stock Report</b>
    //     </NavLink>
    //   </div>
    //   &nbsp;
    //   <div style={{ display: 'flex', justifyContent: 'start', gap: '50px' }}>
    //     <div
    //       style={{
    //         display: 'flex',
    //         border: '2px solid #535353',
    //         width: '50%',
    //         borderRadius: '5px',
    //       }}
    //     >
    //       <div
    //         style={{
    //           borderRight: '2px solid #535353',
    //           padding: '10px',
    //           fontWeight: 'bold',
    //           fontSize: '16px',
    //         }}
    //       >
    //         Client Code
    //       </div>
    //       <div
    //         style={{ padding: '10px', fontWeight: 'bold', fontSize: '16px' }}
    //       >
    //         {ClientCode}
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         display: 'flex',
    //         border: '2px solid #535353',
    //         width: '50%',
    //         borderRadius: '5px',
    //         fontWeight: 'bold',
    //       }}
    //     >
    //       <div
    //         style={{
    //           borderRight: '2px solid #535353',
    //           padding: '10px',
    //           fontWeight: 'bold',
    //           fontSize: '16px',
    //         }}
    //       >
    //         Client Name
    //       </div>
    //       <div
    //         style={{ padding: '10px', fontSize: '16px', fontWeight: 'bold' }}
    //       >
    //         {ClientName}
    //       </div>
    //     </div>
    //   </div>
    //   &nbsp;
    //   <div class='main_stock'>
    //     <div class='stock_script'>
    //       <div style={{ width: '16.66%' }} class='stock_right'>
    //         Script
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right'
    //       >
    //         Quantity
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right'
    //       >
    //         Trade Rate
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right'
    //       >
    //         CMP
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right'
    //       >
    //         Value
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right_one'
    //       >
    //         Profit/Loss
    //       </div>
    //     </div>
    //     <div class='border_bottom'></div>

    //     {data?.slice(0, -1)?.map((item, key) => {
    //       return (
    //         <>
    //           <div class='stock_script'>
    //             <div style={{ width: '16.66%' }} class='stock_right_second'>
    //               {item.Script}
    //             </div>
    //             <div
    //               style={{ width: '16.66%', textAlign: 'right' }}
    //               class='stock_right_second'
    //             >
    //               {item.TotalQty}
    //             </div>
    //             <div
    //               style={{ width: '16.66%', textAlign: 'right' }}
    //               class='stock_right_second'
    //             >
    //               {item.TradedRate}
    //             </div>
    //             <div
    //               style={{ width: '16.66%', textAlign: 'right' }}
    //               class='stock_right_second'
    //             >
    //               {item.CMP}
    //             </div>
    //             <div
    //               style={{ width: '16.66%', textAlign: 'right' }}
    //               class='stock_right_second'
    //             >
    //               {item.Value}
    //             </div>
    //             <div
    //               style={{ width: '16.66%', textAlign: 'right' }}
    //               class='stock_right_one_new'
    //             >
    //               {item.Pnl}
    //             </div>
    //           </div>
    //           {data.length - 2 !== key ? (
    //             <div class='border_bottom' key={-1}></div>
    //           ) : (
    //             ''
    //           )}
    //         </>
    //       )
    //     })}
    //   </div>
    //   <br />
    //   <div class='main_stock'>
    //     <div class='stock_script'>
    //       <div style={{ width: '16.66%' }} class='stock_right_second'>
    //         <b>{data[data?.length - 1].Script}</b>
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right_second'
    //       >
    //         <b>{data[data?.length - 1].TotalQty}</b>
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right_second'
    //       >
    //         <b>{data[data?.length - 1].TradedRate}</b>
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right_second'
    //       >
    //         <b>{data[data?.length - 1].CMP}</b>
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right_second'
    //       >
    //         <b>{data[data?.length - 1].Value}</b>
    //       </div>
    //       <div
    //         style={{ width: '16.66%', textAlign: 'right' }}
    //         class='stock_right_one_new'
    //       >
    //         <b>{data[data?.length - 1].Pnl}</b>
    //       </div>
    //     </div>
    //   </div>
    // </>
  )
}
