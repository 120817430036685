import gql from 'graphql-tag'

export const CREATE_NEW_IMAGE = gql`
  mutation Mutation($file: Upload!) {
    createLoginImage(file: $file) {
      createdAt
      id
      imageName
      imageUrl
      updatedAt
    }
  }
`

export const DELETE_LOGIN_IMAGE = gql`
  mutation DeleteLoginImage($id: Int!) {
    deleteLoginImage(id: $id)
  }
`
