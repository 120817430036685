import { Modal, Descriptions, Checkbox, Button } from 'antd'
import React, { useContext, useState } from 'react'

import client from 'apollo'
import { AppContext } from 'AppContext'
import openNotification from 'utils/Notification'
import { COMPLETE_STEP, FETCH_CVL_STATUS } from '../graphql/Mutations'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
} from 'modules/Users/graphql/Mutations'
import VerificationModal from './VerificationModal'

const VerifyDetailsShow = ({
  visible,
  onCancel,
  formValue,
  next,
  reEvaluate,
}) => {
  const { dispatch, state } = useContext(AppContext)
  const [otpModal, setOtpModal] = useState(false)
  const [agree, setAgree] = useState(true)
  const [modalClose, setModalClose] = useState(true)
  const [loadings, setLoadings] = useState()
  const {
    firstName,
    lastName,
    userName,
    email,
    clientEmail,
    phone,
    panCardNo,
    // referenceBy,
    password,
    clientCode,
  } = formValue

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  const onNext = () => {
    setLoadings(true)
    const { id } = state.currentUser

    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id,
          data: {
            userName,
            firstName,
            lastName,
            email,
            clientEmail,
            phone,
            password,
            clientCode,
            // isPanCardIndividual,
            panCardNo: panCardNo.toUpperCase(),
            // createdById: parseInt(referenceBy),
            completedSteps: 'CLIENT_PROFILE_1',
          },
        },
      })
      .then((res) => {
        client.mutate({ mutation: FETCH_CVL_STATUS })
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'CLIENT_PROFILE_1',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        openNotification('success', 'Client Basic Details Updated Successfully')
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadings(false))
  }

  const onOk = () => {
    setLoadings(true)
    if (reEvaluate) {
      setOtpModal(true)
      client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
        openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
        setLoadings(false)
      })
      setModalClose(false)
    } else {
      onNext()
    }
  }

  return (
    <>
      {modalClose ? (
        <Modal
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          footer={
            <>
              <Button onClick={() => onCancel()}>Cancel</Button>
              <Button onClick={() => onOk()} type='primary' disabled={!agree}>
                Next
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          {' '}
          <Descriptions bordered>
            <Descriptions.Item
              label='FirstName'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {firstName}
            </Descriptions.Item>
            <Descriptions.Item label='LastName' span={3}>
              {lastName}
            </Descriptions.Item>
            <Descriptions.Item label='ClientCode' span={3}>
              {clientCode}
            </Descriptions.Item>
            <Descriptions.Item label='UserName' span={3}>
              {userName}
            </Descriptions.Item>
            <Descriptions.Item label='E-mail' span={3}>
              {email}
            </Descriptions.Item>
            <Descriptions.Item label='Client E-mail' span={3}>
              {clientEmail}
            </Descriptions.Item>
            <Descriptions.Item label='Mobile Number' span={3}>
              +91 {phone}
            </Descriptions.Item>
            <Descriptions.Item label='PAN No' span={3}>
              {panCardNo.toUpperCase()}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Reference By" span={3}>{getName(getUsers.find((u) => u.id === referenceBy))}</Descriptions.Item> */}
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} checked={agree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
        </Modal>
      ) : (
        <Modal
          style={{ display: 'none' }}
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          footer={
            <>
              <Button onClick={() => onCancel()}>Cancel</Button>
              <Button
                onClick={() => onOk()}
                type='primary'
                disabled={!agree}
                loading={loadings}
              >
                Next
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          <Descriptions bordered>
            <Descriptions.Item
              label='FirstName'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {firstName}
            </Descriptions.Item>
            <Descriptions.Item label='LastName' span={3}>
              {lastName}
            </Descriptions.Item>
            <Descriptions.Item label='ClientCode' span={3}>
              {clientCode}
            </Descriptions.Item>
            <Descriptions.Item label='UserName' span={3}>
              {userName}
            </Descriptions.Item>
            <Descriptions.Item label='E-mail' span={3}>
              {email}
            </Descriptions.Item>
            <Descriptions.Item label='Client E-mail' span={3}>
              {clientEmail}
            </Descriptions.Item>
            <Descriptions.Item label='Mobile Number' span={3}>
              +91 {phone}
            </Descriptions.Item>
            <Descriptions.Item label='PAN No' span={3}>
              {panCardNo.toUpperCase()}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Reference By" span={3}>{getName(getUsers.find((u) => u.id === referenceBy))}</Descriptions.Item> */}
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} checked={agree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
        </Modal>
      )}

      <VerificationModal
        visible={otpModal}
        onCancel={onCancel}
        onNext={onNext}
      />
    </>
  )
}

const ClientProfileModal1 = (props) => {
  return (
    <div>
      <VerifyDetailsShow {...props} />
    </div>
  )
}

export default ClientProfileModal1
