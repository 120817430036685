import gql from 'graphql-tag'

export const GET_BULK_PERFORMANCE_REPORT_SETTING = gql`
  query getBulkPerformanceReportSetting {
    getBulkPerformanceReportSetting {
      type
      performanceReportDate
      userType
      mailDraft
      createAt
      updatedAt
      autoReportDate
    }
  }
`

export const GENERATE_BULK_PERFORMANCE_REPORT = gql`
  query generateBulkPerformanceReport($where: generateBulkReport) {
    generateBulkPerformanceReport(where: $where)
  }
`
export const GET_ALL_CLIENT_BY_TYPE = gql`
  query getUserAccordingUserType($where: getUserAccordingType) {
    getUserAccordingUserType(where: $where) {
      id
      clientCode
      firstName
      lastName
      email
      phone
    }
  }
`
