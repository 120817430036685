import gql from 'graphql-tag'

export const GET_GROUPS = gql`
  query getFamilyGroups(
    $where: WhereFamilyGroupInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getFamilyGroups(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      groups {
        id
        name
        userIds
        users {
          id
          phone
          email
          firstName
          lastName
        }
        createdAt
      }
      count
    }
  }
`
