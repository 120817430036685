import React from 'react'
import { Col, Row, Tabs } from 'antd'

import Description from './Description'
import Widget from 'components/Widget'
import { userDetails } from 'utils/User'
import AboutItem from 'components/About/AboutItem'

const TabPane = Tabs.TabPane

export default function (props) {
  const { details, ticket } = props
  const { reason, status } = ticket
  const aboutList = userDetails(details)
  const isClosed = reason && status === 'CLOSED'

  return (
    <Widget title='Details' styleName='gx-card-tabs gx-card-tabs-right gx-card-profile'>
      <Tabs defaultActiveKey={isClosed ? 0 : 1}>
        {
          isClosed &&
          <TabPane tab='Summary' key='0'>
            <div className='gx-mb-2'>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Description {...ticket} />
                </Col>
              </Row>
            </div>
          </TabPane>
        }
        <TabPane tab='Overview' key='1'>
          <div className='gx-mb-2'>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Description {...ticket} Overview={true} />
              </Col>
            </Row>
          </div>
        </TabPane>
        <TabPane tab='User Details' key='2'>
          <div className='gx-mb-2'>
            <Row>
              {aboutList.map((about, index) =>
                <Col key={index} xl={6} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem {...about} />
                </Col>
              )}
            </Row>
          </div>
        </TabPane>
      </Tabs>
    </Widget>
  )
}
