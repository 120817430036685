import gql from 'graphql-tag'

export const CREATE_FLAG = gql`
mutation createFlag($data: FlagCreateInput){
  createFlag(data: $data) {
    id
  }
}
`

export const UPDATE_FLAG = gql`
mutation updateFlag($data: FlagUpdateInput, $where: FlagWhereUniqueInput!) {
  updateFlag(data: $data, where: $where) {
    id
  }
}
`

export const DELETE_FLAG = gql`
mutation deleteFlag($where: FlagWhereUniqueInput) {
  deleteFlag(where: $where) {
    id
  }
}
`
