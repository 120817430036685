import React, { useEffect, useState } from 'react'
import get from 'lodash/get'

import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import DownloadReport from 'modules/Reports/DownloadReport'
import NlvTrackerTable from 'modules/Reports/NLVTracker/NlvTrackerTable'
import { GET_NLV_TRACKER_REPORT } from 'modules/Reports/graphql/Queries'
import 'modules/Reports/index.css'
import client from 'apollo'
import NoData from 'components/NoData'

export default function (props) {
  const [selectPeriod, setSelectPeriod] = useState('Daily')
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState('')

  let {
    match: {
      params: { clientCode },
    },
  } = props

  useEffect(() => {
    setLoader(true)
    client
      .query({
        query: GET_NLV_TRACKER_REPORT,
        variables: { where: { clientCode, selectPeriod } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoader(false)
        setData(res?.data)
      })
      .catch((err) => {
        setError(err)
      })
  }, [clientCode, selectPeriod])

  if (error) return <Page404 error={error} />
  if (!get(data, 'spNLVTracker')) return <Loader />

  const {
    spNLVTracker: [spNLVTracker],
  } = data

  if (!spNLVTracker?.length) return <NoData />

  let spNLVTrackerFilterData = spNLVTracker

  const configuration = [
    {
      key: 'ReportDate',
      name: 'Report Date',
    },
    {
      key: 'NIFTY',
      name: 'NIFTY',
    },
    {
      key: 'NetAmt',
      name: 'Net Amount',
    },
    {
      key: 'NLV',
      name: 'NLV Net Liquidation Value',
    },
    {
      key: 'PnlWithInterest',
      name: 'P&L with Interest',
    },
    {
      key: 'PnlWithOutInterest',
      name: 'P&L without Interest',
    },
    {
      key: 'TotalExposure',
      name: 'Original Exposure',
    },
    {
      key: 'TotExposureWithNifty',
      name: 'Current Exposure',
    },
    {
      key: 'Interest',
      name: 'Interest per Day',
    },
    {
      key: 'CumInterest',
      name: 'Cumulative Interest',
    },
    {
      key: 'TotNLV',
      name: 'Total NLV',
    },
    {
      key: 'NIFTY%',
      name: 'NIFTY %',
    },
    {
      key: 'TotNLV%',
      name: 'Total NLV %',
    },
  ]

  function renameKeys(input, configuration) {
    return input.map((obj) => {
      const renamedObj = {}
      configuration.forEach((config) => {
        const { key, name } = config
        if (obj.hasOwnProperty(key)) {
          renamedObj[name] = obj[key]
        }
      })
      return renamedObj
    })
  }

  // console.log(renameKeys(spNLVTrackerFilterData, configuration))
  const spNLVTrackerFilterDataForCSV = renameKeys(
    spNLVTrackerFilterData,
    configuration
  )
  return (
    <>
      <DownloadReport
        isYearPeriod={true}
        data={spNLVTrackerFilterDataForCSV}
        fileName='NLV-Tracker-report'
        id='NLV-TRACKER-REPORT-DOWNLOAD-PDF'
        setSelectPeriod={setSelectPeriod}
        selectPeriod={selectPeriod}
        loadingSpData={loader}
        isComparisonReport={true}
        clientCode={clientCode}
      />
      <div className='report-table-wrapper' id='NLV-TRACKER-REPORT-PDF'>
        <NlvTrackerTable
          reportData={spNLVTrackerFilterData}
          loading={loader}
          id={'NLV-TRACKER-REPORT-PDF'}
        />
      </div>
      <div style={{ display: 'none' }}>
        <div
          className='report-table-wrapper'
          id='NLV-TRACKER-REPORT-DOWNLOAD-PDF'
        >
          <NlvTrackerTable
            reportData={spNLVTrackerFilterData}
            loading={loader}
            id={'NLV-TRACKER-REPORT-DOWNLOAD-PDF'}
          />
        </div>
      </div>
    </>
  )
}
