import React from 'react'
import { Modal, Form, Input, Button } from 'antd'
import Loader from 'components/loaders/Loader'

export const CreateFAQuestionModel = ({
  isModalOpen,
  handleCancel,
  questionId,
  onCreate,
  questionLoading,
  submitLoading,
  data,
}) => {
  const [form] = Form.useForm()

  const resetForm = () => {
    form.resetFields()
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => onCreate(values, resetForm))
      .catch((info) => console.log('Validate Failed:', info))
  }

  return (
    <>
      <Modal
        className='faqs-modals'
        visible={isModalOpen}
        onCancel={handleCancel}
        title={questionId ? 'Update Question' : 'Create Question'}
      >
        {questionLoading ? (
          <Loader />
        ) : (
          <Form
            form={form}
            layout='vertical'
            name='form_in_modal'
            initialValues={data || {}}
            onFinish={onFinish}
          >
            <Form.Item
              name='question'
              label='Question'
              rules={[
                {
                  required: true,
                  message: 'Please write your Question!',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder='Write your Question' />
            </Form.Item>
            <Form.Item
              name='answer'
              label='Answer'
              rules={[
                {
                  required: true,
                  message: 'Please write your answer!',
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea placeholder='Write your answer' rows={5} />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit' loading={submitLoading}>
                Submit Question
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
export default CreateFAQuestionModel
