import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Col } from 'antd'
import { Chart } from "react-google-charts";

import { GET_DASHBOARD_STRATEGY_INDEX_CLIENT } from 'modules/Dashboard/graphql/Queries'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext';

export default function ({ clientCode }) {
  const { state: { currentUser: { clientCode: userCode }, } } = useContext(AppContext)
  const { data, loading } = useQuery(GET_DASHBOARD_STRATEGY_INDEX_CLIENT, { variables: { where: { clientCode: (clientCode || userCode) } }, fetchPolicy: 'network-only' })
  if (loading) return <></>

  const options = {
    legend: { position: "none" },
  }

  return (
    <>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Widget>
          <h2 className='h4 gx-mb-3'>Absolute Return</h2>
          <Chart chartType="ColumnChart"
            options={options}
            data={data?.reportsGraph?.absoluteReturnGraph}
          />
        </Widget>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Widget>
          <h2 className='h4 gx-mb-3'>Annual Return</h2>
          <Chart chartType="ColumnChart" options={options} data={data?.reportsGraph?.annualReturnGraph} />
        </Widget>
      </Col>
    </>
  )
}
