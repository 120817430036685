import gql from 'graphql-tag'

export const CREATE_GROUP = gql`
  mutation createGroup($data: CreateGroupInput!) {
    createGroup(data: $data) {
      id
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation updateGroup($data: UpdateGroupInput!, $id: ID!) {
    updateGroup(data: $data, where: { id: $id }) {
      id
    }
  }
`

export const DELETE_GROUP = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(where: { id: $id }) {
      id
    }
  }
`

export const SEND_GROUP_NOTIFICATION = gql`
  mutation sendGroupNotification($data: SendGroupNotificationInput!) {
    sendGroupNotification(data: $data)
  }
`

export const UPDATE_FIELD_SELECTION = gql`
  mutation updateFieldSelection(
    $where: FieldSelectionWhereInput!
    $data: FieldSelectionUpdateInput!
  ) {
    updateFieldSelection(where: $where, data: $data) {
      id
      role
      firstName
      lastName
      userName
      completedSteps
      clientCode
      email
      phone
      remark
      action
      registrationDate
      investmentDate
      amcInPer
      clientConsentTime
      sourceOfLead
      associateIntroducer
      invoiceStatus
      riskProfileType
      discountStatus
      address
      panCardNo
      clientScore
      disableDate
      initialExposure
      annualIncome
      dateOfBirth
      agreementDataMailSent
      plan
      netAvailableExposure
      incomeProof
      hierarchy
      amcReceivedDate
      amcInvoiceNo
      amcRecentAmount
      isPanCardIndividual
    }
  }
`
