import gql from 'graphql-tag'

export const GET_ADVISORY_TIME = gql`
  query GetAdvisoryTime {
    getAdvisoryTime {
      id
      startTime
      endTime
    }
  }
`
