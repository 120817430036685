import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Unhedge Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'ClientCode',
        dataIndex: 'ClientCode',
        key: 'ClientCode',
        width: '20%',
        className: 'report-text'
      },
      {
        title: 'CE_QTY',
        dataIndex: 'CE_QTY',
        key: 'CE_QTY',
        width: '20%',
        className: 'report-numeric'
      },
      {
        title: 'PE_QTY',
        dataIndex: 'PE_QTY',
        key: 'PE_QTY',
        width: '20%',
        className: 'report-numeric'
      },
      {
        title: 'TotalEQ',
        dataIndex: 'TotalEQ',
        key: 'TotalEQ',
        width: '20%',
        className: 'report-numeric'
      },
      {
        title: 'UnhedgeQty',
        dataIndex: 'UnhedgeQty',
        key: 'UnhedgeQty',
        width: '20%',
        className: 'report-numeric'
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      columns={columns}
      dataSource={data}
      className='unhedge-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}
