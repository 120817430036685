import React from 'react'
import Spinner from '../components/loaders/Spinner'

export default function () {
  return (
    <div className='callback-wrapper'>
      <Spinner />
    </div>
  )
}
