import gql from 'graphql-tag'
export const CREATE_REMINDER = gql`
  mutation createReminder($input: createReminderInput) {
    createReminder(input: $input) {
      id
      name
      days
      scheduleType
      isWhatsAppSend
      status
      createdAt
      mandateTemplate
      nonMandateTemplate
    }
  }
`

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplateData($type: String, $htmlData: String) {
    updateTemplateData(type: $type, htmlData: $htmlData)
  }
`
export const UPDATE_REMINDER = gql`
  mutation updateReminder($input: updateReminderInput, $where: whereUpdate) {
    updateReminder(input: $input, where: $where) {
      id
      name
      days
      scheduleType
      isWhatsAppSend
      isDiscontinue
      status
      createdAt
      mandateTemplate
      nonMandateTemplate
    }
  }
`

export const DELETE_REMINDER = gql`
  mutation deleteReminder($input: deleteReminderInput) {
    deleteReminder(input: $input) {
      id
      name
      days
      scheduleType
      isWhatsAppSend
      status
      createdAt
      mandateTemplate
      nonMandateTemplate
    }
  }
`
export const SEND_REMINDER = gql`
mutation sendReminder($where: whereUpdate){
  sendReminder(where:$where)
}
`
