import React, { useState, useContext } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { useQuery } from '@apollo/react-hooks'
import { Select } from 'antd'
import _get from 'lodash/get'
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent'

import { GET_NLV_INDIVIDUAL_REPORT } from 'modules/Reports/graphql/Queries'
// import ChartLoader from 'components/loaders/ChartLoader'
import ChartError from 'components/Page404/ChartError'
import { exportGraphAsImage } from 'utils/Graph'
import Loader from 'components/loaders/Loader'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext'

const Option = Select.Option

export default function () {
  const {
    state: { selectedGroup },
  } = useContext(AppContext)
  const [selectedField, setSelectedField] = useState('NLV')
  const { data, loading, error } = useQuery(GET_NLV_INDIVIDUAL_REPORT, {
    variables: { groupId: selectedGroup },
    fetchPolicy: 'network-only',
  })

  // if (loading) return <ChartLoader title='NLV Individual Report' />
  if (error) return <ChartError title='NLV Individual Report' />
  // if (!_get(data, 'nlvIndividualReport.length')) return <ChartError title='NLV Individual Report' desc='No Data' />

  let nlvIndividualReport
  if (!loading && _get(data, 'nlvIndividualReport.length')) {
    nlvIndividualReport = data.nlvIndividualReport
  }

  const isEmpty = !_get(data, 'nlvIndividualReport.length')

  function handleChange(value) {
    setSelectedField(value)
  }

  const divId = 'export-graph-NLV-individual-chart'

  function handleExport(value) {
    exportGraphAsImage('NLV-Individual-Report', value, divId)
  }

  let renderData
  if (nlvIndividualReport) {
    renderData = nlvIndividualReport.map((reportData) => ({
      name: reportData.Client,
      ...reportData,
    }))
  }

  const CustomTooltip = (props) => {
    if (!props.active) {
      return null
    }
    const newPayload = [
      {
        name: 'Name',
        value: _get(props, 'payload[0].payload.Name'),
      },
      ...props.payload,
    ]
    return <DefaultTooltipContent {...props} payload={newPayload} />
  }

  return (
    <Widget
      title={
        <h2 className='h4 gx-text-capitalize gx-mb-0'>NLV Individual Report</h2>
      }
      styleName='gx-text-center'
    >
      <div className='ant-row-flex gx-px-4 gx-pt-4'>
        <div className='gx-ml-auto'>
          <Select
            className='gx-mb-2 gx-select-sm'
            defaultValue=''
            onChange={handleChange}
            style={{ minWidth: 170 }}
          >
            <Option value='' disabled>
              Select Field
            </Option>
            <Option value='StockPnl'>Stock PNL</Option>
            <Option value='POSPnl'>POSPnl</Option>
            <Option value='LedgerBalance'>Ledger Balance</Option>
            <Option value='PnlBooked'>PNL Booked</Option>
            <Option value='PnlunBooked'>PNL Unbooked</Option>
            <Option value='PnlWithOutInterest'>PNL Without Interest</Option>
            <Option value='InterestSaved'>Interest Saved</Option>
            <Option value='PnlAfterInterest'>PNL After Interest</Option>
            <Option value='ProffitPayOut'>Profit PayOut</Option>
            <Option value='TotalExposure'>Total Exposure</Option>
            <Option value='StockReceived'>Stock Received</Option>
            <Option value='NLV'>NLV</Option>
          </Select>
          &nbsp;
          <Select
            className='gx-mb-2 gx-select-sm'
            value='1'
            onChange={handleExport}
          >
            <Option value='1' disabled>
              Export As
            </Option>
            <Option value='png'>PNG</Option>
            <Option value='jpeg'>JPEG</Option>
          </Select>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            minHeight: 160,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loader />
        </div>
      ) : isEmpty ? (
        <h2
          className='h4 gx-text-capitalize gx-mb-0'
          style={{
            minHeight: 160,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          No Data
        </h2>
      ) : (
        <ResponsiveContainer width='100%' height={250} id={divId}>
          <BarChart data={renderData}>
            <XAxis dataKey='name' />
            <YAxis />
            <CartesianGrid strokeDasharray='3 3' />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey={selectedField} fill='#003366' />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Widget>
  )
}
