import React, { useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Input,
  Switch,
  Select,
  Col,
  Row,
  Tabs,
  Alert,
  Checkbox,
  DatePicker,
  Radio,
} from 'antd'
import ReactQuill from 'react-quill'
import moment from 'moment'
import client from 'apollo'
import { GET_REMINDER_USERS } from '../graphql/query'

const CreateReminder = ({
  visible,
  onCreate,
  onUpdate,
  onCancel,
  isEdit,
  selectedReminder,
  setTemplateHtmlMandate,
  setTemplateHtmlNonMandate,
  templateHtmlMandate,
  templateHtmlNonMandate,
  loadingReminder,
  memberName,
}) => {
  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
  }
  const [form] = Form.useForm()

  const [reminderWhatsappStatus, setReminderWhatsappStatus] = useState(
    selectedReminder?.isWhatsAppSend
  )
  const [isDiscontinueValue, setIsDiscontinue] = useState(
    selectedReminder?.isDiscontinue === false ? false : true
  )
  const [isDueDate, setIsDueDate] = useState(
    selectedReminder?.amcDueDate ?? null
  )
  const [selectMembers, setSelectMembers] = useState(
    selectedReminder?.amcUserSelectMember?.map((item) => {
      return +item
    }) ?? []
  )
  const [userType, setUserType] = useState(
    selectedReminder?.amcUserSelectedType ?? ''
  )
  const [totalReminderUser, setTotalReminderUser] = useState(0)

  useEffect(() => {
    if (isDueDate ) {
      client
        .query({
          query: GET_REMINDER_USERS,
          variables: {
            data: {
              amcDueDate: isDueDate,
              amcUserSelectMember: selectMembers,
              amcUserSelectedType: userType,
            },
          },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setTotalReminderUser(res.data.getReminderUsers)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [isDueDate, selectMembers, userType])

  const onChangeCheckBox = (checked) => {
    setReminderWhatsappStatus(checked === true ? true : false)
  }

  function resetForm() {
    form.resetFields()
  }

  const handleValueMandate = (content) => {
    setTemplateHtmlMandate(content)
  }

  const handleValueNonMandate = (content) => {
    setTemplateHtmlNonMandate(content)
  }

  const onChangeDiscontinue = (e) => {
    setIsDiscontinue(e.target.checked)
  }

  const onChangeDueDate = (e) => {
    setIsDueDate(e && moment(e).format())
  }

  const selectMember = (checkedValues) => {
    setSelectMembers(checkedValues)
  }

  const selectUserType = (e) => {
    setUserType(e.target.value)
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]
  return (
    <Modal
      className='feedback_form'
      maskClosable={false}
      visible={visible}
      title={isEdit ? 'Edit Reminder' : 'Add New Reminder'}
      okText={isEdit ? 'Update Reminder' : 'Add Reminder'}
      okButtonProps={{
        loading: loadingReminder,
      }}
      cancelText='Cancel'
      onCancel={() => {
        setTemplateHtmlMandate('')
        setTemplateHtmlNonMandate('')
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const data = {
              ...values,
              isWhatsAppSend: reminderWhatsappStatus,
              mandateTemplate: templateHtmlMandate,
              nonMandateTemplate: templateHtmlNonMandate,
              isDiscontinue: isDiscontinueValue,
              amcDueDate: isDueDate,
              amcUserSelectMember: selectMembers,
              amcUserSelectedType: userType,
            }
            if (isEdit) {
              onUpdate(data, resetForm)
            } else {
              onCreate(data, resetForm)
            }
          })
          .catch((info) => console.log('Validate Failed:', info))
      }}
    >
      <Form
        {...formItemLayout}
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
          ...selectedReminder,
          amcDueDate: selectedReminder?.amcDueDate
            ? moment(selectedReminder?.amcDueDate)
            : '',
          amcUserSelectMember: selectedReminder?.amcUserSelectMember?.map(
            (item) => {
              return +item
            }
          ),
        }}
      >
        <Row
          gutter={16}
          style={{ display: '-webkit-flex', flexDirection: 'unset' }}
        >
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='name'
              label='Reminder Name'
              rules={[
                { required: true, message: 'Please input your Reminder name!' },
              ]}
            >
              <Input placeholder='Name' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='days'
              label='Days'
              rules={[{ required: true, message: 'Please input days!' }]}
            >
              <Input type='number' placeholder='Days' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='scheduleType'
              label='Schedule Type'
              rules={[
                { required: true, message: 'Please select Schedule Type!' },
              ]}
            >
              <Select
                placeholder='Select Schedule Type'
                value={
                  selectedReminder?.scheduleType
                    ? selectedReminder?.scheduleType
                    : 'BEFORE'
                }
                options={[
                  {
                    value: 'BEFORE',
                    label: 'BEFORE',
                  },
                  {
                    value: 'AFTER',
                    label: 'AFTER',
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name='isWhatsAppSend' label='Whatsapp Notification'>
              <Switch
                checked={reminderWhatsappStatus}
                onChange={onChangeCheckBox}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name='amcUserSelectMember' label='Member Name'>
              <Checkbox.Group
                style={{
                  width: '100%',
                  display: 'grid',
                }}
                onChange={selectMember}
              >
                {memberName.map((item) => {
                  return (
                    <Checkbox
                      value={item.ID}
                      style={{
                        marginLeft: 0,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end',
                      }}
                    >
                      {item.MemberCode}
                    </Checkbox>
                  )
                })}
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name='amcUserSelectedType' label='User Type'>
              <Radio.Group onChange={selectUserType} value={userType}>
                <Radio value={'mandate'}>Mandate</Radio>
                <Radio value={'nonMandate'}>Non-Mandate</Radio>
                <Radio value={'all'}>Both</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name='amcDueDate' label='AMC Due Date'>
              <DatePicker format={'DD-MM-YYYY'} onChange={onChangeDueDate} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item name='isDiscontinue' className='reminder_checkbox'>
              <Checkbox
                onChange={onChangeDiscontinue}
                checked={isDiscontinueValue}
              >
                Discontinue - &nbsp;
                <span style={{ color: '#f9000aa6' }}>
                  It will automatically disable the client account after the
                  mail has been sent.
                </span>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {totalReminderUser.length > 0 && (
          <span style={{ fontSize: '16px' }}>
            <b>{totalReminderUser.length}</b> Amcs according to above filters
          </span>
        )}
        <Alert
          message='You can use {{First name}}, {{Amc Due Date}}, {{Invoice Link}} variables for the dynamic value. It is case sensitive'
          type='warning'
          showIcon
        />
        <Tabs defaultActiveKey='1'>
          {(userType === 'mandate' ||
            userType === 'all' ||
            userType === '') && (
            <Tabs.TabPane tab='Mandate Template' key='1'>
              <ReactQuill
                className='reminderQuill'
                theme='snow'
                value={
                  templateHtmlMandate
                    ? templateHtmlMandate
                    : selectedReminder?.mandateTemplate || ''
                }
                onChange={handleValueMandate}
                modules={modules}
                formats={formats}
              />
            </Tabs.TabPane>
          )}
          {(userType === 'nonMandate' ||
            userType === 'all' ||
            userType === '') && (
            <Tabs.TabPane tab='Non Mandate Template' key='2'>
              <ReactQuill
                className='reminderQuill'
                theme='snow'
                value={
                  templateHtmlNonMandate
                    ? templateHtmlNonMandate
                    : selectedReminder?.nonMandateTemplate || ''
                }
                onChange={handleValueNonMandate}
                modules={modules}
                formats={formats}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Form>
    </Modal>
  )
}

const CreateReminderModal = (props) => {
  return (
    <div>
      <CreateReminder {...props} />
    </div>
  )
}

export default CreateReminderModal
