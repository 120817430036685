/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Form, Menu, Row, Spin, Tag } from 'antd'

import { LOGIN_WITH_OTP_FOR_WEB } from 'auth/graphql/Mutations'
import { authWithOtp } from '.'

import './index.css'
import client from 'apollo'
import history from 'CustomHistory'
import Logo from 'assets/images/logo-tagline-new.png'
import { GET_IMAGES } from 'modules/LoginImages/graphql/Query'
import Page404 from 'components/Page404'
import { random } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import * as Auth from '.'

const FormItem = Form.Item

function LoginWithGoogle() {
  const [form] = Form.useForm()
  const [otp, setOtp] = useState()
  const [userId, setUserId] = useState()
  const [loader, setLoader] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [selectionData, setSelectionData] = useState([])
  const [newKycRegistration, setNewKycRegistration] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const isAuthenticated = Auth.isAuthenticated()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [isAuthenticated])

  const { loading, error, data } = useQuery(GET_IMAGES, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  useEffect(() => {
    const url = window.location.href
    setOtp(url.split('number=')[1])
  }, [])

  useEffect(() => {
    if (!loading) {
      setImageUrl(
        data.getAllLoginImages[random(0, data.getAllLoginImages?.length - 1)]
          .imageUrl
      )
    }
  }, [data, loading])

  useEffect(() => {
    if (otp) {
      setLoader(true)
      client
        .mutate({
          mutation: LOGIN_WITH_OTP_FOR_WEB,
          variables: { otp },
        })
        .then((res) => {
          setSelectionData(res.data.loginWithOtpForWeb)
          if (res.data.loginWithOtpForWeb?.length) {
            setSelectedUser(res.data.loginWithOtpForWeb[0])
            setUserId(res.data.loginWithOtpForWeb[0]?.id)
          }
          setLoader(false)
        })
        .catch((err) => {
          setLoader(false)
          console.log(err)
        })
    }
  }, [otp])

  const handleMenuClick = (client) => {
    setUserId(client?.id)
    setSelectedUser(client)
    if (typeof client === 'string' && client === 'New KYC Registration') {
      setNewKycRegistration(true)
    } else {
      setNewKycRegistration(false)
    }
  }

  function handleLogin() {
    setLoader(true)

    if (newKycRegistration === true) {
      client
        .mutate({
          mutation: LOGIN_WITH_OTP_FOR_WEB,
          variables: {
            otp: otp,
            newKycRegistration: newKycRegistration,
          },
        })
        .then((res) => {
          authWithOtp(res)
          setLoader(false)
        })
        .catch((err) => {
          setLoader(false)
          console.log(err)
          if (err.message.split(':')[1] !== ' Invalid OTP') {
            history.push('/login')
          }
        })
    } else {
      client
        .mutate({
          mutation: LOGIN_WITH_OTP_FOR_WEB,
          variables: { otp, signerUserId: userId.toString() },
        })
        .then((res) => {
          authWithOtp(res)
          setLoader(false)
        })
        .catch((err) => {
          setLoader(false)
          console.log(err)
          if (err.message.split(':')[1] !== ' Invalid OTP') {
            history.push('/login')
          }
        })
    }
  }

  const menu = (
    <Menu>
      {selectionData?.map((client) => {
        return (
          <Menu.Item
            className='main-tag-menu'
            key={client.id}
            onClick={() => handleMenuClick(client)}
          >
            {client.clientCode ? client.clientCode : 'N/A'}{' '}
            {client.firstName && '-'} {client.firstName} {client.lastName}{' '}
            {client.role && '-'} {client.role} &nbsp;&nbsp;
            {client.completedSteps !== 'DONE' ? (
              <Tag color='processing'>Pending KYC</Tag>
            ) : null}
          </Menu.Item>
        )
      })}
      <Menu.Item
        key={'New KYC Registration'}
        onClick={() => handleMenuClick('New KYC Registration')}
      >
        <b>{'New KYC Registration'}</b>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Row gutter={16}>
        <Col xs={0} sm={0} md={12} xl={14} lg={14}>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading ? (
              <Spin />
            ) : (
              <img
                src={imageUrl}
                style={{ height: 'auto', width: '100%' }}
                alt='img'
              />
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} xl={10} lg={10}>
          <div
            style={{
              display: 'grid',
              alignItems: 'center',
              height: '100vh',
              padding: '0 70px',
            }}
          >
            <div style={{ display: 'grid' }}>
              <div
                className='gx-app-logo'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '50px',
                }}
              >
                <img alt='Finideas' src={Logo} style={{ height: '100px' }} />
              </div>
              <div
                style={{
                  fontSize: '30px',
                  fontWeight: 500,
                  color: '#000',
                  marginBottom: '10px',
                }}
              >
                Welcome to Finideas
              </div>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '15px',
                  cursor: 'pointer',
                }}
                className='vector-img'
                onClick={() => {
                  history.push('/login')
                }}
              >
                <div>
                  <ArrowLeftOutlined />
                </div>
                <div style={{ marginLeft: '0.3rem' }}>Back</div>
              </div>
              <Form
                form={form}
                onFinish={() => handleLogin(otp)}
                className='gx-signin-form gx-form-row0'
              >
                <div className='user-dropdown'>
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    style={{ width: '100%' }}
                  >
                    <Button style={{ width: '100%' }}>
                      {selectedUser
                        ? typeof selectedUser === 'string'
                          ? 'New KYC Registration'
                          : `${selectedUser.clientCode} -
                                  ${selectedUser.firstName || ''} ${' '} ${
                              selectedUser.lastName || ''
                            }`
                        : 'Select User'}
                      <DownOutlined
                        style={{
                          fontSize: '18px',
                          float: 'right',
                          marginTop: '0.5rem',
                        }}
                      />
                    </Button>
                  </Dropdown>
                </div>

                <FormItem>
                  <Button
                    htmlType='submit'
                    type='primary'
                    style={{ width: '100%', fontSize: '16px' }}
                    loading={loader}
                  >
                    Submit
                  </Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </Col>

        <div>
          <div className='user-dropdown'>
            <Dropdown
              overlay={menu}
              trigger={['click']}
              style={{ width: '100%' }}
            >
              <Button style={{ width: '100%' }}> </Button>
            </Dropdown>
          </div>
        </div>
      </Row>
    </>
  )
}

export default LoginWithGoogle
