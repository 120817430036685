import { useQuery } from '@apollo/react-hooks'
import { Button, DatePicker, Input, PageHeader, Space, Table, Tag } from 'antd'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'
import Page404 from 'components/Page404'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { amcStatus, getName } from 'utils/User'
import {
  BULK_INVOICE_COUNTER,
  CUSTOM_CSV_DOWNLOAD,
  GET_ALL_DUE_AMCS,
  GET_BULK_DATA,
} from '../graphql/Queries'
import {
  SearchOutlined,
  LinkOutlined,
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import _isEmpty from 'lodash/isEmpty'
import states from 'utils/states.json'
import client from 'apollo'
import { GET_DUE_AMC } from 'modules/Users/graphql/Queries'
import { get } from 'lodash'
import { BULK_INVOICE } from '../graphql/Mutations'
import openNotification from 'utils/Notification'

const { RangePicker } = DatePicker

const selectedTypeDate = [
  { text: 'YES', value: 'true' },
  { text: 'NO', value: 'false' },
]

const BulkAmc = () => {
  const { state } = useContext(AppContext)
  const {
    isClient,
    currentUser: { id, role },
  } = state
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState(
    isClient ? { clientId: parseInt(id).toString() } : { status: 'PENDING' }
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [CSVLoading, setCSVLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [bulkInvoiceReport, setBulkInvoiceReport] = useState(false)
  const [selectAllInvoiceForBulk, setSelectAllInvoiceForBulk] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('PENDING')
  const [displayDataStatus, setDisplayDataStatus] = useState()
  const [loadingForBulk, setLoading] = useState(false)
  const [allBulkData, setAllBulkData] = useState([])

  let variable = {
    where: { ...filters, isBulkInvoicePage: true, isActiveUser: 'true' },
    sort,
    skip,
    limit,
  }
  if (filters.associateName) {
    delete variable.skip
    delete variable.limit
  }

  let { data, loading, error } = useQuery(GET_ALL_DUE_AMCS, {
    variables: {
      ...variable,
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: bulkInvoiceCounterData,
    loading: bulkInvoiceCounterLoading,
    error: bulkInvoiceCounterError,
  } = useQuery(BULK_INVOICE_COUNTER, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (
      skip === 0 &&
      limit === data?.getAllDueAmcs?.count &&
      !loading &&
      _isEmpty(selectedRow)
    ) {
      let newIds = data?.getAllDueAmcs?.amc?.map((amc) => amc.id)
      setSelectedRow(newIds)
      setSelectedData(data?.getAllDueAmcs?.amc)
    }
  }, [skip, limit, loading, data, selectedRow])

  const getData = (allAmcData) => {
    let data = allAmcData
    data = data.map((amc) => {
      let amcObj = amc
      let count = 1
      const getRole = (amcData) => {
        if (
          (amcData?.role === 'MASTER_ASSOCIATE' ||
            amcData?.role === 'ASSOCIATE') &&
          count === 1
        ) {
          amcObj.associateName = amcData?.firstName + ' ' + amcData?.lastName
          amcObj.associateEmail = amcData?.email
          amcObj.associatePhone = amcData?.phone
          amcObj.associateId = amcData?.id
        } else if (amcData?.role === 'SALES_EXECUTIVE') {
          amcObj.rmName = amcData?.firstName + ' ' + amcData?.lastName
          amcObj.rmEmail = amcData?.email
          amcObj.rmPhone = amcData?.phone
          amcObj.rmId = amcData?.id
        }

        if (amcData?.createdBy) {
          count += 1
          getRole(amcData?.createdBy)
        }
      }
      getRole(amc?.client?.createdBy)
      return { ...amc, ...amcObj }
    })
    return data
  }

  if (!loading && get(data, 'getAllDueAmcs.amc')) {
    data.getAllDueAmcs.amc = getData(data?.getAllDueAmcs?.amc)
  }

  useEffect(() => {
    if (selectAllInvoiceForBulk) {
      setLoading(true)
      const variables = {
        ...variable,
        limit: 500000,
      }
      client
        .query({
          query: GET_ALL_DUE_AMCS,
          variables: variables,
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setSelectedData(res.data.getAllDueAmcs.amc)
          setAllBulkData(getData(res?.data?.getAllDueAmcs?.amc))
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllInvoiceForBulk])

  useEffect(() => {
    if (allBulkData.length) {
      setSelectedRow(
        allBulkData.map((item) => {
          return item.id
        })
      )
    }

    if (selectedData.length) {
      setSelectedRow(
        selectedData.map((item) => {
          return item.id
        })
      )
    }
  }, [selectedData, allBulkData])

  if (error || bulkInvoiceCounterError) return <Page404 error={error} />

  let totalCount = data?.getAllDueAmcs?.count

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.amcStatusType) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = filter.amcStatusType
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.eMandateStatus) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['deduction'] = filter.eMandateStatus
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.amcGeneratedAt) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['amcGeneratedAt'] = filter.amcGeneratedAt
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = 'PENDING'
      // delete tempFilters.status
      tempFilters['amcGeneratedAt'] = ''
      delete tempFilters.amcGeneratedAt
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus={true}
            value={selectedKeys[0]}
            placeholder={`Search ${dataIndex}`}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
          />
          <Space>
            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, dataIndex)}
              size='small'
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleCsvDownload() {
    setCSVLoading(true)
    client
      .query({
        query: GET_DUE_AMC,
        variables: {
          where: { ...filters, isBulkInvoicePage: true, isActiveUser: 'true' },
          skip: 0,
          sort,
          limit: totalCount,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        const data = getData(res?.data?.getAllDueAmcs.amc)
        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data,

                fields: [
                  { value: 'client.clientCode', label: 'client Code' },
                  { value: 'client.firstName', label: 'Client First name' },
                  { value: 'client.lastName', label: 'Client Last name' },
                  // { value: 'client.email', label: 'Client Email' },
                  // { value: 'client.phone', label: 'Client Mobile No' },
                  {
                    value: 'associateName',
                    label: 'Associate Name',
                  },
                  // {
                  //   value: 'associateEmail',
                  //   label: 'Associate Email id',
                  // },
                  // {
                  //   value: 'associatePhone',
                  //   label: 'Associate Mobile No',
                  // },
                  // {
                  //   value: 'createdAt',
                  //   label: 'AMC Generated at',
                  // },
                  {
                    value: 'rmName',
                    label: 'RM Name',
                  },
                  // {
                  //   value: 'rmEmail',
                  //   label: 'RM Email id',
                  // },
                  // {
                  //   value: 'emPhone',
                  //   label: 'RM Mobile No',
                  // },
                  { value: 'status', label: 'Amc Status Type' },
                  // { value: 'nextDueDate', label: 'Next due Date' },
                  // { value: 'paidAt', label: 'Paid At Date' },
                  {
                    value: 'invoice.assetUnderAdvisory',
                    label: 'Current AUA',
                  },
                  {
                    value: 'nextAmc.totalExposure',
                    label: 'Next AMC Current AUA',
                  },
                  { value: 'invoice.amcInPer', label: 'Amc %' },
                  {
                    value: 'nextAmc.amcInPer',
                    label: 'Next AMC AMC %',
                  },
                  // { value: 'startDate', label: 'Amc Start Date' },
                  // { value: 'endDate', label: 'Amc End Date' },
                  // { value: 'nextAmc.dueDays', label: 'Due Days' },
                  { value: 'invoice.discountAmount ', label: 'Discount ' },
                  { value: 'invoice.commissionInPer', label: 'Commission %' },
                  {
                    value: 'nextAmc.commissionInPer',
                    label: 'Next AMC Commission %',
                  },
                  {
                    value: 'invoice.grossTotalAmount',
                    label: 'Total Gross Value',
                  },
                  {
                    value: 'nextAmc.grossTotalAmount',
                    label: 'Next AMC Total Gross Value',
                  },
                  { value: 'invoice.igst18InPer', label: 'GST 18%' },
                  {
                    value: 'nextAmc.igst18InPer',
                    label: 'Next AMC GST 18%',
                  },
                  {
                    value: 'invoice.totalNetAmount',
                    label: 'Final Net Amount',
                  },
                  {
                    value: 'nextAmc.totalNetAmount',
                    label: 'Next AMC Final Net Amount',
                  },
                  // { value: 'amcQty', label: 'Quantity' },
                  // { value: 'nextAmc.amcPeriod', label: 'Amc period' },
                  // { value: 'client.address', label: 'Client Address' },
                  // { value: 'client.stateId', label: 'State' },
                  { value: 'nextAmc.mandateStatus', label: 'Emadate Status' },
                  // { value: 'invoicePdf', label: 'Invoice Pdf' },
                ],
                fileName: 'AMCs',
              },
            },
          })
          .then(
            (res) =>
              get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCSVLoading(false)
          })
      })
  }

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectAllInvoiceForBulk(false)
      setSelectedData(selectedRows)
      setSelectedRow(selectedRowKeys)
    },
    onSelectAll: (record) => {
      setSelectAllInvoiceForBulk(true)
      setSelectedStatus(filters.status)
    },

    onSelect: (record) => {
      const isTrue = selectedRow.findIndex((i) => i === record.id)
      if (isTrue > -1) {
        const newValues = selectedRow.filter((i) => i !== record.id)
        setSelectedRow(newValues)
      } else {
        setSelectedRow((oldData) => [...oldData, record.id])
        // setSelectedData((oldData) => [...oldData, record])
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  function handleBulkImport() {
    setBulkInvoiceReport(true)
    let sendBulkDataArray = []

    selectedData.forEach((item) => {
      sendBulkDataArray.push({
        amcId: +item?.id,
        clientId: +item?.client?.id,
        clientType: 'CLIENT_CODE',
        deduction: item?.client?.mandateId ? 'E_MANDATE' : 'NON_E_MANDATE',
        amcType: item?.invoice?.amcType,
        clientName: item?.client?.firstName,
        clientCode: item?.client?.clientCode,
        emailId: item?.client?.email,
        address: item?.client?.address,
        completedSteps: item?.client?.completedSteps,
        state: states
          .filter((state) => state?.id === parseInt(item?.invoice?.state))[0]
          ?.id.toString(),

        discountInPer: item?.invoice?.discountInPer,
        commissionInPer: item?.invoice?.commissionInPer
          ? item?.invoice?.commissionInPer
          : 0,
        quantity: item.amcQty,
        userDiscount: item?.client?.discountStatus
          ? parseFloat(item?.client?.discountStatus)
          : 0,
      })
    })
    setBulkInvoiceReport(false)
    openNotification('success', 'Successfully started invoice generating.')
    client
      .mutate({
        mutation: BULK_INVOICE,
        variables: {
          data: !selectAllInvoiceForBulk
            ? JSON.parse(JSON.stringify(sendBulkDataArray))
            : [],
          where: {
            selectAllInvoiceForBulk,
            selectedStatus,
            status: displayDataStatus,
          },
        },
      })
      .then((res) => {
        setBulkInvoiceReport(false)
        setSelectAllInvoiceForBulk(false)
        setSelectedData([])
        setAllBulkData([])
        setSelectedRow([])
      })
      .catch((err) => {
        setBulkInvoiceReport(false)
        setSelectAllInvoiceForBulk(false)
      })
  }

  const handleGetBulkData = (data) => {
    setDisplayDataStatus(data)
    client
      .query({
        query: GET_BULK_DATA,
        variables: { where: { status: data } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        let amcIds = []
        res.data.getBulkInvoiceData.forEach((item) => {
          amcIds.push(item.amcId)
        })
        setFilters({ amcId: amcIds })
      })
  }

  const handleGetAllBulkData = () => {
    setFilters({ status: 'PENDING' })
  }

  const columns = [
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'clientCode',
      width: 150,
      sorter: (a, b) => a.clientCode - b.clientCode,
      render: (text, record) => (
        <NavLink to={`/clients/${parseInt(record?.client?.id)} `}>
          {record?.client?.clientCode || '-'}
        </NavLink>
      ),
      ...getColumnSearchProps('clientCodeSearch'),
    },
    {
      title: 'Client Name',
      dataIndex: 'client',
      key: 'client',
      width: 150,
      render: (client) => (client === 'Total' ? 'Total' : getName(client)),
      sorter: (a, b) => a.client - b.client,
      ...getColumnSearchProps('clientNameSearch'),
    },
    {
      title: 'Client Email',
      dataIndex: 'client',
      key: 'clientEmail',
      width: 150,
      render: (text, record) => (
        <NavLink to={`/clients/${record?.client?.id} `}>
          {record?.client?.email || '-'}
        </NavLink>
      ),
      sorter: (a, b) => a.client.email - b.client.email,
      ...getColumnSearchProps('clientEmailSearch'),
    },
    {
      title: 'Client Mobile No',
      dataIndex: 'client',
      key: 'clientPhone',
      width: 150,
      render: (text, record) => record?.client?.phone || '-',
      sorter: (a, b) => a.client.phone - b.client.phone,
    },
    {
      title: 'Associate Name',
      dataIndex: 'associateName',
      key: 'associateName',
      width: 150,
      render: (text, record) =>
        (
          <NavLink to={`/users/${record?.associateId} `}>
            {record?.associateName ? record?.associateName : '-'}
            {/* {record?.client?.createdBy &&
            (record?.client?.createdBy?.role === 'MASTER_ASSOCIATE' ||
              record?.client?.createdBy?.role === 'ASSOCIATE')
              ? `${record?.client?.createdBy?.firstName} ${record?.client?.createdBy?.lastName} `
              : '-'} */}
          </NavLink>
        ) || '-',
      sorter: (a, b) => a.associateName - b.associateName,
      ...getColumnSearchProps('associateName'),
    },
    {
      title: 'Associate Email id',
      dataIndex: 'client',
      key: 'associateEmailId',
      width: 150,

      render: (text, record) => record?.associateEmail || '-',
      sorter: (a, b) => a.associateEmail - b.associateEmail,
      ...getColumnSearchProps('associateEmail'),
    },
    {
      title: 'Associate Mobile No',
      dataIndex: 'client',
      key: 'associateMobileNo',
      width: 150,
      render: (text, record) => record?.associatePhone || '-',
      sorter: (a, b) => a.associatePhone - b.associatePhone,
    },
    {
      title: 'Amc Generated At',
      dataIndex: 'amcGeneratedAt',
      key: 'amcGeneratedAt',
      width: 150,
      render: (text, record) =>
        (record?.amcGeneratedAt &&
          moment(record?.amcGeneratedAt).format('Do MMM YYYY')) ||
        '-',
      sorter: (a, b) => console.log(a, b),
      filters: selectedTypeDate,
    },
    {
      title: 'RM Name',
      dataIndex: 'client',
      key: 'rmName',
      width: 150,
      render: (text, record) =>
        (
          <NavLink to={`/users/${record?.rmId} `}>
            {record?.rmName ? record?.rmName : '-'}
            {/* {record?.client?.createdBy?.createdBy
              ? `${record?.client?.createdBy?.createdBy?.firstName} ${record?.client?.createdBy?.createdBy?.lastName}`
              : '-'} */}
          </NavLink>
        ) || '-',
      sorter: (a, b) => a.rmName - b.rmName,
    },
    {
      title: 'RM Email id',
      dataIndex: 'client',
      key: 'rmEmailId',
      width: 150,
      render: (text, record) => record?.rmEmail || '-',
      sorter: (a, b) => a.rmEmail - b.rmEmail,
      filters: '',
    },
    {
      title: 'RM Mobile No',
      dataIndex: 'client',
      key: 'rmMobileNo',
      width: 150,
      render: (text, record) => record?.rmPhone || '-',
      sorter: (a, b) => a.rmPhone - b.rmPhone,
      filters: '',
    },
    {
      title: 'Amc Status Type',
      dataIndex: 'amcStatusType',
      key: 'amcStatusType',
      width: 150,
      render: (text, record) => record?.status,
      filters: amcStatus?.filter((item) => item?.text === 'Completed'),
    },
    {
      title: 'Next due Date',
      dataIndex: 'nextDueDate',
      key: 'nextDueDate',
      width: 150,
      render: (text, record) =>
        record?.nextDueDate
          ? moment(record?.nextDueDate).format('Do MMM YYYY')
          : '-',
      sorter: (a, b) => a.nextDueDate - b.nextDueDate,
      ...getColumnDateProps('nextDueDate'),
    },
    {
      title: 'Paid At Date',
      dataIndex: 'paidAt',
      key: 'paidAtDate',
      width: 150,
      render: (text, record) =>
        record?.paidAt ? moment(record?.paidAt).format('Do MMM YYYY') : '-',
      sorter: (a, b) => a.paidAtDate - b.paidAtDate,
      ...getColumnDateProps('paidAt'),
    },
    {
      title: 'Current AUA',
      dataIndex: 'client',
      key: 'currentAUA',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.assetUnderAdvisory) || '-'}
            {record?.nextAmc?.totalExposure && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.totalExposure})
              </p>
            )}
          </div>
        )
      },
      sorter: (a, b) =>
        a.client?.performance[0]?.TotalExposure -
        b.client?.performance[0]?.TotalExposure,
    },
    {
      title: 'Amc %',
      dataIndex: 'amcInPer',
      key: 'amcInPer',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record?.invoice?.amcInPer || '-'}
            {record?.nextAmc?.amcInPer && (
              <p style={{ color: 'orange' }}>({record?.nextAmc?.amcInPer})</p>
            )}
          </div>
        )
      },
    },
    {
      title: 'created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      render: (text, record) =>
        record.createdAt
          ? moment(record.createdAt).format('DD MMM YYYY, h:mm:ss a')
          : '-',
      sorter: (a, b) => a.createdAt - b.createdAt,
      ...getColumnDateProps('createdAt'),
    },
    {
      title: 'Amc Start Date',
      dataIndex: 'startDate',
      key: 'amcStartDate',
      width: 150,
      render: (text, record) =>
        moment(record?.startDate).format('Do MMM YYYY') || '-',
      sorter: (a, b) => a.amcStartDate - b.amcStartDate,
      ...getColumnDateProps('startDate'),
    },
    {
      title: 'Amc End Date',
      dataIndex: 'endDate',
      key: 'amcEndDate',
      width: 150,
      render: (text, record) =>
        moment(record?.endDate).format('Do MMM YYYY') || '-',
      sorter: (a, b) => a.amcEndDate - b.amcEndDate,
      ...getColumnDateProps('endDate'),
    },
    {
      title: 'Due Days',
      dataIndex: 'dueDays',
      key: 'dueDays',
      width: 150,
      render: (text, record) =>
        `${
          moment(record?.endDate, 'YYYY-MM-DD').diff(
            moment(record?.startDate, 'YYYY-MM-DD'),
            'days'
          ) + 1
        } Days`,
    },
    {
      title: 'Discount %',
      dataIndex: 'discount',
      key: 'discount',
      width: 150,
      render: (text, record) =>
        Math.round(record?.invoice?.discountInPer) || '-',
    },
    {
      title: 'Commission %',
      dataIndex: 'commission',
      key: 'commission',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.commissionInPer) || '-'}
            {record?.nextAmc?.commissionInPer > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.commissionInPer})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Total Gross Value',
      dataIndex: 'totalGrossValue',
      key: 'totalGrossValue',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.grossTotalAmount) || '-'}
            {record?.nextAmc?.grossTotalAmount > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.grossTotalAmount})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'GST 18%',
      dataIndex: 'gst18InPer',
      key: 'gst18InPer',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.igst18InPer) || '-'}
            {record?.nextAmc?.igst18InPer > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.igst18InPer})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Final Net Amount',
      dataIndex: 'finalNetAmount',
      key: 'finalNetAmount',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.totalNetAmount) || '-'}
            {record?.nextAmc?.totalNetAmount > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.totalNetAmount})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
      render: (text, record) => record?.amcQty || '-',
    },
    {
      title: 'Amc period',
      dataIndex: 'amcPeriod',
      key: 'amcPeriod',
      width: 150,
      render: (text, record) =>
        `${moment(record?.startDate).format('DD-MMM-YYYY')} to ${moment(
          record?.endDate
        ).format('DD-MMM-YYYY')}` || '-',
    },
    {
      title: 'Client Address',
      dataIndex: 'clientAddress',
      key: 'clientAddress',
      width: 150,
      render: (text, record) => record?.client?.address || '-',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 150,
      render: (text, record) =>
        states.filter((item) => item.id === +record?.client?.stateId)[0]?.name,
    },
    {
      title: 'E mandate Status',
      dataIndex: 'eMandateStatus',
      key: 'eMandateStatus',
      width: 150,
      render: (text, record) =>
        record.client.umrnNumber !== null && record.client.umrnNumber
          ? 'E_MANDATE'
          : 'NON_E_MANDATE',
    },
    {
      title: 'Invoice Pdf',
      key: 'invoicePdf',
      dataIndex: 'invoicePdf',
      width: 150,
      render: (text, record) => (
        <div style={{ textAlign: 'center' }}>
          {record?.invoice?.invoicePdf ? (
            <a
              href={`${record?.invoice?.invoicePdf}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkOutlined />
            </a>
          ) : (
            '-'
          )}
        </div>
      ),
    },
  ]
  return (
    <>
      <CustomMeta title='Invoice' />
      <PageHeader
        className='box'
        title='Bulk Amcs'
        extra={[
          <>
            {filters.amcId ? (
              <Button
                key='3'
                type='primary'
                style={{ cursor: 'pointer' }}
                onClick={() => handleGetAllBulkData()}
              >
                <ArrowLeftOutlined /> Back
              </Button>
            ) : (
              ''
            )}
            <Tag color='blue' icon={<MinusCircleOutlined />}>
              <span style={{ fontSize: '14px' }}>
                {' '}
                {`Total:
                ${bulkInvoiceCounterData?.getBulkInvoiceCounter?.total || 0}`}
              </span>
            </Tag>
            <Tag
              style={{ cursor: 'pointer' }}
              color='green'
              icon={<CheckCircleOutlined />}
              onClick={() => handleGetBulkData('success')}
            >
              {`Completed:
                    ${
                      bulkInvoiceCounterData?.getBulkInvoiceCounter
                        ?.completed || 0
                    }`}
            </Tag>
            <Tag
              style={{ cursor: 'pointer' }}
              color='red'
              icon={<CloseCircleOutlined />}
              onClick={() => handleGetBulkData('skipped')}
            >
              {`Skipped:
              ${bulkInvoiceCounterData?.getBulkInvoiceCounter?.skipped || 0}`}
            </Tag>
            <Tag
              color={`${
                bulkInvoiceCounterData?.getBulkInvoiceCounter?.status ===
                'completed'
                  ? 'green'
                  : 'blue'
              }`}
              icon={
                bulkInvoiceCounterData?.getBulkInvoiceCounter?.status ===
                'completed' ? (
                  <CheckCircleOutlined />
                ) : bulkInvoiceCounterData?.getBulkInvoiceCounter?.status ===
                  undefined ? (
                  <SyncOutlined />
                ) : (
                  <SyncOutlined spin />
                )
              }
            >
              <span
                style={{ textTransform: 'capitalize', fontSize: '14px' }}
              >{`Status:
                ${
                  bulkInvoiceCounterData?.getBulkInvoiceCounter?.status || ''
                }`}</span>
            </Tag>
            <Button
              key='1'
              type='primary'
              onClick={handleCsvDownload}
              loading={CSVLoading}
            >
              Export Csv
            </Button>
            {role === 'ADMIN' && (
              <Button
                key='2'
                type='primary'
                loading={bulkInvoiceReport}
                onClick={handleBulkImport}
                disabled={_isEmpty(selectedData) && _isEmpty(allBulkData)}
              >
                Bulk Invoice
              </Button>
            )}
          </>,
        ]}
      />

      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        loading={loading || bulkInvoiceCounterLoading || loadingForBulk}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={
          allBulkData.length !== 0
            ? allBulkData
            : data && data.getAllDueAmcs.amc
        }
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        pagination={
          allBulkData.length === 0
            ? {
                pageSize: limit,
                total: data?.getAllDueAmcs?.count,
                onChange: handlePagination,
                current: currentPage,
                onShowSizeChange: handlePageSizeChange,
              }
            : ''
        }
      />
    </>
  )
}

export default BulkAmc
