import React, { useState } from 'react'
import { Modal, Form, Input, Button, DatePicker } from 'antd'

import Loader from 'components/loaders/Loader'
import moment from 'moment'
import { isEmpty } from 'lodash'

const CreateNSEHoliday = ({
  isModalOpen,
  handleCancel,
  holidayId,
  onCreate,
  holidayLoading,
  modalLoading,
  data,
}) => {
  const [form] = Form.useForm()
  const [day, setDay] = useState(
    !isEmpty(data) ? moment(data?.date).format('dddd') : ''
  )
  const [date, setDate] = useState(data ? moment(data?.date) : '')

  const resetForm = () => {
    form.resetFields()
  }

  const onChange = (date, dateString) => {
    setDay(moment(date).format('dddd'))
    setDate(moment(dateString).format('YYYY-MM-DD'))
  }
  const onFinish = (values) => {
    const dataObj = {
      ...values,
      date: moment(date).format('YYYY-MM-DD'),
    }
    form
      .validateFields()
      .then(() => onCreate(dataObj, resetForm))
      .catch((info) => console.log('Validate Failed:', info))
  }
  return (
    <>
      <Modal
        className='faqs-modals'
        visible={isModalOpen}
        onCancel={handleCancel}
        title={holidayId ? 'Update Holiday' : 'Create Holiday'}
      >
        {holidayLoading ? (
          <Loader />
        ) : (
          <Form
            form={form}
            layout='vertical'
            name='form_in_modal'
            initialValues={
              !isEmpty(data)
                ? { title: data?.title, date: moment(data?.date) }
                : {}
            }
            onFinish={onFinish}
          >
            <Form.Item
              name='date'
              label='Date'
              rules={[
                {
                  required: true,
                  message: 'Please select date!',
                },
              ]}
            >
              <DatePicker onChange={onChange} />
            </Form.Item>
            <Form.Item label='Day'>
              <Input disabled value={day} placeholder='Select Day' />
            </Form.Item>
            <Form.Item
              name='title'
              label='Description'
              rules={[
                {
                  required: true,
                  message: 'Please write holiday description!',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder='Write holiday description' />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit' loading={modalLoading}>
                Submit Holiday
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
export default CreateNSEHoliday
