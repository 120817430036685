import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'antd'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from 'recharts'
import { QuestionOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import MoreDetails from './MoreDetails'
import ScenarioPendingAdvisoryIndex from './ScenarioPendingAdvisoryIndex'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import { uniq } from 'lodash'

const PendingAdvisoryIndex = ({
  loading,
  onFinish,
  type,
  onReject,
  advisoryData,
  advisoryDate,
  rejectLoading,
  data,
  graphData1,
  userData,
}) => {
  const [openMoreDetailsModal, setOpenMoreDetailsModal] = useState(false)
  const [range, setRange] = useState([])
  const [range1, setRange1] = useState([])

  const openModal = () => {
    setOpenMoreDetailsModal(!openMoreDetailsModal)
  }
  const closeModal = () => {
    setOpenMoreDetailsModal(false)
  }

  useEffect(() => {
    YAxisData(data)
    YAxisData1(graphData1)
  }, [data, graphData1])

  const YAxisData = (data) => {
    let array = []
    for (const d of data) {
      array.push(+d.pl, +d.nlv)
    }
    array = uniq(array)
    setRange([Math.min(...array) - 10000, Math.max(...array) + 10000])
  }

  const YAxisData1 = (data) => {
    let array = []
    for (const d of data) {
      array.push(+d.pl, +d.nlv)
    }
    array = uniq(array)
    setRange1([Math.min(...array) - 10000, Math.max(...array) + 10000])
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <div className='custom-tooltip' style={{ background: '#fff' }}>
          <p style={{ color: 'orange' }}>{label}</p>
          <p style={{ color: 'red' }}>{`P&L: ${convertNumberToCommaSeparate(
            +payload[0].value
          )}`}</p>
          <p style={{ color: 'green' }}>{`NLV: ${convertNumberToCommaSeparate(
            +payload[1].value
          )}`}</p>
        </div>
      )
    }

    return null
  }

  return (
    <>
      <div className='pending_advisory_main_div'>
        {type === 'Scenario' || type === 'Shifting' || type === 'Rollover' ? (
          <ScenarioPendingAdvisoryIndex
            loading={loading}
            onFinish={onFinish}
            data={advisoryData}
            onReject={onReject}
            rejectLoading={rejectLoading}
          />
        ) : (
          <Row gutter={16} className='pending_advisory_sub_div'>
            <Col
              lg={16}
              md={16}
              sm={24}
              xs={24}
              className='pending_advisory_div_column'
            >
              <div className='update_trade_table'>
                <Row
                  className='update_trade_table_row'
                  gutter={16}
                  style={{ fontWeight: 500 }}
                >
                  <Col
                    className='update_trade_table_script'
                    lg={12}
                    md={12}
                    xs={9}
                    sm={9}
                  >
                    Script
                  </Col>
                  <Col
                    className='update_trade_table_col update_trade_table_col_border'
                    span={4}
                  >
                    Trade
                  </Col>
                  <Col
                    className='update_trade_table_col'
                    lg={4}
                    md={4}
                    sm={5}
                    xs={5}
                    style={{ borderRight: '1px solid #000' }}
                  >
                    Quantity
                  </Col>
                  <Col className='update_trade_table_col' span={4}>
                    Price
                  </Col>
                </Row>
                {advisoryData?.currentAdvisoryData?.length &&
                  advisoryData?.currentAdvisoryData?.map((item, key) => {
                    const { Script, Trade, Quantity, TradePrice } = item
                    return (
                      <Row
                        className={
                          advisoryData?.currentAdvisoryData?.length - 1 === key
                            ? 'update_trade_table_row_new'
                            : 'update_trade_table_row'
                        }
                        gutter={16}
                      >
                        <Col
                          className='update_trade_table_script'
                          lg={12}
                          md={12}
                          xs={9}
                          sm={9}
                        >
                          {Script}
                        </Col>
                        <Col
                          className='update_trade_table_col update_trade_table_col_border'
                          span={4}
                        >
                          {Trade}
                        </Col>
                        <Col
                          className='update_trade_table_col'
                          lg={4}
                          md={4}
                          sm={5}
                          xs={5}
                          style={{ borderRight: '1px solid #000' }}
                        >
                          {Quantity}
                        </Col>
                        <Col className='update_trade_table_col' span={4}>
                          {TradePrice}
                        </Col>
                      </Row>
                    )
                  })}
              </div>
              <Row gutter={16} className='column_for_advisory_table'>
                <Col span={18}>
                  <Button
                    className='pending_advisory_accept_btn'
                    onClick={onFinish}
                    loading={loading}
                  >
                    Accept
                  </Button>
                  <Button
                    type='primary'
                    loading={rejectLoading}
                    onClick={onReject}
                  >
                    Reject
                  </Button>
                </Col>
                <Col span={6}>
                  <div className='question_icon'>
                    <div className='faqs-icon'>
                      <Link
                        to='/faqs'
                        className='nav-text'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <QuestionOutlined className='que_mark' />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              lg={8}
              md={8}
              sm={24}
              xs={24}
              className='scenario_analysis_div_1'
            >
              <div className='scenario_analysis_div'>
                <b className='pending_advisory_row_col_2'>Scenario Analysis</b>
                <p className='pending_advisory_row_col_2_text'>
                  As Warren Buffet says,"The best Investment you can make is an
                  Investment in yourself.. The more you learn, the more you
                  earn".
                </p>
                <p className='pending_advisory_row_col_2_para'>
                  So let's understand the scenario analysis of your position.
                  The below given attachment will show your indicative Profit
                  and Loss and your fund value at different levels of market.
                  Please note that the below data may change time to time. Feel
                  free to ask us if you have any questions on this.
                </p>
                {/* chart */}

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ResponsiveContainer width='100%' height={300}>
                    <LineChart
                      data={data}
                      margin={{ top: 5, right: 20, bottom: 5, left: 20 }}
                    >
                      <Tooltip content={<CustomTooltip />} />
                      <Legend
                        verticalAlign='top'
                        align='center'
                        height={36}
                        content={(props) => (
                          <div className='chart_legend'>
                            <div className='legend1' style={{ color: 'red' }}>
                              <div
                                className='legend_dot'
                                style={{ background: 'red' }}
                              ></div>
                              <span
                                // style={{ marginLeft: '7px' }}
                                className='legend_name'
                              >
                                Expected P&L at Trading Member
                              </span>
                            </div>
                            <div
                              className='legend1'
                              style={{ color: 'green', marginLeft: '7px' }}
                            >
                              <div
                                className='legend_dot'
                                style={{ background: 'green' }}
                              ></div>
                              <span
                                // style={{ marginLeft: '7px' }}
                                className='legend_name'
                              >
                                Expected NLV
                              </span>
                            </div>
                          </div>
                        )}
                      />
                      <Line
                        type='monotone'
                        dataKey='pl'
                        stroke='red'
                        dot={{ stroke: 'red', strokeWidth: 2, fill: 'white' }}
                        activeDot={{ r: 8 }}
                      />
                      <Line
                        type='monotone'
                        dataKey='nlv'
                        stroke='green'
                        dot={{ stroke: 'green', strokeWidth: 2, fill: 'white' }}
                        activeDot={{ r: 8 }}
                      />
                      <XAxis dataKey='nifty'>
                        <Label
                          value='Nifty Levels'
                          position='insideBottom'
                          offset={-2}
                        />
                      </XAxis>
                      <YAxis domain={range}>
                        <Label
                          value='Expected P&L and NLV'
                          position='left'
                          angle={-90}
                          offset={15}
                          dy={-55}
                        />
                      </YAxis>
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <Button className='more_details_btn' onClick={openModal}>
                  More Details
                </Button>
              </div>
            </Col>
          </Row>
        )}

        {openMoreDetailsModal && (
          <MoreDetails
            isOpenModal={openMoreDetailsModal}
            closeModal={closeModal}
            bookedAdvisory={advisoryData}
            advisoryDate={advisoryDate}
            mapData1={data}
            mapData2={graphData1}
            range1={range}
            range2={range1}
            userData={userData}
          />
        )}
      </div>
    </>
  )
}
export default PendingAdvisoryIndex
