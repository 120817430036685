import { Button, Col, Form, Input, PageHeader, Row, Select, Switch } from "antd"
import React, { useState, useContext } from "react"

import { AppContext } from "AppContext"
import { GET_SYSTEM_ALERT } from "../graphql/Queries"
import client from "apollo"
import { UPDATE_SYSTEM_ALERT } from "../graphql/Mutations"

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } }
}

const tailFormItemLayout = {
  wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } }
}

const { Option } = Select
export default function MaintenanceStatus() {
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false)
  const { state: { MaintenanceStatus: { id, isActive, type, message } }, dispatch } = useContext(AppContext)

  const onChange = (value) => {
    setLoader(true)
    client.mutate({
      mutation: UPDATE_SYSTEM_ALERT,
      variables: { data: { isActive: !isActive, }, where: { id } },
      refetchQueries: [{ query: GET_SYSTEM_ALERT, fetchPolicy: 'network-only' }]
    })
      .then((res) => {
        setLoader(false)
        dispatch({ type: 'MAINTENANCE_STATUS', data: res.data.updateSystemAlert })
      })
      .catch((err) => console.log(err))
  }

  const onFinish = (value) => {
    client.mutate({
      mutation: UPDATE_SYSTEM_ALERT, variables: {
        data: value, where: { id }
      },
    })
      .then(res => {
        dispatch({ type: 'MAINTENANCE_STATUS', data: res.data.updateSystemAlert })
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <PageHeader className='box' title='Alert Notification' />
      <Form
        {...formItemLayout}
        form={form}
        name='message'
        onFinish={onFinish}
        className='user-form'
        initialValues={{
          message: message,
          type: type
        }}
        scrollToFirstError
      >
        <Row gutter={16}>
          <Col className='gutter-row' span={24}>
            <Form.Item
              label='Active'
              name='isActive'
            >
              <Switch
                checked={isActive}
                onChange={onChange}
                loading={loader}
              />
            </Form.Item>
          </Col>
          {isActive ?
            <>
              <Col className='gutter-row' span={24}>
                <Form.Item
                  name='message'
                  label='Maintenance Message'
                  rules={[{ required: true, message: 'Please input Maintenance message!' }]}
                >
                  <Input placeholder='Enter Maintenance Message' />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={10}>
                <Form.Item
                  label='Maintenance Status'
                  name='type'
                  rules={[{ required: true, message: 'Please Select Maintenance status' }]}
                >
                  <Select
                    placeholder='Select Maintenance Status'
                  >
                    <Option value="SUCCESS">Success</Option>
                    <Option value="INFO">Info</Option>
                    <Option value="DANGER">Danger</Option>
                    <Option value="WARNING">Warning</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={24} style={{ textAlign: 'center' }} >
                <Form.Item {...tailFormItemLayout}>
                  <Button type='primary' htmlType='submit' >Save</Button>
                </Form.Item>
              </Col>
            </>
            : ''
          }
        </Row>
      </Form>
    </>
  )
}

