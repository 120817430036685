import React from 'react'
import { Row, Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Widget from '../Widget'

export default function ({ title }) {
  return (
    <Widget title={<h2 className='h4 gx-text-capitalize gx-mb-0'>{title}</h2>} styleName='gx-text-center'>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <LoadingOutlined style={{ fontSize: 40 }} />
        </Col>
      </Row>
    </Widget>
  )
}
