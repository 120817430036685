import React, { useContext } from 'react'
import SignDoc from '../../assets/images/doc_sign.webp'
import { AppContext } from 'AppContext'

export default function CourierPage() {
  const { state } = useContext(AppContext)
  const { kyc } = state?.currentUser

  return (
    <>
      <div>
        <h3>
          Kindly Courier all these documents signed and stamped to the below
          address:
        </h3>
        <h4>
          Address: Registered Office Address: 1002, Luxuria Business Hub, Nr. VR
          Mall, Gaurav Path Road, Surat – 395007.
        </h4>
        <br />
        <img
          src={SignDoc}
          alt='Sign Document'
          width='200px'
          height='200px'
          style={{ borderRadius: '50%' }}
        />
        <br />
        <br />
        <h4>
          If You forgot to download your agreement, Download from{' '}
          <a
            style={{ cursor: 'pointer' }}
            href={
              kyc?.agreementForNonIndividual && kyc?.agreementForNonIndividual
            }
            target='_blank'
            rel='noopener noreferrer'
          >
            Here
          </a>
        </h4>
      </div>
    </>
  )
}
