import gql from 'graphql-tag'

export const CREATE_GROUP = gql`
  mutation createFamilyGroup($data: CreateFamilyGroupInput!) {
    createFamilyGroup(data: $data) {
      id
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation updateFamilyGroup($data: UpdateFamilyGroupInput!, $id: ID!) {
    updateFamilyGroup(data: $data, where: { id: $id }) {
      id
    }
  }
`

export const DELETE_GROUP = gql`
  mutation deleteFamilyGroup($id: ID!) {
    deleteFamilyGroup(where: { id: $id }) {
      id
    }
  }
`
