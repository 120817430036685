import React, { Fragment, useState, useContext } from 'react'
import { MenuOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { PageHeader, Button, Menu, Dropdown, Tag, Modal } from 'antd'
import get from 'lodash/get'

import client from 'apollo'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import TicketDetails from './TicketDetails'
import { useQuery } from '@apollo/react-hooks'
import Loader from 'components/loaders/Loader'
import CustomMeta from 'components/CustomMeta'
import { GET_TICKET } from './graphql/Queries'
import openNotification from 'utils/Notification'
import CloseTicketModal from './CloseTicketModal'
import { UPDATE_TICKET } from './graphql/Mutations'

const { confirm } = Modal

export default function (props) {
  let ticket = {}
  let clientDetails = {}
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [showFormModal, setShowFormModal] = useState(false)
  const {
    history,
    match: {
      params: { ticketId },
    },
  } = props
  const {
    state: {
      currentReport,
      isClient,
      currentUser: { id },
    },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_TICKET, {
    variables: { id: ticketId },
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  if (!loading && get(data, 'getTicket')) {
    ticket = data.getTicket
    clientDetails = data.getTicket.generatedBy
  }
  const selfGenerated = ticket.generatedBy.id === id
  function handleCloseTicket(values, resetForm) {
    const { reason } = values
    const data = { reason, status: 'CLOSED', closeById: id }
    client
      .mutate({
        mutation: UPDATE_TICKET,
        variables: { data, id: ticketId },
        refetchQueries: [
          {
            query: GET_TICKET,
            variables: { id: ticketId },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification('success', 'Ticket Closed Successfully')
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function showConfirm(text, status) {
    confirm({
      title: `Do you want to ${text} this ticket?`,
      icon: <ExclamationCircleOutlined />,
      content: `When clicked the OK button, this ticket will be ${text}`,
      onOk() {
        client
          .mutate({
            mutation: UPDATE_TICKET,
            variables: { data: { status }, id: ticketId },
            refetchQueries: [
              { query: GET_TICKET, variables: { id: ticketId } },
            ],
          })
          .then((res) =>
            openNotification('success', 'Ticket Opened Successfully')
          )
          .catch((err) => console.log(err))
      },
    })
  }

  function renderStatusButton() {
    if (isClient || selfGenerated || !currentReport.includes('Update Ticket'))
      return <Fragment />
    if (window.innerWidth >= 991) {
      switch (ticket.status) {
        case 'PENDING':
          return (
            <Button
              key='1'
              type='primary'
              onClick={() => showConfirm('open', 'OPEN')}
            >
              Open
            </Button>
          )
        case 'OPEN':
          return (
            <Button
              key='1'
              type='primary'
              onClick={() => setShowFormModal(true)}
            >
              Close
            </Button>
          )
        default:
          return <Fragment />
      }
    } else {
      switch (ticket.status) {
        case 'PENDING':
          return (
            <Menu.Item
              key='1'
              onClick={() => {
                showConfirm('open', 'OPEN')
                setVisibleMenu(!visibleMenu)
              }}
            >
              Open Ticket
            </Menu.Item>
          )
        case 'OPEN':
          return (
            <Menu.Item
              key='1'
              onClick={() => {
                showConfirm('closed', 'CLOSED')
                setVisibleMenu(!visibleMenu)
              }}
            >
              Close Ticket
            </Menu.Item>
          )
        default:
          return (
            <Menu.Item
              key='1'
              onClick={() => {
                showConfirm('re-open', 'OPEN')
                setVisibleMenu(!visibleMenu)
              }}
            >
              Re-Open Ticket
            </Menu.Item>
          )
      }
    }
  }

  const menu = <Menu>{renderStatusButton()}</Menu>

  return (
    <Fragment>
      <CustomMeta title={`#${ticket.ticketNumber}`} description='Tickets' />
      {!isClient && showFormModal && (
        <CloseTicketModal
          visible={showFormModal}
          onCreate={handleCloseTicket}
          onCancel={() => setShowFormModal(false)}
        />
      )}
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title={`#${ticket.ticketNumber}`}
        tags={<Tag className={ticket.status}>{ticket.status}</Tag>}
        extra={[
          window.innerWidth >= 991 ? (
            <Fragment key='0'>{renderStatusButton()}</Fragment>
          ) : (
            !isClient &&
            !selfGenerated &&
            ticket.status !== 'CLOSED' && (
              <Dropdown
                onVisibleChange={() => setVisibleMenu(false)}
                key='0'
                overlay={menu}
                onClick={() => setVisibleMenu(!visibleMenu)}
                visible={visibleMenu}
              >
                <Button>
                  <MenuOutlined />
                </Button>
              </Dropdown>
            )
          ),
        ]}
      />
      <TicketDetails details={clientDetails} ticket={ticket} />
    </Fragment>
  )
}
