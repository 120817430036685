import React, { useState } from 'react'
import { PageHeader, Button, Table, Tooltip } from 'antd'
import { EditTwoTone, DeleteOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'

import { GET_ALL_NSE_HOLIDAYS, GET_NSE_HOLIDAY } from './graphql/Query'
import Page404 from 'components/Page404'
import moment from 'moment'
import client from 'apollo'
import CreateNSEHoliday from './CreateNSEHoliday'
import {
  CREATE_HOLIDAY,
  DELETE_HOLIDAY,
  UPDATE_HOLIDAY,
} from './graphql/Mutation'
import openNotification from 'utils/Notification'
import { get } from 'lodash'

const NSEHoliday = () => {
  const [openModal, setOpenModal] = useState(false)
  const [holidayId, setHolidayId] = useState(null)
  const [holidayLoading, setHolidayLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [holidayData, setHolidayData] = useState({})
  let { data, loading, error } = useQuery(GET_ALL_NSE_HOLIDAYS, {
    fetchPolicy: 'network-only',
  })

  if (!loading && get(data, 'getHoliday')) {
    const array = data.getHoliday.map((item, key) => {
      return { ...item, key: key + 1 }
    })
    data.getHoliday = array
  }

  if (error) return <Page404 />

  const openNSEholidayModal = (id) => {
    if (id) {
      setHolidayLoading(true)
      client
        .query({
          query: GET_NSE_HOLIDAY,
          variables: { where: { id: parseInt(id) } },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          console.log(res?.data)
          setHolidayData(res?.data?.getHolidayById)
          setHolidayId(id)
          setHolidayLoading(false)
          setOpenModal(!openModal)
        })
        .catch((err) => {
          console.log(err)
          setHolidayLoading(false)
        })
    } else {
      setOpenModal(!openModal)
    }
  }

  const handleCancel = () => {
    setOpenModal(false)
    setHolidayId(null)
    setHolidayData({})
  }

  const deleteNSEholiday = (id) => {
    client
      .mutate({
        mutation: DELETE_HOLIDAY,
        variables: { where: { id } },
        refetchQueries: [
          {
            query: GET_ALL_NSE_HOLIDAYS,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification('success', 'Holiday Deleted Successfully')
      })
      .catch((err) => {
        console.log(err)
        openNotification('error', err)
      })
  }

  const createHoliday = (values, resetForm) => {
    setModalLoading(true)
    client
      .mutate({
        mutation: CREATE_HOLIDAY,
        variables: { data: values },
        refetchQueries: [
          {
            query: GET_ALL_NSE_HOLIDAYS,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setModalLoading(false)
        resetForm()
        setOpenModal(false)
        openNotification('success', 'Holiday Created Successfully')
      })
      .catch((err) => {
        console.log(err)
        setModalLoading(false)
        resetForm()
        setOpenModal(false)
        openNotification('error', err)
      })
      .finally(() => {
        setModalLoading(false)
        resetForm()
        setOpenModal(false)
      })
  }

  const updateHoliday = (values, resetForm, id) => {
    setModalLoading(true)
    client
      .mutate({
        mutation: UPDATE_HOLIDAY,
        variables: { data: values, where: { id } },
        refetchQueries: [
          {
            query: GET_ALL_NSE_HOLIDAYS,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setModalLoading(false)
        resetForm()
        setOpenModal(false)
        setHolidayId(null)
        openNotification('success', 'Holiday Updated Successfully')
      })
      .catch((err) => {
        console.log(err)
        setModalLoading(false)
        resetForm()
        setOpenModal(false)
        setHolidayId(null)
        openNotification('error', err)
      })
      .finally(() => {
        setModalLoading(false)
        resetForm()
        setOpenModal(false)
        setHolidayId(null)
      })
  }

  const renderAction = (record) => {
    const { id } = record
    return (
      <div className='action-icons'>
        <Tooltip title='Edit'>
          <EditTwoTone onClick={() => openNSEholidayModal(id)} />
        </Tooltip>
        <Tooltip title='Delete'>
          <DeleteOutlined onClick={() => deleteNSEholiday(id)} />
        </Tooltip>
      </div>
    )
  }

  const submitHoliday = (values, resetForm) => {
    if (holidayId) {
      updateHoliday(values, resetForm, holidayId)
    } else {
      createHoliday(values, resetForm)
    }
  }

  const columns = [
    {
      title: 'SR NO.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => moment(record?.date).format('DD-MMM-YY'),
    },
    {
      title: 'DAY',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => moment(record?.date).format('dddd'),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: 100,
    },
  ]

  return (
    <>
      <PageHeader
        className='box'
        title='NSE Holiday Dashboard'
        extra={[
          <Button
            key='1'
            type='primary'
            onClick={() => openNSEholidayModal(null)}
          >
            Create NSE Holiday
          </Button>,
        ]}
      />

      <Table
        loading={loading}
        dataSource={data?.getHoliday}
        columns={columns}
      />

      {openModal && (
        <CreateNSEHoliday
          isModalOpen={openModal}
          handleCancel={handleCancel}
          holidayId={holidayId}
          onCreate={submitHoliday}
          holidayLoading={holidayLoading}
          modalLoading={modalLoading}
          data={holidayData}
        />
      )}
    </>
  )
}
export default NSEHoliday
