import gql from 'graphql-tag'

export const UPDATE_ADVISORY_VALUE = gql`
mutation updateAdvisoryValue($data: [updateAdvisoryValueData]!){
  updateAdvisoryValue(data: $data)
}
`

export const UPDATE_ADVISORY_STATUS = gql`
mutation updateAdvisoryStatus($data: [updateAdvisoryStatusData]!){
  updateAdvisoryStatus(data: $data)
}
`

