export const capitalizeFirstLetter = (input) => {
  const words = input.split(' ')
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )
  return capitalizedWords.join(' ')
}

export const handleInputChange = (form, fieldName, e) => {
  const transformedValue = capitalizeFirstLetter(e.target.value)
  form.setFieldsValue({ [fieldName]: transformedValue })
}

const capitalizeAllLetters = (value) => {
  return value.toUpperCase()
}

export const handlePanCard = (form, fieldName, e) => {
  const transformedValue = capitalizeAllLetters(e.target.value)
  form.setFieldsValue({ [fieldName]: transformedValue })
}

export function convertNumberToCommaSeparate(number, isToFixed = false) {
  if (isNaN(number)) {
    return ''
  }

  let fixedNumber = number
  if (isToFixed) {
    fixedNumber = number.toFixed(2)
    const intNumber = +fixedNumber.split('.')[0]
    return intNumber?.toLocaleString('en-IN') + '.' + fixedNumber.split('.')[1]
  } else {
    return fixedNumber?.toLocaleString('en-IN')
  }
}
