import gql from 'graphql-tag'

export const UPDATE_ACCESS_SETTING = gql`
  mutation updateAccessSetting(
    $data: AccessSettingUpdateInput!
    $where: AccessSettingWhereInput!
  ) {
    updateAccessSetting(data: $data, where: $where) {
      id
    }
  }
`

export const UPDATE_SITE_SETTING = gql`
  mutation updateSiteSetting(
    $data: SiteSettingUpdateInput!
    $where: SiteSettingWhereInput!
  ) {
    updateSiteSetting(data: $data, where: $where) {
      id
      url
      status
    }
  }
`

export const UPDATE_SYSTEM_ALERT = gql`
  mutation updateSystemAlert(
    $data: SystemAlertUpdateInput!
    $where: SystemAlertWhereUniqueInput!
  ) {
    updateSystemAlert(data: $data, where: $where) {
      id
      message
      isActive
      type
      createdAt
      updatedAt
    }
  }
`

export const USER_FIELD_SELECTION = gql`
  mutation userFieldSelection($data: FieldSelectionUpdateInput) {
    userFieldSelection(data: $data) {
      id
      role
      firstName
      lastName
      userName
      completedSteps
      clientCode
      email
      phone
      remark
    }
  }
`
export const UPDATE_INVOICE_SETTING = gql`
  mutation updateInvoiceSetting($data: InvoiceSettingUpdateInput!) {
    updateInvoiceSetting(data: $data) {
      id
      type
      companyName
      webEmail
      address
      invoiceNumber
      accountDetails
      bankName
      branchName
      ifscCode
      panNumber
      autoGenerateInvoiceStartNumber
      invoiceDateType
      invoiceDate
      gstinNumber
      upiId
      qrImage
      invoiceYear
      billTypeParticular
      otherMailId
      amcDates
    }
  }
`
export const UPDATE_GENERAL_SETTING = gql`
  mutation updateGeneralSetting($data: GeneralSettingCreateInput!) {
    updateGeneralSetting(data: $data) {
      id
      company
      amcEndDate
      niftyLotSize
      createdAt
    }
  }
`
export const SEND_DYNAMIC_EMAIL = gql`
  mutation sendDynamicEmail($data: DynamicEmailInput!) {
    sendDynamicEmail(data: $data)
  }
`

export const CREATE_INVOICE = gql`
  mutation createInvoice($data: InvoiceMasterCreateInput!) {
    createInvoice(data: $data) {
      id
      client {
        id
        firstName
      }
      clientType
      deduction
      amcType
      clientName
      clientCode
      emailId
      address
      state
      discountInPer
      commissionInPer
      niftySpot
      niftyLot
      quantity
      assetUnderAdvisory
      amcInPer
      discountAmcInPer
      startDate
      endDate
      dueDay
      dueDate
      amcAmount
      discountAmount
      commissionAmount
      grossTotalAmount
      cgst9InPer
      sgst9InPer
      igst18InPer
      totalNetAmount
      amc {
        id
      }
      mailBody
      amcExplanation
      amcCalculateType
      invoicePdf
      additionalDiscountAmount
      eMandateDiscountPer
      eMandateDiscountAmount
    }
  }
`

export const UPDATE_INVOICE = gql`
  mutation updateInvoice(
    $where: updateInvoiceWhereInput!
    $data: InvoiceMasterCreateInput!
  ) {
    updateInvoice(where: $where, data: $data)
  }
`

export const UPDATE_TEMPLATE_DATA = gql`
  mutation updateTemplateData($type: String, $htmlData: String) {
    updateTemplateData(type: $type, htmlData: $htmlData)
  }
`
