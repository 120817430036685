import React, { useState, useMemo, useEffect, useContext } from 'react'
import { SearchOutlined, LinkOutlined } from '@ant-design/icons'
import {
  PageHeader,
  Table,
  Button,
  Input,
  Space,
  Form,
  Row,
  Col,
  DatePicker,
  Modal,
} from 'antd'
import { useQuery } from '@apollo/react-hooks'
import _isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import moment from 'moment'

import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import { GET_USERS } from '../Users/graphql/Queries'
import { NavLink } from 'react-router-dom'
import { GET_NIFTY } from 'modules/Reports/graphql/Queries'
import NotifyMail from './notifyMail'
import { AppContext } from 'AppContext'

const { RangePicker } = DatePicker

const statusField = [
  { text: 'YES', value: true },
  { text: 'NO', value: false },
]

const userField = [
  { text: 'SELECT USER TYPE', value: 'SELECT_USER_TYPE' },
  { text: 'LOGIN CREATED', value: 'LOGIN_CREATED' },
  { text: 'KYC STATUS', value: 'KYC_STATUS' },
  { text: 'CLIENT PROFILE_1', value: 'CLIENT_PROFILE_1' },
  { text: 'CLIENT PROFILE_2', value: 'CLIENT_PROFILE_2' },
  { text: 'RISK PROFILE', value: 'RISK_PROFILE' },
  { text: 'SUMMARY', value: 'SUMMARY' },
  { text: 'KYC DOC', value: 'KYC_DOC' },
  { text: 'KYC FORM', value: 'KYC_FORM' },
  { text: 'CLIENT CONSENT', value: 'CLIENT_CONSENT' },
  { text: 'INVESTMENT PLANNING', value: 'INVESTMENT_PLANNING' },
  { text: 'AGREEMENT SIGN', value: 'AGREEMENT_SIGN' },
  { text: 'COURIER INSTRUCTION', value: 'COURIER_INSTRUCTION' },
  { text: 'E MANDATE', value: 'E_MANDATE' },
  { text: 'ADVISORY', value: 'ADVISORY' },
  { text: 'DONE', value: 'DONE' },
]

const userType = [
  { text: 'Individual', value: true },
  { text: 'Non-Individual', value: false },
]

const userStatus = [
  { text: 'ACTIVE', value: 'true' },
  { text: 'DEACTIVE', value: 'false' },
]

export default function ({ history }) {
  let tableData = []
  const {
    state: {
      generalSetting: { niftyLotSize },
    },
  } = useContext(AppContext)
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [modalForNotification, setModalForNotification] = useState(false)
  const [userDataForMail, setUserDataForMail] = useState({})
  const [filters, setFilters] = useState(
    localStorage.getItem('filter')
      ? JSON.parse(localStorage.getItem('filter'))
      : {
          role: 'CLIENT',
          mdate: '',
          strikerate: 0,
          cp: '',
          company: '',
          isActive: 'true',
        }
  )

  const filterData = useMemo(() => {
    const userRole = {
      ...filters,
      role: 'CLIENT',
    }
    if (filters.mdate) {
      userRole.mdate = filters.mdate
    } else {
      delete userRole.mdate
    }
    if (filters.strikerate) {
      userRole.strikerate = filters.strikerate
    } else {
      delete userRole.strikerate
    }
    if (filters.cp) {
      userRole.cp = filters.cp
    } else {
      delete userRole.cp
    }
    if (filters.company) {
      userRole.company = filters.company
    } else {
      delete userRole.company
    }
    return userRole
  }, [filters])

  const [selectedRow, setSelectedRow] = useState([])

  const { data, loading, error } = useQuery(GET_USERS, {
    variables: { where: filterData, limit, skip, sort },
    fetchPolicy: 'cache-first',
  })

  const { data: niftyData, loading: niftyLoading } = useQuery(GET_NIFTY, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  useEffect(() => {
    if (skip === 0 && limit === 1500 && !loading && _isEmpty(selectedRow)) {
      let newIds = data?.getUsers?.users?.map((user) => user.id)
      setSelectedRow(newIds)
    }
  }, [skip, limit, loading, data, selectedRow])

  let totalCount = 0
  if (!loading && get(data, 'getUsers.users')) {
    totalCount = data.getUsers.count
    tableData = data.getUsers.users.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.completedSteps) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['completedSteps'] = filter.completedSteps
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.cvlReportStatus) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['cvlReportStatus'] = filter.cvlReportStatus
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.riskSuitabilityReportStatus) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['riskSuitabilityReportStatus'] =
        filter.riskSuitabilityReportStatus
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.suitabilityReportStatus) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['suitabilityReportStatus'] = filter.suitabilityReportStatus
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.agreementReportStatus) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['agreementReportStatus'] = filter.agreementReportStatus
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.mandateId) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['isExistMandateId'] = filter.mandateId
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.rationalStatus) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['rationalStatus'] = filter.rationalStatus
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.invoiceStatus) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['invoiceStatus'] = filter.invoiceStatus
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.isActive) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['isActive'] = filter.isActive
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.isPanCardIndividual) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['isPanCardIndividual'] = filter.isPanCardIndividual
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.planId) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['planId'] = parseInt(filter.planId)
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['completedSteps'] = ''
      tempFilters['isExistMandateId'] = ''
      tempFilters['isActive'] = 'true'
      tempFilters['isPanCardIndividual'] = ''
      delete tempFilters.completedSteps
      delete tempFilters.isExistMandateId
      delete tempFilters.isPanCardIndividual
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }
  function handlePagination(page) {
    if (limit !== 1500) {
      setSkip((page - 1) * limit)
    }
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value =
      typeof value === 'object' && filedName !== 'clientName' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
    localStorage.setItem('filter', JSON.stringify(tempFilters))
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    localStorage.setItem('filter', JSON.stringify(tempFilters))
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          // value={selectedKeys[0]}
          defaultValue={
            JSON.parse(localStorage.getItem('filter'))
              ? JSON.parse(localStorage.getItem('filter'))[dataIndex]
              : ''
          }
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  let minimumExposure =
    50000 *
    Math.round(
      (!niftyLoading && niftyData?.getNifty50[0]?.CMP * niftyLotSize) / 50000
    )

  const showConfirm = (record) => {
    setUserDataForMail(record)
    setModalForNotification(true)
  }

  const modalCloseForNotification = () => {
    setModalForNotification(false)
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        <Button type='primary' size='small' onClick={() => showConfirm(record)}>
          Notify
        </Button>
      </div>
    )
  }

  const columns = [
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'clientCode',
      className: 'column-name-type',
      render: (text, record) => record?.clientCode,
      width: '120px',
      ...getColumnSearchProps('clientCode'),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      className: 'column-name-type',
      render: (text, record) => (
        <NavLink to={`/clients/${record.id}`}>
          {record['firstName'] || '-'}
        </NavLink>
      ),
      width: '120px',
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      className: 'column-name-type',
      render: (text, record) => (
        <NavLink to={`/clients/${record.id}`}>
          {record['lastName'] || '-'}
        </NavLink>
      ),
      width: '120px',
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      className: 'column-name-type',
      width: '120px',
      render: (date) => (date ? moment(date).format('Do MMMM YYYY') : '-'),
      sorter: (a, b) => a.age - b.age,
      ...getColumnDateProps('registrationDate'),
    },
    {
      title: 'CVL Status',
      dataIndex: 'cvlReportStatus',
      key: 'cvlReportStatus',
      className: 'column-name-type',
      width: '120px',
      filters: statusField,
      sorter: (a, b) => a.age - b.age,
      render: (text, record) =>
        record?.cvlReportStatus === null
          ? '-'
          : record?.cvlReportStatus === true
          ? 'Yes'
          : 'No',
    },
    {
      title: 'Risk Profile',
      dataIndex: 'riskSuitabilityReportStatus',
      key: 'riskSuitabilityReportStatus',
      width: '150px',
      filters: statusField,
      sorter: (a, b) => a.age - b.age,
      render: (text, record) =>
        record?.riskSuitabilityReportStatus === null
          ? '-'
          : record?.riskSuitabilityReportStatus === true
          ? 'Yes'
          : 'No',
    },
    {
      title: 'Client Score',
      dataIndex: 'clientScore',
      key: 'clientScore',
      width: '120px',
      render: (data) => (data ? data : '-'),
      className: 'column-name-type',
      ...getColumnSearchProps('clientScore'),
    },
    {
      title: 'Suitability Report',
      dataIndex: 'suitabilityReportStatus',
      key: 'suitabilityReportStatus',
      width: '150px',
      filters: statusField,
      sorter: (a, b) => a.age - b.age,
      render: (text, record) =>
        record?.suitabilityReportStatus === null
          ? '-'
          : record?.suitabilityReportStatus === true
          ? 'Yes'
          : 'No',
    },
    {
      title: 'Agreement',
      dataIndex: 'agreementReportStatus',
      key: 'agreementReportStatus',
      width: '150px',
      filters: statusField,
      sorter: (a, b) => a.age - b.age,
      render: (text, record) =>
        record?.agreementReportStatus === null
          ? '-'
          : record?.agreementReportStatus === true
          ? 'Yes'
          : 'No',
    },
    {
      title: 'E - mandate Status',
      dataIndex: 'mandateId',
      key: 'mandateId',
      width: '150px',
      filters: statusField,
      render: (text, record) =>
        record?.mandateId !== null ? record?.umrnNumber || '-' : 'No',
    },
    {
      title: 'Annual Income',
      dataIndex: 'annualIncome',
      key: 'annualIncome',
      className: 'column-name-type',
      render: (text, record) => record.annualIncome,
      width: '120px',
      sorter: (a, b) => a.age - b.age,
      ...getColumnSearchProps('annualIncome'),
    },
    {
      title: 'Current AUA',
      dataIndex: 'initialExposure',
      key: 'initialExposure',
      className: 'column-name-type',
      render: (text, record) => record.performance[0]?.TotalExposure || '-',
      width: '150px',
      sorter: (a, b) => a.age - b.age,
      ...getColumnSearchProps('initialExposure'),
    },
    {
      title: 'Net Available AUA',
      dataIndex: 'netAvailableAUA',
      key: 'netAvailableAUA',
      width: '150px',
      render: (text, record) => (
        <span
          style={{
            color:
              minimumExposure >
                record?.annualIncome * 10 - record?.initialExposure && 'red',
          }}
        >
          {record?.annualIncome * 10 - record?.initialExposure}
        </span>
      ),
    },
    {
      title: 'Rational Status',
      dataIndex: 'rationalStatus',
      key: 'rationalStatus',
      width: '150px',
      filters: statusField,
      sorter: (a, b) => a.age - b.age,
      render: (text, record) =>
        record?.rationalStatus === null
          ? '-'
          : record?.rationalStatus === true
          ? 'Yes'
          : 'No',
    },
    {
      title: 'Invoice Status',
      dataIndex: 'invoiceStatus',
      key: 'invoiceStatus',
      width: '150px',
      filters: statusField,
      sorter: (a, b) => a.age - b.age,
      render: (text, record) =>
        record?.invoiceStatus === null
          ? '-'
          : record?.invoiceStatus === true
          ? 'Yes'
          : 'No',
    },
    {
      title: 'User Status',
      dataIndex: 'isActive',
      key: 'isActive',
      className: 'column-name-type',
      width: '120px',
      render: (text, record) =>
        record?.isActive === true ? 'ACTIVE' : 'DEACTIVE',
      filters: userStatus,
    },
    {
      title: 'Select User Type',
      dataIndex: 'isPanCardIndividual',
      key: 'isPanCardIndividual',
      className: 'column-name-type',
      width: '120px',
      render: (text, record) =>
        record?.isPanCardIndividual === false ? 'Non-Individual' : 'Individual',
      filters: userType,
    },
    {
      title: 'Completed Steps',
      dataIndex: 'completedSteps',
      key: 'completedSteps',
      className: 'column-name-type',
      width: '120px',
      filters: userField,
    },
    {
      title: 'Download Report',
      dataIndex: 'Download Report',
      key: 'Download Report',
      width: '120px',
      render: (text, record) =>
        record?.kyc?.agreementForNonIndividual ? (
          <a
            href={`${record?.kyc?.agreementForNonIndividual}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <LinkOutlined />
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: '80px',
    },
  ]

  const clearFilters = () => {
    localStorage.removeItem('filter')
    setFilters({})
  }

  return (
    <>
      <CustomMeta title='Compliance' />
      <PageHeader className='box' title='Compliance' />
      <Form style={{ marginLeft: '50px' }}>
        <Row style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <Col className='gutter-row'>
            <Form.Item name='fields'>
              <Button type='primary' onClick={clearFilters}>
                Clear All
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        width={700}
        title='Mail Send for Notification'
        visible={modalForNotification}
        onCancel={modalCloseForNotification}
        footer={[]}
      >
        <NotifyMail
          userDataForMail={userDataForMail}
          setModalForNotification={setModalForNotification}
        />
      </Modal>
      <Table
        loading={loading}
        columns={columns}
        rowKey='id'
        dataSource={!loading && tableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        pagination={{
          pageSize: limit === 1500 ? 10 : limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
          showSizeChanger: false,
        }}
      />
    </>
  )
}
