import React, { Fragment, useState } from 'react'
import moment from 'moment'
import get from 'lodash/get'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { PageHeader } from 'antd'
import isEmpty from 'lodash/isEmpty'
import _reverse from 'lodash/reverse'
import _forEach from 'lodash/forEach'

import client from 'apollo'
import ClientForm from './components/ClientForm'
import openNotification from 'utils/Notification'
import {
  CREATE_USER,
  UPDATE_KYC,
  CREATE_USER_REVENUE,
} from '../Users/graphql/Mutations'

function getFilesObject(tempFiles) {
  let files = pick(tempFiles, [
    'panCard',
    'signature',
    'aadharCard',
    'passportSize',
    'bankStatement',
    'cancelledChequeSnap',
  ])
  if (get(files, 'panCard[0].originFileObj')) {
    files.panCard = get(files, 'panCard[0].originFileObj')
  } else {
    delete files.panCard
  }
  if (get(files, 'signature[0].originFileObj')) {
    files.signature = get(files, 'signature[0].originFileObj')
  } else {
    delete files.signature
  }
  if (get(files, 'aadharCard[0].originFileObj')) {
    files.aadharCard = get(files, 'aadharCard[0].originFileObj')
  } else {
    delete files.aadharCard
  }
  if (get(files, 'passportSize[0].originFileObj')) {
    files.passportSize = get(files, 'passportSize[0].originFileObj')
  } else {
    delete files.passportSize
  }
  if (get(files, 'bankStatement[0].originFileObj')) {
    files.bankStatement = get(files, 'bankStatement[0].originFileObj')
  } else {
    delete files.bankStatement
  }
  if (get(files, 'cancelledChequeSnap[0].originFileObj')) {
    files.cancelledChequeSnap = get(
      files,
      'cancelledChequeSnap[0].originFileObj'
    )
  } else {
    delete files.cancelledChequeSnap
  }
  return files
}

function getNewValues(values) {
  let users = []
  Object.keys(values).forEach((value, key) => {
    if (values[key] === 'names' || values[key] === 'userNames') {
      delete values[key]
    }
    if (
      (values[value] || values[value] === 0) &&
      typeof values[value] === 'number' &&
      value.startsWith('ratio_')
    ) {
      users.push({ userId: value.replace('ratio_', ''), ratio: values[value] })
      delete values[value]
    }
  })
  values.ratio = JSON.stringify(users)
  return values
}

export default function ({ history }) {
  const [loading, setLoading] = useState()

  function addRatio(variables) {
    client
      .mutate({ mutation: CREATE_USER_REVENUE, variables })
      .catch((err) => console.log(err))
  }

  const onFinish = (values) => {
    values = {
      ...values,
      firstName:
        values.firstName.charAt(0).toUpperCase() +
        values.firstName.slice(1).toLowerCase(),
      lastName:
        values.lastName.charAt(0).toUpperCase() +
        values.lastName.slice(1).toLowerCase(),
      motherName:
        values.motherName.charAt(0).toUpperCase() +
        values.motherName.slice(1).toLowerCase(),
      fatherName:
        values.fatherName.charAt(0).toUpperCase() +
        values.fatherName.slice(1).toLowerCase(),
      email: values.email.toLowerCase(),
      dateOfBirth: values?.dateOfBirth?.format('YYYY-MM-DD'),
      amcStartDate: values?.amcStartDate?.format('YYYY-MM-DD'),
      closedDate: values?.closedDate?.format('YYYY-MM-DD'),
      disableDate: values?.disableDate?.format('YYYY-MM-DD'),
      investmentDate: values?.investmentDate?.format('YYYY-MM-DD'),
      registrationDate: values?.registrationDate?.format('YYYY-MM-DD'),
      sipStartDate: values?.sipStartDate?.format('YYYY-MM-DD'),
      discountStatus: values?.discountStatus?.toString(),
    }
    let allRatio
    setLoading(true)
    let data = omit(values, [
      'confirm',
      'panCard',
      'signature',
      'aadharCard',
      'passportSize',
      'bankStatement',
      'cancelledChequeSnap',
    ])
    data = getNewValues(data)
    if (data.ratio) {
      allRatio = JSON.parse(data.ratio)
      allRatio = _reverse(allRatio)
      delete data.ratio
    }
    data.amcAmount = 0
    data.sipAmount = 0

    if (data.plan) {
      data.planId = parseInt(data.plan)
    }
    delete data.plan
    const queryVariables = { data }
    client
      .mutate({ mutation: CREATE_USER, variables: queryVariables })
      .then((res) => {
        const userId = res.data.createUser.id
        _forEach(allRatio, function (ratio, key) {
          addRatio({
            data: {
              clientId: parseInt(userId),
              year: parseInt(moment().format('YYYY')),
              ratio: parseFloat(ratio.ratio),
              level: key + 1,
              chainMemberId: parseInt(ratio.userId),
            },
          })
        })
        const files = getFilesObject(values)
        if (isEmpty(files)) {
          openNotification('success', 'Client Created Successfully')
          history.push(`/clients/${userId}`)
          setLoading(false)
        } else {
          client
            .mutate({
              mutation: UPDATE_KYC,
              variables: { id: parseInt(userId), ...files },
            })
            .then((res) => {
              setLoading(false)
              history.replace(`/clients/${userId}`)
              openNotification('success', 'Client Created Successfully')
            })
            .catch((err) => {
              console.log(err)
              setLoading(false)
            })
            .finally(() => setLoading(false))
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <Fragment>
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title='Add New Client'
      />
      <ClientForm onFinish={onFinish} loading={loading} />
    </Fragment>
  )
}
