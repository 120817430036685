import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Cost to Profit Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'Script',
        dataIndex: 'Script',
        key: 'Script',
        width: '20%',
      },
      {
        title: 'Quantity',
        dataIndex: 'TotalQty',
        key: 'TotalQty',
        width: '10%',
      },
      {
        title: 'LTP',
        dataIndex: 'LTP',
        key: 'LTP',
        width: '10%',
      },
      {
        title: 'Current Rate',
        dataIndex: 'CMP',
        key: 'CMP',
        width: '10%',
      },
      {
        title: 'Gap90%Price',
        dataIndex: 'Gap90%Price',
        key: 'Gap90%Price',
        width: '15%',
      },
      {
        title: 'Gap90%ChangeInProfit',
        dataIndex: 'Gap90%ChangeInProfit',
        key: 'Gap90%ChangeInProfit',
        width: '15%',
      },
      {
        title: 'Gap110%Price',
        dataIndex: 'Gap110%Price',
        key: 'Gap110%Price',
        width: '10%',
      },
      {
        title: 'Gap110%ChangeInProfit',
        dataIndex: 'Gap110%ChangeInProfit',
        key: 'Gap110%ChangeInProfit',
        width: '10%',
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      columns={columns}
      dataSource={data}
      className='performance-index-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}
