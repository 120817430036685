import React, { useState } from 'react'
import { Form, InputNumber, Popconfirm, Table, Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment'

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = (
    <InputNumber
      min={0}
      onChange={(e) => {
        record['day'] = e
        record['amcFinalDueDate'] = moment(record?.invoiceDate, 'DD-MMM-YYYY')
          .add(e, 'days')
          .format('DD-MMM-YYYY')
      }}
    />
  )
  if (record) {
    record.amcFinalDueDate = moment(record?.invoiceDate, 'DD-MMM-YYYY')
      .add(record?.day, 'days')
      .format('DD-MMM-YYYY')
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}
const InvoiceTable = ({ setData, data }) => {
  const [form] = Form.useForm()

  const [editingKey, setEditingKey] = useState('')
  const isEditing = (record) => record.key === editingKey
  const edit = (record) => {
    form.setFieldsValue({
      amcType: '',
      invoiceDate: '',
      day: '',
      amcFinalDueDate: '',
      ...record,
    })
    setEditingKey(record.key)
  }
  const cancel = () => {
    setEditingKey('')
  }
  const save = async (key) => {
    try {
      setData(data)
      setEditingKey('')
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }
  const columns = [
    {
      title: 'AMC Type',
      dataIndex: 'amcType',
      editable: false,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      editable: false,
    },
    {
      title: 'Day',
      dataIndex: 'day',
      editable: true,
    },
    {
      title: 'AMC Final Due Date',
      dataIndex: 'amcFinalDueDate',
      editable: false,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Typography
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
                cursor: 'pointer',
              }}
            >
              Save
            </Typography>
            <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
              <a href='.'>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography disabled={editingKey !== ''} onClick={() => edit(record)}>
            <span style={{ color: '#038fde', cursor: 'pointer' }}>
              <EditOutlined />
              &nbsp; Edit
            </span>
          </Typography>
        )
      },
    },
  ]
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  return (
    <Form form={form} component={false}>
      <Table
        className='invoice_table_amc'
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
      />
    </Form>
  )
}
export default InvoiceTable
