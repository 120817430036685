import React from 'react'
import {
  Steps,
  // Button, message
} from 'antd'
import {
  SmileOutlined,
  AuditOutlined,
  MailOutlined,
  ApartmentOutlined,
  ScheduleOutlined,
  UserOutlined,
  LoginOutlined,
  FormOutlined,
  TrademarkOutlined,
} from '@ant-design/icons'

// import { UPDATE_USER } from '../../Users/graphql/Mutations'
// import { GET_USER } from '../../Users/graphql/Queries'
import Widget from 'components/Widget'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
// import client from 'apollo'

const { Step } = Steps

const defaultSteps = [
  'SELECT_USER_TYPE',
  'LOGIN_CREATED',
  'KYC_STATUS',
  'CLIENT_PROFILE_1',
  'CLIENT_PROFILE_2',
  'RISK_PROFILE',
  'SUMMARY',
  'KYC_DOC',
  'KYC_FORM',
  'INVESTMENT_PLANNING',
  'AGREEMENT_SIGN',
  'COURIER_INSTRUCTION',
  'E_MANDATE',
  'ADVISORY',
  'DONE',
]

export default function ({
  clientId,
  completedStep,
  isClient,
  isPanCardIndividual,
}) {
  const currentStep = defaultSteps.findIndex(
    (defaultStep) => defaultStep === completedStep
  )

  // function handleSteps(isPrevious) {
  //   const index = isPrevious ? currentStep - 1 : currentStep + 1
  //   const completedSteps = index === -1 ? null : defaultSteps[index]
  //   const queryVariables = { id: clientId, data: { completedSteps } }

  //   client.mutate({ mutation: UPDATE_USER, variables: queryVariables, refetchQueries: [{ query: GET_USER, variables: { id: clientId } }] })
  //     .then(() => message.success('Client Updated Successfully!'))
  //     .catch((err) => console.log(err))
  // }

  const USER_VERIFICATION_STEP_LOGS = gql`
    query userVerificationStepLogs($userId: Int!) {
      userVerificationStepLogs(where: { userId: $userId }) {
        id
        step
        from
        createdAt
        updatedAt
      }
    }
  `

  const { data } = useQuery(USER_VERIFICATION_STEP_LOGS, {
    variables: { userId: parseInt(clientId) },
    fetchPolicy: 'network-only',
  })

  const CLIENT_PROFILE_1 = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'CLIENT_PROFILE_1'
  )
  const CLIENT_PROFILE_2 = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'CLIENT_PROFILE_2'
  )
  const RISK_PROFILE = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'RISK_PROFILE'
  )
  const SUMMARY = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'SUMMARY'
  )
  const KYC_DOC = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'KYC_DOC'
  )
  const KYC_FORM = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'KYC_FORM'
  )
  const INVESTMENT_PLANNING = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'INVESTMENT_PLANNING'
  )
  const AGREEMENT_SIGN = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'AGREEMENT_SIGN'
  )
  const COURIER_INSTRUCTION = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'COURIER_INSTRUCTION'
  )
  const DONE = data?.userVerificationStepLogs?.find(
    (data) => data?.step === 'DONE'
  )

  return (
    <Widget>
      <Steps current={currentStep} style={{ display: 'block' }}>
        <Step
          className='login-step'
          title={'Login Created'}
          icon={<LoginOutlined />}
        />
        <Step
          className='login-step'
          title={`Client Basic Detail`}
          description={` ${
            CLIENT_PROFILE_1?.from ? `(${CLIENT_PROFILE_1?.from})` : ''
          }`}
          icon={<UserOutlined />}
        />
        <Step
          className='login-step'
          title='Client Profile'
          description={` ${
            CLIENT_PROFILE_2?.from ? `(${CLIENT_PROFILE_2?.from})` : ''
          }`}
          icon={<UserOutlined />}
        />
        <Step
          className='login-step'
          title='Risk Profile Assessment'
          description={` ${
            RISK_PROFILE?.from ? `(${RISK_PROFILE?.from})` : ''
          }`}
          icon={<TrademarkOutlined />}
        />
        <Step
          className='login-step'
          title='Summary'
          description={` ${SUMMARY?.from ? `(${SUMMARY?.from})` : ''}`}
          icon={<FormOutlined />}
        />
        <Step
          className='login-step'
          title='KYC Document'
          description={` ${KYC_DOC?.from ? `(${KYC_DOC?.from})` : ''}`}
          icon={<MailOutlined />}
        />
        <Step
          className='login-step'
          title='KYC E-Sign'
          description={` ${KYC_FORM?.from ? `(${KYC_FORM?.from})` : ''}`}
          icon={<FormOutlined />}
        />
        <Step
          className='login-step'
          title='Investment Planning'
          description={` ${
            INVESTMENT_PLANNING?.from ? `(${INVESTMENT_PLANNING?.from})` : ''
          }`}
          icon={<ApartmentOutlined />}
        />
        <Step
          className='login-step'
          title='Agreement Sign'
          description={`${
            AGREEMENT_SIGN?.from ? `(${AGREEMENT_SIGN?.from})` : ''
          }`}
          icon={<AuditOutlined />}
        />
        {!isPanCardIndividual && (
          <Step
            className='login-step'
            title='Courier Instruction'
            description={`${
              COURIER_INSTRUCTION?.from ? `(${COURIER_INSTRUCTION?.from})` : ''
            }`}
            icon={<AuditOutlined />}
          />
        )}
        <Step
          className='login-step'
          title='E-Mandate Registration'
          description={`${DONE?.from ? `(${DONE?.from})` : ''}`}
          icon={<ScheduleOutlined />}
        />
        <Step
          className='login-step'
          title='Done'
          description={`${DONE?.from ? `(${DONE?.from})` : ''}`}
          icon={<SmileOutlined />}
        />
      </Steps>
      {/* {
        !isClient &&
        <div className='steps-action'>
          {currentStep !== -1 && <Button type='primary' onClick={() => handleSteps(true)}>Previous</Button>}
          {currentStep !== 8 && <Button type='primary' onClick={() => handleSteps()}> {currentStep === 7 ? 'Done' : 'Next'}</Button>}
        </div>
      } */}
    </Widget>
  )
}
