import gql from 'graphql-tag'

export const UPDATE_ADVISORY_TIME = gql`
  mutation UpdateAdvisoryTime(
    $where: AdvisoryTimeWhereInput!
    $data: AdvisoryTimeCreateInput
  ) {
    updateAdvisoryTime(where: $where, data: $data) {
      id
      startTime
      endTime
    }
  }
`
