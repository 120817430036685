import React from 'react'
import { Button } from 'antd'

import './pendingAdvisory.css'

const TermsCondition = ({ data, loading, onFinish }) => {
  return (
    <>
      <div className='terms_page'>
        <p className='terms_page_title'>
          We are happy to see your interest in Index Long Term Strategy
        </p>
        <div className='terms'>
          <p>A few feature of Index Long Term Strategy are as below:</p>
          <ul style={{ listStyle: 'number' }}>
            <li>This Strategy invests money in the Nifty index.</li>
            <li>It is a combination of Equity ETFs, Futures, and Options.</li>
            <li>
              You have selected the&nbsp;
              <b>{data?.getPendingBookingAdvisory?.productRecommended}</b>.
            </li>
            <li>
              You want to take AUA of Rs.
              <b>{data?.getPendingBookingAdvisory?.productExposure}</b>.
            </li>
            <li>
              You will invest&nbsp;
              <b>
                Rs.{data?.getPendingBookingAdvisory?.productInitialInvestment}
              </b>
              &nbsp;now. It indirectly means that you will save interest
              on&nbsp;
              <b>Rs.{data?.getPendingBookingAdvisory?.saveInterest}</b>.
            </li>
            <li>
              The total annual cost is around&nbsp;
              <b>{data?.getPendingBookingAdvisory?.productAnnual}%</b>.
            </li>
            <li>
              There is no lock-in period of investment in this strategy, still
              we recommend the logical period of investment is around 5 to 10
              Years.
            </li>
            <li>
              You also understand that you will have risk and return on full
              exposure as below:
              <p className='list_per_value'>
                Annual Hedging & Financing Cost:&nbsp;
                <b>{data?.getPendingBookingAdvisory?.productHedging}%</b>
              </p>
              <p className='list_per_value'>
                Add: AMC:&nbsp;
                <b>{data?.getPendingBookingAdvisory?.productAMC}%</b>
              </p>
              <p className='list_per_value'>
                Gross Total Cost:&nbsp;
                <b>{data?.getPendingBookingAdvisory?.grossTotal}%</b>
              </p>
              <p className='list_per_value'>
                Less: Interest saved:&nbsp;
                <b>{data?.getPendingBookingAdvisory?.productInterest}%</b>
              </p>
              <p className='list_per_value'>
                Total Annual Cost:&nbsp;
                <b>{data?.getPendingBookingAdvisory?.productAnnual}%</b>
              </p>
            </li>
            <li>
              Please note that the above cost is indicative as per the current
              market scenario & it may change from time to time.
            </li>
            <li>
              We advise you to invest above the gross total cost figure every
              month so that the above cost can be managed easily at the year-end
              on a monthly SIP basis.
            </li>
            <li>
              Your payment schedule will be&nbsp;
              <b>
                Rs.{data?.getPendingBookingAdvisory?.productInitialInvestment}
              </b>
              &nbsp;initially &&nbsp;
              <b>Rs.{data?.getPendingBookingAdvisory?.productSIP} </b>
              monthly.
            </li>
          </ul>
          <p>
            Let us know if you have any queries. We would like to help you with
            the same. Kindly send your confirmation after reading this mail so
            that we can proceed further.
          </p>
        </div>
        <Button className='green_btn' loading={loading} onClick={onFinish}>
          Confirm
        </Button>
      </div>
    </>
  )
}
export default TermsCondition
