import gql from 'graphql-tag'

export const UPDATE_BULK_PERFORMANCE_REPORT_SETTING = gql`
  mutation updateBulkPerformanceReportSetting($data: createBulkReportSetting) {
    updateBulkPerformanceReportSetting(data: $data) {
      id
      type
      performanceReportDate
      userType
      mailDraft
      createAt
      updatedAt
      autoReportDate
    }
  }
`

export const SEND_BULK_PERFORMANCE_REPORT_MAIL = gql`
  mutation sendBulkPerformanceMailSend($data: [inputForSendPerformanceReport]) {
    sendBulkPerformanceMailSend(data: $data)
  }
`
