import React from 'react'
import { Empty } from 'antd'

import './index.css'

export default function () {
  return (
    <div className='no-data'>
      <Empty />
    </div>
  )
}
