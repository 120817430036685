import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  Button,
  Col,
  PageHeader,
  Row,
  Select,
  DatePicker,
  Form,
  Table,
  Space,
  Input,
} from 'antd'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import { GET_ALL_CLIENT } from 'modules/Users/graphql/Queries'
import { SearchOutlined } from '@ant-design/icons'
import {
  GET_ALL_FIELD_FOR_LOG,
  GET_ALL_LOGS,
  EXPORT_CLIENT_LOGS,
} from './graphql/Queries'
import client from 'apollo'
import moment from 'moment'
import { get } from 'lodash'

const { Option } = Select
const { RangePicker } = DatePicker

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

export default function ClientLog() {
  const [formData] = Form.useForm()

  const dateFormat = 'DD-MMM-YYYY'

  const [load, setLoad] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [sort, setSort] = useState()
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [filedData, setFiledData] = useState({})

  const { data, loading, error } = useQuery(GET_ALL_CLIENT, {
    variables: { where: {}, limit: 10000 },
  })
  const {
    data: fieldData,
    loading: fieldLoading,
    error: fieldError,
  } = useQuery(GET_ALL_FIELD_FOR_LOG)
  const {
    data: logData,
    loading: logLoading,
    error: logError,
  } = useQuery(GET_ALL_LOGS, {
    variables: { where: filedData, sort, skip, limit },
    fetchPolicy: 'network-only',
  })
  if (error || fieldError || logError) return <Page404 />

  let totalCount = logData?.getAllLogs?.count
  const changeFormData = (value) => {
    if (value.date) {
      const createdAtFrom = value.date[0]
      const createdAtTo = value.date[1]

      value = {
        ...value,
        createdAtFrom,
        createdAtTo,
      }
      delete value.date
    } else if (!value.date) {
      value = { ...value }
      delete value.date
    }
    setFiledData(value)
    setLoad(false)
    // client.query({ query: GET_ALL_LOGS, variables: { where: value }, sort, skip, limit })
    //   .then(res => {
    //     setLogsData(res.data.getAllLogs.log)
    //     setLoad(false)
    //   })
    //   .catch(err => console.log(err))
  }

  const exportClientLog = (value) => {
    setExportLoading(true)
    client
      .query({
        query: EXPORT_CLIENT_LOGS,
        variables: { where: filedData, sort, skip: 0, limit: totalCount },
      })
      .then((res) => {
        setExportLoading(false)
        window.open(get(res, 'data.exportLogs.url'), '_blank')
      })
      .catch((err) => {
        setExportLoading(false)
      })
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    }
  }

  const handlePagination = (page) => {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  const handlePageChangeSize = (current, size) => {
    setLimit(size)
  }

  const formDataReset = () => {
    formData.resetFields()
    setFiledData({})
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filedData))
    tempFilters[filedName] = value
    setFiledData({ fieldName: tempFilters[filedName] })
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filedData))
    delete tempFilters[filedName]
    setFiledData(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'client',
      key: 'client',
      className: 'client-log-table',
      render: (record) => `${record?.firstName} ${record.lastName}`,
    },
    {
      title: 'Client Email',
      dataIndex: 'client',
      key: 'client',
      render: (record) => `${record?.email}`,
      sorter: (a, b) => a.record?.email - b.record?.email,
    },
    {
      title: 'Updated Field Name',
      dataIndex: 'fieldName',
      key: 'fieldName',
      sorter: (a, b) => a.fieldName - b.fieldName,
      ...getColumnSearchProps('fieldName'),
    },
    {
      title: 'Old Data',
      dataIndex: 'oldData',
      key: 'oldData',
      sorter: (a, b) => a.oldData - b.oldData,
    },
    {
      title: 'New Data',
      dataIndex: 'newData',
      key: 'newData',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    // {
    //   title: 'Updated Date',
    //   dataIndex: 'updatedAt',
    //   key: 'updatedAt',
    //   render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
    // }
  ]

  return (
    <>
      <PageHeader
        className='box'
        title='Client Log'
        extra={[
          <Button
            key='1'
            type='primary'
            onClick={exportClientLog}
            loading={exportLoading}
          >
            Export
          </Button>,
        ]}
      />
      <Form
        {...formItemLayout}
        form={formData}
        name='clientLog'
        className='user-form'
        onFinish={changeFormData}
      >
        {!loading ? (
          <>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label='Select Client' name='clientId'>
                  <Select
                    placeholder='Select Client'
                    showSearch
                    allowClear
                    optionFilterProp='children'
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {!loading &&
                      !error &&
                      data?.getClientUsers?.users.map((ele, index) => {
                        return (
                          <Option value={ele.id} key={index}>
                            {ele.clientCode}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Select Field' name='fieldName'>
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp='children'
                    placeholder='Select Field'
                  >
                    {!fieldLoading &&
                      !fieldError &&
                      fieldData?.getAllFieldsForLog.map((ele, index) => {
                        return (
                          <Option value={ele} key={index}>
                            {' '}
                            {ele}{' '}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} style={{ display: 'flex' }}>
                <Form.Item label='Date' name='date'>
                  <RangePicker format={dateFormat} />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Form.Item>
                <Button type='primary' htmlType='submit' loading={load}>
                  Search
                </Button>
              </Form.Item>
              <Form.Item style={{ marginLeft: '20px' }}>
                <Button
                  htmlType='button'
                  type='primary'
                  onClick={formDataReset}
                >
                  Reset
                </Button>
              </Form.Item>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Form>
      {!loading && logData?.getAllLogs?.log && (
        <Table
          columns={columns}
          loading={logLoading}
          onChange={handleTableChange}
          dataSource={logData?.getAllLogs?.log}
          scroll={{ x: true, y: true }}
          rowKey='id'
          pagination={{
            pageSize: limit,
            total: totalCount,
            current: currentPage,
            onChange: handlePagination,
            onShowSizeChange: handlePageChangeSize,
          }}
        />
      )}
    </>
  )
}
