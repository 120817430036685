import gql from 'graphql-tag'

export const CREATE_COMPANY_SUMMARY = gql`
  mutation CreateCompanyDetails($data: companyDetailsInput) {
    createCompanyDetails(data: $data) {
      id
      marketExperience
      advisoryAua
      advisoryAuaDate
      createdAt
      updatedAt
      referralPartner
    }
  }
`

export const UPDATE_COMPANY_SUMMARY = gql`
  mutation UpdateCompanyDetails($id: ID!, $data: companyDetailsInput) {
    updateCompanyDetails(id: $id, data: $data) {
      id
      marketExperience
      advisoryAua
      advisoryAuaDate
      referralPartner
      createdAt
      updatedAt
    }
  }
`

export const DELETE_COMPANY_SUMMARY = gql`
  mutation DeleteCompanyDetails($id: Int!) {
    deleteCompanyDetails(id: $id)
  }
`
