import React from 'react'
import { Modal, Button } from 'antd'
import Success from '../../../assets/images/sucess.png'
import './pendingAdvisory.css'

export const ConfirmAdvisoryModal = ({ visible, onOk, confirmLoading }) => {
  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        maskClosable={false}
        className='book_advisory_modal'
      >
        <div className='confirm_main_div'>
          <div className='confirm_img'>
            <img src={Success} alt='success' width={100} />
          </div>
          <div className='confirm_text_div'>
            <div className='confirm_text_second_div'>
              <span className='confirm_text'>
                Your trades have been successfully uploaded and you will be able
                to see your Performance report within 48 hours.
              </span>
            </div>
          </div>
          <div className='confirm_btn_div'>
            <Button
              type='primary'
              onClick={() => onOk()}
              loading={confirmLoading}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
