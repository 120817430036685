import React, { useState } from 'react'
import { PageHeader, Table, Tooltip } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import { GET_ANALYSIS_EXPIRY_DATE } from './graphql/Queries'
import UpdateAnalysisExpiryDateModal from './UpdateAnalysisExpiryDateModal'
import client from 'apollo'
import { UPDATE_ANALYSIS_EXPIRY_DATE } from './graphql/Mutations'
import moment from 'moment'

const AnalysisExpiryDate = () => {
  const [openModal, setOpenModal] = useState(false)
  const [updateData, setUpdateData] = useState({})
  const [btnLoading, setBtnLoading] = useState(false)

  const { data, loading, error } = useQuery(GET_ANALYSIS_EXPIRY_DATE, {
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 />

  const renderAction = (record) => {
    return (
      <Tooltip title='Edit'>
        <EditTwoTone
          onClick={() => {
            setUpdateData(record)
            setOpenModal(true)
          }}
        />
      </Tooltip>
    )
  }
  const columns = [
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
    },
    {
      title: 'Action',
      data: 'action',
      key: 'action',
      width: 120,
      render: (text, record) => renderAction(record),
    },
  ]

  const updateAnalysisExpiryDate = (values) => {
    setBtnLoading(true)
    client
      .mutate({
        mutation: UPDATE_ANALYSIS_EXPIRY_DATE,
        variables: {
          data: { expiryDate: moment(values?.expiryDate).format('YYYY-MM-DD') },
          where: { id: parseInt(updateData?.id) },
        },
        refetchQueries: [
          {
            query: GET_ANALYSIS_EXPIRY_DATE,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setUpdateData({})
        setBtnLoading(false)
        setOpenModal(false)
      })
      .catch((err) => {
        setUpdateData({})
        setBtnLoading(false)
        setOpenModal(false)
      })
      .finally(() => {
        setUpdateData({})
        setBtnLoading(false)
        setOpenModal(false)
      })
  }
  return (
    <>
      {!loading && <PageHeader className='box' title='Analysis Expiry Date' />}
      <Table
        loading={loading}
        columns={columns}
        dataSource={data?.getAnalysisExpiryDate}
        pagination={false}
      />

      {openModal && (
        <UpdateAnalysisExpiryDateModal
          openModal={openModal}
          updateData={updateData}
          onCancel={() => setOpenModal(false)}
          btnLoading={btnLoading}
          onFinish={updateAnalysisExpiryDate}
        />
      )}
    </>
  )
}

export default AnalysisExpiryDate
