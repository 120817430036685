import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  Button,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Input,
} from 'antd'

import '../index.css'
import { getName } from 'utils/User'
import Page404 from 'components/Page404'
import IltsShiftTable from './IltsShiftTable'
import DownloadReport from '../DownloadReport'
import { GET_ILTS_SHIFT, GET_NIFTY } from '../graphql/Queries'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import Loader from 'components/loaders/Loader'
import moment from 'moment'
import client from 'apollo'
import { AppContext } from 'AppContext'
import { orderBy } from 'lodash'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { GET_ANALYSIS_EXPIRY_DATE } from 'modules/AnalysisExpiryDate/graphql/Queries'

const { Option } = Select

export default function IltsShift() {
  const [form] = Form.useForm()
  let [usersData, setUsersData] = useState([])
  const [userLoadingClient, setUserLoading] = useState(true)

  const {
    state: { currentUser, isClient },
  } = useContext(AppContext)

  const [newLevelData, setNewLevelData] = useState(
    !isClient ? undefined : 19000
  )

  const { data: AnalysisExpiryDate, loading: AnalysisExpiryDateLoading } =
    useQuery(GET_ANALYSIS_EXPIRY_DATE, { fetchPolicy: 'network-only' })

  const [filters, setFilters] = useState({
    where: {
      clientCode: !isClient ? '' : `${currentUser?.clientCode}`,
      analysisLevel: 19000,
      analysisExp: '2024-12-26',
      final: new Date().toISOString(),
      step: 3000,
      intRate: 9,
      round: 1000,
    },
  })

  useEffect(() => {
    if (!AnalysisExpiryDateLoading && AnalysisExpiryDate) {
      setFilters({
        where: {
          ...filters.where,
          analysisExp: AnalysisExpiryDate?.getAnalysisExpiryDate[0]?.expiryDate,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AnalysisExpiryDateLoading, AnalysisExpiryDate])

  let year = moment().format('YYYY')
  let month = moment().format('MM')
  let date = moment().format('DD')

  useEffect(() => {
    setUserLoading(true)
    client
      .query({
        query: GET_CLIENT_USERS_FOR_STAFF,
        variables: {
          where: {},
          limit: 10000,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        res.data.getClientUsersForStaff.users.map((user) => {
          return usersData.push(user)
        })
        setUsersData(usersData)
        setUserLoading(false)
      })
      .catch((err) => console.log({ err }))
      .finally(() => setUserLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_ILTS_SHIFT, {
    variables: filters,
    fetchPolicy: 'network-only',
  })

  let ieltsShift = data?.ieltsShift?.response
  const clientCode = data?.ieltsShift?.clientCode
  const clientName = data?.ieltsShift?.clientName

  const { data: niftyData, loading: niftyLoader } = useQuery(GET_NIFTY, {
    fetchPolicy: 'network-only',
  })
  // let selectedClient
  // if (
  //   !userLoading &&
  //   get(userData, 'getUsers.users') &&
  //   get(filters, 'where.clientCode')
  // ) {
  //   selectedClient = get(userData, 'getUsers.users').filter(
  //     (u) => u.clientCode === filters.where.clientCode
  //   )[0]
  // }

  if (userLoadingClient || loading || AnalysisExpiryDateLoading)
    return <Loader />

  const columns = [
    {
      title: (
        <NavLink
          to='#'
          style={{
            color: '#000',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Scenario Analysis
        </NavLink>
      ),
      className: 'report-title main-title',
      children: [
        {
          title: (
            <div className='hidden-client-data'>
              Client Code:{' '}
              <span className='hidden-client-code'>{clientCode || ''}</span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Particulars',
              dataIndex: 'particulars',
              key: 'particulars',
              className: 'report-text',
            },
          ],
        },
        {
          title: (
            <div className='hidden-client-data'>
              Client Name:{' '}
              <span className='hidden-client-code'>{clientName || ''}</span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Level1',
              dataIndex: 'level1',
              key: 'level1',
              className: 'report-numeric',
            },
            {
              title: 'Level2',
              dataIndex: 'level2',
              key: 'level2',
              className: 'report-numeric',
            },
            {
              title: 'Level3',
              dataIndex: 'level3',
              key: 'level3',
              className: 'report-numeric',
            },
            {
              title: 'Level4',
              dataIndex: 'level4',
              key: 'level4',
              className: 'report-numeric',
            },
            {
              title: 'Level5',
              dataIndex: 'level5',
              key: 'level5',
              className: 'report-numeric',
            },
          ],
        },
      ],
    },
  ]

  const onFinish = (values) => {
    setNewLevelData(values?.analysisLevel)
    const data = {
      ...values,
      analysisExp: moment(values.analysisExp).format('YYYY-MM-DD'),
      final: new Date(moment(values.final).add(330, 'minutes')).toISOString(),
      intRate: values.intRate,
    }
    setFilters({ where: data })
  }
  let csvInput = ieltsShift?.map((obj, index) => {
    const newObj = {}
    for (const key in obj) {
      const newKey = key.charAt(0).toUpperCase() + key.slice(1)
      newObj[newKey] = obj[key]
    }
    return newObj
  })

  const customData = [
    'Client Code:',
    `${clientCode ? clientCode : ''}`,
    '',
    '',
    'Client Name:',
    `${clientName ? clientName : ''}`,
  ]
  const keys = Object.keys(csvInput[0])
  const dataArray = csvInput.map((obj) => keys.map((key) => obj[key]))
  const output = [keys, ...dataArray]

  const csvContent = [
    customData.join(','),
    '',
    output.map((row) => row?.join(',')).join('\n'),
  ].join('\n')

  return (
    !niftyLoader && (
      <>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!error && ieltsShift && (
            <DownloadReport
              data={csvContent}
              fileName='ieltsShift-report'
              id='ieltsShift-REPORT-PDF'
            />
          )}
        </div>

        <div>
          <Row gutter={8}>
            <Col span={20}>
              <Form
                form={form}
                onFinish={onFinish}
                name='advanced_search'
                labelCol={{
                  span: 14,
                }}
                wrapperCol={{
                  span: 10,
                }}
                initialValues={{
                  step: 3000,
                  intRate: 9,
                  round: 1000,
                  analysisLevel:
                    niftyData &&
                    Math.round(niftyData?.getNifty50[0]?.CMP / 1000, 0) * 1000,
                  clientCode: !isClient
                    ? usersData[0]?.userName
                    : `${currentUser?.clientCode}`,
                  analysisExp: AnalysisExpiryDate?.getAnalysisExpiryDate
                    ? moment(
                        AnalysisExpiryDate?.getAnalysisExpiryDate[0]?.expiryDate
                      )
                    : moment(`${year}-12-26`),
                  final: moment(`${year}-${month}-${date}`),
                }}
              >
                <Row>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='clientCode'
                      label='User'
                      rules={[
                        { required: true, message: 'Please select users!' },
                      ]}
                      hasFeedback
                    >
                      {!isClient ? (
                        <Select
                          allowClear
                          showSearch
                          filterOption={true}
                          optionFilterProp='title'
                          placeholder='Please select users'
                        >
                          {!userLoadingClient &&
                            usersData.length &&
                            orderBy(usersData, 'id', 'desc').map(
                              (user, key) => {
                                return (
                                  user.clientCode && (
                                    <>
                                      <Option
                                        key={key}
                                        value={user.clientCode}
                                        title={`${user.clientCode} - ${getName(
                                          user
                                        )}`}
                                      >
                                        {user.clientCode} - {getName(user)}
                                      </Option>
                                    </>
                                  )
                                )
                              }
                            )}
                        </Select>
                      ) : (
                        <Input name='clientCode' readOnly />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='analysisLevel'
                      label='New Level'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select Analysis Level!',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder='Analysis Level'
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='analysisExp'
                      label='New Expiry'
                      rules={[
                        { required: true, message: 'Please select date!' },
                      ]}
                    >
                      <DatePicker
                        // format={'DD-MMM-YYYY'}
                        autoComplete='off'
                        showToday={false}
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='final'
                      label='Reporting Date'
                      rules={[
                        { required: true, message: 'Please select date!' },
                      ]}
                    >
                      <DatePicker
                        // format={'DD-MMM-YYYY'}
                        autoComplete='off'
                        showToday={false}
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='step'
                      label='Interval'
                      rules={[
                        { required: true, message: 'Please Select step!' },
                      ]}
                    >
                      <InputNumber
                        placeholder='Step'
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='intRate'
                      label='Interest'
                      rules={[
                        { required: true, message: 'Please Select intRate!' },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace('%', '')}
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='round'
                      label='Round'
                      rules={[
                        { required: true, message: 'Please select users!' },
                      ]}
                    >
                      <Select
                        loading={userLoadingClient}
                        optionFilterProp='title'
                        placeholder='Please select users'
                        style={{ width: '150px' }}
                      >
                        <Option value={1}>1</Option>
                        <Option value={10}>10</Option>
                        <Option value={100}>100</Option>
                        <Option value={1000}>1000</Option>
                        <Option value={10000}>10000</Option>
                        <Option value={100000}>Lakh</Option>
                        <Option value={1000000}>Million</Option>
                        <Option value={10000000}>Crore</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}></Col>
                </Row>

                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type='primary' htmlType='submit'>
                      Search
                    </Button>
                    <Button
                      onClick={() => {
                        form.resetFields()
                        setFilters({
                          where: {
                            clientCode: !isClient
                              ? ''
                              : `${currentUser?.clientCode}`,
                            analysisLevel: 19000,
                            analysisExp:
                              AnalysisExpiryDate?.getAnalysisExpiryDate
                                ? AnalysisExpiryDate?.getAnalysisExpiryDate[0]
                                    ?.expiryDate
                                : '2024-12-26',
                            final: new Date().toISOString(),
                            step: 3000,
                            intRate: 9,
                            round: 1000,
                          },
                        })
                      }}
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>

        {error ? (
          <Page404 error={error} isShow={false} />
        ) : (
          <div className='report-table-wrapper' id='ieltsShift-REPORT-PDF'>
            <IltsShiftTable
              reportData={ieltsShift || []}
              columns={columns}
              loading={loading}
              newLevelData={newLevelData}
            />
          </div>
        )}
      </>
    )
  )
}
