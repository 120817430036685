import gql from 'graphql-tag'

export const GET_ALL_TRANSACTIONS = gql`
  query getAllTransaction(
    $where: transactionWhereUniqueInput!
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getAllTransaction(where: $where, skip: $skip, sort: $sort, limit: $limit) {
      count
      transactions {
        id
        userId
        amcId
        amount
        payerUpiId
        transactionId
        upiTxnNo
        paidDate
        status
        statusDescription
        responseCode
        payerBankName
        payerBankIfsc
        cusRefNo
        payType
        txnNo
        createdAt
        updatedAt
        invoiceNo
        payer {
          id
          firstName
          lastName
          userName
          clientCode
          address
        }
        AMC {
          id
          paymentType
          amcQty
          amcPaidForPeriod
        }
      }
    }
  }
`

export const GET_TRANSACTION = gql`
  query getTransaction($where: transactionWhereUniqueTransactionIDInput!) {
    getTransaction(where: $where) {
      id
      userId
      amcId
      amount
      payerUpiId
      transactionId
      upiTxnNo
      paidDate
      status
      statusDescription
      responseCode
      payerBankName
      payerBankIfsc
      cusRefNo
      payType
      txnNo
      createdAt
      updatedAt
      invoiceNo
      payer {
        id
        firstName
        lastName
        userName
        clientCode
        address
      }
      AMC {
        id
        paymentType
        amcQty
        amcPaidForPeriod
      }
    }
  }
`
