import React, { Fragment, useState } from 'react'
import omit from 'lodash/omit'
import { PageHeader } from 'antd'

import client from 'apollo'
import UserForm from './components/UserForm'
import { CREATE_USER, UPDATE_KYC } from './graphql/Mutations'
import openNotification from 'utils/Notification'
import moment from 'moment'
import { get, isEmpty, pick } from 'lodash'

export default function ({ history }) {
  const [loading, setLoading] = useState()
  const [uploadDataLoader, setUploadDataLoader] = useState(false)

  function getFilesObject(tempFiles) {
    let files = pick(tempFiles, ['cancelledChequeSnap', 'associateAgreement'])

    if (get(files, 'cancelledChequeSnap[0].originFileObj')) {
      files.cancelledChequeSnap = get(
        files,
        'cancelledChequeSnap[0].originFileObj'
      )
    } else {
      delete files.cancelledChequeSnap
    }
    if (get(files, 'associateAgreement[0].originFileObj')) {
      files.associateAgreement = get(
        files,
        'associateAgreement[0].originFileObj'
      )
    } else {
      delete files.associateAgreement
    }
    return files
  }

  const onFinish = (values) => {
    setUploadDataLoader(true)
    const fileValues = {
      cancelledChequeSnap: values?.cancelledChequeSnap,
      associateAgreement: values?.associateAgreement,
    }
    delete values.cancelledChequeSnap
    delete values.associateAgreement

    values = {
      ...values,
      firstName:
        values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1),
      lastName:
        values.lastName.charAt(0).toUpperCase() + values.lastName.slice(1),
      email: values.email.toLowerCase(),
      dateOfBirth: moment(values?.dateOfBirth).format('YYYY-MM-DD'),
    }
    setLoading(true)
    const queryVariables = { data: omit(values, 'confirm') }
    client
      .mutate({ mutation: CREATE_USER, variables: queryVariables })
      .then((res) => {
        setUploadDataLoader(false)
        const files = getFilesObject(fileValues)
        if (isEmpty(files)) {
          openNotification('success', 'User Created Successfully')
          history.goBack()
        } else {
          setUploadDataLoader(true)
          client
            .mutate({
              mutation: UPDATE_KYC,
              variables: { id: +res?.data?.createUser?.id, ...files },
            })
            .then((res) => {
              setUploadDataLoader(true)
              setLoading(false)
              openNotification('success', 'User Created Successfully')
              history.goBack()
            })
            .catch((err) => console.log(err))
        }
      })
  }

  return (
    <Fragment>
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title='Add New User'
      />
      <UserForm onFinish={onFinish} loading={loading || uploadDataLoader} />
    </Fragment>
  )
}
