import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { AppContext } from 'AppContext'
import { getName, getCityState } from 'utils/User'

export default function () {
  const { state: { currentUser, isClient } } = useContext(AppContext)

  return (
    <div className='gx-profile-banner'>
      <div className='gx-profile-container'>
        <div className='gx-profile-banner-top'>
          <div className='gx-profile-banner-top-left'>
            <div className='gx-profile-banner-avatar-info'>
              <h2 className='gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light'>{getName(currentUser)}</h2>
              <p className='gx-mb-0 gx-fs-lg'>{isClient ? getCityState(currentUser) : currentUser.role.split('_').join(' ')}</p>
            </div>
          </div>
          <div className='gx-profile-banner-top-right'>
            <NavLink to='/profile/edit' className='nav-text'>
              <span className='gx-link gx-profile-setting'>
                <i className='icon icon-setting gx-fs-lg gx-mr-1 gx-mr-sm-2 gx-d-inline-flex gx-vertical-align-middle' />
                <span className='gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0'>Edit Details</span>
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}
