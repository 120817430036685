/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { get, uniqBy } from 'lodash'
import ReactQuill from 'react-quill'
import { useQuery } from '@apollo/react-hooks'
import { USER_PUBLIC } from 'modules/Users/graphql/Queries'
import Loader from 'components/loaders/Loader'
import { SEND_DYNAMIC_EMAIL } from 'modules/Settings/graphql/Mutations'
import client from 'apollo'
import openNotification from 'utils/Notification'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const PerformanceEmail = ({
  setOpenReactQuill,
  invoiceCreateData,
  clientCode,
  performanceReportLink,
  buttonLoading,
}) => {
  const { emailId, ccMailIds } = invoiceCreateData
  const [onMailSend, setOnMailSend] = useState(false)
  const [subjectForMail, setSubjectForMail] = useState('')
  const [toMailOnChange, setToMailOnChange] = useState(
    emailId ? (typeof emailId === 'object' ? emailId : [emailId]) : []
  )
  const [ccMail, setCcMail] = useState(
    ccMailIds
      ? ccMailIds?.map((item) => {
          return {
            email: item,
          }
        })
      : []
  )
  const [receiveMailTemplate, setReceiveMailTemplate] = useState('')

  let userEmails = []

  const { data: userData, loading: userLoading } = useQuery(USER_PUBLIC, {
    fetchPolicy: 'network-only',
  })

  let clientName
  if (!userLoading && get(userData, 'getUsersPublic.users')) {
    userData?.getUsersPublic?.users.forEach((item, key) => {
      if (item.clientCode === clientCode) {
        clientName = `${item?.firstName} ${item?.lastName}`
      }
      userEmails.push({
        key,
        label: item?.email,
        value: item?.email,
      })
    })
  }

  useEffect(() => {
    setSubjectForMail(`Finideas Index Long Term Strategy Performance Report`)
  }, [clientName])

  useEffect(() => {
    if (!userLoading && clientName) {
      const InvoiceExplanation = `
      <div>
      Dear ${clientName},<br/>
      <br/>
      Greetings from Finideas !!!!
      <br/>
      <div>&nbsp;</div>
      We are happy to serve you. Please find the attached performance report of your investment in Index Long Term Strategy.</div>

      <div>&nbsp;</div>

      <div>Kindly get in touch with us by Email in case of any queries.<br><br/>
      <br> Regards,<br> Team Finideas</div>`
      setReceiveMailTemplate(InvoiceExplanation)
    }
  }, [userLoading, clientName])

  const handleMailSend = () => {
    setOnMailSend(true)
    let data = {
      subject: subjectForMail,
      toMailId: toMailOnChange,
      mailBody: receiveMailTemplate,
      attachedPdf: performanceReportLink,
      pdfName: `ILTS-PERFORMANCE-REPORT-${clientCode}`,
    }

    if (ccMail.length) {
      data.ccMailIds = ccMail
    }

    ccMail?.length &&
      client
        .mutate({
          mutation: SEND_DYNAMIC_EMAIL,
          variables: { data },
        })
        .then((res) => {
          openNotification('success', 'Mail Send Successfully')
          setOpenReactQuill(false)
          setOnMailSend(false)
        })
        .catch((err) => {
          setOnMailSend(true)
          setOpenReactQuill(false)
        })
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],

      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],

      ['clean'],
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  const handleEditInputChange = (e) => {
    const array = e.map((email) => {
      return {
        email,
      }
    })

    console.log(array)
    setCcMail(array)
  }

  const handleToMailChange = (e) => {
    setToMailOnChange(e)
  }

  return !clientName && !buttonLoading ? (
    <Loader />
  ) : (
    <>
      <Form
        {...formItemLayout}
        layout='vertical'
        initialValues={{
          emailId: emailId,
          ccMails: ccMailIds,
        }}
      >
        <Row>
          <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label='Subject'>
              <Input
                value={subjectForMail}
                placeholder='Enter Subject'
                onChange={(e) => setSubjectForMail(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={10} lg={10} xl={10}>
            <FormItem label='To Mail' name='emailId'>
              <Select
                mode='tags'
                allowClear
                style={{ width: '100%' }}
                placeholder='Please select'
                onChange={(e) => handleToMailChange(e)}
                options={uniqBy(userEmails, 'value')}
              />
            </FormItem>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={10} lg={10} xl={10}>
            <Form.Item label='CC Mail' name='ccMails'>
              <Select
                mode='tags'
                allowClear
                style={{ width: '100%' }}
                placeholder='Please select'
                onChange={(e) => handleEditInputChange(e)}
                options={uniqBy(userEmails, 'value')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <ReactQuill
        theme='snow'
        value={receiveMailTemplate}
        onChange={(value) => {
          setReceiveMailTemplate(value)
        }}
        modules={modules}
        formats={formats}
      />
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button
          type='primary'
          onClick={emailId || toMailOnChange ? handleMailSend : ''}
          style={{ marginTop: '20px' }}
          loading={onMailSend}
          disabled={ccMail?.length === 0 || toMailOnChange?.length === 0}
        >
          Send Mail
        </Button>
        <Button
          type='primary'
          style={{ marginTop: '20px' }}
          onClick={() => {
            setOpenReactQuill(false)
          }}
        >
          Cancel
        </Button>
      </Form.Item>
    </>
  )
}

export default PerformanceEmail
