import gql from 'graphql-tag'

export const SEND_PNL_MAIL = gql`
  mutation plChangeMail($data: [PlChangeWhereUserInput]) {
    plChangeMail(data: $data)
  }
`

export const BULK_INVOICE = gql`
  mutation bulkInvoice(
    $data: [InvoiceMasterCreateInput]
    $where: SelectAllInvoiceForBulk
  ) {
    bulkInvoice(data: $data, where: $where)
  }
`
