import gql from 'graphql-tag'

export const GET_ALL_NSE_HOLIDAYS = gql`
  query GetHoliday {
    getHoliday {
      date
      id
      title
    }
  }
`

export const GET_NSE_HOLIDAY = gql`
  query GetHolidayById($where: HolidayWhereInput) {
    getHolidayById(where: $where) {
      id
      date
      title
    }
  }
`
