import React from 'react'
import { Modal, Form, Input, InputNumber } from 'antd'

const CollectionCreateForm = ({ visible, onCreate, onUpdate, onCancel, isEdit, selectedPlan }) => {
  const [form] = Form.useForm()

  function resetForm() {
    form.resetFields()
  }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={isEdit ? 'Edit Plan' : 'Add New Plan'}
      okText={isEdit ? 'Update Plan' : 'Add Plan'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            const data = {
              ...values,
            }
            if (isEdit) {
              onUpdate(data, resetForm)
            } else {
              onCreate(data, resetForm)
            }
          })
          .catch(info => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
          ...selectedPlan
        }}
      >
        <Form.Item
          name='name'
          label='Plan Name'
          rules={[{ required: true, message: 'Please input your plan name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='description'
          label='Plan Description'
          rules={[{ required: true, message: 'Please input your description!' }]}
          hasFeedback
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name='minPoints'
          label='Min Points'
          rules={[{ required: true, message: 'Please input your Min Points!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name='maxPoints'
          label='Max Points'
          rules={[{ required: true, message: 'Please input your Max Points!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name='percentage'
          label='Percentage'
          rules={[{ required: true, message: 'Please input your Percentage!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name='stock'
          label='Stock'
          rules={[{ required: true, message: 'Please input your Stock!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name='sip'
          label='Sip'
          rules={[{ required: true, message: 'Please input your Sip!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name='pnlOn'
          label='Pnl On'
          rules={[{ required: true, message: 'Please input your Pnl On!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name='interestFromDebt'
          label='interestFromDebt'
          rules={[{ required: true, message: 'Please input your interest From Debt!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name='features'
          label='Features'
          rules={[{ required: true, message: 'Please input your Features!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='planRiskType'
          label='Plan Risk Type'
          rules={[{ required: true, message: 'Please input your Plan Risk Type!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='planRisk'
          label='Plan Risk'
          rules={[{ required: true, message: 'Please input your Plan Risk!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='minRisk'
          label='Min Risk'
          rules={[{ required: true, message: 'Please input your Min Risk !' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name='maxRisk'
          label='Max Risk'
          rules={[{ required: true, message: 'Please input your Max Risk!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name='tCost'
          label='T Cost'
          rules={[{ required: true, message: 'Please input your T Cost!' }]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreatePlanModal = (props) => {
  return (
    <div>
      <CollectionCreateForm
        {...props}
      />
    </div>
  )
}

export default CreatePlanModal
