import React, { useState } from 'react'
import { PageHeader, Table, Tooltip } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'

import Page404 from 'components/Page404'
import { GET_ADVISORY_TIME } from './graphql/Query'
import CreateAdvisoryTime from './CreateAdvisoryTime'

const AdvisoryTime = () => {
  const [openModal, setOpenModal] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  let { data, loading, error } = useQuery(GET_ADVISORY_TIME, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 />

  const handleCancel = () => {
    setOpenModal(false)
  }

  const updateAdvisoryTime = (values, resetForm) => {}

  const renderAction = (data) => {
    return (
      <div className='action-icons'>
        <Tooltip title='Edit'>
          <EditTwoTone onClick={() => setOpenModal(!openModal)} />
        </Tooltip>
      </div>
    )
  }

  const submitAdvisoryTime = (values, resetForm) => {
    updateAdvisoryTime(values, resetForm)
  }

  const columns = [
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      render: (startTime) => {
        const formattedStartTime = startTime?.slice(0, 8)
        return formattedStartTime
      },
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      render: (endTime) => {
        const formattedEndTime = endTime?.slice(0, 8)
        return formattedEndTime
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(data?.getAdvisoryTime),
      width: 100,
    },
  ]

  return (
    <>
      <PageHeader className='box' title='Advisory Working Time Dashboard' />

      <Table
        loading={loading}
        dataSource={
          data && data?.getAdvisoryTime ? [data?.getAdvisoryTime] : []
        }
        columns={columns}
      />

      {openModal && (
        <CreateAdvisoryTime
          onCreate={submitAdvisoryTime}
          isModalOpen={openModal}
          setOpenModal={setOpenModal}
          setModalLoading={setModalLoading}
          handleCancel={handleCancel}
          modalLoading={modalLoading}
          data={data}
        />
      )}
    </>
  )
}

export default AdvisoryTime
