import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
  Collapse,
  Radio,
  Input,
  Checkbox,
  Form,
  Button,
  // Select,
  Tag,
  Row,
  Col,
} from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { get, includes, isEmpty, sum } from 'lodash'

import '../verification.css'
import { GET_SECTIONS, GET_USER_ANSWERS } from '../graphql/Queries'
import Loader from '../../../components/loaders/Loader'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import { AppContext } from 'AppContext'
// import { GET_PLANS } from 'modules/Plans/graphql/Query'
import RiskProfile from '../Model/RiskProfileModel'
import _sortBY from 'lodash/sortBy'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import { CONSENT_DATA, RISK_ASSESSMENT_MAIL } from '../graphql/Mutations'
import client from 'apollo'
import openNotification from 'utils/Notification'

const { Panel } = Collapse
const FormItem = Form.Item
// const { Option } = Select
export default function ({ next, reEvaluate }) {
  let initialValues = {}
  const [form] = Form.useForm()
  const [formValue, setFormValue] = useState({})
  const [showVerifyModal, setShowVerifyModal] = useState(false)
  const [points, setPoints] = useState({})
  // const [planValue, setPlanValue] = useState(0)
  const [reEvaluateValue, setReEvaluateValue] = useState(reEvaluate)
  const [loadingData, setLoadingData] = useState(false)
  const {
    dispatch,
    state: {
      currentUser: { annualIncome, cvlStatus, id, plan, isPanCardIndividual },
    },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_SECTIONS, {
    variables: {
      where: {
        // planId: parseInt(plan?.id),
        forNonIndividual: !isPanCardIndividual,
        planName: 'Power Booster Plan',
      },
    },
    fetchPolicy: 'network-only',
  })
  const {
    data: userAnswers,
    loading: userAnswersLoading,
    error: userAnswersError,
  } = useQuery(
    GET_USER_ANSWERS,
    { variables: { where: { planName: 'Power Booster Plan' } } },
    { fetchPolicy: 'network-only' }
  )

  // const {
  //   data: getPlan,
  //   loading: planLoading,
  //   error: planError,
  // } = useQuery(GET_PLANS, { fetchPolicy: 'network-only' })

  // useEffect(() => {
  //   if (!planLoading && getPlan && !plan.id) {
  //     const planId = getPlan?.getPlans[0]?.id
  //     // const planId = getPlan?.getPlans.find(plan => plan.name.toString() === 'Basic')?.id
  //     setPlanValue(parseInt(planId))
  //   }
  // }, [getPlan, planLoading, plan.id])

  useEffect(() => {
    if (!userAnswersLoading && userAnswers?.getUserAnswers?.length) {
      let tempPoints = {}
      userAnswers.getUserAnswers.forEach((answer) => {
        if (answer?.question) {
          if (answer.question.type === 'RADIO') {
            tempPoints[answer.question.id] = JSON.parse(
              answer.selectedOptions
            )[0].point
          } else if (answer.question.type === 'TEXT') {
            const option = JSON.parse(answer.selectedOptions)
            tempPoints[answer.question.id] =
              option.length && option[0]?.option ? option[0]?.point : 0
          } else {
            const option = JSON.parse(answer.selectedOptions)
            if (option.length) {
              tempPoints[answer.question.id] = sum(
                JSON.parse(answer.selectedOptions).map((d) => d.point)
              )
            }
          }
        }
      })
      setPoints(tempPoints)
    }
  }, [userAnswers, userAnswersLoading])

  useEffect(() => {
    let tempPoints = {}
    if (!loading && get(data, 'getSections')) {
      if (!userAnswers?.getUserAnswers?.length) {
        data.getSections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.type === 'RADIO') {
              question.options.forEach((option) => {
                if (option.isDefault) {
                  tempPoints[question.id] = option.point
                }
              })
            }
            if (question.type === 'CHECK_BOX') {
              tempPoints[question.id] = sum(
                question.options
                  .filter((options) => options.isDefault)
                  .map((option) => option?.point)
              )
            }
          })
        })
        setPoints(tempPoints)
      }
    }
  }, [data, loading, userAnswers])

  if (loading || userAnswersLoading) return <Loader />
  if (error || userAnswersError) return <Page404 error={error} />

  if (!loading && get(data, 'getSections')) {
    if (!userAnswers?.getUserAnswers?.length) {
      initialValues = getDefaultValues(data.getSections)
    }
  }

  if (get(userAnswers, 'getUserAnswers')) {
    userAnswers.getUserAnswers.forEach((d) => {
      if (d.question?.type === 'TEXT') {
        initialValues[d.question.id] = JSON.parse(d.selectedOptions)[0]?.option
      } else if (d.question?.type === 'RADIO') {
        initialValues[d.question?.id] = `${
          JSON.parse(d.selectedOptions)[0]?.id
        }-radio`
      } else {
        initialValues[d.question?.id] = JSON.parse(d.selectedOptions).map((o) =>
          o.id.toString()
        )
      }
    })
  }

  function getDefaultValues(sections) {
    let initialValues = {}
    sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.type === 'RADIO') {
          question.options.forEach((option) => {
            if (option.isDefault) {
              initialValues[question.id] = `${option.id}-radio`
            }
          })
        }
        if (question.type === 'CHECK_BOX') {
          initialValues[question.id] = question.options
            .filter((options) => options.isDefault)
            .map((option) => option?.id)
        }
      })
    })

    return initialValues
  }
  const onFinish = (values) => {
    setLoadingData(true)
    if (reEvaluateValue === true) {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id,
            data: {
              completedSteps: 'RISK_PROFILE',
              clientScore: sum(Object.values(points)),
            },
          },
        })
        .then((res) => {
          setLoadingData(false)
          client.mutate({ mutation: RISK_ASSESSMENT_MAIL })
          client.mutate({ mutation: CONSENT_DATA })
          dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
          openNotification('success', 'Risk Profile Updated Successfully')
          next()
        })
    } else {
      setShowVerifyModal(true)
      let temp = Object.keys(values)
        .filter((e) => e !== 'annualIncome')
        .map((e) => {
          const tempObject = {
            questionId: parseInt(e),
            planId: plan ? plan.id : null,
          }
          if (typeof values[e] === 'object') {
            tempObject['selectedOptionsId'] = values[e].map(Number)
          } else {
            if (includes(values[e], '-radio')) {
              tempObject['selectedOptionsId'] = parseInt(
                values[e].split('-')[0]
              )
            } else {
              tempObject['answer'] = values[e]
            }
          }
          return tempObject
        })
      setFormValue({
        answers: temp,
        annualIncome: values['annualIncome'],
      })
    }
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name)
  }

  // function handleChange(value) {
  //   setPlanValue(value)
  // }

  const checkBoxChange = (values, id, options) => {
    let tempPoints = { ...points }
    let point = 0
    values.forEach((value) => {
      point = point + options.find((option) => option.id === value).point
    })
    tempPoints[id] = point
    setPoints(tempPoints)
  }

  const radioChange = ({ target: { value } }, id, options) => {
    value = value.replace('-radio', '')
    let tempPoints = { ...points }
    tempPoints[id] = options.find((option) => option.id === value).point
    setPoints(tempPoints)
  }

  const inputChange = (id, point, { target: { value } }) => {
    let tempPoints = { ...points }
    tempPoints[id] = value ? point : 0
    setPoints(tempPoints)
  }

  const handleHideFormModal = () => {
    setLoadingData(false)
    setShowVerifyModal(false)
  }

  const editAccessFunc = () => {
    setReEvaluateValue(false)
  }
  return (
    <>
      <Row>
        <Col span={22} style={{ textAlign: 'center' }}>
          <h1>Risk Profile Assessment</h1>
        </Col>
        <Col span={2} style={{ textAlign: 'end' }}>
          {reEvaluateValue && (
            <Button type='primary' onClick={editAccessFunc}>
              Edit
            </Button>
          )}
        </Col>
      </Row>

      <div>
        <CustomMeta title='Risk Profile' />
        <div>
          <Row>
            <Col span={12}>
              {/* <div>
                <Select
                  defaultValue={planValue}
                  style={{ width: '40%' }}
                  onChange={handleChange}
                  disabled={!reEvaluateValue ? false : true}
                >
                  {getPlan &&
                    getPlan?.getPlans.map((plan, key) => {
                      return (
                        <Option key={key} value={parseInt(plan.id)}>
                          {plan.name}
                        </Option>
                      )
                    })}
                </Select>
              </div> */}
            </Col>
            <Col span={12} style={{ textAlign: 'end' }}>
              <Tag defaultValue={sum(Object.values(points))} color='red'>
                Score (Q/A) : {sum(Object.values(points))}
              </Tag>
              <Tag color={cvlStatus ? 'green' : 'red'}>
                PAN KYC Status ({cvlStatus ? 'YES' : 'NO'})
              </Tag>
            </Col>
          </Row>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          className='risk-profile-form'
          initialValues={{
            annualIncome: annualIncome || undefined,
            ...initialValues,
          }}
          onFinishFailed={onFinishFailed}
        >
          <div>
            <div className='gx-app-login-content verification'>
              <div className='collapse collapse-question'>
                {/* <Collapse defaultActiveKey={['1']}>
                  <Panel header='Annual Income' key='1'>
                    <div>
                      <p className='text-verification'>
                        1. What is your annual Income?
                      </p>
                      <FormItem
                        name='annualIncome'
                        rules={[
                          {
                            required: true,
                            message: 'Answer is require',
                          },
                        ]}
                      >
                        <Radio.Group disabled={!reEvaluateValue ? false : true}>
                          <Radio
                            value='1000000'
                            className='annual-radio-select'
                          >
                            Less than Rs.10 Lakhs
                          </Radio>
                          <Radio
                            value='5000000'
                            className='annual-radio-select'
                          >
                            Between 10 Lakh and 50 Lakh{' '}
                          </Radio>
                          <Radio
                            value='20000000'
                            className='annual-radio-select'
                          >
                            Between 50 Lakh and 2 Crore
                          </Radio>
                          <Radio
                            value='50000000'
                            className='annual-radio-select'
                          >
                            Between 2 Crore and 5 Crore
                          </Radio>
                          <Radio
                            value='100000000'
                            className='annual-radio-select'
                          >
                            Above Rs.5 Crores
                          </Radio>
                        </Radio.Group>
                      </FormItem>
                    </div>
                  </Panel>
                </Collapse> */}
                {_sortBY(data.getSections, ['index']).map(
                  (section, key, index) => {
                    return (
                      <Collapse defaultActiveKey={[key]} key={key}>
                        <Panel header={section.section} key={key}>
                          {_sortBY(section.questions, ['index']).map(
                            (question, key) => {
                              if (question.type === 'TEXT') {
                                return (
                                  <Fragment key={key}>
                                    <p className='text-verification'>
                                      {question.question}
                                      <span className='require-star'>
                                        {question.isRequired ? '*' : ''}
                                      </span>
                                    </p>
                                    <FormItem
                                      name={question.id}
                                      className='text-verification'
                                      rules={[
                                        {
                                          required: question.isRequired
                                            ? question.isRequired
                                            : false,
                                          message: 'Answer is require',
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder='Please enter here'
                                        className='verification-input-field'
                                        onChange={(value) =>
                                          inputChange(
                                            question.id,
                                            question.point,
                                            value
                                          )
                                        }
                                        disabled={
                                          !reEvaluateValue ? false : true
                                        }
                                      />
                                    </FormItem>
                                    <hr />
                                  </Fragment>
                                )
                              } else if (question.type === 'RADIO') {
                                return (
                                  <Fragment key={key}>
                                    <p className='text-verification'>
                                      {question.question}
                                      <span className='require-star'>
                                        {question.isRequired ? '*' : ''}
                                      </span>
                                    </p>
                                    <FormItem
                                      name={question.id}
                                      rules={[
                                        {
                                          required: question.isRequired
                                            ? question.isRequired
                                            : false,
                                          message: 'Answer is require',
                                        },
                                      ]}
                                    >
                                      <Radio.Group
                                        onChange={(e) =>
                                          radioChange(
                                            e,
                                            question.id,
                                            question.options
                                          )
                                        }
                                      >
                                        {question.options.map((option, key) => {
                                          return (
                                            <label
                                              key={key}
                                              style={{ whiteSpace: 'nowrap' }}
                                            >
                                              <Radio
                                                value={`${option.id}-radio`}
                                                key={key}
                                                disabled={
                                                  !reEvaluateValue
                                                    ? false
                                                    : true
                                                }
                                              >
                                                <span
                                                  style={{
                                                    whiteSpace: 'normal',
                                                  }}
                                                  className='wrapper'
                                                >
                                                  {option.option}
                                                </span>
                                              </Radio>
                                            </label>
                                          )
                                        })}
                                      </Radio.Group>
                                    </FormItem>
                                    <hr />
                                  </Fragment>
                                )
                              } else {
                                return (
                                  <Fragment key={key}>
                                    <p className='text-verification'>
                                      {question.question}
                                      <span className='require-star'>
                                        {question.isRequired ? ' * ' : ''}
                                      </span>
                                    </p>
                                    <FormItem
                                      name={question.id}
                                      rules={[
                                        {
                                          required: question.isRequired
                                            ? question.isRequired
                                            : false,
                                          message: 'Answer is require',
                                        },
                                      ]}
                                    >
                                      <Checkbox.Group
                                        className='checkbox'
                                        onChange={(value) =>
                                          checkBoxChange(
                                            value,
                                            question.id,
                                            question.options
                                          )
                                        }
                                      >
                                        {question.options.map((option, key) => {
                                          return (
                                            <Checkbox
                                              key={key}
                                              value={option.id}
                                              disabled={
                                                !reEvaluateValue ? false : true
                                              }
                                            >
                                              {option.option}
                                            </Checkbox>
                                          )
                                        })}
                                      </Checkbox.Group>
                                    </FormItem>
                                    <hr />
                                  </Fragment>
                                )
                              }
                            }
                          )}
                        </Panel>
                      </Collapse>
                    )
                  }
                )}
              </div>
            </div>
          </div>
          <div style={{ marginTop: '15px', textAlign: 'center' }}>
            <Button type='primary' htmlType='submit' loading={loadingData}>
              Verify And Next
            </Button>

            {showVerifyModal && !isEmpty(formValue) && (
              <RiskProfile
                section={data.getSections}
                next={next}
                formValue={formValue}
                visible={setShowVerifyModal}
                onCancel={() => handleHideFormModal()}
                points={sum(Object.values(points))}
                reEvaluate={reEvaluate}
              />
            )}
          </div>
        </Form>
      </div>
    </>
  )
}
