import gql from 'graphql-tag'

export const DELETE_POPULAR_BLOG = gql`
  mutation DeletePopularBlogs($id: Int!) {
    deletePopularBlogs(id: $id)
  }
`

export const CREATE_POPULAR_BLOG = gql`
  mutation CreatePopularBlogs($data: popularBlogsInput) {
    createPopularBlogs(data: $data) {
      id
      imageName
      imageUrl
      blogHeader
      createdAt
      updatedAt
    }
  }
`
