import React, { useEffect, useState } from 'react'
import { Button, PageHeader, Select } from 'antd'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Container from './Container'
import CreateSectionModal from './CreateSectionModal'
import { useQuery } from '@apollo/react-hooks'
import { CREATE_SECTION } from './graphql/Mutation'
import { GET_PLANS } from 'modules/Plans/graphql/Query'
import { GET_SECTIONS } from 'modules/Verification/graphql/Queries'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import openNotification from 'utils/Notification'
import client from 'apollo'

const { Option } = Select

const Questionnaires = () => {
  const [showFormModal, setShowFormModal] = useState(false)
  const [planValue, setPlanValue] = useState(null)
  const [userType, setUserType] = useState(false)

  const { data, loading, error } = useQuery(GET_PLANS)

  useEffect(() => {
    if (!loading && data && !planValue) {
      const planId = data?.getPlans[0]?.id
      // const planId = data?.getPlans.find(plan => plan.name.toString() === 'Basic Plan')?.id
      setPlanValue(parseInt(planId))
    }
  }, [data, loading, planValue])

  if (loading || !planValue) return <Loader />

  if (error) return <Page404 error={error} />
  const planName = data?.getPlans.find(
    (plan) => parseInt(plan.id) === planValue
  )?.name

  function handleHideFormModal() {
    setShowFormModal(false)
  }

  function handleCreateSection(values, resetForm) {
    setUserType(undefined)
    client.query({ query: GET_SECTIONS }).then((res) => {
      const data = {
        ...values,
        forNonIndividual: userType,
        index: res.data.getSections.length + 1,
      }

      client
        .mutate({
          mutation: CREATE_SECTION,
          variables: { data },
        })
        .then((res) => {
          setUserType(userType)
          openNotification('success', 'Section Added Successfully')
          handleHideFormModal()
          resetForm()
        })
        .catch((err) => console.log(err))
    })
  }

  function handleChange(value) {
    setPlanValue(value)
  }

  function handleChangeForUserType(value) {
    setUserType(value)
  }

  return (
    <>
      <PageHeader
        className='box'
        title='Questionnaires'
        extra={[
          <Button key='1' type='primary' onClick={() => setShowFormModal(true)}>
            Add Section
          </Button>,
        ]}
      />
      <div style={{ marginBottom: '15px' }}>
        <Select
          value={planValue}
          style={{ width: 120 }}
          onChange={handleChange}
        >
          {data &&
            data?.getPlans.map((plan, index) => {
              return (
                <Option value={parseInt(plan.id)} key={index}>
                  {plan.name}
                </Option>
              )
            })}
        </Select>
        &nbsp;&nbsp;
        <Select value={userType} onChange={handleChangeForUserType}>
          <Option value={false}>Individual</Option>
          <Option value={true}>Non-Individual</Option>
        </Select>
      </div>
      <DndProvider backend={HTML5Backend}>
        <Container
          planId={planValue}
          planName={planName}
          userType={userType}
          setUserType={setUserType}
        />
      </DndProvider>
      {showFormModal && (
        <CreateSectionModal
          visible={showFormModal}
          onCreate={handleCreateSection}
          onCancel={() => handleHideFormModal()}
        />
      )}
    </>
  )
}

export default Questionnaires
