import React, { useContext, useEffect, useState } from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _first from 'lodash/first'
import { useQuery } from '@apollo/react-hooks'
import { PageHeader, Select, Button, Modal } from 'antd'
import sumBy from 'lodash/sumBy'

import client from 'apollo'
import history from 'CustomHistory'
import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import ILTS from 'modules/Reports/ILTS/Tables/ILTS'
import Stock from 'modules/Reports/ILTS/Tables/Stock'
import Ledger from 'modules/Reports/ILTS/Tables/Ledger'
import DownloadReport from 'modules/Reports/DownloadReport'
import {
  GET_ILTS_P_REPORT,
  GET_ILTS_REPORT,
  GET_TRADER_EQ_REPORT,
  GET_TRADER_FO_REPORT,
} from 'modules/Reports/graphql/Queries'
import OpenPosition from 'modules/Reports/ILTS/Tables/OpenPosition'
import { GET_CC_EMAIL, GET_STRATEGY } from 'modules/Settings/graphql/Queries'
import 'modules/Reports/index.css'
import moment from 'moment'
import { AppContext } from 'AppContext'
import { CSVLink } from 'react-csv'
import mailImage from '../../assets/images/gmail-icon-logo-svgrepo-com.svg'
import PerformanceEmail from './components/PerformanceEmail'
import { sendMePdf } from 'utils/Graph'

const { Option } = Select

export default function (props) {
  const {
    state: { currentReport, currentUser },
  } = useContext(AppContext)
  const [strategy, setStrategy] = useState()
  const [sendMeLoading, setSendMeLoading] = useState(false)
  const [strategyList, setStrategyList] = useState(undefined)
  const [exportDataCsv, setExportDataCsv] = useState([])
  const [exportDataCsvFO, setExportDataCsvFO] = useState([])
  const [loadingExportData, setLoading] = useState(false)
  const [loadingFO, setLoadingFO] = useState(false)
  const [openSendMail, setOpenSendMail] = useState(false)
  const [invoiceCreateData, setInvoiceCreateData] = useState()
  const [performanceReportLink, setPerformanceReportLink] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)

  let {
    match: {
      params: { clientCode, clientId },
    },
  } = props

  const { data, loading, error } = useQuery(GET_ILTS_REPORT, {
    variables: {
      where: { clientCode, date: null, strategy: strategy ? strategy : '1005' },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    client
      .query({
        query: GET_STRATEGY,
        variables: { where: { clientCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (_get(res, 'data.getStrategy')) {
          setStrategyList(_get(res, 'data.getStrategy'))
          if (res.data.getStrategy.length === 1) {
            setStrategy(res.data.getStrategy[0].StrategyCode)
          }
        }
      })
      .catch(() => setStrategyList([]))
  }, [clientCode])

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_TRADER_EQ_REPORT,
        variables: { where: { clientCode: clientCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoading(false)
        setExportDataCsv(res?.data?.spTradeReportEQ)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLoadingFO(true)
    client
      .query({
        query: GET_TRADER_FO_REPORT,
        variables: { where: { clientCode: clientCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoadingFO(false)
        setExportDataCsvFO(res?.data?.spTradeReportFO)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataForExportCSV = exportDataCsv?.map((item) => {
    return {
      script: item?.script,
      qty: item?.qty,
      rate: item?.rate,
      entrydate: item?.entrydate,
      ClientCode: item?.ClientCode,
    }
  })

  const dataForExportCSVFO = exportDataCsvFO?.map((item) => {
    return {
      script: item?.Script,
      qty: item?.qty,
      rate: item?.rate,
      entrydate: item?.entrydate,
      ClientCode: item?.ClientCode,
    }
  })

  let spIltsreport
  let futuresOptionsReport = {}
  let stockReport = {}
  let ledgerReport = {}

  if (!loading && _get(data, 'spIltsreport')) {
    spIltsreport = data.spIltsreport[0]
    const reportFutures = spIltsreport.filter(
      (report, key) => report.Script && !report.Script.endsWith(' EQ')
    )
    futuresOptionsReport = reportFutures.map((reportFuture) => {
      return {
        ...reportFuture,
        TotalQty: Math.round(reportFuture.TotalQty),
        TradedRate: Math.round(reportFuture.TradedRate),
        CMP: Math.round(reportFuture.CMP),
        Value: Math.round(reportFuture.Value),
        Pnl: Math.round(reportFuture.Pnl),
      }
    })
    futuresOptionsReport = [
      ...futuresOptionsReport,
      {
        Script: 'Total',
        TotalQty: sumBy(futuresOptionsReport, 'TotalQty'),
        Value: sumBy(futuresOptionsReport, 'Value'),
        Pnl: sumBy(futuresOptionsReport, 'Pnl'),
      },
    ]

    const reportStocks = spIltsreport.filter(
      (report, key) => report.Script && report.Script.endsWith(' EQ')
    )
    stockReport = reportStocks.map((reportStock) => {
      return {
        ...reportStock,
        TotalQty: Math.round(reportStock.TotalQty),
        TradedRate: Math.round(reportStock.TradedRate),
        CMP: Math.round(reportStock.CMP),
        Value: Math.round(reportStock.Value),
        Pnl: Math.round(reportStock.Pnl),
      }
    })
    stockReport = [
      ...stockReport,
      {
        Script: 'Total',
        TotalQty: sumBy(stockReport, 'TotalQty'),
        Value: sumBy(stockReport, 'Value'),
        Pnl: sumBy(stockReport, 'Pnl'),
      },
    ]

    const ledgers = spIltsreport.filter(
      (report, key) => key !== 0 && key !== 1 && key !== 2 && !report.Script
    )
    ledgerReport = ledgers.map((reportLedger) => {
      return {
        ...reportLedger,
        Entrydate: moment(reportLedger?.Entrydate).format('DD-MMM-YYYY'),
        DR_Amount: Math.round(reportLedger.DR_Amount),
        CR_Amount: Math.round(reportLedger.CR_Amount),
      }
    })
    ledgerReport = [
      ...ledgerReport,
      {
        Entrydate: 'Total',
        DR_Amount: sumBy(ledgerReport, 'DR_Amount'),
        CR_Amount: sumBy(ledgerReport, 'CR_Amount'),
        CumAmount:
          sumBy(ledgerReport, 'CR_Amount') - sumBy(ledgerReport, 'DR_Amount'),
        remark: '',
      },

      // {
      //   Entrydate: 'Net Value',
      //   DR_Amount:
      //     sumBy(ledgerReport, 'CR_Amount') - sumBy(ledgerReport, 'DR_Amount'),
      //   CR_Amount: '',
      //   remark: '',
      // },
    ]
  }

  const renderSendMail = () => {
    setButtonLoading(true)
    client
      .query({
        query: GET_ILTS_P_REPORT,
        variables: { where: { id: parseInt(clientId) } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setButtonLoading(true)
        client
          .query({
            query: GET_CC_EMAIL,
            variables: { clientCode },
          })
          .then((res) => {
            setButtonLoading(false)
            data.emailId = res?.data?.getCCEmails?.email?.split(',')
            data.ccMailIds = res?.data?.getCCEmails?.HKeyEmail?.split(',')
            setInvoiceCreateData(data)
            setOpenSendMail(true)
          })
          .catch((err) => console.log(err))
          .finally(() => setButtonLoading(false))
        setPerformanceReportLink(res?.data?.getIltspreport)
        setButtonLoading(false)
      })
  }

  const spILTSReportData = _map(spIltsreport, (report, key) => {
    if (key === 0) return report
    if (report.ClientCode) delete report.ClientCode
    return report
  })

  return (
    <>
      <PageHeader
        className='box button-contents'
        title='Client Position'
        onBack={() => history.goBack()}
        extra={[
          <div style={{ display: 'flex' }}>
            <Button
              type='primary'
              onClick={() =>
                history.push(
                  `/clients/${clientId}/position/${clientCode}/${clientId}/comparison-of-fund/${clientCode}`
                )
              }
            >
              View comparison
            </Button>
            <Select
              defaultValue={strategy ? strategy : 'ILTS'}
              style={{ width: 80 }}
              className='gx-mr-3 gx-select-sm'
              onChange={(e) => setStrategy(e)}
              loading={!strategyList}
            >
              {_map(strategyList, (strategy, key) => {
                const { StrategyName, StrategyCode } = strategy
                return (
                  <Option value={StrategyCode} key={key}>
                    {StrategyName}
                  </Option>
                )
              })}
            </Select>
            <img
              src={mailImage}
              alt='mailImage'
              width={25}
              height={30}
              style={{
                cursor: `${sendMeLoading ? 'not-allowed' : 'pointer'}`,
                marginRight: '10px',
              }}
              onClick={() => {
                if (currentUser.role === 'ADMIN') {
                  console.log('iniffff')
                  renderSendMail()
                } else {
                  console.log('inelssss')
                  setSendMeLoading(true)
                  sendMePdf(clientId).then(() => setSendMeLoading(false))
                }
              }}
            />
            <DownloadReport
              data={spILTSReportData}
              fileName={`ILTS-PERFORMANCE-REPORT-${clientCode}`}
              id='ILTS-REPORT-PDF'
              isILTSNewReport={true}
              clientId={clientId}
              newPdfIcon={true}
            />
          </div>,
        ]}
      />
      {loading ? (
        <Loader />
      ) : error ? (
        <Page404 error={error} />
      ) : !_get(data, 'spIltsreport') || !_first(spIltsreport) ? (
        <NoData />
      ) : (
        <div className='report-table-wrapper' id='ILTS-REPORT-PDF'>
          <div
            id='ILTS-PDF-Client'
            style={{
              pageBreakAfter: 'always',
              margin: '0 20px',
            }}
          >
            {/* <DownloadReport className="ExportAS" data={[_first(spIltsreport)]} fileName='ILTS' id='ILTS-PDF-Client' /> */}
            <ILTS
              clientId={clientId}
              reportData={_first(spIltsreport)}
              strategy={
                strategyList &&
                strategy &&
                strategyList.find((s) => s.StrategyCode === strategy)
                  .StrategyCode
              }
              ledgerReport={ledgerReport}
            />
          </div>
          <br />
          <br />
          <div
            id='ILTS-PDF-Stock'
            style={{ pageBreakAfter: 'always', margin: '0 20px' }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {currentReport.includes('Stock Report All Export As Button') && (
                <CSVLink
                  className='ExportAS'
                  style={{ marginRight: '10px' }}
                  data={dataForExportCSV}
                  filename={`Stock Holding Report ${clientCode}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    loading={loadingExportData}
                    type='primary'
                    style={{
                      height: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Export Trades
                  </Button>
                </CSVLink>
              )}

              <DownloadReport
                className='ExportAS'
                data={stockReport}
                fileName={`Stock Holding Report ${clientCode}`}
                id='ILTS-PDF-Stock'
                isStockReport={true}
                newPdfIcon={true}
                clientId={clientId}
              />
            </div>
            <Stock reportData={stockReport} clientData={_first(spIltsreport)} />
          </div>
          <br />
          <br />
          <div
            id='ILTS-PDF-Futures'
            style={{ pageBreakAfter: 'always', margin: '0 20px' }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {currentReport.includes('FO Report All Export As Button') && (
                <CSVLink
                  data={dataForExportCSVFO}
                  className='ExportAS'
                  style={{ marginRight: '10px' }}
                  filename={`Trade F&O Report ${clientCode}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    type='primary'
                    style={{
                      height: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    loading={loadingFO}
                  >
                    Export Trades
                  </Button>
                </CSVLink>
              )}
              <DownloadReport
                className='ExportAS'
                data={futuresOptionsReport}
                fileName={`Trade F&O Report ${clientCode}`}
                id='ILTS-PDF-Futures'
                isFutureAndOptionReport={true}
                newPdfIcon={true}
                clientId={clientId}
              />
            </div>
            <OpenPosition
              reportData={futuresOptionsReport}
              clientData={_first(spIltsreport)}
            />
          </div>
          <br />
          <br />
          <div id='ILTS-PDF-Ledger' style={{ margin: '0 20px' }}>
            <DownloadReport
              className='ExportAS'
              data={ledgerReport}
              fileName={`Ledger Report ${clientCode}`}
              id='ILTS-PDF-Ledger'
              isLedgerReport={true}
              newPdfIcon={true}
              clientId={clientId}
            />
            <Ledger
              reportData={ledgerReport}
              clientData={_first(spIltsreport)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '16px',
            }}
          >
            This is an indicative report. Please check your back-office for
            final report
          </div>
        </div>
      )}
      {openSendMail && (
        <Modal
          visible={openSendMail}
          width={1200}
          onCancel={() => setOpenSendMail(false)}
          footer={null}
          title='Performance Mail Body'
        >
          <PerformanceEmail
            buttonLoading={buttonLoading}
            invoiceCreateData={invoiceCreateData}
            setOpenReactQuill={setOpenSendMail}
            clientCode={clientCode}
            performanceReportLink={performanceReportLink}
          />
        </Modal>
      )}
    </>
  )
}
