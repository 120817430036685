import gql from 'graphql-tag'

export const CREATE_FAQ_QUESTION = gql`
  mutation Mutation($data: FAquestionData!) {
    createFAQuestion(data: $data) {
      answer
      createdAt
      id
      question
      updatedAt
    }
  }
`

export const UPDATE_FAQ_QUESTION = gql`
  mutation UpdateFAQuestion(
    $data: FAquestionData
    $where: whereFAQuestionUpdate
  ) {
    updateFAQuestion(data: $data, where: $where) {
      answer
      createdAt
      id
      question
      updatedAt
    }
  }
`

export const DELETE_FAQ_QUESTION = gql`
  mutation DeleteFAQuestion($where: whereFAQuestionUpdate) {
    deleteFAQuestion(where: $where)
  }
`
