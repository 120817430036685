import React from 'react'
import { Modal, Form, Input, Select } from 'antd'

const { Option } = Select
const CollectionCreateForm = ({
  visible,
  onCreate,
  onUpdate,
  onCancel,
  isEdit,
  selectedFlag,
}) => {
  const [form] = Form.useForm()

  function resetForm() {
    form.resetFields()
  }

  const FlagName = [
    { text: 'SELECT USER TYPE', value: 'SELECT_USER_TYPE' },
    { name: 'LOGIN CREATED', value: 'LOGIN_CREATED' },
    { name: 'KYC STATUS', value: 'KYC_STATUS' },
    { name: 'CLIENT PROFILE 1', value: 'CLIENT_PROFILE_1' },
    { name: 'CLIENT PROFILE 2', value: 'CLIENT_PROFILE_2' },
    { name: 'RISK PROFILE', value: 'RISK_PROFILE' },
    { name: 'KYC DOC', value: 'KYC_DOC' },
    { name: 'KYC FORM', value: 'KYC_FORM' },
    { name: 'CLIENT CONSENT', value: 'CLIENT_CONSENT' },
    { name: 'INVESTMENT PLANNING', value: 'INVESTMENT_PLANNING' },
    { name: 'AGREEMENT SIGN', value: 'AGREEMENT_SIGN' },
    { name: 'COURIER INSTRUCTION', value: 'COURIER_INSTRUCTION' },
    { name: 'E-MANDATE', value: 'E_MANDATE' },
    { name: 'ADVISORY', value: 'ADVISORY' },
    { name: 'DONE', value: 'DONE' },
  ]

  return (
    <Modal
      className='feedback_form'
      maskClosable={false}
      visible={visible}
      title={isEdit ? 'Edit Flag' : 'Add New Flag'}
      okText={isEdit ? 'Update Flag' : 'Add Flag'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const data = {
              ...values,
            }
            if (isEdit) {
              onUpdate(data, resetForm)
            } else {
              onCreate(data, resetForm)
            }
          })
          .catch((info) => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
          ...selectedFlag,
        }}
      >
        <Form.Item
          name='name'
          label='Flag Name'
          rules={[
            {
              required: true,
              message: 'Flag name is require!',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Select
            style={{ width: 150 }}
            disabled={isEdit}
            placeholder='Flag Name'
          >
            {FlagName.map((flag, key) => {
              return (
                <Option key={key} value={flag.value}>
                  {flag.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='value'
          label='Value'
          // rules={[{ required: true, message: 'Value is require!', whitespace: true }]}
          hasFeedback
        >
          <Select style={{ width: 150 }} defaultValue={true}>
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='message'
          label='Message'
          rules={[
            {
              required: true,
              message: 'Message is require!',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Input placeholder='Message' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateFlagModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateFlagModal
