import React from 'react'
import { Button, Col, Row } from 'antd'

import history from 'CustomHistory'
import Widget from 'components/Widget'
import LineIndicator from './LineIndicator'

const Portfolio = (props) => {
  const { type = 'SIP', Completed, CompletedPer, Pending, PendingPer, isClient } = props

  return (
    <Widget>
      <h2 className='h4 gx-mb-3'>{type} Summary</h2>
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className='ant-row-flex'>
            <h2 className='gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium'>₹{props[`Total${type}`] ? Math.round(props[`Total${type}`]) : 0}</h2>
          </div>
          <p className='gx-text-grey'>Total Amount</p>
          <div className='ant-row-flex gx-mb-3 gx-mb-md-2'>
            <Button className='gx-mr-2' type='primary' size='small' onClick={() => history.push(`${isClient ? "/" : ""}${type.toLowerCase()}`)}>View All</Button>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className='gx-site-dash'>
            <h5 className='gx-mb-3'>Overview</h5>
            <ul className='gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0'>
              <li>
                <LineIndicator width={`${CompletedPer || 0}%`} title='Paid' title2={`₹${Completed || 0}`} color='primary' value={CompletedPer ? `${CompletedPer.toFixed(2)}%` : `0%`} />
              </li>
              <li>
                <LineIndicator width={`${PendingPer || 0}%`} title='Pending' title2={`₹${Pending || 0}`} color='pink' value={PendingPer ? `${PendingPer.toFixed(2)}%` : `0%`} />
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Widget>
  )
}

export default Portfolio
