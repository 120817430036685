import React, { useReducer, createContext } from 'react'

const initialState = {
  currentUser: {},
  currentReport: [],
  isAdmin: false,
  isStaff: false,
  isClient: false,
  isAssociate: false,
  isSalesExecutive: false,
  isMasterAssociate: false,
  selectedGroup: undefined,
  selectedStrategy: 'ILTS',
  isVerificationDone: false,
  MaintenanceStatus: {},
  invoiceSetting: {},
  generalSetting: {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'CURRENT_USER':
      return { ...state, currentUser: action.data }
    case 'CURRENT_REPORT':
      return { ...state, currentReport: action.data }
    case 'SELECTED_GROUP':
      return { ...state, selectedGroup: action.data }
    case 'SELECTED_STRATEGY':
      return { ...state, selectedStrategy: action.data }
    case 'IS_ADMIN':
      return { ...state, isAdmin: action.data }
    case 'IS_STAFF':
      return { ...state, isStaff: action.data }
    case 'IS_MASTER_ASSOCIATE':
      return { ...state, isMasterAssociate: action.data }
    case 'IS_ASSOCIATE':
      return { ...state, isAssociate: action.data }
    case 'IS_SALES_EXECUTIVE':
      return { ...state, isSalesExecutive: action.data }
    case 'IS_CLIENT':
      return { ...state, isClient: action.data }
    case 'IS_VERIFICATION_DONE':
      return { ...state, isVerificationDone: action.data }
    case 'MAINTENANCE_STATUS':
      return { ...state, MaintenanceStatus: action.data }
    case 'INVOICE_SETTING':
      return { ...state, invoiceSetting: action.data }
    case 'GENERAL_SETTING':
      return { ...state, generalSetting: action.data }
    default:
      return {}
  }
}

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
})

function AppContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  )
}

const AppContextConsumer = AppContext.Consumer

export { AppContext, AppContextProvider, AppContextConsumer }
