import React, { Fragment, useContext, useState } from 'react'
import { MenuOutlined, EditOutlined, MailOutlined } from '@ant-design/icons'
import { PageHeader, Button, Menu, Dropdown } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'

import About from 'components/About'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import { GET_USER } from './graphql/Queries'
import CustomMeta from 'components/CustomMeta'
import Loader from 'components/loaders/Loader'
import { getName, staffUsersData } from 'utils/User'
import LeadUsersChain from 'modules/Clients/components/LeadUsersChain'

export default function (props) {
  let userDetails = {}
  const [visibleMenu, setVisibleMenu] = useState(false)
  const {
    state: { currentReport },
  } = useContext(AppContext)
  const {
    history,
    match: {
      params: { userId },
    },
  } = props
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  if (!loading && get(data, 'getUser')) {
    userDetails = data.getUser
  }

  function handleMenuClick(e) {}

  const menu = (
    <Menu onClick={handleMenuClick}>
      {userDetails.email && (
        <Menu.Item
          key='1'
          onClick={() => history.push(`/users/${userId}/${userDetails.email}`)}
        >
          <MailOutlined /> Mails
        </Menu.Item>
      )}
      {currentReport.includes('Update User') && (
        <Menu.Item
          key='1'
          onClick={() => history.push(`/users/${userId}/edit`)}
        >
          <EditOutlined /> Edit
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <Fragment>
      <CustomMeta title={getName(userDetails)} description='Users' />
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title={getName(userDetails)}
        extra={[
          window.innerWidth >= 991 ? (
            <Fragment key={0}>
              {userDetails.email && (
                <Button
                  key={1}
                  type='primary'
                  onClick={() =>
                    history.push(`/users/${userId}/${userDetails.email}`)
                  }
                >
                  Mails
                </Button>
              )}
              {currentReport.includes('Update User') && (
                <Button
                  key={2}
                  type='primary'
                  onClick={() => history.push(`/users/${userId}/edit`)}
                >
                  Edit
                </Button>
              )}
            </Fragment>
          ) : (
            <Dropdown
              onVisibleChange={() => setVisibleMenu(false)}
              key={0}
              overlay={menu}
              onClick={() => setVisibleMenu(!visibleMenu)}
              visible={visibleMenu}
            >
              <Button key={1}>
                <MenuOutlined />
              </Button>
            </Dropdown>
          ),
        ]}
      />
      {userDetails.role !== 'ADMIN' && <LeadUsersChain userId={userId} />}
      <About
        details={staffUsersData(userDetails)}
        userId={userDetails.id}
        id={userId}
      />
    </Fragment>
  )
}
