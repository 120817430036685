import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Col, Row } from 'antd'
import moment from 'moment'
import ReactSpeedometer from 'react-d3-speedometer'

import { AppContext } from 'AppContext'
import { GET_USER_ANSWERS } from './graphql/Queries'
import Loader from 'components/loaders/Loader'
import client from 'apollo'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import { COMPLETE_STEP, SENDING_ASSESSMENT_MAILS } from './graphql/Mutations'
import { useQuery } from '@apollo/react-hooks'
import { GET_PLANS_BASED_POINTS } from 'modules/Questionnaires/graphql/Query'
import { GET_RISK_PROFILE_SCORE } from 'modules/Settings/RiskProfileScore/graphql/Queries'

function Summary({ back, next, reEvaluate }) {
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext)
  const { isPanCardIndividual } = currentUser
  const [checkBox, setCheckBox] = useState(true)
  const [loading, setLoading] = useState(false)
  const [nextLoading, setNextLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  // const [questionId, setQuestionId] = useState(null)

  const [riskProfile, setRiskProfile] = useState()

  const { data, loading: planLoading } = useQuery(GET_PLANS_BASED_POINTS, {
    variables: { points: currentUser?.clientScore },
    fetchPolicy: 'network-only',
  })

  const { data: riskProfileScoreRange, loading: rangeLoading } = useQuery(
    GET_RISK_PROFILE_SCORE,
    { fetchPolicy: 'network-only' }
  )

  useEffect(() => {
    client
      .query({
        query: GET_USER_ANSWERS,
        variables: { where: { planId: parseInt(currentUser?.plan?.id) } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        // if (questionId !== null) {
        if (
          currentUser?.clientScore <= 22 ||
          (currentUser?.clientScore >= 23 && currentUser?.clientScore <= 40)
        ) {
          setRiskProfile('Moderate')
        } else if (currentUser?.clientScore >= 41) {
          setRiskProfile('High')
        }
        // }
        setDataLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setDataLoading(false))
  }, [currentUser])

  if (dataLoading && riskProfile && planLoading) return <Loader />

  let str = ''
  let range = []
  if (!planLoading) {
    let planArray = []
    data.fetchPlansBasedOnPoints.forEach((plan) => {
      planArray.push(plan.name.trim())
    })
    str = planArray.join(',')
  }

  if (!rangeLoading && riskProfileScoreRange?.getRiskProfile) {
    range.push(
      riskProfileScoreRange?.getRiskProfile?.lowRisk?.minScore,
      riskProfileScoreRange?.getRiskProfile?.moderateRisk?.minScore,
      riskProfileScoreRange?.getRiskProfile?.highRisk?.minScore,
      riskProfileScoreRange?.getRiskProfile?.highRisk?.maxScore
    )
  }
  const onChange = (e) => {
    setCheckBox(e.target.checked)
  }

  const reSubmit = () => {
    setLoading(true)
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id: currentUser?.id,
          data: { completedSteps: 'CLIENT_PROFILE_2' },
        },
      })
      .then((res) => {
        setLoading(false)
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        back()
      })
      .catch((err) => {
        console.log({ err })
      })
      .finally(() => setLoading(false))
  }

  const onNext = () => {
    setNextLoading(true)
    isPanCardIndividual
      ? client
          .mutate({ mutation: SENDING_ASSESSMENT_MAILS })
          .then((res) => {
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  id: currentUser?.id,
                  data: {
                    completedSteps: isPanCardIndividual
                      ? reEvaluate && currentUser?.cvlStatus
                        ? 'KYC_FORM'
                        : currentUser?.cvlStatus
                        ? 'KYC_FORM'
                        : 'SUMMARY'
                      : reEvaluate && currentUser?.cvlStatus
                      ? 'KYC_FORM'
                      : 'SUMMARY',
                  },
                },
              })
              .then((res) => {
                setNextLoading(false)
                dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
                client
                  .mutate({
                    mutation: COMPLETE_STEP,
                    variables: {
                      data: {
                        step: 'SUMMARY',
                        from: 'WEB',
                      },
                    },
                  })
                  .then((res) => {
                    next()
                  })
              })
          })
          .catch((err) => {
            console.log({ err })
          })
          .finally(() => setNextLoading(false))
      : client
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              id: currentUser?.id,
              data: {
                completedSteps: isPanCardIndividual
                  ? reEvaluate && currentUser?.cvlStatus
                    ? 'KYC_FORM'
                    : currentUser?.cvlStatus
                    ? 'KYC_FORM'
                    : 'SUMMARY'
                  : reEvaluate && currentUser?.cvlStatus
                  ? 'KYC_FORM'
                  : 'SUMMARY',
              },
            },
          })
          .then((res) => {
            setNextLoading(false)
            dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
            client
              .mutate({
                mutation: COMPLETE_STEP,
                variables: {
                  data: {
                    step: 'SUMMARY',
                    from: 'WEB',
                  },
                },
              })
              .then((res) => {
                next()
              })
          })
          .catch((err) => {
            console.log({ err })
          })
          .finally(() => setNextLoading(false))
  }

  return (
    <div>
      <div>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className='gutter-row' span={7}>
            <div className='summary'>
              <div>
                <span className='summary_heading'>Risk Assessment of</span>
              </div>
              <div>
                <span className='summary_client_name'>{`${currentUser?.firstName} ${currentUser?.lastName}`}</span>
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={4}>
            <div className='summary'>
              <div>
                <span className='summary_heading'>Prepared on</span>
              </div>
              <div>
                <span className='summary_data'>
                  {moment().format('DD-MMM-YY')}
                </span>
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={3}>
            <div className='summary'>
              <div>
                <span className='summary_heading'>Your Score</span>
              </div>
              <div>
                <span className='summary_data'>
                  {currentUser?.clientScore}/93
                </span>
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={6}>
            <div className='summary'>
              <div>
                {data?.fetchPlansBasedOnPoints.length > 1 ? (
                  <span className='summary_heading'>Eligible Plans are</span>
                ) : (
                  <span className='summary_heading'>Eligible Plan is</span>
                )}
              </div>
              <div>
                <span className='summary_data'>{str}</span>
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={4}>
            <div className='summary'>
              <div>
                <span className='summary_heading'>Risk Profile</span>
              </div>
              <div
                style={{
                  backgroundColor: '#c4e9c4',
                  borderRadius: '0 0 10px 10px',
                }}
              >
                <span className='summary_heading'>{riskProfile}</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <br />
      <br />
      <div>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className='gutter-row' span={12}>
            {riskProfile === 'Moderate' ? (
              <div>
                <div className='summary_risk_profile'>
                  <span className='summary_risk_profile_heading'>
                    What is Moderate risk?
                  </span>
                </div>
                <br />
                <div className='summary_risk_profile_content'>
                  <span>
                    As a Moderate Investor your portfolio should have a bias
                    towards capital growth and a little need for income. You are
                    prepared to accept a higher degree of volatility and risk.
                    Your primary concern is accumulating assets over the medium
                    to long terms.
                  </span>
                </div>
              </div>
            ) : riskProfile === 'High' ? (
              <div>
                <div className='summary_risk_profile'>
                  <span className='summary_risk_profile_heading'>
                    What is High risk?
                  </span>
                </div>
                <br />
                <div className='summary_risk_profile_content'>
                  <span>
                    As a High Risk Investor you are ready to take higher risk
                    expecting greater returns. This is a result of your urge to
                    get more income and capital growth. You are well placed to
                    recover from unforeseen market downturns either because you
                    have time on your side or access to capital returns.
                  </span>
                </div>
              </div>
            ) : riskProfile === 'Low' ? (
              <div>
                <div className='summary_risk_profile'>
                  <span className='summary_risk_profile_heading'>
                    What is Low risk?
                  </span>
                </div>
                <br />
                <div className='summary_risk_profile_content'>
                  <span>
                    As a Low risk Investor your investment portfolio is focused
                    on Preservation of capital and current income. Your Primary
                    concern is capital protection and avoiding high risk
                    investment
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col className='gutter-row' span={10}>
            {range.length && (
              <div>
                <div>
                  <ReactSpeedometer
                    width={400}
                    height={230}
                    segments={3}
                    maxValue={93}
                    value={currentUser?.clientScore}
                    needleHeightRatio={0.7}
                    needleTransitionDuration={3333}
                    needleTransition='easeElastic'
                    needleColor={'#000'}
                    customSegmentStops={range}
                    customSegmentLabels={[
                      {
                        position: 'INSIDE',
                        color: '#fff',
                      },
                      {
                        position: 'INSIDE',
                        color: '#fff',
                      },
                      {
                        position: 'INSIDE',
                        color: '#fff',
                      },
                    ]}
                    segmentColors={['green', 'orange', 'red']}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
        <br />
        <br />

        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className='gutter-row' span={6}>
            <div className='summary summary_suitability'>
              <div className='summary_assessment'>
                <span className='summary_heading'>Suitability Assessment</span>
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={6}>
            <div className='summary'>
              <div className='summary_objective'>
                <span className='summary_heading'>Investment Objective</span>
              </div>
              <div className='summary_objective_below summary_objective'>
                <span className='summary_data'>
                  Long Term Capital Growth with calculated Risk
                </span>
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={6}>
            <div className='summary' style={{ height: '100%' }}>
              <div className='summary_objective'>
                <span className='summary_heading'>Time Horizon</span>
              </div>
              <div className='summary_objective'>
                <span className='summary_data'>5-10 Years</span>
              </div>
            </div>
          </Col>
          <Col className='gutter-row' span={6}>
            <div
              className='summary'
              style={{ marginRight: '7px', height: '100%' }}
            >
              <div className='summary_objective'>
                <span className='summary_heading'>Risk Appetite</span>
              </div>
              <div className='summary_objective_below summary_objective summary_objective'>
                <span className='summary_data'>
                  Keep investments as they are
                </span>
              </div>
            </div>
          </Col>
        </Row>

        <br />
        <br />

        <Row>
          <div>
            <div className='summary_risk_profile'>
              <div style={{ marginLeft: '10px' }}>
                <div className='agree-wrapper'>
                  <Checkbox onChange={onChange} checked={checkBox} />
                  <p className='agree-content' style={{ fontSize: '17px' }}>
                    I have been explanined all the products with detailed
                    features and benefits by the counsellor and I confirm that I
                    wish to subscribe this product with my own consent.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>

      <br />
      <br />

      <div>
        <Button type='primary' loading={loading} onClick={reSubmit}>
          Re-Submit Risk Profile
        </Button>
        <Button
          loading={nextLoading}
          type='primary'
          onClick={onNext}
          disabled={!checkBox}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default Summary
