import React from 'react'
import { Table } from 'antd'
import { NavLink } from 'react-router-dom'

const columns = [
  {
    title: (
      <NavLink
        to='#'
        className='reports-position-name'
        style={{
          color: '#000',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '18px',
        }}
      >
        Comparison of Fund with Index Report
      </NavLink>
    ),
    className: 'report-title main-title',
    children: [
      {
        title: (
          <div
            style={{
              textAlign: 'right',
              width: '100px',
            }}
          >
            Report Date
          </div>
        ),
        dataIndex: 'ReportDate',
        key: 'ReportDate',
        className: 'report-text',
        // width: 150,
      },
      {
        title: 'NIFTY',
        dataIndex: 'NIFTY',
        key: 'NIFTY',
        className: 'report-numeric',
        render: (text) => (text ? Math.round(text) : text),
        // width: 100,
      },
      {
        title: 'Net Amount',
        dataIndex: 'NetAmt',
        key: 'NetAmt',
        className: 'report-numeric',
        render: (text) => (text ? Math.round(text) : text),
        // width: 100,
      },
      {
        title: 'NLV Net Liquidation Value',
        dataIndex: 'NLV',
        key: 'NLV',
        className: 'report-numeric',
        // width: 200,
        render: (text) => (text ? Math.round(text) : text),
      },
      {
        title: 'P&L with Interest',
        dataIndex: 'PnlWithInterest',
        key: 'PnlWithInterest',
        className: 'report-numeric',
        // width: 150,
      },
      {
        title: 'P&L without Interest',
        dataIndex: 'PnlWithOutInterest',
        key: 'PnlWithOutInterest',
        className: 'report-numeric',
        // width: 170,
        render: (text) => (text ? Math.round(text) : text),
      },
      {
        title: 'Original Exposure',
        dataIndex: 'TotalExposure',
        key: 'TotalExposure',
        className: 'report-numeric',
        // width: 150,
        render: (text) => (text ? Math.round(text) : text),
      },
      {
        title: 'Current Exposure',
        dataIndex: 'TotExposureWithNifty',
        key: 'TotExposureWithNifty',
        className: 'report-numeric',
        // width: 150,
        render: (text) => (text ? Math.round(text) : text),
      },
      {
        title: 'Interest per Day',
        dataIndex: 'Interest',
        key: 'Interest',
        className: 'report-numeric',
        // width: 150,
        render: (text) => (text ? Math.round(text) : text),
      },
      {
        title: 'Cumulative Interest',
        dataIndex: 'CumInterest',
        key: 'CumInterest',
        className: 'report-numeric',
        // width: 170,
        render: (text) => (text ? Math.round(text) : text),
      },
      {
        title: 'Total NLV',
        dataIndex: 'TotNLV',
        key: 'TotNLV',
        className: 'report-numeric',
        // width: 100,
        render: (text) => (text ? Math.round(text) : text),
      },
      {
        title: 'NIFTY %',
        dataIndex: 'NIFTY%',
        key: 'NIFTY%',
        // width: 100,
        className: 'report-percentage',
        render: (text) => (text ? Math.round(text) : text),
      },
      {
        title: 'Total NLV %',
        dataIndex: 'TotNLV%',
        key: 'TotNLV%',
        className: 'report-percentage',
        // width: 100,
        render: (text) => (text ? Math.round(text) : text),
      },
    ],
  },
]

export default function ({ reportData, id, loading }) {
  const data = reportData.map((report, key) => {
    return { key, ...report }
  })

  if (id === 'NLV-TRACKER-REPORT-DOWNLOAD-PDF') {
    return (
      <Table
        dataSource={data}
        columns={columns}
        className='nlv-tracker-report new-nlv-report'
        pagination={false}
      />
    )
  } else {
    return (
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        className='nlv-tracker-report new-nlv-report'
        pagination={false}
        bordered
        scroll={{
          x: 1500,
          y: 800,
        }}
      />
    )
  }
}
