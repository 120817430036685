import gql from 'graphql-tag'

export const GET_MAIL_LOG = gql`
  query Query($where: whereInput) {
    getMailLogs(where: $where)
  }
`

export const GET_MAIL_CONTENT = gql`
  query Query($where: whereInputForContent) {
    getMailContent(where: $where)
  }
`
