import React, { useContext, useEffect, useState } from 'react'
import { Progress, Steps } from 'antd'
import client from 'apollo'
import { useHistory } from 'react-router-dom'

import './verification.css'
import RiskProfile from './RiskProfile'
import { AppContext } from 'AppContext'
import ClientProfile1 from './ClientProfile1'
import ClientProfile2 from './ClientProfile2'
import RiskProfileForm from './RiskProfileForm'
import SelectTypeForm from './SelectTypeForm'
import InvestmentPlanning from './InvestmentPlanning'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import Summary from './Summary'
import ClientProfileNon1 from './ClientProfileNon1'
import ClientProfileNon2 from './ClientProfileNon2'
import CourierPage from './CourierPage'

const { Step } = Steps

const Verification = () => {
  const history = useHistory()
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext)
  const { state } = useContext(AppContext)
  const { completedSteps, isPanCardIndividual } = state?.currentUser

  useEffect(() => {
    window.location.hash = 'no-back-button'
    window.location.hash = 'Again-No-back-button'
    window.onhashchange = function () {
      window.location.hash = 'no-back-button'
    }
  }, [])

  let tempCurrent = 0
  switch (completedSteps) {
    case 'LOGIN_CREATED':
      tempCurrent = 1
      break
    case 'SELECT_USER_TYPE':
      tempCurrent = 2
      break
    case 'CLIENT_PROFILE_1':
      tempCurrent = 3
      break
    case 'CLIENT_PROFILE_2':
      tempCurrent = 4
      break
    case 'RISK_PROFILE':
      tempCurrent = 5
      break
    case 'SUMMARY':
      tempCurrent = 6
      break
    case 'INVESTMENT_PLANNING':
      tempCurrent = 7
      break
    // case 'AGREEMENT_SIGN':
    //   tempCurrent = 8
    //   break
    case 'COURIER_INSTRUCTION':
      tempCurrent = 8
      break
    case 'DONE':
      tempCurrent = 9
      break
    default:
      break
  }

  const [current, setCurrent] = useState(tempCurrent)

  const next = (nextStep = 1) => {
    if (
      current === 5 &&
      currentUser.reEvaluate === true &&
      currentUser.cvlStatus === true
    ) {
      setCurrent(9)
    } else if (
      currentUser?.isPanCardIndividual &&
      current === 5 &&
      currentUser.cvlStatus === true
    ) {
      setCurrent(8)
    } else if (
      currentUser?.isPanCardIndividual &&
      current === 7 &&
      currentUser.reEvaluate
    ) {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              completedSteps: 'DONE',
              reEvaluate: false,
            },
            id: parseInt(currentUser.id),
          },
        })
        .then((res) => {
          dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
          dispatch({ type: 'IS_VERIFICATION_DONE', data: true })
          history.push('/')
        })
        .catch((err) => console.log(err))
    } else {
      setCurrent(current + nextStep)
    }
  }

  const back = (backStep = 1) => {
    if (currentUser?.completedSteps === 'RISK_PROFILE') {
      setCurrent(current - backStep)
    }
  }

  const steps = [
    {
      title: 'Disclaimer',
      content: <RiskProfileForm next={next} />,
    },
    {
      title: 'Select Type',
      content: <SelectTypeForm next={next} />,
    },
    {
      title: 'Client Basic Details',
      content: (
        <>
          {isPanCardIndividual ? (
            <ClientProfile1 next={next} reEvaluate={currentUser.reEvaluate} />
          ) : (
            <ClientProfileNon1
              next={next}
              reEvaluate={currentUser.reEvaluate}
            />
          )}
        </>
      ),
    },
    {
      title: 'Client Profile',
      content: (
        <>
          {isPanCardIndividual ? (
            <ClientProfile2 next={next} reEvaluate={currentUser.reEvaluate} />
          ) : (
            <ClientProfileNon2
              next={next}
              reEvaluate={currentUser.reEvaluate}
            />
          )}
        </>
      ),
    },
    {
      title: 'Risk Profile Assessment',
      content: <RiskProfile next={next} reEvaluate={currentUser.reEvaluate} />,
    },
    {
      title: 'Summary',
      content: (
        <Summary back={back} next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Investment Planning',
      content: (
        <InvestmentPlanning next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    // {
    //   title: 'Agreement Sign',
    //   content: (
    //     <AgreementSign next={next} reEvaluate={currentUser.reEvaluate} />
    //   ),
    // },
    {
      title: 'Courier Instruction',
      content: <CourierPage next={next} reEvaluate={currentUser.reEvaluate} />,
    },
  ]

  return (
    <>
      <div className='.gx-app-login-content.content-wrapper'>
        <Steps size='small' current={current}>
          {steps.map((item) => {
            return <Step key={item.title} title={item.title} />
          })}
        </Steps>
        <div className='progress'>
          <Progress
            percent={Math.round((100 / 9) * (tempCurrent + 1))}
            format={(p) => `${p}% Progress`}
          />
        </div>
        <div className='steps-content'>{steps[current]?.content}</div>
      </div>
    </>
  )
}

export default Verification
