import React from 'react'
import Routes from 'Routes'
import 'assets/css/index.css'
import './assets/css/App.css'
import './assets/css/Main.css'
import './assets/css/Custom.css'

function App() {
  return <Routes />
}

export default App
