import React from "react"
import _get from "lodash/get"
import { Row, Col, Avatar } from "antd" // , Dropdown, Menu
import CustomScrollbars from "utils/CustomScrollbars"

// const options = [
//   'Reply',
//   'Forward',
//   'Print',
// ]

export default function MailDetail(props) {

  // function optionMenu() {
  //   return (
  //     <Menu id="long-menu">
  //       {options.map(option =>
  //         <Menu.Item key={option}>
  //           {option}
  //         </Menu.Item>,
  //       )}
  //     </Menu>)
  // }

  function createMarkup(__html) {
    return { __html }
  }

  function openImage(data) {
    const image = new Image()
    image.src = data
    const w = window.open("")
    w.document.write(image.outerHTML)
  }

  function downloadPDF(data, fileName, type) {
    const linkSource = `data:${type};base64,${data}`
    const downloadLink = document.createElement("a")
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  function _arrayBufferToBase64(buffer) {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  function renderAttachment(attachment) {
    const { contentType, filename, content } = attachment
    switch (contentType) {
      case 'image/png':
      case 'image/jpeg':
        const base64String = btoa(String.fromCharCode(...new Uint8Array(content.data)))
        const url = `data:${contentType};base64,${base64String}`
        return (
          <div className="attachment-wrapper">
            <img src={url} alt={filename} onClick={() => openImage(url)} />
            <div className="size">{filename}</div>
          </div>
        )
      case 'text/csv':
      case 'text/plain':
      case 'application/pdf':
      case 'application/zip':
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        const base64StringFile = _arrayBufferToBase64(content.data)
        return (
          <div
            className="attachment-wrapper"
            onClick={() => downloadPDF(base64StringFile, filename, contentType)}
          >
            <div className="text-center">
              <i className="icon icon-editor gx-fs-icon-lg gx-text-primary" />
            </div>
            <div className="size text-center">{filename}</div>
          </div>
        )
      default:
        return <></>
    }
  }

  const { mail } = props

  return (
    <div className="gx-module-detail gx-mail-detail">
      <CustomScrollbars className="gx-module-content-scroll">
        <div className="gx-mail-detail-inner">
          <div className="gx-mail-user-info gx-ml-0 gx-mb-3">
            {
              mail.avatar ?
                <Avatar className="gx-size-40 gx-mr-3" alt="Alice Freeman" src={mail.avatar} /> :
                <Avatar className="gx-avatar gx-bg-blue gx-size-40 gx-mr-3"> {mail.from.value[0].address.charAt(0).toUpperCase()}</Avatar>
            }
            <div className="gx-sender-name">
              {mail.from.value[0].address}
              {/* <div className="gx-send-to gx-text-grey">to me</div> */}
            </div>
            {/* <Dropdown trigger={['click']} overlay={() => optionMenu()}>
              <span className="gx-ml-auto"><i className="icon icon-ellipse-v gx-icon-btn" /></span>
            </Dropdown> */}
          </div>
          <div className="gx-show-detail">
            <div>
              <strong>Subject: </strong>{mail.subject}
            </div>
            <div>
              <strong>From: </strong>{mail.from.value[0].address}
            </div>
            <div>
              <strong> To: </strong>
              {
                mail.to.value.map((to, index) => <span>{index > 0 && ', '} {to.address}</span>)
              }
            </div>
            <div><strong>Date: </strong>{mail.date} </div>
          </div>
          <div dangerouslySetInnerHTML={createMarkup(mail.html)} />
          {/* <div dangerouslySetInnerHTML={createMarkup(mail.textAsHtml)} /> */}
          {
            _get(mail, 'attachments') &&
            <div className="gx-attachment-block">
              <h3>Attachments ({mail.attachments.length})</h3>
              <Row>
                {
                  mail.attachments.map((attachment, index) => {
                    return (
                      <Col xs={24} sm={24} md={6} lg={6} xl={6} key={index}>
                        {renderAttachment(attachment)}
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          }
        </div>
      </CustomScrollbars>
    </div>
  )
}

