import React from 'react'
import { Modal, Form, Input } from 'antd'
import './index.css'

const CollectionCreateForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm()

  function resetForm() {
    form.resetFields()
  }

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      title='Change Password'
      okText='Change'
      cancelText='Cancel'
      onCancel={() => {
        onCancel()
        resetForm()
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields()
            onCreate(values, resetForm)
          })
          .catch(info => console.log('Validate Failed:', info))
      }}
    >
      <Form
        id='change-password'
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
        }}
      >

        <Form.Item
          name='oldPassword'
          label='Old Password'
          rules={[{ required: true, message: 'Please input your old password!' }]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name='newPassword'
          label='New Password'
          rules={[
            {
              required: true,
              message: 'Please input your password between 8 to 16 characters!',
              min: 8,
              max: 16
            }
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name='confirm'
          label='Confirm New Password'
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject('The two passwords that you entered do not match!')
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const ChangePassword = (props) => {

  return (
    <div>
      <CollectionCreateForm
        {...props}
      />
    </div>
  )
}

export default ChangePassword
