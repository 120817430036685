import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Current Position Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'script',
        dataIndex: 'script',
        key: 'script',
        width: '29%',
        className: 'report-text'
      },
      {
        title: 'ClientCode',
        dataIndex: 'ClientCode',
        key: 'ClientCode',
        width: '10%',
        className: 'report-text'
      },
      {
        title: 'StrategyCode',
        dataIndex: 'StrategyCode',
        key: 'StrategyCode',
        width: '11%',
        className: 'report-numeric'
      },
      {
        title: 'company',
        dataIndex: 'company',
        key: 'company',
        width: '8%',
        className: 'report-text'
      },
      {
        title: 'ExpiryDate',
        dataIndex: 'ExpiryDate',
        key: 'ExpiryDate',
        width: '10%',
        className: 'report-text'
      },
      {
        title: 'Strike',
        dataIndex: 'Strike',
        key: 'Strike',
        width: '8%',
        className: 'report-numeric'
      },
      {
        title: 'CP',
        dataIndex: 'CP',
        key: 'CP',
        width: '3%',
        className: 'report-text',
      },
      {
        title: 'qty',
        dataIndex: 'qty',
        key: 'qty',
        width: '5%',
        className: 'report-numeric',
      },
      {
        title: 'Value',
        dataIndex: 'Value',
        key: 'Value',
        width: '8%',
        className: 'report-numeric'
      },
      {
        title: 'NetPrice',
        dataIndex: 'NetPrice',
        key: 'NetPrice',
        width: '8%',
        className: 'report-numeric'
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      columns={columns}
      dataSource={data}
      className='current-position-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}
