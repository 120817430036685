import React from 'react'
import { Table } from 'antd'

export default function ({ reportData, ClientCode, user }) {
  const columns = [
    {
      title: (
        <div
          className='reports-position-name'
          style={{
            color: '#000',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '18px',
          }}
        >
          Interest Report
        </div>
      ),
      className: 'report-title main-title',
      children: [
        {
          title: (
            <div className='hidden-client-data'>
              Client Code:{' '}
              <span className='hidden-client-code'>{ClientCode}</span>{' '}
            </div>
          ),
          className: 'hidden-client-data-th ',
          children: [
            {
              title: 'Month',
              dataIndex: 'Mnth',
              key: 'Mnth',
              width: '20%',
              className: 'report-numeric interest-column-align',
              align: 'right',
            },
            {
              title: 'Exposure',
              dataIndex: 'CumExposure',
              key: 'CumExposure',
              width: '25%',
              className: 'report-numeric interest-column-align',
            },
          ],
        },
        {
          title: (
            <div className='hidden-client-data'>
              Client Name:{' '}
              <span className='hidden-client-code'>{`${user?.getUser?.firstName} ${user?.getUser?.lastName}`}</span>
            </div>
          ),
          className: 'hidden-client-data-th ',
          children: [
            {
              title: 'Payment',
              dataIndex: 'CumDPayment',
              key: 'CumDPayment',
              width: '15%',
              className: 'report-numeric interest-column-align',
            },
            {
              title: 'Net Exposure',
              dataIndex: 'TotExposure',
              key: 'TotExposure',
              width: '20%',
              className: 'report-numeric interest-column-align',
            },
            {
              title: 'Interest',
              dataIndex: 'Interest',
              key: 'Interest',
              width: '20%',
              className: 'report-numeric interest-column-align',
            },
          ],
        },
      ],
    },
  ]
  const data = reportData.map((report, key) => {
    const round = {
      CumExposure: report.CumExposure,
      CumDPayment: report.CumDPayment,
      TotExposure: report.TotExposure,
      Interest: report.Interest,
      rate: report.rate,
    }
    if (key + 1 === reportData.length)
      return { key, ...report, Mnth: report.Type, ...round }
    return { key, ...report, ...round }
  })

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      className='interest-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}
