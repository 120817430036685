import React, { useContext, useEffect, useState } from 'react'
import { get } from 'lodash'

import NoData from 'components/NoData'
import Loader from 'components/loaders/Loader'
import DownloadReport from '../DownloadReport'
import NlvMisMatchTable from './NlvMisMatchTable'
import { GET_NLV_MISMATCH_REPORT } from '../graphql/Queries'
import '../index.css'
import { Select } from 'antd'
import client from 'apollo'
import { AppContext } from 'AppContext'

export default function () {
  const { Option } = Select
  const {
    state: {
      currentUser: { role },
    },
  } = useContext(AppContext)
  const [changeBod, setChangeBod] = useState(0)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const handleChange = (e) => {
    setChangeBod(e)
  }

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_NLV_MISMATCH_REPORT,
        variables: { where: { afterBod: changeBod, strategy: 'ILTS' } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        console.log(res.data)
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        if (err.message.split(':')[1] !== 'NO_USER_FOUND') {
          setLoading(false)
        }
      })
  }, [changeBod])

  if (loading) return <Loader />
  if (!get(data, 'spNlvMisMatchReport')) return <NoData />
  const {
    spNlvMisMatchReport: [spNlvMisMatchReport],
  } = data

  return (
    <>
      {role === 'ADMIN' && (
        <>
          <label>Select BOD: </label>
          <Select
            value={changeBod}
            onChange={handleChange}
            style={{ width: '150px' }}
            placeholder='Select BOD'
          >
            <Option value={0}>Pre BOD</Option>
            <Option value={1}>Post BOD</Option>
          </Select>
        </>
      )}
      <DownloadReport
        data={spNlvMisMatchReport}
        fileName='NLV-report'
        id='NLV-REPORT-PDF'
      />
      <div className='report-table-wrapper' id='NLV-REPORT-PDF'>
        <NlvMisMatchTable reportData={spNlvMisMatchReport} />
      </div>
    </>
  )
}
