import React, { useState, useEffect } from 'react'
import { Button, Form, Input } from 'antd'
import {
  ArrowLeftOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from '@ant-design/icons'

import {
  CHANGE_PASSWORD_V2,
  SEND_OTP_FOR_FORGOT_PASSWORD,
  VERIFY_OTP_FOR_FORGOT_PASSWORD,
} from './graphql/Queries'
import openNotification from 'utils/Notification'
import Logo from 'assets/images/logo-tagline-new.png'
import client from 'apollo'
import './index.css'

const FormItem = Form.Item
const OTP_RESEND_INTERVAL = 180

export default function ({ history }) {
  const [screenType, setScreenType] = useState('input')
  const [token, setToken] = useState('')
  const [sendOtpText, setSendOtpText] = useState('')
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const otpSentTime = localStorage.getItem('otpSentTime')
    if (otpSentTime) {
      const elapsedTime = Math.floor((Date.now() - otpSentTime) / 1000)
      if (elapsedTime < OTP_RESEND_INTERVAL) {
        setScreenType('otp')
        setCounter(OTP_RESEND_INTERVAL - elapsedTime)
      } else {
        localStorage.removeItem('otpSentTime')
      }
    }
  }, [])

  useEffect(() => {
    let timer
    if (counter > 0) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1)
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [counter])

  const sendOtpOnUser = () => {
    if (counter <= 0) {
      client
        .mutate({
          mutation: SEND_OTP_FOR_FORGOT_PASSWORD,
          variables: {
            where: { userName: window.location.href.split('name=')[1] },
          },
        })
        .then((res) => {
          if (res.data.sendOtpForForgotPassword) {
            setSendOtpText(res.data.sendOtpForForgotPassword)
            openNotification('success', res.data.sendOtpForForgotPassword)
          }
          setScreenType('otp')
          setCounter(OTP_RESEND_INTERVAL)
          localStorage.setItem('otpSentTime', Date.now())
        })
        .catch((err) => console.log(err))
    }
  }

  const onFinish = (values) => {
    const { userName, otp, newPassword } = values
    if (screenType === 'input') {
      client
        .mutate({
          mutation: SEND_OTP_FOR_FORGOT_PASSWORD,
          variables: { where: { userName } },
        })
        .then((res) => {
          if (res.data.sendOtpForForgotPassword) {
            setSendOtpText(res.data.sendOtpForForgotPassword)
            openNotification('success', res.data.sendOtpForForgotPassword)
          }
          setScreenType('otp')
          setCounter(OTP_RESEND_INTERVAL)
          localStorage.setItem('otpSentTime', Date.now())
        })
        .catch((err) => console.log(err))
    }

    if (screenType === 'otp') {
      client
        .mutate({
          mutation: VERIFY_OTP_FOR_FORGOT_PASSWORD,
          variables: { otp },
        })
        .then((res) => {
          if (res.data.verifyOtpForForgotPassword.token) {
            setToken(res.data.verifyOtpForForgotPassword.token)
            localStorage.setItem(
              'token',
              JSON.stringify(res.data.verifyOtpForForgotPassword.token)
            )
          }
          if (res.message) {
            openNotification('success', res.message)
          }
          setScreenType('newPassword')
        })
        .catch((err) => console.log(err))
    }

    if (token) {
      client
        .mutate({
          mutation: CHANGE_PASSWORD_V2,
          variables: { newPassword },
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        })
        .then((res) => {
          if (res.data.changePasswordV2 === true) {
            openNotification('success', 'Password Updated SuccessFully')
            localStorage.removeItem('token')
          }
          history.push('/login')
        })
        .catch((err) => console.log(err))
    }
  }

  let userName =
    window.location.href.split('=')[1] === 'undefined'
      ? ''
      : window.location.href.split('=')[1]

  return (
    <div className='gx-login-container forgot-password-wrapper'>
      <div className='gx-login-content'>
        <div className='gx-login-header'>
          <img alt='Finideas' src={Logo} />
        </div>
        {screenType !== 'newPassword' && (
          <div className='gx-mb-4'>
            <h2>Forgot Your Password ?</h2>
            <p>
              Don't worry. Recovering the password is easy. Enter the Username
              you have registered with Finideas
            </p>
            {screenType === 'otp' && (
              <p style={{ fontWeight: '500' }}>{sendOtpText}</p>
            )}
          </div>
        )}
        <Form
          layout='vertical'
          name='basic'
          onFinish={onFinish}
          initialValues={{ remember: true, userName }}
          className='gx-signin-form gx-form-row0'
        >
          {screenType === 'otp' && (
            <FormItem
              name='otp'
              rules={[
                {
                  required: true,
                  message: 'Please input your valid otp!',
                },
              ]}
            >
              <Input className='gx-input-lineheight' placeholder='OTP' />
            </FormItem>
          )}

          {screenType === 'input' && (
            <FormItem
              name='userName'
              rules={[
                {
                  required: true,
                  message: 'Please input your valid username!',
                },
              ]}
            >
              <Input className='gx-input-lineheight' placeholder='Username' />
            </FormItem>
          )}

          {screenType === 'newPassword' && (
            <>
              <div className='new_password_field'>
                <ArrowLeftOutlined className='arrow_icon' />
                <span className='new_password_text'>Create Password</span>
              </div>

              <FormItem
                label={
                  <span className='new_password_field_label'>New Password</span>
                }
                name='newPassword'
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    min: 8,
                    message: 'Password must be at least 8 characters!',
                  },
                ]}
              >
                <Input.Password
                  className='gx-input-lineheight confirm_password'
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </FormItem>

              <FormItem
                label={
                  <span className='new_password_field_label'>
                    Confirm Password
                  </span>
                }
                name='Confirm Password'
                dependencies={['newPassword']}
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          'The new password that you entered do not match!'
                        )
                      )
                    },
                  }),
                ]}
              >
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  className='gx-input-lineheight confirm_password'
                />
              </FormItem>
            </>
          )}
          {screenType === 'otp' && (
            <FormItem>
              <div
                className='resend_opt'
                style={{ cursor: counter > 0 ? 'not-allowed' : 'pointer' }}
                onClick={sendOtpOnUser}
              >
                {counter > 0
                  ? `Resend OTP in ${counter} seconds`
                  : 'Resend OTP'}
              </div>
            </FormItem>
          )}
          <FormItem>
            <Button type='primary' htmlType='submit' size='large'>
              {screenType === 'otp'
                ? 'Verify'
                : screenType === 'newPassword'
                ? 'Reset Password'
                : 'Send'}
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  )
}
