import React, { Component } from 'react'
import { Router, Switch, Route } from 'react-router-dom'

import LoginWithMobile from 'auth/LoginWithMobile'
import LoginWithEmail from 'auth/LoginWithEmail'
import ForgotPassword from 'auth/ForgotPassword'
import ResetPassword from 'auth/ResetPassword'
import GuardedRoute from 'auth/GuardedRoute'
import history from 'CustomHistory'
import Logout from 'auth/Logout'
import Login from 'auth/Login'
import NewLogin from 'auth/NewLogin'
import Layout from 'layout'
import LoginWithGoogle from 'auth/LoginWithGoogle'

export default class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path='/login' component={NewLogin} />
          <Route exact path='/login2' component={Login} />
          <Route exact path='/logout' component={Logout} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route
            exact
            path='/reset-password/:token'
            component={ResetPassword}
          />
          <Route exact path='/login-with-email' component={LoginWithEmail} />
          <Route exact path='/login-with-mobile' component={LoginWithMobile} />
          <Route exact path='/google/select-user' component={LoginWithGoogle} />
          <GuardedRoute path='/' name='layout' component={Layout} />
        </Switch>
      </Router>
    )
  }
}
