import React, { useContext, useEffect, useState } from 'react'
import { Button, Input, Space, DatePicker, Select, Form } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import LedgerTable from './LedgerTable'
import DownloadReport from '../DownloadReport'
import { GET_LEDGER_REPORT } from '../graphql/Queries'
import '../index.css'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import { AppContext } from 'AppContext'
import { getName } from 'utils/User'
import { isEmpty } from 'lodash'

const { RangePicker } = DatePicker
const { Option } = Select

export default function () {
  const {
    state: {
      currentUser: { role },
    },
  } = useContext(AppContext)
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({})
  const [data, setData] = useState()

  const { data: allClient, loading: userLoading } = useQuery(
    GET_CLIENT_USERS_FOR_STAFF,
    {
      variables: { where: {}, limit: 10000 },
      fetchPolicy: 'network-only',
    }
  )

  const { data: allData, loading } = useQuery(GET_LEDGER_REPORT, {
    variables: { where: filters },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isEmpty(filters) && !loading) {
      setData(allData)
    }
  }, [allData, loading, filters])

  const onFinish = (values) => {
    setFilters(() => {
      return { ...filters, clientCode: values?.clientCode }
    })
  }

  // if (loading && networkStatus === 1) return <Loader />
  // if (error) return <Page404 error={error} />
  // if (!get(data, 'spLedgerReport')) return <NoData />

  // const { spLedgerReport } = data

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          format={'DD-MMM-YYYY'}
          onChange={(e) => {
            const from = `fromDate`
            const to = `toDate`
            let tempFilters
            if (e) {
              confirm()
              tempFilters = JSON.parse(JSON.stringify(filters))
              tempFilters[from] = e[0]
              tempFilters[to] = e[1]
            } else {
              clearFilters()
              tempFilters = JSON.parse(JSON.stringify(filters))
              delete tempFilters[from]
              delete tempFilters[to]
            }
            setFilters(tempFilters)
          }}
        />
      </div>
    ),
  })

  const columns = [
    {
      title: 'Ledger Report',
      className: 'report-title main-title',
      children: [
        {
          title: 'Entry Date',
          dataIndex: 'Entrydate',
          key: 'Entrydate',
          className: 'report-text',
          width: '7%',
          ...getColumnDateProps('entrydate'),
        },
        {
          title: 'Type',
          dataIndex: 'Type',
          key: 'Type',
          className: 'report-text',
        },
        {
          title: 'Remarks',
          dataIndex: 'remark',
          key: 'remark',
          className: 'report-text',
          width: '15%',
        },
        {
          title: 'DR Amount',
          dataIndex: 'DR_Amount',
          key: 'DR_Amount',
          className: 'report-numeric',
        },
        {
          title: 'CR Amount',
          dataIndex: 'CR_Amount',
          key: 'CR_Amount',
          className: 'report-numeric',
        },
        {
          title: 'Strategy Code',
          dataIndex: 'StrategyCode',
          key: 'StrategyCode',
          className: 'report-text',
          ...getColumnSearchProps('strategyCode'),
        },
        {
          title: 'Client Code',
          dataIndex: 'ClientCode',
          key: 'ClientCode',
          className: 'report-text',
          ...getColumnSearchProps('clientCode'),
        },
        {
          title: 'Member Code',
          dataIndex: 'membercode',
          key: 'membercode',
          className: 'report-text',
          ...getColumnSearchProps('memberCode'),
        },
      ],
    },
  ]

  return (
    <>
      {role !== 'CLIENT' && data?.spLedgerReport?.length ? (
        <DownloadReport
          data={data?.spLedgerReport}
          fileName='LEDGER-report'
          id='LEDGER-REPORT-PDF'
          onlyCSV={true}
        />
      ) : (
        allData?.spLedgerReport && (
          <DownloadReport
            data={allData?.spLedgerReport}
            fileName='LEDGER-report'
            id='LEDGER-REPORT-PDF'
            onlyCSV={true}
          />
        )
      )}

      {role !== 'CLIENT' && allData?.spLedgerReport?.length ? (
        <DownloadReport
          data={allData?.spLedgerReport}
          fileName='LEDGER-report'
          id='TRADER-EQ-REPORT-PDF'
          // staticHtml={staticHtml}
          onlyCSV={true}
          getAllData={true}
        />
      ) : (
        ''
      )}
      {role !== 'CLIENT' && (
        <Form form={form} onFinish={onFinish} name='advanced_search'>
          <Form.Item
            name='clientCode'
            label='Select Client Code'
            rules={[{ required: true, message: 'Please select clientCode!' }]}
            hasFeedback
          >
            <Select
              style={{ width: '200px' }}
              showSearch
              loading={userLoading}
              placeholder='Select Client Code'
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option?.children[0] ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase()) ||
                (option?.children[2] ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {!userLoading &&
                allClient &&
                allClient?.getClientUsersForStaff?.users.map((user, key) => {
                  return (
                    <Option
                      key={key}
                      value={user.clientCode}
                      title={getName(user)}
                    >
                      {user.clientCode}-{getName(user)}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({})
                setData({})
              }}
            >
              Clear
            </Button>
          </div>
        </Form>
      )}
      {role !== 'CLIENT' && data?.spLedgerReport ? (
        <div className='report-table-wrapper' id='LEDGER-REPORT-PDF'>
          <LedgerTable
            reportData={data?.spLedgerReport}
            columns={columns}
            loading={loading}
          />
        </div>
      ) : (
        ''
      )}

      {role === 'CLIENT' ? (
        <div className='report-table-wrapper' id='LEDGER-REPORT-PDF'>
          <LedgerTable
            reportData={allData?.spLedgerReport}
            columns={columns}
            loading={loading}
          />
        </div>
      ) : (
        ''
      )}
    </>
  )
}
