import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import InterestTable from './InterestTable'
import Loader from 'components/loaders/Loader'
import DownloadReport from '../DownloadReport'
import { GET_INTEREST_REPORT } from '../graphql/Queries'
import { GET_USER } from 'modules/Users/graphql/Queries'
import '../index.css'
import { AppContext } from 'AppContext'

export default function (props) {
  let {
    match: {
      params: { clientCode, clientId },
    },
  } = props

  const {
    state: { currentUser },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_INTEREST_REPORT, {
    variables: {
      where: {
        clientCode: clientCode || currentUser?.clientCode,
        date: null,
        strategy: 'ILTS',
      },
    },
  })
  const {
    data: getUserData,
    loading: getUserLoading,
    error: getUserError,
  } = useQuery(GET_USER, { variables: { id: clientId || currentUser?.id } })

  if (loading || getUserLoading) return <Loader />
  if (error || getUserError) return <Page404 error={error} />
  if (!get(data, 'spInterestReport')) return <NoData />

  const {
    spInterestReport: [spInterestReport],
  } = data

  return (
    <>
      <DownloadReport
        data={JSON.parse(JSON.stringify(spInterestReport))?.map((item, key) => {
          if (key + 1 === JSON.parse(JSON.stringify(spInterestReport)).length) {
            return {
              Month: item.Type,
              CumDPayment: item.CumDPayment,
              CumExposure: item.CumExposure,
              Interest: item.Interest,
              TotExposure: item.TotExposure,
            }
          } else {
            return {
              Month: item.Mnth,
              CumDPayment: item.CumDPayment,
              CumExposure: item.CumExposure,
              Interest: item.Interest,
              TotExposure: item.TotExposure,
            }
          }
        })}
        fileName='interest-report'
        id='INTEREST-REPORT-PDF'
      />
      <div
        className='report-table-wrapper interest-table-report'
        id='INTEREST-REPORT-PDF'
      >
        <InterestTable
          reportData={spInterestReport}
          ClientCode={clientCode || currentUser?.clientCode}
          user={getUserData}
        />
      </div>
    </>
  )
}
