import _sortBY from 'lodash/sortBy'
import React, { useEffect, useState } from 'react'
import { Collapse, Table, Tooltip, Modal, Tag, Button } from 'antd'
import {
  CaretRightOutlined,
  MenuOutlined,
  EditTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'

import './question.css'
import client from 'apollo'
import { GET_QUESTIONS } from './graphql/Query'
import CreateQuestionModal from './CreateQuestionModal'
import CreateSectionModal from './CreateSectionModal'
import openNotification from 'utils/Notification'
import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  DELETE_SECTION,
  UPDATE_QUESTION,
  UPDATE_SECTION,
} from './graphql/Mutation'

const { Panel } = Collapse
const { confirm } = Modal

const Section = ({
  planId,
  planName,
  section,
  name,
  questions,
  setUserType,
  userType,
}) => {
  const [selectedSection, setSelectedSection] = useState(undefined)
  const [selectedQuestion, setSelectedQuestion] = useState(undefined)
  const [showSectionModal, setShowSectionModal] = useState(false)
  const [showQuestionModal, setShowQuestionModal] = useState(false)
  const SortableItem = SortableElement((props) => <tr {...props} />)
  const SortableBody = SortableContainer((props) => <tbody {...props} />)
  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ))
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    setDataSource(_sortBY(questions, ['index']))
  }, [questions])

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Questions',
      dataIndex: 'question',
      className: 'drag-visible',
      render: (record, text) => {
        return (
          <p className='question'>
            {record}
            <span className='star'>{text.isRequired ? ' * ' : ''}</span>
          </p>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Options (Points)',
      dataIndex: 'options',
      className: 'option-tag',
      render: (text, record) =>
        record.type === 'TEXT' ? (
          <Tag color='blue'>{record.point}</Tag>
        ) : (
          record.options.map((options) => {
            const { option, point } = options
            return (
              <Tag color='blue' key={option}>
                {option.toUpperCase()} ({point})
              </Tag>
            )
          })
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
    },
  ]

  function handleHidSectionModal() {
    setShowSectionModal(false)
    setSelectedSection(undefined)
  }

  function handleUpdateSection(values, resetForm) {
    setUserType(undefined)
    const data = values
    const { id } = section
    client
      .mutate({
        mutation: UPDATE_SECTION,
        variables: { data, id },
      })
      .then((res) => {
        setUserType(userType)
        openNotification('success', 'Section Updated Successfully')
        handleHidSectionModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleHideSectionModal() {
    setShowSectionModal(false)
    setSelectedSection(undefined)
  }

  function showSectionConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this section?`,
      content: `When clicked the OK button, this section will be Deleted`,
      onOk() {
        setUserType(undefined)
        client
          .mutate({
            mutation: DELETE_SECTION,
            variables: { id },
          })
          .then(() => {
            setUserType(userType)
          })
          .catch((err) => console.log(err))
        openNotification('success', 'Section Deleted Successfully')
      },
    })
  }

  function handleHideQuestionModal() {
    setShowQuestionModal(false)
    setSelectedQuestion(undefined)
  }

  function handleCreateQuestion(values, resetForm) {
    setUserType(undefined)
    client.query({ query: GET_QUESTIONS }).then((res) => {
      const data = {
        ...values,
        sectionId: parseInt(section.id),
        index: res.data.getQuestions.length + 1,
      }
      client
        .mutate({
          mutation: CREATE_QUESTION,
          variables: { data },
        })
        .then((res) => {
          setUserType(userType)
          openNotification('success', 'Question Added Successfully')
          handleHideQuestionModal()
          resetForm()
        })
        .catch((err) => console.log(err))
    })
  }

  function handleUpdateQuestion(values, resetForm) {
    setUserType(undefined)
    const data = { ...values, planId }
    const { id } = selectedQuestion

    client
      .mutate({
        mutation: UPDATE_QUESTION,
        variables: { data, id },
      })
      .then((res) => {
        setUserType(userType)
        openNotification('success', 'Question Updated Successfully')
        setShowQuestionModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        {
          <Tooltip title='Edit'>
            <EditTwoTone
              onClick={() => {
                setShowQuestionModal(true)
                setSelectedQuestion(record)
              }}
            />
          </Tooltip>
        }
        {
          <Tooltip title='Delete Plan'>
            <DeleteOutlined onClick={() => showQuestionConfirm(record.id)} />
          </Tooltip>
        }
      </div>
    )
  }

  function showQuestionConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this Question?`,
      content: `When clicked the OK button, this Question will be Deleted`,
      onOk() {
        setUserType(undefined)
        client
          .mutate({
            mutation: DELETE_QUESTION,
            variables: { id },
          })
          .then(() => {
            setUserType(userType)
            openNotification('success', 'Question Deleted Successfully')
          })
          .catch((err) => console.log(err))
      },
    })
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el)
      newData.forEach((question, key) => {
        client.mutate({
          mutation: UPDATE_QUESTION,
          variables: {
            id: question.id,
            data: {
              index: key,
              planId,
            },
          },
        })
      })
      setDataSource(newData)
    }
  }

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      // disableAutoScroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps['data-row-key']
    )
    return <SortableItem index={index} {...restProps} />
  }

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className='site-collapse-custom-collapse'
      >
        <Panel
          header={
            <>
              {name} <Tag color='red'>{planName}</Tag>
            </>
          }
          key='1'
          className='site-collapse-custom-panel '
          extra={
            <>
              <div className='action-icons'>
                {
                  <Tooltip title='Edit'>
                    <EditTwoTone
                      onClick={() => {
                        setShowSectionModal(true)
                        setSelectedSection(section)
                      }}
                    />
                  </Tooltip>
                }
                {
                  <Tooltip title='Delete Plan'>
                    <DeleteOutlined
                      onClick={() => showSectionConfirm(section.id)}
                    />
                  </Tooltip>
                }
                {showSectionModal && (
                  <CreateSectionModal
                    visible={showSectionModal}
                    onUpdate={handleUpdateSection}
                    section={section}
                    isEdit={selectedSection !== undefined}
                    onCancel={() => handleHideSectionModal()}
                  />
                )}
                <DragHandle />
              </div>
            </>
          }
        >
          <div style={{ textAlign: 'right' }}>
            <Button
              key='1'
              type='primary'
              onClick={() => setShowQuestionModal(true)}
              style={{ height: '28px', lineHeight: '26px' }}
            >
              Add Question
            </Button>
          </div>
          <Table
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey='index'
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
        </Panel>
      </Collapse>
      {showQuestionModal && (
        <CreateQuestionModal
          planId={planId}
          visible={showQuestionModal}
          onCreate={handleCreateQuestion}
          onUpdate={handleUpdateQuestion}
          question={selectedQuestion}
          isEdit={selectedQuestion !== undefined}
          onCancel={() => handleHideQuestionModal()}
        />
      )}
    </>
  )
}

export default Section
