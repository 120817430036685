import { Button, Form, Row, Col, Input, Select } from 'antd'
import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get, isEmpty } from 'lodash'
import _map from 'lodash/map'
import client from 'apollo'

import '../index.css'
import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import history from 'CustomHistory'
import { GET_STRATEGY_LIST } from 'modules/Settings/graphql/Queries'
import { SP_CURRENT_POSITIONEQ } from '../graphql/Queries'
import DownloadReport from '../DownloadReport'
import PositionEQTable from './PositionEQTable'

const { Option } = Select

export default function () {
  const [form] = Form.useForm()
  const [strategyList, setStrategyList] = useState([])
  const {
    state: {
      isClient,
      isStaff,
      isAdmin,
      currentUser: { clientCode },
    },
    dispatch,
  } = useContext(AppContext)
  const [filters, setFilters] = useState({
    clientCode: isAdmin || isStaff ? '' : clientCode,
    strategy: '',
    script: '',
  })
  const showFilters = get(history, 'location.pathname') === '/' && !isClient

  useEffect(() => {
    dispatch({ type: 'SELECTED_GROUP', data: undefined })
    dispatch({ type: 'SELECTED_STRATEGY', data: 'ILTS' })
  }, [dispatch, showFilters])

  useEffect(() => {
    client
      .query({ query: GET_STRATEGY_LIST, fetchPolicy: 'network-only' })
      .then((res) => {
        setStrategyList(res.data.getStrategyList)
      })
  }, [])

  const { data, loading, error } = useQuery(SP_CURRENT_POSITIONEQ, {
    variables: { where: filters },
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spCurrentPositionEQ')) return <NoData />

  let currentPositionEQBalanceData
  const { spCurrentPositionEQ } = data
  currentPositionEQBalanceData = spCurrentPositionEQ.map((EqBalance) => {
    return {
      ...EqBalance,
      qty: Math.round(EqBalance.qty),
      Value: Math.round(EqBalance.Value),
      NetPrice: Math.round(EqBalance.NetPrice),
    }
  })

  const columns = [
    {
      title: 'EQ Position Report',
      className: 'report-title main-title',
      children: [
        {
          title: 'ClientCode',
          dataIndex: 'ClientCode',
          key: 'ClientCode',
          width: '15%',
          className: 'report-text',
        },
        {
          title: 'StrategyCode',
          dataIndex: 'StrategyCode',
          key: 'StrategyCode',
          width: '15%',
          className: 'report-numeric',
        },
        {
          title: 'Script',
          dataIndex: 'script',
          key: 'script',
          width: '15%',
          className: 'report-numeric',
        },
        {
          title: 'qty',
          dataIndex: 'qty',
          key: 'qty',
          width: '15%',
          className: 'report-numeric',
        },
        {
          title: 'Value',
          dataIndex: 'Value',
          key: 'Value',
          width: '20%',
          className: 'report-numeric',
        },
        {
          title: 'NetPrice',
          dataIndex: 'NetPrice',
          key: 'NetPrice',
          width: '20%',
          className: 'report-numeric',
        },
      ],
    },
  ]

  const onFinish = (values) => {
    setFilters({
      ...values,
      clientCode: '',
      strategy: values.strategy ? values.strategy : '',
      script: values.script ? values.script : '',
    })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: 0,
        }}
      >
        {!error && (
          <DownloadReport
            data={currentPositionEQBalanceData}
            fileName='current-positionEQ'
            id='SP_CURRENT_POSITIONFO_PDF'
          />
        )}
      </div>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Col span={20}>
          <Form form={form} onFinish={onFinish} name='advanced_search'>
            <Row style={{ justifyContent: 'center' }}>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item name='script' label='script'>
                  <Input placeholder='Enter script' style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item name='strategy' label='strategy'>
                  <Select
                    style={{ width: 80 }}
                    className='gx-mr-3 gx-select-sm'
                    onChange={(e) =>
                      dispatch({ type: 'SELECTED_STRATEGY', data: e })
                    }
                  >
                    {_map(strategyList, (strategy, key) => {
                      const { StrategyName, StrategyCode } = strategy
                      return (
                        <Option value={StrategyCode} key={key}>
                          {StrategyName}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Button type='primary' htmlType='submit'>
                  Search
                </Button>
                <Button
                  onClick={(values) => {
                    form.resetFields()
                    setFilters({})
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {error ? (
        <Page404 error={error} isShow={false} />
      ) : (
        !isEmpty(filters) && (
          <div className='report-table-wrapper' id='SP_CURRENT_POSITIONFO_PDF'>
            <PositionEQTable
              filters={filters}
              data={currentPositionEQBalanceData}
              columns={columns}
              loading={loading}
            />
          </div>
        )
      )}
    </>
  )
}
