import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  ALL_FIELD_SELECTION,
  GET_FIELD_SELECTION,
} from 'modules/Groups/graphql/Queries'
import { Divider, PageHeader } from 'antd'
import client from 'apollo'
import { UPDATE_FIELD_SELECTION } from 'modules/Groups/graphql/Mutations'
import { Button, Checkbox, Form, Select } from 'antd'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import openNotification from 'utils/Notification'
const { Option } = Select

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

function ClientFieldAccess() {
  const [selectedValue, setSelectedValue] = useState('1')
  const [loading, setLoading] = useState(false)
  const [updatedField, setUpdatedField] = useState({})
  const [selectAllCheck, setSelectAllCheck] = useState()
  const {
    data: selectedField,
    loading: selectedFieldLoading,
    error: selectedFieldError,
  } = useQuery(ALL_FIELD_SELECTION, { fetchPolicy: 'network-only' })

  useEffect(() => {
    client
      .query({
        query: GET_FIELD_SELECTION,
        variables: { where: { id: selectedValue } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setUpdatedField(res?.data?.getFieldSelection)
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }, [selectedValue])

  if (selectedFieldError) return <Page404 error={selectedFieldError} />

  const selectValue = (value, option) => {
    setLoading(true)
    setSelectedValue(value)
  }

  const updateSelectedFieldModal = (e, key, data) => {
    setSelectAllCheck(false)
    setUpdatedField({
      ...updatedField,
      [key]: e.target.checked,
    })
  }

  const handleOk = () => {
    setLoading(true)
    delete updatedField.__typename
    delete updatedField.createdAt
    delete updatedField.updatedAt
    delete updatedField.deletedAt
    delete updatedField.userId
    delete updatedField.id
    delete updatedField.role
    delete updatedField.action

    if (selectedValue !== '1') {
      client
        .mutate({
          mutation: UPDATE_FIELD_SELECTION,
          variables: { data: updatedField, where: { id: selectedValue } },
        })
        .then((res) => {
          setLoading(false)
          openNotification('success', 'Client fields update successfully')
        })
        .catch((err) => {
          setLoading(false)
        })
    } else {
      client
        .mutate({
          mutation: UPDATE_FIELD_SELECTION,
          variables: { data: updatedField, where: { id: selectedValue } },
        })
        .then((res) => {
          let tempLocalObj = {}
          for (const key in res?.data?.updateFieldSelection) {
            if (res?.data?.updateFieldSelection[key] === true) {
              tempLocalObj[key] = res?.data?.updateFieldSelection[key]
            }
          }
          localStorage.setItem('updateFieldAdmin', JSON.stringify(tempLocalObj))
          setLoading(false)
          openNotification('success', 'Client fields update successfully')
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  const selectAll = (value) => {
    setSelectAllCheck(value)
    for (const item in updatedField) {
      setUpdatedField((prevStep) => ({
        ...prevStep,
        [item]: value,
      }))
    }
  }

  return (
    <>
      <PageHeader className='box' title='Client-Fields' />
      <>
        {!selectedFieldLoading && !selectedFieldError && (
          <Form
            {...formItemLayout}
            className='user-form'
            initialValues={{
              selectRole: selectedField?.getAllFieldSelection[0].role,
            }}
          >
            <Form.Item label='Select Role' name='selectRole'>
              <Select
                showSearch
                style={{ width: '15%', marginBottom: '12px' }}
                placeholder='Option Type'
                optionFilterProp='children'
                onChange={selectValue}
              >
                {selectedField?.getAllFieldSelection.map((data, key) => {
                  return (
                    <Option value={data.id} key={key}>
                      {data.role}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Form>
        )}

        {selectedValue && (
          <Checkbox
            onChange={(e) => selectAll(e.target.checked)}
            name='selectAllcheck'
            checked={selectAllCheck}
          >
            ---SELECT ALL---
          </Checkbox>
        )}
        <Divider />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {!loading ? (
            Object.keys(updatedField)?.map((key) => {
              if (
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'deletedAt' &&
                key !== 'userId' &&
                key !== 'id' &&
                key !== 'role' &&
                key !== 'action' &&
                key !== '__typename'
              ) {
                return (
                  <div style={{ width: '25%' }}>
                    <div key={key}>
                      <Checkbox
                        checked={updatedField[key]}
                        key={key}
                        onChange={(e) => updateSelectedFieldModal(e, key)}
                        loading={loading}
                        style={{
                          textTransform: 'capitalize',
                          fontSize: 'medium',
                        }}
                      >
                        {key.split(/(?=[A-Z])/).join(' ')}
                      </Checkbox>
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })
          ) : (
            <Loader />
          )}
        </div>
        <div style={{ marginTop: '20px' }}>
          <Button key='submit' type='primary' onClick={handleOk}>
            Save
          </Button>
          <Button onClick={() => selectValue(selectedValue)}>Reset</Button>
        </div>
      </>
    </>
  )
}

export default ClientFieldAccess
