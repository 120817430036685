import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import DownloadReport from '../DownloadReport'
import Loader from 'components/loaders/Loader'
import AverageIndexTable from './AverageIndexTable'
import { GET_AVERAGE_INDEX_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const {
    state: {
      currentUser: { clientCode, firstName, lastName },
    },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_AVERAGE_INDEX_REPORT, {
    variables: { where: { clientCode, date: null, strategy: 'ILTS' } },
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spAverageIndexreport')) return <NoData />

  const {
    spAverageIndexreport: [spAverageIndexreport],
  } = data

  return (
    <>
      <DownloadReport
        data={JSON.parse(JSON.stringify(spAverageIndexreport))?.map((item) => {
          return {
            EntryDate: item.entrydate,
            Type: item.Type,
            ClosingIndexValue: item.ClosingIndexValue,
            Quantity: item.Qty,
            Value: item.Value,
          }
        })}
        fileName='average-report'
        id='AVG-REPORT-PDF'
      />
      <div
        className='report-table-wrapper average-table-report'
        id='AVG-REPORT-PDF'
      >
        <AverageIndexTable
          reportData={spAverageIndexreport}
          ClientCode={clientCode}
          firstName={firstName}
          lastName={lastName}
        />
      </div>
    </>
  )
}
