import gql from 'graphql-tag'

export const UPDATE_TICKET = gql`
  mutation updateTicket($data: TicketUpdateInput!, $id: ID!) {
    updateTicket(data: $data, where: { id: $id }) {
      id
    }
  }
`

export const CREATE_TICKET = gql`
  mutation createTicket(
    $subject: String!
    $description: String!
    $fileUrl: [Upload]
  ) {
    createTicket(
      data: { subject: $subject, description: $description, fileUrl: $fileUrl }
    ) {
      id
      ticketNumber
    }
  }
`
