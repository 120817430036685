import React, { Fragment, useState, useContext } from 'react'
import { PageHeader, Button, Tabs, notification } from 'antd'
import get from 'lodash/get'

import client from 'apollo'
import TabTickets from './TabTickets'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'
import { CREATE_TICKET } from './graphql/Mutations'
import CreateTicketModal from './CreateTicketModal'
import { useQuery } from '@apollo/react-hooks'
import { GET_TICKETS } from './graphql/Queries'
import { CUSTOM_CSV_DOWNLOAD } from 'modules/Reports/graphql/Queries'

const TabPane = Tabs.TabPane

export default function () {
  const {
    state: {
      currentReport,
      isClient,
      isAdmin,
      currentUser: { id },
    },
  } = useContext(AppContext)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState(
    !isClient && !isAdmin ? { assignUserId: parseInt(id) } : {}
  )
  const [CSVLoading, setCSVLoading] = useState(false)

  let tableData = []

  let variables = { where: filters, limit, skip, sort }
  const { data, loading, error } = useQuery(GET_TICKETS, {
    variables,
    fetchPolicy: 'network-only',
  })

  let totalCount = 0
  if (!loading && get(data, 'getTickets.tickets')) {
    totalCount = data.getTickets.count
    tableData = data.getTickets.tickets.map((ticket, key) => ({
      key: key.toString(),
      ...ticket,
    }))
  }

  const changeTab = (key, tab) => {
    console.log({ isAdmin, isClient })
    if (!isClient && !isAdmin) {
      if (key === '1') {
        setFilters({ assignUserId: parseInt(id) })
      } else if (key === '2') {
        setFilters({ generatedById: parseInt(id) })
      } else {
        setFilters({ assignUserId: parseInt(id) })
      }
    }
  }

  function handleCreateTicket(values, resetForm) {
    setShowFormLoading(true)
    let files = []
    if (values?.fileUrl) {
      values.fileUrl.forEach((file) => {
        files.push(file.originFileObj)
      })
      values.fileUrl = files
    } else {
      delete values.fileUrl
    }

    client
      .mutate({
        mutation: CREATE_TICKET,
        variables: { ...values },
      })
      .then((res) => {
        notification.success({
          duration: 2.5,
          message: (
            <>
              Ticket created Successfully
              <br />
              {`Note Your Ticket Number.:${res?.data?.createTicket?.ticketNumber}`}
            </>
          ),
        })
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  const handleCsvDownload = () => {
    setCSVLoading(true)
    client
      .query({
        query: GET_TICKETS,
        variables: { where: { ...filters }, skip: 0, sort, limit: totalCount },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data: res.data.getTickets.tickets,

                fields: [
                  {
                    value: 'generatedBy.firstName',
                    label: 'User First Name',
                  },
                  {
                    value: 'generatedBy.lastName',
                    label: 'User Last Name',
                  },
                  { value: 'ticketNumber', label: 'Ticket Number' },
                  { value: 'subject', label: 'Subject' },
                  { value: 'status', label: 'Status' },
                  {
                    value: 'description',
                    label: 'Description',
                  },
                  {
                    value: 'assignUser.firstName',
                    label: 'Assigned User First Name',
                  },
                  {
                    value: 'assignUser.lastName',
                    label: 'Assigned User Last Name',
                  },
                  { value: 'fileUrl', label: 'Attachment' },
                  {
                    value: 'closedByUser.firstName',
                    label: 'Closed By User First Name',
                  },
                  {
                    value: 'closedByUser.lastName',
                    label: 'Closed By User Last Name',
                  },
                  { value: 'createdAt', label: 'Created At' },
                  { value: 'closedDate', label: 'Closed At' },
                ],
                fileName: 'Tickets',
              },
            },
          })
          .then(
            (res) =>
              get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCSVLoading(false)
          })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLimit(10)
        setSkip(0)
        setCSVLoading(false)
      })
  }

  return (
    <Fragment>
      <CustomMeta title='Tickets' />
      <PageHeader
        className='box'
        title='Tickets'
        extra={[
          !isAdmin && currentReport.includes('Create Ticket') && (
            <Button
              key='1'
              type='primary'
              onClick={() => setShowFormModal(true)}
            >
              Generate Ticket
            </Button>
          ),
          isAdmin && (
            <Button
              key='2'
              type='primary'
              onClick={handleCsvDownload}
              loading={CSVLoading}
            >
              Export Csv
            </Button>
          ),
        ]}
      />
      {!isAdmin && showFormModal && (
        <CreateTicketModal
          visible={showFormModal}
          loading={showFormLoading}
          onCreate={handleCreateTicket}
          onCancel={() => setShowFormModal(false)}
          fromFaq={false}
        />
      )}
      {isClient || isAdmin ? (
        <TabTickets
          setSort={setSort}
          setSkip={setSkip}
          setLimit={setLimit}
          setFilters={setFilters}
          limit={limit}
          filters={filters}
          data={data}
          loading={loading}
          error={error}
          tableData={tableData}
          totalCount={totalCount}
        />
      ) : (
        <Widget
          title='&nbsp;'
          styleName='gx-card-tabs gx-card-tabs-right gx-card-profile'
        >
          <Tabs defaultActiveKey='1' onChange={changeTab}>
            <TabPane tab='Assigned Tickets' key='1'>
              <div className='gx-mb-2'>
                <TabTickets
                  defaultFilters={{
                    assignUserId: parseInt(id),
                  }}
                  setSort={setSort}
                  setSkip={setSkip}
                  setLimit={setLimit}
                  setFilters={setFilters}
                  limit={limit}
                  filters={filters}
                  data={data}
                  loading={loading}
                  error={error}
                  tableData={tableData}
                  totalCount={totalCount}
                />
              </div>
            </TabPane>
            <TabPane tab='Generated Tickets' key='2'>
              <div className='gx-mb-2'>
                <TabTickets
                  defaultFilters={{
                    generatedById: parseInt(id),
                  }}
                  setSort={setSort}
                  setSkip={setSkip}
                  setLimit={setLimit}
                  setFilters={setFilters}
                  limit={limit}
                  filters={filters}
                  data={data}
                  loading={loading}
                  error={error}
                  tableData={tableData}
                  totalCount={totalCount}
                />
              </div>
            </TabPane>
          </Tabs>
        </Widget>
      )}
    </Fragment>
  )
}
