import React from 'react'
import { PageHeader } from 'antd'

import Details from '../../modules/Support/Details'

export default function Support() {

  return (
    <>
      <PageHeader className='box' title='Support' />
      <Details />
    </>
  )
}
