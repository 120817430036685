import gql from 'graphql-tag'

export const UPDATE_ANALYSIS_EXPIRY_DATE = gql`
  mutation UpdateAnalysisExpiry(
    $data: analysisExpiryDateInput
    $where: analysisExpiryDateWhereUniqueInput
  ) {
    updateAnalysisExpiry(data: $data, where: $where) {
      id
      expiryDate
      createdAt
      updatedAt
    }
  }
`
