import React from "react"
import moment from "moment"
import { Avatar } from "antd"

const MailListItem = ({ mail, onMailSelect }) => {
  return (
    <div className="gx-module-list-item gx-mail-cell">
      <div className="gx-module-list-icon">
        <div className="gx-ml-2">
          {
            mail.avatar ?
              <Avatar className="gx-size-40" alt="Alice Freeman" src={mail.avatar} /> :
              <Avatar className="gx-avatar gx-bg-blue gx-size-40">{mail.from.charAt(0).toUpperCase()}</Avatar>
          }
        </div>
      </div>
      <div
        className="gx-mail-list-info"
        onClick={() => onMailSelect(mail.messageUid)}
      >
        <div className="gx-module-list-content">
          <div className="gx-mail-user-des">
            <span className="gx-sender-name">{mail.from}</span>
            <div className="gx-time">{moment(mail.date).format('Do MMMM YYYY')}</div>
          </div>
          <div className="gx-message">
            <p className="gx-text-truncate">{mail.subject}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MailListItem
