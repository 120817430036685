import { AppContext } from 'AppContext'
import { Button, Form, Select } from 'antd'
import client from 'apollo'
import CustomMeta from 'components/CustomMeta'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import React, { useContext, useState } from 'react'
import { COMPLETE_STEP } from './graphql/Mutations'
import openNotification from 'utils/Notification'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
}

const SelectTypeForm = ({ next }) => {
  const [form] = Form.useForm()
  const { dispatch, state } = useContext(AppContext)
  const [loading, setLoadings] = useState(false)

  const onFinish = (values) => {
    setLoadings(true)
    let isPanCardIndividual
    if (values?.entityStatus === 'Individual') {
      isPanCardIndividual = true
    } else {
      isPanCardIndividual = false
    }
    const { id } = state.currentUser

    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id,
          data: {
            entityStatus: !isPanCardIndividual
              ? values.entityStatus
              : undefined,
            isPanCardIndividual: isPanCardIndividual,
            completedSteps: 'SELECT_USER_TYPE',
          },
        },
      })
      .then((res) => {
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'SELECT_USER_TYPE',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        openNotification('success', 'User type Updated Successfully')
        setLoadings(false)
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadings(false))
  }

  return (
    <>
      <div className='gx-app-login-wrap title-verification'>
        <CustomMeta title='Select User Type' />
        <div className='gx-app-login-content verification'>
          <Form
            {...formItemLayout}
            form={form}
            name='register'
            onFinish={onFinish}
            labelAlign='left'
            scrollToFirstError
          >
            <div>
              <FormItem
                name='entityStatus'
                label='Type of Investor'
                rules={[{ required: true, message: 'Select Type of Investor' }]}
              >
                <Select
                  placeholder='Select Type of Investor'
                  options={[
                    {
                      value: 'Individual',
                      label: 'Individual',
                    },
                    {
                      value: 'HUF',
                      label: 'HUF',
                    },
                    {
                      value: 'LLP',
                      label: 'LLP',
                    },
                    {
                      value: 'partnershipFirm',
                      label: 'Partnership Firm',
                    },
                    {
                      value: 'Corporate',
                      label: 'Corporate',
                    },
                    {
                      value: 'Trust',
                      label: 'Trust',
                    },
                  ]}
                />
              </FormItem>
            </div>
            <Button type='primary' htmlType='submit' loading={loading}>
              Verify And Next
            </Button>
          </Form>
        </div>
      </div>
    </>
  )
}

export default SelectTypeForm
