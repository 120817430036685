import React, { useState } from 'react'
import { PageHeader, Table, Tooltip } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { GET_RISK_PROFILE_SCORE } from './graphql/Queries'
import Page404 from 'components/Page404'
import CreateRiskProfileScoreSetting from './CreateRiskProfileScoreSetting'

const RiskProfileScore = () => {
  const [openModal, setOpenModal] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  const { data, loading, error } = useQuery(GET_RISK_PROFILE_SCORE, {
    fetchPolicy: 'network-only',
  })
  if (error) return <Page404 />

  const renderAction = (data) => {
    return (
      <div className='action-icons'>
        <Tooltip title='Edit'>
          <EditTwoTone onClick={() => setOpenModal(!openModal)} />
        </Tooltip>
      </div>
    )
  }
  const columns = [
    {
      title: 'Low Risk',
      dataIndex: 'lowRisk',
      className: 'hidden-client-data-th',
      children: [
        {
          title: 'Min Risk Score',
          dataIndex: 'lowRisk',
          render: (text, record) => record?.lowRisk?.minScore,
          className: 'report-numeric',
        },
        {
          title: 'Max Risk Score',
          dataIndex: 'lowRisk',
          render: (text, record) => record?.lowRisk?.maxScore,
          className: 'report-numeric',
        },
      ],
    },
    {
      title: 'Moderate Risk',
      dataIndex: 'moderateRisk',
      className: 'hidden-client-data-th',
      children: [
        {
          title: 'Min Risk Score',
          dataIndex: 'moderateRisk',
          render: (text, record) => record?.moderateRisk?.minScore,
          className: 'report-numeric',
        },
        {
          title: 'Max Risk Score',
          dataIndex: 'moderateRisk',
          render: (text, record) => record?.moderateRisk?.maxScore,
          className: 'report-numeric',
        },
      ],
    },
    {
      title: 'High Risk',
      dataIndex: 'highRisk',
      className: 'hidden-client-data-th',
      children: [
        {
          title: 'Min Risk Score',
          dataIndex: 'highRisk',
          render: (text, record) => record?.highRisk?.minScore,
          className: 'report-numeric',
        },
        {
          title: 'Max Risk Score',
          dataIndex: 'highRisk',
          render: (text, record) => record?.highRisk?.maxScore,
          className: 'report-numeric',
        },
      ],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: 100,
    },
  ]
  const handleCancel = () => {
    setOpenModal(false)
  }

  return (
    <>
      <PageHeader className='box' title='Risk Profile Score Setting' />

      <Table
        loading={loading}
        columns={columns}
        dataSource={data && data?.getRiskProfile ? [data?.getRiskProfile] : []}
      />

      {openModal && (
        <CreateRiskProfileScoreSetting
          isModalOpen={openModal}
          handleCancel={handleCancel}
          setOpenModal={setOpenModal}
          setModalLoading={setModalLoading}
          modalLoading={modalLoading}
          data={data?.getRiskProfile}
        />
      )}
    </>
  )
}
export default RiskProfileScore
