import gql from 'graphql-tag'

export const UPDATE_RISK_PROFILE_SCORE = gql`
  mutation UpdateRiskProfile(
    $where: whereRiskProfileUpdated
    $data: updateRiskProfileInput
  ) {
    updateRiskProfile(where: $where, data: $data)
  }
`
