import { useQuery } from '@apollo/react-hooks'
import { Modal, Table, Tooltip } from 'antd'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getName } from 'utils/User'
import _isEmpty from 'lodash/isEmpty'
import states from 'utils/states.json'
import { GET_ALL_DUE_AMCS } from 'modules/Reports/graphql/Queries'
import { DELETE_INVOICE } from 'modules/Users/graphql/Mutations'
import client from 'apollo'
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import openNotification from 'utils/Notification'
import './index.css'

const { confirm } = Modal

const DueInvoiceTable = (clientDetailsGet) => {
  const { state } = useContext(AppContext)
  const {
    isClient,
    currentUser: { id },
  } = state

  const [sort, setSort] = useState()
  const [deleteLoading, setLoading] = useState(false)
  const [filters, setFilters] = useState(
    isClient
      ? { clientId: parseInt(id).toString() }
      : {
          status: 'PENDING',
          clientCodeSearch: clientDetailsGet?.clientDetailsGet?.clientCode,
          isPending: true,
        }
  )
  const [selectedRow, setSelectedRow] = useState([])
  const [tableData, setTableData] = useState()

  let variable = { where: { ...filters, isActiveUser: 'true' }, sort }
  const { data, loading, error } = useQuery(GET_ALL_DUE_AMCS, {
    variables: variable,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading && _isEmpty(selectedRow) && data?.getAllDueAmcs?.amc?.length) {
      let newIds = data?.getAllDueAmcs?.amc?.map((amc) => amc.id)
      setSelectedRow(newIds)
    }
  }, [loading, data, selectedRow])

  if (error) return <Page404 error={error} />

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
      }
    } else if (filter.amcStatusType) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = filter.amcStatusType
      setFilters(tempFilters)
    } else if (filter.eMandateStatus) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['deduction'] = filter.eMandateStatus
      setFilters(tempFilters)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = 'PENDING'
      setFilters(tempFilters)
    }
  }

  const showConfirm = (record) => {
    let id
    let deleteFormAmcID
    if (record?.invoice?.id === undefined) {
      id = record?.id
      deleteFormAmcID = true
    } else {
      id = record?.invoice?.id
      deleteFormAmcID = false
    }

    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Are you sure you want to delete this invoice?`,
      content: `If you click the OK button, the invoice will be permanently removed along with the associated AMC.`,
      onOk() {
        setTableData({ ...tableData, loading: true })
        client
          .mutate({
            mutation: DELETE_INVOICE,
            variables: { where: { id, deleteFormAmcID } },
          })
          .then(() => {
            setLoading(true)
            client
              .query({
                query: GET_ALL_DUE_AMCS,
                variables: {
                  where: { ...filters, isActiveUser: 'true' },
                  sort,
                },
                fetchPolicy: 'network-only',
              })
              .then((res) => {
                setLoading(false)
                openNotification('success', 'Invoice deleted successfully!')
              })
          })
      },
    })
  }

  const renderAction = (record) => {
    return (
      <div className='action-icons'>
        <Tooltip title='Delete Invoice'>
          <DeleteOutlined onClick={() => showConfirm(record)} />
        </Tooltip>
      </div>
    )
  }

  const columns = [
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'clientCode',
      width: 150,
      render: (text, record) => (
        <NavLink to={`/clients/${parseInt(record?.client?.id)} `}>
          {record?.client?.clientCode || '-'}
        </NavLink>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'client',
      key: 'client',
      width: 150,
      render: (client) => (client === 'Total' ? 'Total' : getName(client)),
    },
    {
      title: 'Client Email',
      dataIndex: 'client',
      key: 'clientEmail',
      width: 150,
      render: (text, record) => (
        <NavLink to={`/clients/${record?.client?.id} `}>
          {record?.client?.email || '-'}
        </NavLink>
      ),
    },
    {
      title: 'Client Mobile No',
      dataIndex: 'client',
      key: 'clientPhone',
      width: 150,
      render: (text, record) => record?.client?.phone || '-',
    },
    {
      title: 'Associate Name',
      dataIndex: 'associateName',
      key: 'associateName',
      width: 150,
      render: (text, record) =>
        (
          <NavLink to={`/users/${record?.client?.createdBy?.id} `}>
            {record?.client?.createdBy
              ? `${record?.client?.createdBy?.firstName} ${record?.client?.createdBy?.lastName} `
              : '-'}
          </NavLink>
        ) || '-',
    },
    {
      title: 'Associate Email id',
      dataIndex: 'client',
      key: 'associateEmailId',
      width: 150,
      render: (text, record) => record?.client?.createdBy?.email || '-',
    },
    {
      title: 'Associate Mobile No',
      dataIndex: 'client',
      key: 'associateMobileNo',
      width: 150,
      render: (text, record) => record?.client?.createdBy?.phone || '-',
    },
    {
      title: 'RM Name',
      dataIndex: 'client',
      key: 'rmName',
      width: 150,
      render: (text, record) =>
        (
          <NavLink to={`/users/${record?.client?.createdBy?.createdBy?.id} `}>
            {record?.client?.createdBy?.createdBy
              ? `${record?.client?.createdBy?.createdBy?.firstName} ${record?.client?.createdBy?.createdBy?.lastName}`
              : '-'}
          </NavLink>
        ) || '-',
    },
    {
      title: 'RM Email id',
      dataIndex: 'client',
      key: 'rmEmailId',
      width: 150,
      render: (text, record) =>
        record?.client?.createdBy?.createdBy?.email || '-',
    },
    {
      title: 'RM Mobile No',
      dataIndex: 'client',
      key: 'rmMobileNo',
      width: 150,
      render: (text, record) =>
        record?.client?.createdBy?.createdBy?.phone || '-',
    },
    {
      title: 'Amc Status Type',
      dataIndex: 'amcStatusType',
      key: 'amcStatusType',
      width: 150,
      render: (text, record) => record?.status,
    },
    {
      title: 'Next due Date',
      dataIndex: 'nextDueDate',
      key: 'nextDueDate',
      width: 150,
      render: (text, record) =>
        record?.nextDueDate
          ? moment(record?.nextDueDate).format('Do MMM YYYY')
          : '-',
    },
    {
      title: 'Paid At Date',
      dataIndex: 'paidAt',
      key: 'paidAtDate',
      width: 150,
      render: (text, record) =>
        record?.paidAt ? moment(record?.paidAt).format('Do MMM YYYY') : '-',
    },
    {
      title: 'Current AUA',
      dataIndex: 'client',
      key: 'currentAUA',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.assetUnderAdvisory) || '-'}
            {record?.nextAmc?.totalExposure && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.totalExposure})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Amc %',
      dataIndex: 'amcInPer',
      key: 'amcInPer',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record?.invoice?.amcInPer || '-'}
            {record?.nextAmc?.amcInPer && (
              <p style={{ color: 'orange' }}>({record?.nextAmc?.amcInPer})</p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Amc Start Date',
      dataIndex: 'startDate',
      key: 'amcStartDate',
      width: 150,
      render: (text, record) =>
        moment(record?.startDate).format('Do MMM YYYY') || '-',
    },
    {
      title: 'Amc End Date',
      dataIndex: 'endDate',
      key: 'amcEndDate',
      width: 150,
      render: (text, record) =>
        moment(record?.endDate).format('Do MMM YYYY') || '-',
    },
    {
      title: 'Due Days',
      dataIndex: 'dueDays',
      key: 'dueDays',
      width: 150,
      render: (text, record) =>
        `${moment(record?.endDate, 'YYYY-MM-DD').diff(
          moment(record?.startDate, 'YYYY-MM-DD'),
          'days'
        )} Days`,
    },
    {
      title: 'Discount %',
      dataIndex: 'discount',
      key: 'discount',
      width: 150,
      render: (text, record) =>
        Math.round(record?.invoice?.discountInPer) || '-',
    },
    {
      title: 'Commission %',
      dataIndex: 'commission',
      key: 'commission',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.commissionInPer) || '-'}
            {record?.nextAmc?.commissionInPer > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.commissionInPer})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Total Gross Value',
      dataIndex: 'totalGrossValue',
      key: 'totalGrossValue',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.grossTotalAmount) || '-'}
            {record?.nextAmc?.grossTotalAmount > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.grossTotalAmount})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'GST 18%',
      dataIndex: 'gst18InPer',
      key: 'gst18InPer',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.igst18InPer) || '-'}
            {record?.nextAmc?.igst18InPer > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.igst18InPer})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Final Net Amount',
      dataIndex: 'finalNetAmount',
      key: 'finalNetAmount',
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {Math.round(record?.invoice?.totalNetAmount) || '-'}
            {record?.nextAmc?.totalNetAmount > 0 && (
              <p style={{ color: 'orange' }}>
                ({record?.nextAmc?.totalNetAmount})
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
      render: (text, record) => record?.amcQty || '-',
    },
    {
      title: 'Amc period',
      dataIndex: 'amcPeriod',
      key: 'amcPeriod',
      width: 150,
      render: (text, record) =>
        `${moment(record?.startDate).format('DD-MMM-YYYY')} to ${moment(
          record?.endDate
        ).format('DD-MMM-YYYY')}` || '-',
    },
    {
      title: 'Client Address',
      dataIndex: 'clientAddress',
      key: 'clientAddress',
      width: 150,
      render: (text, record) => record?.client?.address || '-',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 150,
      render: (text, record) =>
        states.filter((item) => item.id === +record?.client?.stateId)[0]?.name,
    },
    {
      title: 'E mandate Status',
      dataIndex: 'eMandateStatus',
      key: 'eMandateStatus',
      width: 150,
      render: (text, record) =>
        record?.client.mandateId === null ? 'NON_E_MANDATE' : 'E_MANDATE',
    },
    {
      title: 'Invoice Pdf',
      key: 'invoicePdf',
      dataIndex: 'invoicePdf',
      width: 150,
      render: (text, record) =>
        record?.invoice?.invoicePdf ? (
          <a href={`${record?.invoice?.invoicePdf}`}>
            <LinkOutlined />
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: '80px',
    },
  ]

  return (
    <>
      <Table
        loading={loading || deleteLoading}
        rowKey='id'
        columns={columns}
        dataSource={(data && data?.getAllDueAmcs?.amc) || []}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        className='invoice-due-table'
        pagination={false}
      />
    </>
  )
}

export default DueInvoiceTable
