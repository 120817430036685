import React, { useState, useContext } from 'react'
import {
  Tooltip,
  Table,
  PageHeader,
  DatePicker,
  Modal,
  Button,
  Space,
  Form,
  Upload,
  Input,
  Tag,
  Alert,
} from 'antd'
import {
  EditTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  UploadOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import moment from 'moment'
import client from 'apollo'

import {
  UPDATE_AMC,
  DELETE_AMC,
  IMPORT_AMC,
} from 'modules/Users/graphql/Mutations'
import { GET_ALL_AMC, GET_AMC } from 'modules/Users/graphql/Queries'
import openNotification from 'utils/Notification'
import { AppContext } from 'AppContext'
import CreateAMCModal from './Modal'
import { amcStatus, amcType, getName } from 'utils/User'
import {
  GENERATE_PDF,
  CUSTOM_CSV_DOWNLOAD,
} from 'modules/Reports/graphql/Queries'
import Page404 from 'components/Page404'
import { CSVLink } from 'react-csv'
import UpiPayment from 'modules/Verification/UpiPayment'
import { CREATE_TRANSACTION_VIA_UPI } from 'modules/Verification/graphql/Mutations'
import { GET_TRANSACTION } from 'modules/Transactions/Graphql/Queries'
import './index.css'
import { GET_INVOICES } from 'modules/Settings/graphql/Queries'
import Loader from 'components/loaders/Loader'

const { confirm } = Modal
const { RangePicker } = DatePicker

export default function () {
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [transactionStatus, setTransactionStatus] = useState('')
  const [showQrCode, setShowQrCode] = useState(false)
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  const [upiInputModel, setUpiInputModel] = useState(true)

  const {
    state: {
      isClient,
      currentReport,
      isAdmin,
      currentUser: { id },
    },
  } = useContext(AppContext)
  const [filters, setFilters] = useState(
    isClient ? { clientId: parseInt(id).toString() } : {}
  )
  const [updateData, setUpdateData] = useState(undefined)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)
  const [CSVLoading, setCSVLoading] = useState(false)
  const [PDFLoading, setPDFLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [csv, setCsv] = useState(undefined)
  const [AMCLoading, setAMCLoading] = useState(false)
  const [invoiceLoading, setInvoiceLoading] = useState(false)

  const [isOpenPayment, setIsOpenPayment] = useState(false)
  const [paymentData, setPaymentData] = useState({})
  const [upiTransactionId, setUpiTransactionId] = useState('')
  const [demoCsvLoading, setDemoCsvLoading] = useState(false)
  const [demoCsvData, setDemoCsvData] = useState('')
  const [showQrPopUpDetail, setShowQrPopUpDetail] = useState('')

  let variable = { where: filters, sort, skip, limit }
  if (filters.associateName) {
    delete variable.skip
    delete variable.limit
  }
  const { data, loading, error } = useQuery(GET_ALL_AMC, {
    variables: variable,
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  let totalCount = 0
  if (!loading && get(data, 'getAllAmcs.amc')) {
    totalCount = data.getAllAmcs.count
    tableData = JSON.parse(data.getAllAmcs.amc).map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
  }
  // tableData.map((item) => {
  // console.log(item)
  // let a =
  //   'pay?item.client.firstName?item.amount&item.client.firstName?item.amount'
  // console.log(
  //   `upi://pay?pa=patelkashyap134@oksbi&pn=finideas&mc=HDFC000022134515&tid=8490939000&tr=${item.invoiceNo}&tn=Amcoffinideas2021-2022&am=${item.amount}&mam=null&cu=INR&url=https://finideas-dev.netlify.com`
  // )
  // upi://pay?pa=finideas@hdfcbank&pn=finideas&mc=HDFC000022134515&tid=8490939000&tr=987654321&tn=Amc&am=1.00&mam=null&cu=INR&url=https://finideas-dev.netlify.com
  // })

  function handleTableChange(pagination, filter, sorter) {
    console.log(filter)
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.amcType) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['amcType'] = filter.amcType
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.status) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = filter.status
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      if (!filter.amcType) {
        const tempFilters = JSON.parse(JSON.stringify(filters))
        tempFilters['amcType'] = ''
        delete tempFilters.amcType
        setFilters(tempFilters)
        setCurrentPage(1)
      } else {
        const tempFilters = JSON.parse(JSON.stringify(filters))
        tempFilters['status'] = ''
        delete tempFilters.status
        setFilters(tempFilters)
        setCurrentPage(1)
      }
    }
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleUpdate(values, resetForm) {
    const dateMoment = moment(values.date)
    const date = `${moment().format('YYYY')}-${dateMoment.format(
      'M'
    )}-${dateMoment.format('D')}`
    values.endDate = moment(new Date(date)).add(1, 'years')
    delete values.date
    values.whichYear = parseInt(values.whichYear.format('YYYY'))
    setShowFormLoading(true)
    client
      .mutate({
        mutation: UPDATE_AMC,
        variables: { data: values, id: updateData.id },
        refetchQueries: [
          {
            query: GET_AMC,
            variables: { ...filters, skip, sort, limit },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification('success', 'AMC Updated Successfully')
        setUpdateData(undefined)
        setShowFormModal(false)
        setIsUpdate(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this AMC?`,
      content: `When clicked the OK button, this AMC will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_AMC,
            variables: { id },
            refetchQueries: [
              { query: GET_AMC, variables: { ...filters, skip, sort, limit } },
            ],
          })
          .catch((err) => console.log(err))
      },
    })
  }

  const sendPayData = (data) => {
    let amount = data?.amount
    const obj = {
      amcId: data?.id,
      amount: amount,
      exposure: data?.invoice?.assetUnderAdvisory,
      gst: Math.round(amount * 0.18),
    }
    setPaymentData(obj)
    setIsOpenPayment(true)
    setInvoiceLoading(true)
    client
      .mutate({
        mutation: CREATE_TRANSACTION_VIA_UPI,
        variables: {
          data: {
            amount: amount,
            amcId: +data?.id,
            lotSize: data?.amcQty,
            amountWithGST: Math.round(amount + amount * 0.18),
            onboarding: false,
          },
        },
      })
      .then((res) => {
        setQrCodeUrl(res?.data?.createTransactionViaUpi?.url)
        setShowQrCode(true)
        client
          .query({
            query: GET_INVOICES,
            variables: {
              where: {
                invoiceNo: res?.data?.createTransactionViaUpi?.invoiceNo,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            setInvoiceLoading(false)
            setShowQrPopUpDetail(res?.data?.getInvoices?.rows[0])
            // setTableData({ data: res?.data?.getInvoices, loading: false })
          })

        let upiTransactionId = res?.data?.createTransactionViaUpi?.transactionId
        let status = res?.data?.createTransactionViaUpi?.status
        let upiTxnNumber = res?.data?.createTransactionViaUpi?.upiTxnNo

        setUpiTransactionId(upiTransactionId)
        checkQrPaymentSuccess(upiTransactionId, status, upiTxnNumber)
      })
      .catch((err) => console.log({ err }))
  }

  const checkQrPaymentSuccess = (upiTransactionId, status, upiTxnNumber) => {
    let count = 0

    if (
      (status === 'PENDING' || status === 'FAILURE' || status === null) &&
      (upiTxnNumber === null || upiTxnNumber === undefined)
    ) {
      const callApiAtEvery = setInterval(() => {
        if (upiTransactionId) {
          client
            .query({
              query: GET_TRANSACTION,
              variables: {
                where: { transactionId: upiTransactionId },
              },
              fetchPolicy: 'network-only',
            })
            .then((res) => {
              count++

              if (res?.data?.getTransaction?.status === 'SUCCESS') {
                setTransactionStatus('SUCCESS')
                setUpiInputModel(false)
                clearInterval(callApiAtEvery)
              }

              if (
                res?.data?.getTransaction?.status === 'FAILED' ||
                res?.data?.getTransaction?.status === 'FAILURE' ||
                res?.data?.getTransaction?.status === 'SPAM' ||
                res?.data?.getTransaction?.status === 'REJECTED' ||
                res?.data?.getTransaction?.status === 'EXPIRED'
              ) {
                setTransactionStatus('ERROR')
                // clearInterval(callApiAtEvery)
              }

              if (count >= 120) {
                setTransactionStatus('ERROR')
                // clearInterval(callApiAtEvery)
              }
              return
            })
        }
      }, 5000)
    } else if (
      (status === 'FAILED' ||
        status === 'FAILURE' ||
        status === 'SPAM' ||
        status === 'REJECTED' ||
        status === 'EXPIRED') &&
      upiTxnNumber !== null
    ) {
      setTransactionStatus('ERROR')
    } else {
      setTransactionStatus('SUCCESS')
    }
  }

  const closePaymentModel = () => {
    setIsOpenPayment(false)
    window.location.reload()
  }

  function renderPaidButton(record) {
    return (
      <>
        {(record?.status === 'PENDING' || record?.status === null) &&
        (record?.transaction?.status === 'PENDING' ||
          record?.transaction?.status === null) &&
        record?.transaction?.upiTxnNo ? (
          <Tag
            color='#edc606'
            style={{
              width: '91px',
              lineHeight: '34px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Pending
          </Tag>
        ) : record?.status === 'PENDING' || record?.status === null ? (
          <Button
            type='primary'
            onClick={() => {
              sendPayData(record)
            }}
          >
            Pay Now
          </Button>
        ) : (
          <Tag
            color='#87d068'
            style={{
              width: '91px',
              lineHeight: '34px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Paid
          </Tag>
        )}
      </>
    )
  }
  function renderAction(record) {
    return (
      <div className='action-icons'>
        {currentReport.includes('Update AMC') && (
          <Tooltip title='Edit'>
            <EditTwoTone
              onClick={() => {
                setIsUpdate(true)
                setUpdateData(record)
                setShowFormModal(true)
              }}
            />
          </Tooltip>
        )}
        {currentReport.includes('Delete AMC') && (
          <Tooltip title='Delete AMC'>
            <DeleteOutlined onClick={() => showConfirm(record.id)} />
          </Tooltip>
        )}
      </div>
    )
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const adminFields = [
    { value: 'id', label: 'Id' },
    { value: 'client.clientCode', label: 'Client Code' },
    { value: 'client.firstName', label: 'Client First name' },
    { value: 'client.lastName', label: 'Client Last name' },
    { value: 'client.role', label: 'Type' },
    {
      value: 'client.createdBy.firstName',
      label: 'Associate Name',
    },
    { value: 'amount', label: 'AMC Amount' },
    { value: 'amcQty', label: 'Quantity' },
    { value: 'paymentType', label: 'Payment Type' },
    { value: 'startDate', label: 'Start Date' },
    { value: 'createdAt', label: 'Created At' },
    { value: 'paidAt', label: 'Paid Date' },
    { value: 'nextDueDate', label: 'Next DueDate' },
    { value: 'createdAt', label: 'Created Date' },
    { value: 'description', label: 'Description' },
  ]

  const clientFields = [
    { value: 'id', label: 'Id' },
    { value: 'amount', label: 'Amc Amount' },
    { value: 'startDate', label: 'Start Date' },
    { value: 'paidAt', label: 'Paid Date' },
    { value: 'nextDueDate', label: 'Next DueDate' },
    { value: 'invoice.invoicePdf', label: 'Invoice' },
  ]

  function handleCsvDownload() {
    setCSVLoading(true)
    client
      .query({
        query: GET_AMC,
        variables: { ...filters, skip: 0, sort, limit: totalCount },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data: JSON.parse(res.data.getAllAmcs.amc),

                fields: isClient ? clientFields : adminFields,
                fileName: 'AMCs',
              },
            },
          })
          .then(
            (res) =>
              get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCSVLoading(false)
          })
      })
  }

  function handlePdfDownload() {
    setPDFLoading(true)
    client
      .query({
        query: GET_AMC,
        variables: { ...filters, limit: totalCount, skip: 0 },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        let staticHtml = ``
        if (JSON.parse(get(res, 'data.getAllAmcs.amc')).length) {
          staticHtml = `
            <div class="ant-table-wrapper nlv-tracker-report">
              <div class="ant-spin-nested-loading">
                <div class="ant-spin-container">
                  <div class="ant-table">
                    <div class="ant-table-container">
                      <div class="ant-table-content">
                        <table style="table-layout: auto;">
                          <colgroup>
                            <col style="width: 60px; min-width: 60px;">
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col style="width: 200px; min-width: 200px;">
                          </colgroup>
                          <thead class="ant-table-thead">
                            <tr>
                              <th colspan="14" class="ant-table-cell report-title main-title">AMC</th>
                            </tr>
                            <tr>
                              <th class="ant-table-cell report-text">ClientCode</th>
                              <th class="ant-table-cell report-text">ClientFirstName</th>
                              <th class="ant-table-cell report-text">ClientLastName</th>
                              <th class="ant-table-cell report-text">Type</th>
                              <th class="ant-table-cell report-text">AssociateName</th>
                              <th class="ant-table-cell report-text">Quantity</th>
                              <th class="ant-table-cell report-text">AMCAmount</th>
                              <th class="ant-table-cell report-text">PaymentType</th>
                              <th class="ant-table-cell report-text">StartDate</th>
                              <th class="ant-table-cell report-text">PaidDate</th>
                              <th class="ant-table-cell report-text">NextDueDate</th>
                              <th class="ant-table-cell report-text">CreateDate</th>
                              <th class="ant-table-cell report-text">Description</th>
                            </tr>
                          </thead>
                          <tbody class="ant-table-tbody">`
          staticHtml = `${staticHtml}`
          JSON.parse(res.data.getAllAmcs.amc).forEach((data) => {
            const {
              client: { clientCode, firstName, lastName, role },
              amcQty,
              amount,
              paymentType,
              startDate,
              paidAt,
              nextDueDate,
              createdAt,
              description,
            } = data

            staticHtml = `${staticHtml}
                            <tr data-row-key="KYP" class="ant-table-row ant-table-row-level-0">
                              <td class="ant-table-cell report-text">${
                                clientCode || 'N/A'
                              } </td>
                              <td class="ant-table-cell report-text">${
                                firstName || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                lastName || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                role ? role.split('_').join(' ') : 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                `${data?.client?.createdBy?.firstName} ${data?.client?.createdBy?.lastName}` ||
                                'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                amcQty || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                amount || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                paymentType || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                startDate || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                paidAt || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                nextDueDate || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                createdAt || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                description || 'N/A'
                              }</td>
                            </tr>`
          })
          staticHtml = `${staticHtml}
            </tbody >
                        </table >
                      </div >
                    </div >
                  </div >
                </div >
              </div >
            </div >
              `
        }
        client
          .query({
            query: GENERATE_PDF,
            variables: { data: { html: staticHtml, type: 'landscape' } },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            setPDFLoading(false)
            get(res, 'data.generatePDF') &&
              window.open(get(res, 'data.generatePDF'), '_blank')
          })
          .catch((err) => {
            setPDFLoading(false)
          })
      })
      .catch((err) => setPDFLoading(false))
  }

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    delete tempFilters[filedName]
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const showModal = () => {
    setDemoCsvLoading(true)
    client
      .query({
        query: GET_AMC,
        variables: { status: 'PENDING', limit: totalCount, skip: 0 },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setDemoCsvData(res.data)
        setDemoCsvLoading(false)
        setIsModalVisible(true)
      })
      .catch((err) => console.log(err))
      .finally(() => setDemoCsvLoading(false))
  }

  const [form] = Form.useForm()
  const importBulkAMCs = () => {
    setAMCLoading(true)
    client
      .mutate({
        mutation: IMPORT_AMC,
        variables: { data: { csv: csv[0].originFileObj } },
        refetchQueries: [
          {
            query: GET_AMC,
            variables: { ...filters, skip, sort, limit },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        res?.data?.importAmc
          ? openNotification('success', res?.data?.importAmc)
          : openNotification('error', 'Please check the file')
        setAMCLoading(false)
        setIsModalVisible(false)
      })
      .catch((err) => {
        setAMCLoading(false)
        console.log(err)
      })
  }

  const importBulkAmcsModalClose = () => {
    setIsModalVisible(false)
    setCsv('')
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    if (e && e.fileList && e.fileList.length > 1 && e.file) {
      setCsv([e.file])
      return e && e.fileList
    }
    setCsv(e.fileList)
    return e && e.fileList
  }

  const customCsv = {
    multiple: false,
    onRemove: (file) => setCsv([]),
    beforeUpload: (file) => {
      setCsv(file)
      return false
    },
    csv,
  }

  const fileHeader = [
    { label: 'id', key: 'id' },
    { label: 'Client Code', key: 'clientCode' },
    { label: 'Which Year', key: 'whichYear' },
    { label: 'Amount', key: 'amount' },
    { label: 'Payment Type', key: 'paymentType' },
    { label: 'Description', key: 'description' },
    { label: 'Status', key: 'status' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Amc Qty', key: 'amcQty' },
    { label: 'Tax Invoice Number', key: 'taxInvoiceNumber' },
    { label: 'Invoice Number', key: 'invoiceNo' },
    { label: 'Next Due Date', key: 'nextDueDate' },
    { label: 'Paid At', key: 'paidAt' },
  ]

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus={true}
            value={selectedKeys[0]}
            placeholder={`Search ${dataIndex}`}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
          />
          <Space>
            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, dataIndex)}
              size='small'
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const columns = [
    {
      title: (
        <div
          className='hidden-client-data'
          style={{ borderBottom: '1px solid #e8e8e8' }}
        >
          Total Commission Amount : {data?.getAllAmcs?.total.toFixed(2)}
          <span className='hidden-client-code'></span>{' '}
        </div>
      ),
      children: [
        {
          title: 'Client Code',
          dataIndex: 'clientCode',
          key: 'clientCode',
          render: (text, record) => (
            <NavLink to={`/clients/${record?.client?.id} `}>
              {record?.client?.clientCode || '-'}
            </NavLink>
          ),
          sorter: (a, b) => a.clientCode - b.clientCode,
          width: '120px',
          ...getColumnSearchProps('clientCodeSearch'),
        },
        {
          title: 'Client Name',
          dataIndex: 'client',
          key: 'client',
          sorter: (a, b) => a.client - b.client,
          render: (client) =>
            client === 'Total' ? 'Total' : client ? getName(client) : '-',
          width: '120px',
          ...getColumnSearchProps('clientNameSearch'),
        },
        {
          title: 'Associate Name',
          dataIndex: 'associateName',
          key: 'associateName',
          width: '150px',
          ...getColumnSearchProps('associateName'),
          render: (text, record) => (
            <NavLink
              to={`/users/${record?.client?.createdBy?.id}`}
            >{`${record?.client?.createdBy?.firstName} ${record?.client?.createdBy?.lastName} `}</NavLink>
          ),
          sorter: (a, b) => a.associateName - b.associateName,
        },
        {
          title: 'Type',
          dataIndex: 'role',
          key: 'role',
          render: (text, record) =>
            record?.client?.createdBy?.role
              ? record?.client?.createdBy.role.split('_').join(' ')
              : '',
          width: '120px',
        },
        {
          title: 'AMC Amount',
          dataIndex: 'amount',
          key: 'amount',
          width: '120px',
          // render: (data) => +Math.round(data / 1.18),
          sorter: (a, b) => a.amount - b.amount,
        },
        {
          title: 'Quantity',
          dataIndex: 'amcQty',
          key: 'amcQty',
          width: '100px',
          sorter: (a, b) => a.amcQty - b.amcQty,
        },
        {
          title: 'Payment Type',
          dataIndex: 'paymentType',
          key: 'paymentType',
          width: '100px',
          sorter: (a, b) => a.paymentType - b.paymentType,
        },
        {
          title: 'Tax Invoice Number',
          dataIndex: 'taxInvoiceNumber',
          key: 'taxInvoiceNumber',
          sorter: (a, b) => a.taxInvoiceNumber - b.taxInvoiceNumber,
          width: 170,
          render: (text) => (text ? text : '-'),
          ...getColumnSearchProps('taxInvoiceNumber'),
        },
        {
          title: 'Amc Type',
          dataIndex: 'amcType',
          key: 'amcType',
          width: 100,
          render: (text) => (text ? text : '-'),
          filters: amcType,
        },
        {
          title: 'Select Status type',
          dataIndex: 'status',
          key: 'status',
          width: 100,
          render: (text) => (text ? text : '-'),
          filters: amcStatus,
        },
        {
          title: 'Start Date',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          width: '105px',
          ...getColumnDateProps('startDate'),
        },
        {
          title: 'Paid Date ',
          dataIndex: 'paidAt',
          key: 'paidAt',
          width: '105px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps('paidAt'),
        },
        {
          title: 'Next DueDate',
          dataIndex: 'nextDueDate',
          key: 'nextDueDate',
          width: '125px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps('nextDueDate'),
        },
        {
          title: 'created At',
          key: 'createdAt',
          dataIndex: 'createdAt',
          width: 150,
          render: (text, record) =>
            record.createdAt
              ? moment(record.createdAt).format('DD MMM YYYY, h:mm:ss a')
              : '-',
          sorter: (a, b) => a.createdAt - b.createdAt,
          ...getColumnDateProps('createdAt'),
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
          // render: (text, record) => <NavLink to={`/ clients / ${ record.client.id } `}>{record.description}</NavLink>,
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => renderAction(record),
          width: '80px',
        },
      ],
    },
  ]

  let clientsColumns = [
    {
      // title: (
      //   <div
      //     className='hidden-client-data'
      //     style={{ borderBottom: '1px solid #e8e8e8' }}
      //   >
      //     {/* Total Commission Amount : {data?.getAllAmcs?.total.toFixed(2)} */}
      //     <span className='hidden-client-code'></span>{' '}
      //   </div>
      // ),
      children: [
        {
          title: 'AMC Amount',
          dataIndex: 'amount',
          key: 'amount',
          width: '120px',
          // render: (data) => +Math.floor(data / 1.18),
          sorter: (a, b) => a.amount - b.amount,
        },
        // {
        //   title: 'Quantity',
        //   dataIndex: 'amcQty',
        //   key: 'amcQty',
        //   width: '100px',
        //   sorter: (a, b) => a.amcQty - b.amcQty,
        // },
        // {
        //   title: 'Payment Type',
        //   dataIndex: 'paymentType',
        //   key: 'paymentType',
        //   width: '100px',
        //   sorter: (a, b) => a.paymentType - b.paymentType,
        // },
        {
          title: 'Start Date',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          width: '105px',
          ...getColumnDateProps('startDate'),
        },
        {
          title: 'Paid Date ',
          dataIndex: 'paidAt',
          key: 'paidAt',
          width: '105px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps('paidAt'),
        },
        {
          title: 'Next DueDate',
          dataIndex: 'endDate',
          key: 'endDate',
          width: '125px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps('nextDueDate'),
        },
        // {
        //   title: 'Created Date',
        //   dataIndex: 'createdAt',
        //   key: 'createdAt',
        //   width: '125px',
        //   render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
        //   sorter: (a, b) => a.age - b.age,
        // },
        // {
        //   title: 'Description',
        //   dataIndex: 'description',
        //   key: 'description',
        //   width: '120px',
        //   sorter: (a, b) => a.age - b.age,
        //   // render: (text, record) => <NavLink to={`/ clients / ${ record.client.id } `}>{record.description}</NavLink>,
        // },
        {
          title: 'Invoice',
          dataIndex: 'invoice',
          key: 'invoice',
          width: '120px',
          render: (text) =>
            text?.invoicePdf ? (
              <a href={`${text?.invoicePdf}`}>
                <LinkOutlined />
              </a>
            ) : (
              '-'
            ),
          // render: (text, record) => <NavLink to={`/ clients / ${ record.client.id } `}>{record.description}</NavLink>,
        },
      ],
    },
  ]

  if (isClient) {
    clientsColumns.push({
      title: 'Action',
      key: 'action',
      render: (text, record) => renderPaidButton(record),
      width: '80px',
    })
  }

  return (
    <>
      {showFormModal && (
        <CreateAMCModal
          data={updateData}
          isUpdate={isUpdate}
          visible={showFormModal}
          onUpdate={handleUpdate}
          loading={showFormLoading}
          onCancel={() => {
            setShowFormModal(false)
            setUpdateData(undefined)
            setIsUpdate(false)
          }}
        />
      )}
      {!loading && (
        <PageHeader
          className='box'
          title='AMCs'
          extra={[
            <div>
              {isAdmin && (
                <Button
                  key='1'
                  type='primary'
                  onClick={handlePdfDownload}
                  loading={PDFLoading}
                >
                  Export Pdf
                </Button>
              )}
              <Button
                key='2'
                type='primary'
                onClick={handleCsvDownload}
                loading={CSVLoading}
              >
                Export Csv
              </Button>
              {isAdmin && (
                <Button
                  key='3'
                  type='primary'
                  onClick={showModal}
                  loading={demoCsvLoading}
                >
                  Import Bulk AMCs
                </Button>
              )}
            </div>,
          ]}
        />
      )}
      <Table
        loading={loading}
        dataSource={tableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        columns={isAdmin ? columns : clientsColumns}
        pagination={{
          pageSize: limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
      {isModalVisible && (
        <Modal
          className='feedback_form'
          title='Import Bulk AMCs'
          visible
          onOk={importBulkAMCs}
          onCancel={importBulkAmcsModalClose}
          footer={[
            <Button key='1' type='secondary' style={{ marginRight: '24%' }}>
              <CSVLink
                data={JSON.parse(demoCsvData?.getAllAmcs?.amc).map((ele) => {
                  return {
                    ...ele,
                    clientCode: ele?.client?.clientCode || '',
                    startDate: ele?.startDate
                      ? moment(ele.startDate).format('DD-MM-YYYY')
                      : '',
                    endDate: ele?.endDate
                      ? moment(ele.endDate).format('DD-MM-YYYY')
                      : '',
                    nextDueDate: ele?.nextDueDate
                      ? moment(ele.nextDueDate).format('DD-MM-YYYY')
                      : '',
                    paidAt: ele?.paidAt
                      ? moment(ele?.paidAt).format('DD-MM-YYYY')
                      : '',
                  }
                })}
                headers={fileHeader}
                filename='ImportAmc'
              >
                {' '}
                Download Demo AMCs{' '}
              </CSVLink>
            </Button>,
            <Button key='back' onClick={importBulkAmcsModalClose}>
              Cancel
            </Button>,
            <Button
              key='submit'
              loading={AMCLoading}
              type='primary'
              onClick={importBulkAMCs}
            >
              Ok
            </Button>,
          ]}
        >
          <Form form={form} layout='vertical'>
            <Form.Item
              name='csv'
              label='Please select AMCs CSV file'
              valuePropName='csv'
              getValueFromEvent={normFile}
            >
              <Upload
                name='csv-file'
                listType='text'
                {...customCsv}
                accept='.csv'
                multiple={false}
                fileList={csv}
              >
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
                <Alert
                  type='info'
                  showIcon
                  description='Use Demo CSV to import AMCs.'
                />

                <Alert
                  type='info'
                  showIcon
                  description='If you are updating data, upload the file as it is. If you
                      are creating new data, add a new row in the same format.
                      Leave the ID field blank for new data. Remove any rows
                      from the file that do not need to be updated.'
                />
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {isOpenPayment && (
        <Modal
          visible={isOpenPayment}
          onCancel={transactionStatus !== 'IN_PROCESS' && closePaymentModel}
          footer={null}
          width={840}
          className={`${transactionStatus === 'IN_PROCESS' && 'paymentModal'}`}
          // bodyStyle={{ height: 300 }}
        >
          {invoiceLoading ? (
            <Loader />
          ) : (
            <UpiPayment
              amcId={Math.round(paymentData?.amcId)}
              amount={showQrPopUpDetail?.grossTotalAmount || 0}
              gstAmount={showQrPopUpDetail?.igst18InPer || 0}
              exposure={showQrPopUpDetail?.assetUnderAdvisory || 0}
              grossTotalAmount={showQrPopUpDetail?.totalNetAmount || 0}
              setTransactionStatus={setTransactionStatus}
              transactionStatus={transactionStatus}
              skip={skip}
              limit={limit}
              filters={filters}
              showQrCode={showQrCode}
              qrCodeUrl={qrCodeUrl}
              upiInputModel={upiInputModel}
              setUpiInputModel={setUpiInputModel}
              transactionId={upiTransactionId}
              isOnBoard={false}
            />
          )}
        </Modal>
      )}
    </>
  )
}
