import React, { useContext } from 'react'
import { Button } from 'antd'
import client from 'apollo'

import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import { AppContext } from 'AppContext'

const RiskProfileForm = ({ next }) => {
  const { dispatch, state } = useContext(AppContext)
  const { id } = state.currentUser

  const onAgree = () => {
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id,
          data: {
            completedSteps: 'LOGIN_CREATED',
            memberName: state?.currentUser?.memberName
              ? state?.currentUser?.memberName
              : 5,
            createdById: state?.currentUser?.createdBy?.id
              ? state?.currentUser?.createdBy?.id
              : '1',
          },
        },
      })
      .then((res) => {
        dispatch({
          type: 'CURRENT_USER',
          data: res.data.updateUser,
        })
        next()
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <h1>Disclaimer</h1>
      <div className='risk-profile-form'>
        <p>Dear Investor,</p>
        <p>
          Clients' financial risk tolerance - attitudes, values, motivations,
          preferences and experiences, is measured with a risk profile. The risk
          profile questionnaire helps in understanding the risk tolerance level
          as well as time horizon in investing. The questionnaire is designed to
          show which type of investment approach may suit you best. Each answer
          would be given a point. The total score would suggest the appropriate
          risk profiling and risk assessment and suitability of service for you.
        </p>
        <p>
          Investment involves risk. The price of securities may go down as well
          as up, and under certain circumstances an investor may sustain a total
          or substantial loss of investment. Past performance is not necessarily
          indicative of the future or likely performance. Investors should read
          the relevant information document/ statement of additional information
          and do their own further research before making any investment
          decisions. An Investor should make an appraisal of the risks involved
          in investing in these products and should consult their own
          independent and professional advisors, to ensure that any decision
          made is suitable with regards to their circumstances and financial
          position.
        </p>
        <p>
          Our advice: There are not any right or wrong answers, please follow
          your instincts and answer the questions. Please answer the following
          questions by selecting only one response to each question
        </p>
        <p>
          IMPORTANT: Before making any investment decisions, it is important to
          understand your attitude towards risk. This helps identify an
          appropriate mix of investments that you are comfortable with. The
          Finideas Investment Advisers Risk Profiling Tool will help you
          understand your ability to bear risk and identify the asset classes to
          match your investment needs
        </p>
        <p>NOTE :- All fields are mandatory.</p>
        <div>
          <Button type='primary' onClick={onAgree}>
            I Agree
          </Button>
        </div>
      </div>
    </>
  )
}

export default RiskProfileForm
