import React, { useState } from 'react'
import { Modal, Form, Button, TimePicker } from 'antd'

import moment from 'moment'
import { UPDATE_ADVISORY_TIME } from '../graphql/Mutation'
import { GET_ADVISORY_TIME } from '../graphql/Query'
import client from 'apollo'
import openNotification from 'utils/Notification'

function CreateAdvisoryTime({
  isModalOpen,
  handleCancel,
  modalLoading,
  data,
  setModalLoading,
  setOpenModal,
}) {
  const [form] = Form.useForm()

  const [startTime, setStartTime] = useState(
    data && data?.getAdvisoryTime
      ? moment(data?.getAdvisoryTime?.startTime).format('HH:mm')
      : ''
  )
  const [endTime, setEndTime] = useState(
    data ? moment(data?.getAdvisoryTime?.endTime).format('HH:mm') : ''
  )

  const onChange = (timeRange) => {
    const [startTime, endTime] = timeRange

    const convertedStartTime = moment(startTime)
      .utcOffset('+05:30')
      .format('HH:mm:ss')

    const convertedEndTime = moment(endTime)
      .utcOffset('+05:30')
      .format('HH:mm:ss')

    setStartTime(convertedStartTime)
    setEndTime(convertedEndTime)
  }

  const onFinish = (values) => {
    const dataObj = {
      startTime: startTime,
      endTime: endTime,
    }

    setModalLoading(true)
    client
      .mutate({
        mutation: UPDATE_ADVISORY_TIME,
        variables: {
          data: dataObj,
          where: { id: parseInt(data?.getAdvisoryTime?.id) },
        },
        refetchQueries: [
          {
            query: GET_ADVISORY_TIME,
            variables: { where: { id: parseInt(data?.getAdvisoryTime?.id) } },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setModalLoading(false)
        setOpenModal(false)
        openNotification('success', 'Advisory Time Updated Successfully')
      })
      .catch((err) => {
        console.log(err)
        setModalLoading(false)
        setOpenModal(false)
        openNotification('error', err)
      })
      .finally(() => {
        setModalLoading(false)
        setOpenModal(false)
      })
  }
  return (
    <>
      <Modal
        className='faqs-modals'
        visible={isModalOpen}
        onCancel={handleCancel}
        title='Update Advisory Time'
      >
        <Form
          form={form}
          layout='vertical'
          name='form_in_modal'
          initialValues={{
            startTime:
              data && data.getAdvisoryTime
                ? [
                    moment(data.getAdvisoryTime.startTime, 'HH:mm'),
                    moment(data.getAdvisoryTime.endTime, 'HH:mm'),
                  ]
                : undefined,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name='startTime'
            label='Start And End Time'
            rules={[
              {
                required: true,
                message: 'Please select start and end time!',
              },
            ]}
          >
            <TimePicker.RangePicker format={'HH:mm'} onChange={onChange} />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={modalLoading}>
              Submit Advisory Time
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateAdvisoryTime
