import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'NLV MisMatch Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'Invest Date',
        dataIndex: 'InvestDate',
        key: 'InvestDate',
        className: 'report-text'
      },
      {
        title: 'Report Date',
        dataIndex: 'Reportdate',
        key: 'Reportdate',
        className: 'report-text'
      },
      {
        title: 'Client Code',
        dataIndex: 'ClientCode',
        key: 'ClientCode',
        className: 'report-text'
      },
      {
        title: 'Client Name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        className: 'report-text'
      },
      {
        title: 'Code',
        dataIndex: 'Code',
        key: 'Code',
        className: 'report-numeric'
      },
      {
        title: 'Fund Rec',
        dataIndex: 'FundRec',
        key: 'FundRec',
        className: 'report-numeric'
      },
      {
        title: 'PnLWO Int',
        dataIndex: 'PnLWOInt',
        key: 'PnLWOInt',
        className: 'report-numeric'
      },
      {
        title: 'Exp NLV',
        dataIndex: 'ExpNLV',
        key: 'ExpNLV',
        className: 'report-numeric'
      },
      {
        title: 'NLV',
        dataIndex: 'NLV',
        key: 'NLV',
        className: 'report-numeric'
      },
      {
        title: 'Diff',
        dataIndex: 'Diff',
        key: 'Diff',
        className: 'report-numeric'
      },
      {
        title: 'Tot Exposure',
        dataIndex: 'TotExposure',
        key: 'TotExposure',
        className: 'report-numeric'
      },
      {
        title: 'NLV%',
        dataIndex: 'NLV%',
        key: 'NLV%',
        className: 'report-percentage'
      },
      {
        title: 'Risk%',
        dataIndex: 'Risk%',
        key: 'Risk%',
        className: 'report-percentage'
      }
    ]
  }
]

export default function ({ reportData }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      columns={columns}
      dataSource={data}
      className='nlv-mismatch-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}
