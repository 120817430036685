import React, { useEffect, useState } from 'react'
import IdleJs from 'idle-js'
import { Modal, Button } from 'antd'

const AutoRefresh = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const refreshPage = () => {
    window.location.reload()
  }

  const onIdle = () => {
    setIsModalOpen(true)
  }

  const closeModal = (refresh) => {
    setIsModalOpen(false)
    if (refresh) {
      refreshPage()
    }
  }

  const checkTimeAndRefresh = () => {
    const now = new Date()
    if (now.getHours() === 9 && now.getMinutes() === 31) {
      refreshPage()
    }
  }

  useEffect(() => {
    const idle = new IdleJs({
      idle: 15 * 60 * 1000, // 5 minutes
      onIdle: onIdle,
    })

    idle.start()

    const intervalId = setInterval(checkTimeAndRefresh, 60 * 1000)

    return () => {
      idle.stop()
      clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Modal visible={isModalOpen} footer={null} className='auto_refresh_modal'>
        <h2>You've been idle for a while!</h2>
        <p>Do you want to refresh the page?</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type='primary'
            onClick={() => closeModal(true)}
            style={{ width: '20%' }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default AutoRefresh
