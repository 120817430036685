import React, { Fragment, useContext, useState } from 'react'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'
import {
  PageHeader,
  Table,
  Button,
  Input,
  Space,
  Tooltip,
  Modal,
  Tag,
} from 'antd'
import {
  SearchOutlined,
  EditTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'

import { CREATE_GROUP, UPDATE_GROUP, DELETE_GROUP } from './graphql/Mutations'
import openNotification from 'utils/Notification'
import CreateGroupModal from './CreateGroupModal'
import CustomMeta from 'components/CustomMeta'
import { GET_GROUPS } from './graphql/Queries'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import client from 'apollo'

const { confirm } = Modal

const FamilyGroup = () => {
  let tableData = []
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [sortingOrder, setSortingOrder] = useState('DESC')
  const [showFormModal, setShowFormModal] = useState(false)
  const {
    state: { currentReport },
  } = useContext(AppContext)
  const [selectedGroup, setSelectedGroup] = useState(undefined)
  const variables = {
    where: filters,
    limit,
    skip,
    sort: { sortingOrder, field: 'createdAt' },
  }
  const { data, loading, error } = useQuery(GET_GROUPS, {
    variables,
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  let totalCount = 0

  if (!loading && get(data, 'getFamilyGroups.groups')) {
    totalCount = data.getFamilyGroups.count
    tableData = data.getFamilyGroups.groups.map((group, key) => ({
      key: key.toString(),
      ...group,
    }))
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this group?`,
      content: `When clicked the OK button, this group will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_GROUP,
            variables: { id },
            refetchQueries: [{ query: GET_GROUPS, variables }],
          })
          .catch((err) => console.log(err))
      },
    })
  }

  function handleHideFormModal() {
    setShowFormModal(false)
    setSelectedGroup(undefined)
  }
  function renderAction(record) {
    return (
      <div className='action-icons-family-grp'>
        {currentReport.includes('Update Group') && (
          <Tooltip title='Edit'>
            <EditTwoTone
              onClick={() => {
                setShowFormModal(true)
                setSelectedGroup(record)
              }}
            />
          </Tooltip>
        )}
        <Tooltip title='Delete Group'>
          <DeleteOutlined onClick={() => showConfirm(record.id)} />
        </Tooltip>
      </div>
    )
  }

  function handleCreateGroup(values, resetForm) {
    const data = values
    client
      .mutate({
        mutation: CREATE_GROUP,
        variables: { data },
        refetchQueries: [{ query: GET_GROUPS, variables }],
      })
      .then((res) => {
        openNotification('success', 'Group Added Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleUpdateGroup(values, resetForm) {
    const data = values
    const { id } = selectedGroup
    client
      .mutate({
        mutation: UPDATE_GROUP,
        variables: { data, id },
        refetchQueries: [{ query: GET_GROUPS, variables }],
      })
      .then((res) => {
        openNotification('success', 'Group Updated Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (tempFilters[filedName]) {
      delete tempFilters[filedName]
    }
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleTableChange(pagination, filter, sorter) {
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (filter.status) {
      tempFilters['status'] = filter.status
      setFilters(tempFilters)
    } else if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSortingOrder('DESC')
      } else {
        setSortingOrder('ASC')
      }
    } else {
      tempFilters['status'] = ''
      delete tempFilters.status
      setFilters(tempFilters)
    }
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => text,
      ...getColumnSearchProps('name'),
      width: '10%',
    },
    {
      title: 'Clients',
      dataIndex: 'userIds',
      key: 'userIds',
      render: (text, record) =>
        record?.users?.map((e) => {
          return (
            <>
              <Tag style={{ marginBottom: '10px' }} color='geekblue'>
                {`${e?.firstName || '-'} ${e?.lastName || '-'}`}
              </Tag>
              &nbsp;
            </>
          )
        }),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: '10%',
      fixed: 'right',
    },
  ]

  return (
    <Fragment>
      <CustomMeta title='Family Groups' />
      <PageHeader
        className='box'
        title='Family Groups'
        extra={[
          <Button key='1' type='primary' onClick={() => setShowFormModal(true)}>
            Add Family group
          </Button>,
        ]}
      />
      {showFormModal && (
        <CreateGroupModal
          visible={showFormModal}
          onCreate={handleCreateGroup}
          onUpdate={handleUpdateGroup}
          selectedGroup={selectedGroup}
          isEdit={selectedGroup !== undefined}
          onCancel={() => handleHideFormModal()}
        />
      )}
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        pagination={{
          pageSize: limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </Fragment>
  )
}

export default FamilyGroup
