import React, { useContext } from 'react'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import { useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'

import { GET_PROFIT_LOSS_CHART } from '../../graphql/Queries'
// import ChartLoader from 'components/loaders/ChartLoader'
import ChartError from 'components/Page404/ChartError'
import Loader from 'components/loaders/Loader'
import { AppContext } from 'AppContext'
import Widget from 'components/Widget'

const COLORS = ['#000', '#AF0000', '#0088FE', '#00C49F', '#FFBB28', '#FF8042']
const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
    {`${(percent * 100).toFixed(0)}%`}
  </text>
}


export default function () {
  const { state: { selectedGroup } } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_PROFIT_LOSS_CHART, { variables: { groupId: selectedGroup }, fetchPolicy: 'network-only' })

  if (error) return <ChartError title='Profit Loss Report' />

  const isEmpty = !_get(data, 'profitLossPieChart') || (!_get(data, 'profitLossPieChart[0].Profit') && !_get(data, 'profitLossPieChart[0].Loss'))

  let chartData
  if (!isEmpty) {
    chartData = [
      { name: 'Profit', value: _get(data, 'profitLossPieChart')[0].Profit },
      { name: 'Loss', value: _get(data, 'profitLossPieChart')[0].Loss },
    ]
  }



  return (
    <Widget
      title={<h2 className='h4 gx-text-capitalize gx-mb-0'>Profit Loss Report</h2>}
      styleName='gx-text-center'
    >
      {
        loading ?
          <div style={{ minHeight: 160, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
            <Loader />
          </div>
          :
          isEmpty ?
            <h2 className='h4 gx-text-capitalize gx-mb-0' style={{ minHeight: 160, display: "flex", alignItems: 'center', justifyContent: 'center' }}>No Data</h2>
            :
            <ResponsiveContainer width='100%' height={200} className="pie-chart-wrapper">
              <PieChart>
                <Pie
                  data={chartData}
                  label={renderCustomizedLabel}
                  labelLine={false}
                  outerRadius={80}
                  fill='#8884d8'
                  dataKey='value'
                >
                  {chartData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
      }
    </Widget>
  )
}
