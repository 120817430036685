import React, { useEffect, useState } from 'react'
import { PageHeader, Tooltip, Table } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { GET_ALL_PENDING_ADVISORY_FOR_ASSOCIATE } from './graphql/Queries'
import Page404 from 'components/Page404'
import PendingAdvisory from 'modules/PendingAdvisory'
import client from 'apollo'
import { GET_USER } from 'modules/Users/graphql/Queries'

const PendingAdvisoryForAssociate = () => {
  const [openAdvisory, setOpenAdvisory] = useState(false)
  const [clientData, setClientData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [data, setData] = useState([])

  useEffect(() => {
    getAllPendingAdvisory()
  }, [])
  const getAllPendingAdvisory = () => {
    setLoading(true)
    setOpenAdvisory(false)
    client
      .query({
        query: GET_ALL_PENDING_ADVISORY_FOR_ASSOCIATE,
        variables: { isMenu: false },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        let advisoryData =
          res?.data?.getPendingAdvisoryForAssociate?.advisoryData?.map(
            (item, index) => {
              return { ...item, index: index + 1 }
            }
          )
        setData(advisoryData)
        setOpenAdvisory(false)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setOpenAdvisory(false)
        setError(err)
      })
      .finally(() => {
        setLoading(false)
        setOpenAdvisory(false)
      })
  }

  const getUserByClientCode = (id) => {
    client
      .query({
        query: GET_USER,
        variables: { id: id.toString() },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setClientData(res?.data?.getUser)
        setOpenAdvisory(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const renderAction = (record) => {
    return (
      <div
        className='action-icons'
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Tooltip title='View Advisory'>
          <EyeOutlined
            onClick={() => {
              getUserByClientCode(record?.userId)
            }}
          />
        </Tooltip>
      </div>
    )
  }

  const columns = [
    { title: 'Sr. No', dataIndex: 'index', key: 'index', width: 100 },
    {
      title: 'Client Code',
      dataIndex: 'ClientCode',
      key: 'ClientCode',
      width: 150,
    },
    { title: 'Client Name', dataIndex: 'Name', key: 'Name' },
    { title: 'Role Name', dataIndex: 'AssociteName', key: 'AssociteName' },
    { title: 'Role Type', dataIndex: 'role', key: 'role', width: 200 },
    {
      title: 'View Advisory',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (text, record) => renderAction(record),
    },
  ]

  if (error) return <Page404 />
  return (
    <>
      {!openAdvisory ? (
        <>
          <PageHeader className='box' title='Pending Advisory' />

          <Table
            bordered
            loading={loading}
            dataSource={data ? data : []}
            columns={columns}
          />
        </>
      ) : (
        <PendingAdvisory
          clientData={clientData}
          setOpenAdvisory={setOpenAdvisory}
          getAllPendingAdvisory={getAllPendingAdvisory}
        />
      )}
    </>
  )
}

export default PendingAdvisoryForAssociate
