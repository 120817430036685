import React, { useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { Button, Form, Input, Menu, Dropdown } from 'antd'

import { LOGIN_WITH_OTP, SEND_LOGIN_OTP } from './graphql/Mutations'
import { CHECK_PHONE_BELONGS_TO } from './graphql/Queries'
import Logo from 'assets/images/logo-tagline.png'
import CustomMeta from 'components/CustomMeta'
import { Link } from 'react-router-dom'
import history from 'CustomHistory'
import { authWithOtp } from '.'
import client from 'apollo'
import './index.css'
import openNotification from 'utils/Notification'

const FormItem = Form.Item
export default function () {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [phoneValue, setPhoneValue] = useState()
  const [resendOtp, setResendOtp] = useState()
  const [selectUser, setSelectUser] = useState(false)

  function handleLogin(values) {
    const phone = values.phone
    setPhoneValue(phone)
    setLoading(true)
    client.query({ query: CHECK_PHONE_BELONGS_TO, variables: { phone }, fetchPolicy: 'network-only' })
      .then((res) => {
        if (!res.data.checkPhoneBelongsTo.length) {
          openNotification('error', "Mobile Number is not Registered")
          setLoading(false)
          return false
        }
        setData(res)
        setLoading(false)
      })
      .catch((err) => {
        if (err.message.split(':')[1] !== "NO_USER_FOUND") {
          setLoading(false)
        }
      })
  }

  function handleLoginOtp(values) {
    client.mutate({ mutation: LOGIN_WITH_OTP, variables: { otp: values.text } })
      .then(res => {
        authWithOtp(res)
      })
      .catch((err) => {
        if (err.message.split(':')[1] !== " Invalid OTP") {
          history.push('/login')
        }
      })
  }

  const handleMenuClick = (e) => {
    const id = e.key
    setResendOtp(id)
    client.mutate({ mutation: SEND_LOGIN_OTP, variables: { id } })
      .then(res => {
        setSelectUser(true)
      })
  }

  const handleResendOtp = (e) => {
    client.mutate({ mutation: SEND_LOGIN_OTP, variables: { id: resendOtp } })
      .then(res => {
        setSelectUser(true)
        openNotification('success', "Send Otp Successfully")
      })
  }

  const onBackLogin = () => {
    history.push('/login')
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {data?.data?.checkPhoneBelongsTo.map(client => {
        return (
          <Menu.Item key={client.id}>
            {client.clientCode ? client.clientCode : 'N/A'} - {client.firstName} {client.lastName}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  return (
    <div className='gx-app-login-wrap'>
      <CustomMeta title='Login' />
      <div className='gx-app-login-container'>
        <div className='gx-app-login-main-content'>
          <div className='gx-app-logo-content'>
            <div className='gx-app-logo-content-bg'>
            </div>
            <div className='gx-app-logo-wid'>
              <h1>Sign In</h1>
              <p>By Sign In, you can avail full features of our services.</p>
            </div>
            <div className='gx-app-logo'>
              <img alt='Finideas' src={Logo} />
            </div>
          </div>
          <div className='gx-app-login-content'>
            {selectUser ?
              <Form form={form} onFinish={handleLoginOtp} className='gx-signin-form gx-form-row0' layout="vertical">
                <p className='otp-form-text'> Enter the 6 digit OTP sent via SMS on Your Phone (+91-{phoneValue.replace(/.(?=.{4})/g, 'x')})</p>
                <FormItem name='text' label="OTP"
                  rules={[{ required: true, message: 'Please input your OTP number!', pattern: new RegExp(/^\d{6}(,\d{6})*$/g) }]}
                >
                  <Input placeholder='OTP' autoFocus={true} />
                </FormItem>
                <p style={{ color: 'red' }}>Did not receive?
                  &nbsp;&nbsp; <span><Link onClick={handleResendOtp}>Resend</Link></span>
                </p>
                <div className='signIn-btn'>
                  <FormItem>
                    <Button type='primary' className='gx-mb-0' htmlType='submit'>Sign In</Button>
                  </FormItem>&nbsp;&nbsp;&nbsp;
                  <FormItem>
                    <Button type='primary' className='gx-mb-0' onClick={onBackLogin} >Back</Button>
                  </FormItem>
                </div>
              </Form>
              :
              <Form form={form} onFinish={handleLogin} className='gx-signin-form gx-form-row0' layout="vertical">
                <FormItem name='phone' label="Mobile Number"
                  rules={[{ required: true, message: 'Please Enter your mobile number!', pattern: new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/g) }]}
                >
                  <Input placeholder='Please Enter your mobile number' autoFocus={true} disabled={data?.data} />
                </FormItem>
                {data?.data &&
                  <div className='user-dropdown'>
                    <Dropdown overlay={menu} trigger={['click']} style={{ width: '100%' }}>
                      <Button style={{ width: '100%' }}> Select User <DownOutlined /></Button>
                    </Dropdown>
                  </div>
                }
                <div className='signIn-btn'>
                  <FormItem>
                    <Button type='primary' className='gx-mb-0' htmlType='submit' loading={loading}>Sign In</Button>
                  </FormItem>&nbsp;&nbsp;&nbsp;
                  <FormItem>
                    <Button type='primary' className='gx-mb-0' onClick={onBackLogin} >Back</Button>
                  </FormItem>
                </div>
              </Form>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
