import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink, from, split } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getMainDefinition } from 'apollo-utilities'
import { createHttpLink } from 'apollo-link-http'
import openNotification from 'utils/Notification'
import { WebSocketLink } from 'apollo-link-ws'
import { ErrorLink } from 'apollo-link-error'
import { ApolloClient } from 'apollo-client'
import { version } from '../package.json'
import { isAuthenticated } from 'auth'
import history from 'CustomHistory'

export const cacheData = new InMemoryCache()

function stripTypeNames(obj, propToDelete) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property
      const newData = stripTypeNames(obj[property], propToDelete)
      obj[property] = newData
    } else {
      if (property === propToDelete) {
        delete obj[property]
      }
    }
  }
  return obj
}

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripTypeNames(operation.variables, '__typename')
  }
  return forward ? forward(operation) : null
})

const errorLink = new ErrorLink(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (Array.isArray(path) && path[0] === 'currentUser') {
        history.push('/logout')
      }
      const newMessage =
        message === 'jwt expired' ? 'Please login again!' : message
      openNotification('error', newMessage)

      if (message === 'Not Authorised!') {
        window.location.replace('/login')
      }

      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (response) {
    response.errors.map(({ message, locations, path }) => {
      // openNotification('error', message)
      return console.log(
        `[Response error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (networkError) {
    // openNotification('error', networkError)
    console.log(`[Network error]: ${networkError}`)
  }
})

const httpLink = createHttpLink({
  credentials: 'include',
  uri: process.env.REACT_APP_SERVER_GRAPH_URL,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const authorizationToken = isAuthenticated()
  operation.setContext({
    headers: {
      authorization: authorizationToken ? `Bearer ${authorizationToken}` : null,
      'Strict-Transport-Security':
        'max-age=63072000; includeSubDomains; preload',
      'Content-Security-Policy': "frame-ancestors 'none'",
      'X-Frame-Options': 'DENY',
      'Expect-CT': `max-age=86400, enforce, report-uri="${process.env.REACT_APP_FE_DOMAIN}"`,
      'X-Content-Type-Options': 'nosniff',
      'Referrer-Policy': 'origin-when-cross-origin',
      'Cache-Control': 'no-store',
      'Clear-Site-Data': '"*"',
      'Feature-Policy': "microphone 'none'; camera 'none'",
    },
  })

  return forward(operation)
})

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SERVER_SOCKET_URL,
  options: {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    async connectionParams() {
      const authorizationToken = isAuthenticated()
      return {
        authorization: authorizationToken
          ? `Bearer ${authorizationToken}`
          : null,
      }
    },
  },
})

window.addEventListener('beforeunload', () => {
  // @ts-ignore - the function is private in typescript
  wsLink.subscriptionClient.close()
})

const uploadLink = createUploadLink({
  credentials: 'include',
  uri: process.env.REACT_APP_SERVER_GRAPH_URL,
})

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  cache: cacheData,
  link: from([
    removeTypenameMiddleware,
    errorLink,
    authMiddleware,
    uploadLink,
    link,
  ]),
  name: 'ADMIN',
  version,
})

export default client
