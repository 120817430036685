import { Button, PageHeader, Select } from 'antd'
import client from 'apollo'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import openNotification from 'utils/Notification'
import { UPDATE_TEMPLATE_DATA } from '../graphql/Mutations'
import { GET_TEMPLATE_DATA } from '../graphql/Queries'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'

export default function TicketMailForMate() {
  const [active, setActive] = useState('CREATE_TICKET_MAIL')
  const [loading, setLoading] = useState(false)
  const [templateLoading, setTemplateLoading] = useState(true)
  const [templateError, setTemplateError] = useState(false)

  const [templateHtml, setTemplateHtml] = useState()

  useEffect(() => {
    client
      .query({
        query: GET_TEMPLATE_DATA,
        variables: { type: active },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setTemplateHtml(res?.data?.getTemplateData?.htmlData)
        setTemplateLoading(false)
      })
      .catch((err) => {
        console.log({ err })
        setTemplateLoading(false)
        setTemplateError(true)
      })
  }, [active])

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const options = [
    {
      value: 'CREATE_TICKET_MAIL',
      label: 'CREATE TICKET MAIL',
    },
    {
      value: 'CLOSE_TICKET_MAIL',
      label: 'CLOSE TICKET MAIL',
    },
  ]

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  const handleChange = (value) => {
    setActive(value)
  }

  const handleValue = (content, delta, source, editor) => {
    setTemplateHtml(editor.getHTML())
  }

  const updateCode = () => {
    setLoading(true)
    client
      .mutate({
        mutation: UPDATE_TEMPLATE_DATA,
        variables: { type: active, htmlData: templateHtml },
        refetchQueries: [
          { query: GET_TEMPLATE_DATA, variables: { type: active } },
        ],
      })
      .then((res) => {
        setLoading(false)
        openNotification('success', 'Mail Format Change Successfully')
      })
      .catch((err) => console.log({ err }))
      .finally(() => setLoading(false))
  }
  return (
    <>
      {templateLoading && !templateError ? (
        <Loader />
      ) : templateError ? (
        <Page404 />
      ) : (
        <>
          <PageHeader className='box' title='Ticket Mail Format' />

          <Select
            style={{
              width: 175,
              marginBottom: '20px',
            }}
            value={active}
            placeholder='Tags Mode'
            onChange={handleChange}
            options={options}
          />

          <ReactQuill
            theme='snow'
            value={templateHtml}
            onChange={handleValue}
            modules={modules}
            formats={formats}
          />

          <div>
            <Button
              onClick={updateCode}
              type='primary'
              style={{ marginTop: '10px' }}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  )
}
