import React, { useContext, useState } from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Radio,
} from 'antd'
import { get, isEmpty, orderBy } from 'lodash'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import client from 'apollo'

import './verification.css'
import {
  CHECK_EXIST_CLIENT_CODE,
  GET_USERS_FOR_CLIENT,
} from 'modules/Users/graphql/Queries'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import { COMPLETE_STEP } from './graphql/Mutations'
import ClientProfileModalNon1 from './Model/ClientProfile1Non'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import cities from 'utils/cities.json'
import moment from 'moment'
import { handleInputChange, handlePanCard } from 'utils/utilities'

const FormItem = Form.Item
const { Option } = Select

export default function ({ next, reEvaluate }) {
  const [form] = Form.useForm()
  const [showFormModal, setShowFormModal] = useState(false)
  const [formValue, setFormValue] = useState({})
  const [reEvaluateValue, setReEvaluateValue] = useState(reEvaluate)
  const [loadings, setLoadings] = useState(false)
  const [clientCodeExist, setClientCodeExist] = useState('')
  const { dispatch, state } = useContext(AppContext)
  const {
    userName,
    email,
    phone,
    clientCode,
    loginType,
    nameOfApplicant,
    panCardNo,
    dateOfIncorporation,
    placeOfIncorporation,
    dateOfCommencement,
    entityStatus,
    address,
    annualIncome,
    cityId,
    netWorth,
    plan,
  } = state.currentUser

  const { data, loading, error } = useQuery(GET_USERS_FOR_CLIENT, {
    fetchPolicy: 'network-only',
  })

  const { data: existClientCode, loading: clientCodeLoading } = useQuery(
    CHECK_EXIST_CLIENT_CODE,
    {
      variables: { where: { clientCode: clientCodeExist } },
      fetchPolicy: 'network-only',
    }
  )

  if (error) return <Page404 error={error} />

  let getUsers = []
  if (!loading && get(data, 'getUsersForClient.users')) {
    getUsers = data.getUsersForClient.users
  }

  const onFinish = (values) => {
    if (reEvaluateValue === true) {
      onNext()
    } else {
      setFormValue({
        ...values,
        dateOfIncorporation:
          values && values?.dateOfIncorporation.format('YYYY-MM-DD'),
        dateOfCommencement:
          values?.dateOfCommencement &&
          values?.dateOfCommencement.format('YYYY-MM-DD'),
        stateId: cities.filter((item) => values.cityId === item?.id)[0]
          ?.state_id,
        countryId: 101,
      })
      setShowFormModal(true)
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 18,
      },
    },
  }

  const prefixSelector = (
    <Form.Item name='prefixMobile' noStyle>
      <Select disabled>
        <Option value='91'>+91</Option>
      </Select>
    </Form.Item>
  )

  const handleHideFormModal = () => {
    setShowFormModal(false)
  }

  const editAccessFunc = () => {
    setReEvaluateValue(false)
  }

  const onNext = () => {
    setLoadings(true)
    const { id } = state.currentUser

    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id,
          data: {
            completedSteps: 'CLIENT_PROFILE_1',
          },
        },
      })
      .then((res) => {
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'CLIENT_PROFILE_1',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        openNotification('success', 'Client Basic Details Updated Successfully')
        setLoadings(false)
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadings(false))
  }

  return (
    <>
      <Row>
        <Col span={22} style={{ textAlign: 'center' }}>
          <h1>Client Basic Details</h1>
        </Col>
        <Col span={2} style={{ textAlign: 'end' }}>
          {reEvaluateValue && (
            <Button type='primary' onClick={editAccessFunc}>
              Edit
            </Button>
          )}
        </Col>
      </Row>
      <div className='gx-app-login-wrap title-verification'>
        <CustomMeta title='Client Profile 1' />
        <div className='gx-app-login-content verification'>
          <Form
            {...formItemLayout}
            form={form}
            name='register'
            onFinish={onFinish}
            initialValues={{
              prefixMobile: '91',
              nameOfApplicant,
              panCardNo,
              dateOfIncorporation: dateOfIncorporation
                ? moment(dateOfIncorporation)
                : '',
              placeOfIncorporation,
              dateOfCommencement: dateOfCommencement
                ? moment(dateOfCommencement)
                : '',
              entityStatus:
                entityStatus === 'partnershipFirm'
                  ? 'Partnership Firm'
                  : entityStatus,
              address,
              phone,
              userName,
              clientCode,
              email,
              cityId: cityId || undefined,
              check: true,
              netWorth,
              annualIncome,
              planId: parseInt(plan?.id) || undefined,
            }}
            labelAlign='left'
            scrollToFirstError
          >
            <div>
              <FormItem
                name='nameOfApplicant'
                label='Name of Applicant'
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/\b([A-Za-z]+[ ]*)+/),
                    message: 'Name of Applicant is Required',
                  },
                ]}
              >
                <Input
                  placeholder='Name of Applicant'
                  autoFocus={true}
                  disabled={!reEvaluateValue ? false : true}
                  onChange={(e) =>
                    handleInputChange(form, 'nameOfApplicant', e)
                  }
                />
              </FormItem>
              <FormItem
                name='panCardNo'
                label='Pan of the Applicant'
                rules={[
                  {
                    required: true,
                    message: 'Please input your valid Pan number!',
                    pattern: new RegExp(
                      /[A-Za-z]{3}[ABCFGHLJPTFabcfghjpltf]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}/g
                    ),
                  },
                ]}
              >
                <Input
                  placeholder='Pan of the Applicant'
                  maxLength='10'
                  // disabled={!reEvaluateValue ? false : true}
                  onChange={(e) => handlePanCard(form, 'panCardNo', e)}
                />
              </FormItem>
              <FormItem
                name='dateOfIncorporation'
                label='Date of Incorporation'
                rules={[
                  {
                    required: true,
                    message: 'Date of Incorporation is Required',
                  },
                ]}
              >
                <DatePicker
                  placeholder='Date of Incorporation'
                  format={'DD-MMM-YYYY'}
                  autoComplete='off'
                  style={{ width: '100%' }}
                  disabled={!reEvaluateValue ? false : true}
                />
              </FormItem>
              <FormItem
                name='placeOfIncorporation'
                label='Place of Incorporation'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Place of Incorporation is Required!',
                //   },
                // ]}
              >
                <Input
                  placeholder='Place of Incorporation'
                  disabled={!reEvaluateValue ? false : true}
                  onChange={(e) =>
                    handleInputChange(form, 'placeOfIncorporation', e)
                  }
                />
              </FormItem>
              <FormItem
                name='dateOfCommencement'
                label='Date of Commencement'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Date of Commencement is Required!',
                //   },
                // ]}
              >
                <DatePicker
                  placeholder='Date of Commencement'
                  format={'DD-MMM-YYYY'}
                  autoComplete='off'
                  style={{ width: '100%' }}
                  disabled={!reEvaluateValue ? false : true}
                />
              </FormItem>
              <FormItem
                name='cityId'
                label='City'
                rules={[
                  {
                    required: true,
                    message: 'City is Required !',
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  placeholder='Select City'
                  className='display-field'
                  disabled={!reEvaluateValue ? false : true}
                >
                  {orderBy(cities, 'name').map((city) => (
                    <Option key={city.id} value={city.id}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                name='entityStatus'
                label='Status of the entity'
                rules={[
                  {
                    required: true,
                    message: 'Status of the entity is Required!',
                  },
                ]}
              >
                <Input
                  placeholder='Status of the entity'
                  style={{ textTransform: 'capitalize' }}
                  disabled={true}
                />
              </FormItem>
              <FormItem
                name='address'
                label='Registered Address'
                rules={[
                  {
                    pattern: new RegExp(/\b([A-Za-z]+[ ]*)+/),
                    required: true,
                    message: 'Registered Address is Required!',
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder='Address'
                  style={{ width: '100%', textTransform: 'capitalize' }}
                  disabled={!reEvaluateValue ? false : true}
                  onChange={(e) => handleInputChange(form, 'address', e)}
                />
              </FormItem>
              <FormItem
                name='phone'
                label='Mobile Number'
                rules={[
                  {
                    required: true,
                    message: 'Mobile Number is Required!',
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{
                    width: '100%',
                  }}
                  placeholder='Mobile number'
                  maxLength='10'
                  disabled={phone ? true : false}
                />
              </FormItem>
              <FormItem
                name='email'
                label='E-mail'
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}
              >
                <Input placeholder='Email' disabled={!email ? false : true} />
              </FormItem>
              <FormItem
                label='Annual Income'
                name='annualIncome'
                rules={[
                  {
                    required: true,
                    message: 'Annual Income is required!',
                  },
                ]}
              >
                <Radio.Group
                  disabled={!reEvaluateValue ? false : true}
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  <Radio value='500000' className='annual-radio-select'>
                    Less then Rs.5 Lakhs
                  </Radio>
                  <Radio value='2500000' className='annual-radio-select'>
                    Between 5 Lakh and 25 Lakh{' '}
                  </Radio>
                  <Radio value='10000000' className='annual-radio-select'>
                    Between 25 Lakh and 1 Crore
                  </Radio>
                  <Radio value='50000000' className='annual-radio-select'>
                    Between 1 Crore and 5 Crore
                  </Radio>
                  <Radio value='500000000' className='annual-radio-select'>
                    Above 5 Crore
                  </Radio>
                </Radio.Group>
              </FormItem>

              <FormItem
                name='netWorth'
                label='Net Worth'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Net Worth is Required!',
                //   },
                // ]}
              >
                <Input
                  placeholder='Net Worth'
                  disabled={!reEvaluateValue ? false : true}
                />
              </FormItem>
              <FormItem
                name='userName'
                label='User Name'
                rules={[
                  {
                    required: true,
                    message: 'User Name is required !',
                  },
                ]}
              >
                <Input placeholder='User Name' disabled />
              </FormItem>

              <span style={{ color: '#ff4d4f', fontSize: '14px' }}>
                {!clientCodeLoading &&
                !existClientCode?.checkExistClientCode &&
                !isEmpty(clientCodeExist)
                  ? 'client code already exist'
                  : ''}
              </span>
              <FormItem
                name='clientCode'
                initialValues={clientCode}
                label={
                  <span>
                    Client Code&nbsp;{' '}
                    <Tooltip
                      title='Input the trading
                account code where you intend to take the trades for Index Long Term
                Strategy. Not sure?? Then leave the field blank'
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message:
                      !clientCodeLoading &&
                      !existClientCode?.checkExistClientCode &&
                      !isEmpty(clientCodeExist)
                        ? 'client code already exist'
                        : '',
                  },
                ]}
              >
                <Input
                  placeholder='Client Code'
                  disabled={!reEvaluateValue ? false : true}
                  onChange={(e) => setClientCodeExist(e.target.value)}
                />
              </FormItem>

              {reEvaluate === true
                ? ' '
                : loginType !== 'GOOGLE' && (
                    <>
                      <FormItem
                        name='password'
                        label='Password'
                        rules={[
                          {
                            required: true,
                            message:
                              'password must be between 8 and 16 characters!',
                            min: 8,
                            max: 16,
                          },
                        ]}
                        hasFeedback
                      >
                        <Input
                          type='password'
                          placeholder='Password'
                          disabled={!reEvaluateValue ? false : true}
                        />
                      </FormItem>

                      <FormItem
                        name='passwordConfirm'
                        label='Confirm Password'
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve()
                              }
                              return Promise.reject(
                                new Error(
                                  'The two passwords that you entered do not match!'
                                )
                              )
                            },
                          }),
                        ]}
                      >
                        <Input
                          type='password'
                          placeholder='Password'
                          disabled={!reEvaluateValue ? false : true}
                        />
                      </FormItem>
                    </>
                  )}
            </div>
            <div>
              <Button
                disabled={
                  !existClientCode?.checkExistClientCode &&
                  !isEmpty(clientCodeExist)
                    ? true
                    : false
                }
                type='primary'
                htmlType='submit'
                loading={loadings}
              >
                Verify And Next
              </Button>
              {showFormModal && (
                <ClientProfileModalNon1
                  next={next}
                  formValue={formValue}
                  getUsers={getUsers}
                  visible={showFormModal}
                  prefixSelector={prefixSelector}
                  onCancel={() => handleHideFormModal()}
                  reEvaluate={reEvaluate}
                />
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}
