import { Button, Form, Row, Col, Input, Select } from 'antd'
import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get, isEmpty } from 'lodash'
import _map from 'lodash/map'
import client from 'apollo'

import '../index.css'
import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import DownloadReport from '../DownloadReport'
import { SP_CURRENT_POSITIONFO } from '../graphql/Queries'
import PositionFOTable from './PositionFOTable'
import { GET_STRATEGY_LIST } from 'modules/Settings/graphql/Queries'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'

const { Option } = Select

export default function () {
  const [form] = Form.useForm()
  const [strategyList, setStrategyList] = useState([])
  const {
    state: {
      isAdmin,
      isStaff,
      currentUser: { clientCode, role },
    },
    dispatch,
  } = useContext(AppContext)
  const [filters, setFilters] = useState({
    clientCode: isAdmin || isStaff ? '' : clientCode,
    strategy: '',
    script: '',
  })
  useEffect(() => {
    dispatch({ type: 'SELECTED_GROUP', data: undefined })
    dispatch({ type: 'SELECTED_STRATEGY', data: 'ILTS' })
  }, [dispatch])

  useEffect(() => {
    client
      .query({ query: GET_STRATEGY_LIST, fetchPolicy: 'network-only' })
      .then((res) => {
        setStrategyList(res.data.getStrategyList)
      })
  }, [])

  const { data, loading, error } = useQuery(SP_CURRENT_POSITIONFO, {
    variables: { where: filters },
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spCurrentPositionFO')) return <NoData />

  let currentPositionFOBalanceData
  const { spCurrentPositionFO } = data
  currentPositionFOBalanceData = spCurrentPositionFO.map((EqBalance) => {
    // delete EqBalance.company
    return {
      ...EqBalance,
      Strike: Math.round(EqBalance.Strike),
      qty: Math.round(EqBalance.qty),
      Value: Math.round(EqBalance.NetPrice),
      NetPrice: Math.round(EqBalance.NetPrice),
    }
  })

  const child = [
    {
      title: 'ClientCode',
      dataIndex: 'ClientCode',
      key: 'ClientCode',
      width: '10%',
      className: 'report-text',
    },
    {
      title: 'StrategyCode',
      dataIndex: 'StrategyCode',
      key: 'StrategyCode',
      width: '15%',
      className: 'report-numeric',
    },
    {
      title: 'Script',
      dataIndex: 'script',
      key: 'script',
      width: '350px',
      className: 'report-numeric-position-FO',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'ExpiryDate',
      dataIndex: 'ExpiryDate',
      key: 'ExpiryDate',
      width: '20%',
      className: 'report-numeric',
    },
    {
      title: 'Strike',
      dataIndex: 'Strike',
      key: 'Strike',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'CP',
      dataIndex: 'CP',
      key: 'CP',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'qty',
      dataIndex: 'qty',
      key: 'qty',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      width: '10%',
      className: 'report-numeric',
      // render: (text) => Math.round(text)
    },
    {
      title: 'NetPrice',
      dataIndex: 'NetPrice',
      key: 'NetPrice',
      width: '20%',
      className: 'report-numeric',
    },
  ]

  const columns = [
    {
      title: 'FO Position Report',
      className: 'report-title main-title',
      children:
        role !== 'ADMIN'
          ? child
          : child?.filter((item) => item?.title !== 'Company'),
    },
  ]

  const onFinish = (values) => {
    setFilters({
      ...values,
      clientCode: '',
      strategy: values.strategy ? values.strategy : '',
      script: values.script ? values.script : '',
    })
  }

  let staticHtml = ``
  if (get(data, 'spCurrentPositionFO')) {
    staticHtml = `
<div class="ant-table-wrapper nlv-tracker-report">
  <div class="ant-spin-nested-loading">
    <div class="ant-spin-container">
      <div class="ant-table">
        <div class="ant-table-container">
          <div class="ant-table-content">
            <table style="table-layout: auto;">
              <colgroup>
                <col style="width: 60px; min-width: 60px;">
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col style="width: 200px; min-width: 200px;">
              </colgroup>
              <thead class="ant-table-thead">
                <tr>
                  <th colspan="14" class="ant-table-cell report-title main-title">FO Position Report</th>
                </tr>
                <tr>
                  <th class="ant-table-cell report-text">Client Code</th>
                  <th class="ant-table-cell report-text">StrategyCode</th>
                  <th class="ant-table-cell report-text">script</th>
                  <th class="ant-table-cell report-text">company</th>
                  <th class="ant-table-cell report-numeric">ExpiryDate</th>
                  <th class="ant-table-cell report-numeric">Strike</th>
                  <th class="ant-table-cell report-text">CP</th>
                  <th class="ant-table-cell report-numeric">qty</th>
                  <th class="ant-table-cell report-numeric">Value</th>
                  <th class="ant-table-cell report-numeric">NetPrice</th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">`
    staticHtml = `${staticHtml}`
    data.spCurrentPositionFO.forEach((data) => {
      const {
        ClientCode,
        StrategyCode,
        script,
        company,
        ExpiryDate,
        Strike,
        CP,
        qty,
        Value,
        NetPrice,
      } = data
      staticHtml = `${staticHtml}
                <tr data-row-key="KYP" class="ant-table-row ant-table-row-level-0">
                  <td class="ant-table-cell report-text">${ClientCode} </td>
                  <td class="ant-table-cell report-text">${StrategyCode}</td>
                  <td class="ant-table-cell report-text">${script}</td>
                  <td class="ant-table-cell report-text">${company}</td>
                  <td class="ant-table-cell report-numeric">${ExpiryDate}</td>
                  <td class="ant-table-cell report-numeric">${Strike}</td>
                  <td class="ant-table-cell report-text">${CP}</td>
                  <td class="ant-table-cell report-numeric">${qty}</td>
                  <td class="ant-table-cell report-numeric">${Value.toFixed(
                    2
                  )}</td>
                  <td class="ant-table-cell report-numeric">${NetPrice}</td>
                </tr>`
    })
    staticHtml = `${staticHtml}
              </tbody >
            </table >
          </div >
        </div >
      </div >
    </div >
  </div >
</div >
      `
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: 0,
        }}
      >
        {!error && (
          <DownloadReport
            data={spCurrentPositionFO}
            staticHtml={staticHtml}
            fileName='current-positionFO'
            id='SP_CURRENT_POSITIONFO_PDF'
          />
        )}
      </div>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col span={20}>
          <Form form={form} onFinish={onFinish} name='advanced_search'>
            <Row style={{ justifyContent: 'center' }}>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item name='script' label='script'>
                  <Input placeholder='Enter script' style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item name='strategy' label='strategy'>
                  <Select
                    style={{ width: 80 }}
                    className='gx-mr-3 gx-select-sm'
                    onChange={(e) =>
                      dispatch({ type: 'SELECTED_STRATEGY', data: e })
                    }
                  >
                    {_map(strategyList, (strategy, key) => {
                      const { StrategyName, StrategyCode } = strategy
                      return (
                        <Option value={StrategyCode} key={key}>
                          {StrategyName}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Button type='primary' htmlType='submit'>
                  Search
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFilters({})
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {error ? (
        <Page404 error={error} isShow={false} />
      ) : (
        !isEmpty(filters) && (
          <div className='report-table-wrapper' id='SP_CURRENT_POSITIONFO_PDF'>
            <PositionFOTable
              filters={filters}
              data={currentPositionFOBalanceData}
              columns={columns}
              loading={loading}
            />
          </div>
        )
      )}
    </>
  )
}
