import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import DownloadReport from '../DownloadReport'
import { SP_CURRENT_POSITION_JBALANCE } from '../graphql/Queries'
import '../index.css'
import JbalanceTable from './JbalanceTable'

export default function () {
  const {
    state: {
      isAdmin,
      isStaff,
      currentUser: { clientCode, role },
    },
  } = useContext(AppContext)
  const code = !isAdmin && !isStaff ? clientCode : ''

  const { data, loading, error } = useQuery(SP_CURRENT_POSITION_JBALANCE, {
    variables: { where: { clientCode: code } },
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  if (!get(data, 'spCurrentPositionJBalance')) return <NoData />

  let currentPositionJBalanceData
  const { spCurrentPositionJBalance } = data
  currentPositionJBalanceData = spCurrentPositionJBalance.map((jBalance) => {
    return {
      ...jBalance,
      LedgerAll: Math.round(jBalance.LedgerAll),
      LedgerJCPL: Math.round(jBalance.LedgerJCPL),
      LedgerJFS: Math.round(jBalance.LedgerJFS),
      LedgerJSC: Math.round(jBalance.LedgerJSC),
    }
  })
  return (
    <>
      <DownloadReport
        data={currentPositionJBalanceData}
        fileName='j-balance-report'
        id='J-BALANCE-REPORT-PDF'
      />
      <div className='report-table-wrapper' id='J-BALANCE-REPORT-PDF'>
        <JbalanceTable reportData={currentPositionJBalanceData} role={role} />
      </div>
    </>
  )
}
