import React, { useContext, useState } from 'react'
import get from 'lodash/get'

import client from 'apollo'
import footerLogo from '../../assets/images/footer-logo.png'
import googleplaystoreicon from '../../assets/images/google-playstore-icon.png'
import appleicon from '../../assets/images/apple-icon.png'
import { Button, notification } from 'antd'
import { PhoneFilled } from '@ant-design/icons'
import { AppContext } from 'AppContext'
import CreateTicketModal from '../../modules/Tickets/CreateTicketModal'
import { CREATE_TICKET } from '../../modules/Tickets/graphql/Mutations'

import './index.css'

export const Footer = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  function handleCreateTicket(values, resetForm) {
    setShowFormLoading(true)
    if (values.fileUrl) {
      let store = []
      values.fileUrl.forEach((data) => {
        if (data?.originFileObj) {
          const file = get(data, 'originFileObj')
          store.push(file)
        } else {
          store.push(data.url)
        }
      })
      values.fileUrl = store
    } else {
      values.fileUrl = null
    }
    client
      .mutate({
        mutation: CREATE_TICKET,
        variables: { ...values },
      })
      .then((res) => {
        notification.success({
          duration: 2.5,
          message: (
            <>
              Ticket created Successfully
              <br />
              {`Note Your Ticket Number.:${res?.data?.createTicket?.ticketNumber}`}
            </>
          ),
        })
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  return (
    <div>
      <footer>
        <div className='footer'>
          {!currentUser.createdBy ? (
            <div className='footer-name'>
              <h2> Your RM is not assigned </h2>
            </div>
          ) : (
            <div className='footer-name'>
              <h2>
                {' '}
                {currentUser?.createdBy &&
                  currentUser?.createdBy.firstName +
                    ' ' +
                    currentUser?.createdBy.lastName}
              </h2>
            </div>
          )}
          {/* <a href={`tel:+91${currentUser.createdBy.phone}`}> */}
          <div className='footer-icon'>
            <PhoneFilled style={{ fontSize: '20px' }} />
            &nbsp;
            <h2 className='footer-number'>
              {currentUser?.createdBy && currentUser.createdBy.phone
                ? currentUser.createdBy.phone
                : '-'}
            </h2>
          </div>
          {/* </a> */}
          <a
            href='https://www.finideas.com'
            target='_blank'
            rel='noopener noreferrer'
            className='footer-logo'
          >
            <img
              src={footerLogo}
              alt='Finideas'
              className='footer-center-logo'
            />
          </a>
          <div className='footer-playstore'>
            <img
              src={appleicon}
              alt='Play-store Icon'
              className='footer-playstore-logo'
              onClick={() =>
                window.open(
                  'https://apps.apple.com/in/app/finideas/id1635925829'
                )
              }
            />
          </div>
          <div className='footer-playstore'>
            <img
              src={googleplaystoreicon}
              alt='Play-store Icon'
              className='footer-playstore-logo'
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.finideas.backoffice'
                )
              }
            />
          </div>
          <div className='footer-button'>
            <Button
              type='primary'
              style={{
                margin: '0',
                fontSize: '14px',
                padding: '1px 8px 0px 8px',
              }}
              onClick={() => setShowFormModal(true)}
            >
              Create Ticket
            </Button>
          </div>
        </div>
      </footer>
      {showFormModal && (
        <CreateTicketModal
          visible={showFormModal}
          loading={showFormLoading}
          onCreate={handleCreateTicket}
          onCancel={() => setShowFormModal(false)}
          fromFaq={false}
        />
      )}
    </div>
  )
}
