import React from 'react'
import { Modal, Row, Col } from 'antd'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from 'recharts'

import './pendingAdvisory.css'
import moment from 'moment'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import { sortBy } from 'lodash'

const MoreDetails = ({
  isOpenModal,
  closeModal,
  bookedAdvisory,
  advisoryDate,
  mapData1,
  mapData2,
  range1,
  range2,
  userData,
}) => {
  const nifty =
    bookedAdvisory?.mapScenario1[0] &&
    Object.keys(bookedAdvisory?.mapScenario1[0])
      .filter((key) => key !== 'Description')
      .map((key, index) => bookedAdvisory?.mapScenario1[0][key])

  const nifty1 =
    bookedAdvisory?.mapScenario2[0] &&
    Object.keys(bookedAdvisory?.mapScenario2[0])
      .filter((key) => key !== 'Description')
      .map((key, index) => bookedAdvisory?.mapScenario2[0][key])

  let data = []
  nifty.forEach((item, key) => {
    let obj = {}
    nifty1.forEach((data, index) => {
      if (item === data) {
        obj.niftyLevel = item
        obj.expectedPL1 = mapData1[key].pl
        obj.expectedPL2 = mapData2[index].pl
        obj.expectedNLV1 = mapData1[key].nlv
        obj.expectedNLV2 = mapData2[index].nlv
      }
    })
    data.push(obj)
    data = sortBy(data, 'niftyLevel')
  })

  const niftyDate1 =
    bookedAdvisory?.mapScenario1[0]?.Description?.split(' ')[
      bookedAdvisory?.mapScenario1[0]?.Description?.split(' ')?.length - 1
    ]
  const niftyDate2 =
    bookedAdvisory?.mapScenario2[0]?.Description?.split(' ')[
      bookedAdvisory?.mapScenario2[0]?.Description?.split(' ')?.length - 1
    ]

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <div className='custom-tooltip' style={{ background: '#fff' }}>
          <p style={{ color: 'orange', textAlign: 'center' }}>{label}</p>
          <p style={{ color: 'red' }}>{`P&L: ${convertNumberToCommaSeparate(
            +payload[0].value
          )}`}</p>
          <p style={{ color: 'green' }}>{`NLV: ${convertNumberToCommaSeparate(
            +payload[1].value
          )}`}</p>
        </div>
      )
    }

    return null
  }

  return (
    <>
      <Modal
        visible={isOpenModal}
        onCancel={closeModal}
        footer={null}
        width={1000}
      >
        <div className='pending_advisory_logo_and_title'>
          <div className='pending_advisory_title_div'>
            <b className='pending_advisory_title'>Index Long Term Strategy</b>
            <p className='pending_advisory_modal_sub_title'>
              Scenario Analysis
            </p>
          </div>
          <div>
            <img
              src='https://finideas-india.blr1.digitaloceanspaces.com/Finideas-black-png.png'
              alt='Finideas'
              width={150}
              height={45}
            />
          </div>
        </div>

        <Row gutter={16}>
          <Col lg={16} md={16} sm={24} xs={23}>
            <div className='pending_advisory_client_details_div_1'>
              <div className='clients_details'>
                <div>
                  <div className='client_div_for_advisory_title'>
                    <b>Client Code</b>
                  </div>
                  <div className='client_div_for_advisory_sub_title'>
                    <b>Client Name</b>
                  </div>
                </div>
              </div>
              <div className='client_details_value'>
                <div>
                  <div
                    className='client_code_value'
                    style={{ fontWeight: '500' }}
                  >
                    <span>{userData?.clientCode}</span>
                  </div>
                  <div
                    className='client_name_value'
                    style={{ fontWeight: '500' }}
                  >
                    <span>
                      {userData?.firstName + ' ' + userData?.lastName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8} md={8} sm={24} xs={22} className='new_column_for_type'>
            <div>
              <div className='advisory_date_and_type'>
                <div className='advisory_date_div'>
                  <div className='text_div'>
                    <b className='text_div_title'>Advisory Type</b>
                  </div>
                  <div className='date_value'>
                    <span>New</span>
                  </div>
                </div>
              </div>

              <div className='date_and_time_div'>
                <div className='advisory_date_div'>
                  <div className='text_div'>
                    <b className='text_div_title'>Date & Time</b>
                  </div>
                  <div className='date_value'>
                    <span>
                      {moment(advisoryDate).format('DD-MM-YYYY HH:mm:ss')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <p className='model_static_text'>
          Current Expected AUA :{' '}
          {convertNumberToCommaSeparate(
            +bookedAdvisory?.currentExpectedExposure
          )}
        </p>
        <p className='model_static_text'>
          Current Expected NLV :{' '}
          {convertNumberToCommaSeparate(+bookedAdvisory?.currentExpectedNlv)}
        </p>

        <div className='table_data_div'>
          <Row
            gutter={16}
            style={{
              // padding: '0 10px',
              fontWeight: 500,
              marginRight: '0',
              marginLeft: '0',
            }}
          >
            <Col className='table_data_col_1' lg={6} md={6} sm={6} xs={4}>
              NIFTY Levels
            </Col>
            <Col className='table_data_col_2' lg={9} md={9} sm={10} xs={11}>
              <Row className='table_data_col_2_row_1' gutter={16}>
                Scenario Nifty Levels: {moment(niftyDate1).format('DD-MM-YYYY')}
              </Row>
              <Row className='table_data_col_2_row_2' gutter={16}>
                <Col className='table_data_col_row_2_col_1' span={12}>
                  Expected P&L
                </Col>
                <Col className='table_data_col_row_2_col_2' span={12}>
                  Expected NLV
                </Col>
              </Row>
            </Col>

            <Col span={9} className='table_data_col_3'>
              <Row className='table_data_col_3_row_1' gutter={16}>
                Scenario Nifty Levels: {moment(niftyDate2).format('DD-MM-YYYY')}
              </Row>
              <Row className='table_data_col_3_row' gutter={16}>
                <Col className='table_data_col_row_2_col_1' span={12}>
                  Expected P&L
                </Col>
                <Col className='table_data_col_row_2_col_2' span={12}>
                  Expected NLV
                </Col>
              </Row>
            </Col>
          </Row>

          {data?.map((item, key) => {
            return (
              <Row gutter={16} style={{ marginRight: '0', marginLeft: '0' }}>
                <Col
                  className={
                    data?.length - 1 === key ? 'data_col_1_new' : 'data_col_1'
                  }
                  lg={6}
                  md={6}
                  sm={6}
                  xs={4}
                >
                  {item?.niftyLevel}
                </Col>
                <Col className='data_col_2' lg={9} md={9} sm={10} xs={11}>
                  <Row
                    gutter={16}
                    className={
                      data?.length - 1 === key
                        ? 'data_col_2_row_new'
                        : 'data_col_2_row'
                    }
                  >
                    <Col className='data_col_3_row_col_1' span={12}>
                      {convertNumberToCommaSeparate(+item?.expectedPL1)}
                    </Col>
                    <Col className='data_col_3_row_col_2' span={12}>
                      {convertNumberToCommaSeparate(+item?.expectedNLV1)}
                    </Col>
                  </Row>
                </Col>
                <Col span={9}>
                  <Row
                    className={
                      data?.length - 1 === key
                        ? 'data_col_3_row_new'
                        : 'data_col_3_row'
                    }
                    gutter={16}
                  >
                    <Col className='data_col_3_row_col_1' span={12}>
                      {convertNumberToCommaSeparate(+item?.expectedPL2)}
                    </Col>
                    <Col className='data_col_3_row_col_2' span={12}>
                      {convertNumberToCommaSeparate(+item?.expectedNLV2)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          })}
        </div>

        {/* chart */}
        <Row
          gutter={16}
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Col
            lg={11}
            md={11}
            sm={24}
            xs={24}
            style={{ border: '1px solid #000' }}
          >
            <ResponsiveContainer width='100%' height={300}>
              <LineChart
                data={sortBy(mapData1, 'nifty')}
                margin={{ top: 10, right: 30, bottom: 20, left: 30 }}
              >
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign='top'
                  align='center'
                  height={36}
                  content={(props) => (
                    <div className='chart_legend2'>
                      <div className='legend1' style={{ color: 'red' }}>
                        <div
                          className='legend_dot'
                          style={{ background: 'red' }}
                        ></div>
                        <span className='legend_name_1'>
                          Expected P&L at Trading Member
                        </span>
                      </div>
                      <div className='legend1' style={{ color: 'green' }}>
                        <div
                          className='legend_dot'
                          style={{ background: 'green' }}
                        ></div>
                        <span className='legend_name_1'>Expected NLV</span>
                      </div>
                    </div>
                  )}
                />
                <Line
                  type='monotone'
                  dataKey='pl'
                  stroke='red'
                  dot={{ stroke: 'red', strokeWidth: 2, fill: 'white' }}
                  activeDot={{ r: 8 }}
                />
                <Line
                  type='monotone'
                  dataKey='nlv'
                  stroke='green'
                  dot={{ stroke: 'green', strokeWidth: 2, fill: 'white' }}
                  activeDot={{ r: 8 }}
                />
                <XAxis dataKey='nifty'>
                  <Label
                    value='Nifty Levels'
                    position='insideBottom'
                    offset={-5}
                  />
                </XAxis>
                <YAxis domain={range1}>
                  <Label
                    value='Expected P&L and NLV'
                    position='left'
                    angle={-90}
                    offset={20}
                    dy={-65}
                  />
                </YAxis>
              </LineChart>
            </ResponsiveContainer>

            <p className='chart_description'>
              {bookedAdvisory?.mapScenario1[0]?.Description}
            </p>
          </Col>
          <Col lg={11} md={11} sm={24} xs={24} className='more_details_chart_2'>
            <ResponsiveContainer width='100%' height={300}>
              <LineChart
                data={sortBy(mapData2, 'nifty')}
                margin={{ top: 10, right: 30, bottom: 20, left: 30 }}
              >
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign='top'
                  align='center'
                  height={36}
                  content={(props) => (
                    <div className='chart_legend2'>
                      <div className='legend1' style={{ color: 'red' }}>
                        <div
                          className='legend_dot'
                          style={{ background: 'red' }}
                        ></div>
                        <span className='legend_name_1'>
                          Expected P&L at Trading Member
                        </span>
                      </div>
                      <div className='legend1' style={{ color: 'green' }}>
                        <div
                          className='legend_dot'
                          style={{ background: 'green' }}
                        ></div>
                        <span className='legend_name_1'>Expected NLV</span>
                      </div>
                    </div>
                  )}
                />
                <Line
                  type='monotone'
                  dataKey='pl'
                  stroke='red'
                  dot={{ stroke: 'red', strokeWidth: 2, fill: 'white' }}
                  activeDot={{ r: 8 }}
                />
                <Line
                  type='monotone'
                  dataKey='nlv'
                  stroke='green'
                  dot={{ stroke: 'green', strokeWidth: 2, fill: 'white' }}
                  activeDot={{ r: 8 }}
                />
                <XAxis dataKey='nifty'>
                  <Label
                    value='Nifty Levels'
                    position='insideBottom'
                    offset={-5}
                  />
                </XAxis>
                <YAxis domain={range2}>
                  <Label
                    value='Expected P&L and NLV'
                    position='left'
                    angle={-90}
                    offset={20}
                    dy={-65}
                  />
                </YAxis>
              </LineChart>
            </ResponsiveContainer>
            <p className='chart_description'>
              {bookedAdvisory?.mapScenario2[0]?.Description}
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  )
}
export default MoreDetails
