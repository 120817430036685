import gql from 'graphql-tag'

export const GET_COMPANY_SUMMARY = gql`
  query GetCompanyDetails($id: ID!) {
    getCompanyDetails(id: $id) {
      id
      marketExperience
      advisoryAua
      advisoryAuaDate
      referralPartner
      createdAt
      updatedAt
    }
  }
`

export const GET_ALL_COMPANY_SUMMARY = gql`
  query Query {
    getAllCompanyDetails
  }
`
