import React, { useEffect, useState } from 'react'
import { PageHeader, Form, Row, Col, Radio, DatePicker, Button } from 'antd'
import client from 'apollo'
import { UPDATE_BULK_PERFORMANCE_REPORT_SETTING } from '../graphql/Mutation'
import { GET_BULK_PERFORMANCE_REPORT_SETTING } from '../graphql/Queries'
import moment from 'moment'
import Loader from 'components/loaders/Loader'
import openNotification from 'utils/Notification'
import ReactQuill from 'react-quill'

const BulkClientSetting = () => {
  const [form] = Form.useForm()
  const [type, setType] = useState('')
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [date, setDate] = useState(moment())
  const [mailDraft, setMailDraft] = useState('')
  const [btnLoading, setBtnLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    getBulkReportSetting()
  }, [])

  if (loading) return <Loader />

  const getBulkReportSetting = () => {
    setLoading(true)
    client
      .query({ query: GET_BULK_PERFORMANCE_REPORT_SETTING })
      .then((res) => {
        setType(res?.data?.getBulkPerformanceReportSetting?.type)
        setFormData(res?.data?.getBulkPerformanceReportSetting)
        setDate(
          res?.data?.getBulkPerformanceReportSetting?.performanceReportDate
            ? moment(
                res?.data?.getBulkPerformanceReportSetting
                  ?.performanceReportDate
              )
            : moment()
        )
        setMailDraft(res?.data?.getBulkPerformanceReportSetting?.mailDraft)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setType('')
        setFormData({})
      })
  }
  const onFinish = (values) => {
    setBtnLoading(true)
    if (!mailDraft) {
      setError(true)
      setBtnLoading(false)
    } else {
      setError(false)
      client
        .mutate({
          mutation: UPDATE_BULK_PERFORMANCE_REPORT_SETTING,
          variables: {
            data: {
              ...values,
              type,
              performanceReportDate: date.format(),
              mailDraft,
            },
          },
        })
        .then((res) => {
          if (formData?.type) {
            openNotification(
              'success',
              'Bulk Performance Report Setting Updated Successfully'
            )
          } else {
            openNotification(
              'success',
              'Bulk Performance Report Setting Created Successfully'
            )
          }
          setBtnLoading(false)
        })
        .catch((err) => {
          setBtnLoading(false)
        })
        .finally(() => setBtnLoading(false))
    }
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],

      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],

      ['clean'],
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  return (
    <>
      <PageHeader className='box' title='Bulk Client Setting' />

      <Form
        form={form}
        onFinish={onFinish}
        name='advanced_search'
        initialValues={formData}
      >
        <div
          style={{
            margin: '0 40px',
          }}
        >
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label='Select Performance Report Type'
                required={true}
                validateStatus={!type ? 'error' : ''}
                help={!type ? 'Please select type!' : ''}
              >
                <Radio.Group
                  onChange={(e) => {
                    setType(e.target.value)
                    if (e.target.value === 'AUTO') {
                      setDate(moment(formData?.autoReportDate))
                    }
                  }}
                  value={type}
                >
                  <Radio value={'AUTO'}>Auto</Radio>
                  <Radio value={'MANUAL'}>Manual</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label='Report Date'
                required={true}
                validateStatus={!date ? 'error' : ''}
                help={!date ? 'Please select date!' : ''}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={type === 'AUTO'}
                  onChange={(e) => {
                    setDate(e)
                  }}
                  value={date}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name='userType'
                label='Select User'
                rules={[{ required: true, message: 'Please user type!' }]}
              >
                <Radio.Group>
                  <Radio value={'active'}>Active</Radio>
                  <Radio value={'deActive'}>Deactive</Radio>
                  <Radio value={'both'}>Both</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name='mailDraft'>
                <span style={{ marginBottom: '10px' }}>Template Format:</span>
                <ReactQuill
                  theme='snow'
                  value={mailDraft}
                  onChange={(value) => {
                    setMailDraft(value)
                  }}
                  modules={modules}
                  formats={formats}
                />
              </Form.Item>
              {error && (
                <span style={{ color: 'red' }}>
                  Mail template can not be empty!
                </span>
              )}
            </Col>

            <Col className='gutter-row' span={24}>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={btnLoading}
                  disabled={!date}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  )
}

export default BulkClientSetting
