// import domToPdf from 'dom-to-pdf'
import _get from 'lodash/get'
import domToImage from 'dom-to-image'
import fileDownload from 'js-file-download'

import client from 'apollo'
import openNotification from './Notification'
import {
  GENERATE_PDF,
  GET_ILTS_P_REPORT,
  SEND_ME_PDF_V2,
} from 'modules/Reports/graphql/Queries'

export async function exportGraphAsPdf(
  name,
  id,
  staticHtml = null,
  isLandscape = false
) {
  return new Promise((resolve, reject) => {
    let headingSize = ''
    let type = !staticHtml ? 'portrait' : 'landscape'
    if (isLandscape) {
      type = 'landscape'
    }
    if (name === 'ILTS') {
      headingSize = 'font-size: 15px !important'
    }
    const html = staticHtml || document.getElementById(id)?.innerHTML

    client
      .query({
        query: GENERATE_PDF,
        variables: { data: { html, type, name, headingSize } },
        fetchPolicy: 'network-only',
      })
      .then(
        (res) =>
          _get(res, 'data.generatePDF') &&
          window.open(_get(res, 'data.generatePDF'), '_blank')
      )
      .catch((err) => console.log(err))
      .finally(() => resolve())
  })
  // domToPdf(document.getElementById(id), { filename }, function () {
  //   console.log('PDF Generated')
  // })
}

export function exportGraphAsImage(name, type, id) {
  domToImage
    .toBlob(document.getElementById(id))
    .then((blob) => fileDownload(blob, `${name}.${type}`))
    .catch((err) => console.log(err))
}

export async function sendMePdf(id) {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: GET_ILTS_P_REPORT,
        variables: { where: { id: parseInt(id) } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: SEND_ME_PDF_V2,
            variables: {
              data: { url: res?.data?.getIltspreport, useSendInBlue: true },
            },
            fetchPolicy: 'network-only',
          })
          .then((response) =>
            openNotification('success', 'Mail has been sent!')
          )
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((err) => console.log(err))
      .finally(() => resolve())
  })
}
