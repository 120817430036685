import React from 'react'
import get from 'lodash/get'
import qs from 'query-string'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import DownloadReport from 'modules/Reports/DownloadReport'
import InterestTable from 'modules/Reports/Interest/InterestTable'
import { GET_INTEREST_REPORT } from 'modules/Reports/graphql/Queries'
import 'modules/Reports/index.css'
import { GET_USER } from 'modules/Users/graphql/Queries'

export default function (props) {
  let {
    match: {
      params: { clientCode, clientId },
    },
    history: {
      location: { search },
    },
  } = props
  const parsed = qs.parse(search || {})
  const { strategy = 'ILTS' } = parsed
  const { data, loading, error } = useQuery(GET_INTEREST_REPORT, {
    variables: { where: { clientCode, date: null, strategy } },
  })
  const {
    data: getUserData,
    loading: getUserLoading,
    error: getUserError,
  } = useQuery(GET_USER, { variables: { id: clientId } })

  if (loading || getUserLoading) return <Loader />
  if (error || getUserError) return <Page404 error={error} />
  if (!get(data, 'spInterestReport')) return <NoData />

  const {
    spInterestReport: [spInterestReport],
  } = data
  return (
    <>
      <DownloadReport
        data={spInterestReport}
        fileName='interest-report'
        id='INTEREST-REPORT-PDF'
      />
      <div className='report-table-wrapper' id='INTEREST-REPORT-PDF'>
        <InterestTable
          reportData={spInterestReport}
          ClientCode={clientCode}
          user={getUserData}
        />
      </div>
    </>
  )
}
