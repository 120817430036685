import gql from 'graphql-tag'

export const GET_ADVISORY_DATA = gql`
  query getAdvisoryData($where: AdvisoryDataReport) {
    getAdvisoryData(where: $where)
  }
  # query getAdvisoryData {
  #   getAdvisoryData
  # }
`

export const GET_MULTIPLE_ADVISORY = gql`
  query Query($where: AdvisoryDataReport) {
    getMultipleAdvisoryData(where: $where)
  }
`
