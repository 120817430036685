import React from 'react'
import { Table } from 'antd'

export default function RolloverOpportunityTable({ reportData, columns, loading }) {
  const data = reportData.map((report, key) => {
    return { key, ...report }
  })

  return (
    <div>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        className='nlv-tracker-report'
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
        // scroll={{ x: 500, y: 1500 }}
        scroll={{ x: true, y: 'calc(100vh - 72px - 24px)' }}
      />
    </div>
  )
}
