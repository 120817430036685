import React, { useContext, useState } from 'react'
import { Modal, Descriptions, Checkbox, Button } from 'antd'
import { AppContext } from 'AppContext'
import client from 'apollo'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
} from 'modules/Users/graphql/Mutations'
import { COMPLETE_STEP, DISCLAIMER_MAIL } from '../graphql/Mutations'
import openNotification from 'utils/Notification'
import moment from 'moment'
import VerificationModal from './VerificationModal'
import cities from 'utils/cities.json'

const VerifyDetailsShow = ({
  visible,
  onCancel,
  formValue,
  next,
  reEvaluate,
}) => {
  const [agree, setAgree] = useState(true)
  const [loadings, setLoadings] = useState(false)
  const [otpModal, setOtpModal] = useState(false)
  const [modalClose, setModalClose] = useState(true)
  const { dispatch, state } = useContext(AppContext)
  const { id } = state.currentUser

  let {
    dateOfBirth,
    gender,
    address,
    fatherName,
    nationality,
    residentialStatus,
    maritalStatus,
    sourceOfIncome,
    politicallyExposed,
    countryOfTax,
    cityId,
  } = formValue
  if (gender === 'MALE') {
    gender = 'Male'
  } else if (gender === 'FEMALE') {
    gender = 'Female'
  } else {
    gender = 'Other'
  }

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  const onNext = () => {
    setLoadings(true)
    const data = {
      ...formValue,
      dateOfBirth: moment(formValue.dateOfBirth).format('YYYY-MM-DD'),
      completedSteps: 'CLIENT_PROFILE_2',
    }

    client
      .mutate({ mutation: UPDATE_USER, variables: { id, data } })
      .then((res) => {
        client.mutate({ mutation: DISCLAIMER_MAIL })
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'CLIENT_PROFILE_2',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        openNotification('success', 'Client Profile Updated Successfully')
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadings(false))
  }

  const onOk = () => {
    setLoadings(true)
    if (reEvaluate) {
      setOtpModal(true)
      client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
        openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
        setLoadings(false)
      })
      setModalClose(false)
    } else {
      onNext()
    }
  }

  return (
    <>
      {modalClose ? (
        <Modal
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          footer={
            <>
              <Button onClick={() => onCancel()}> Cancel </Button>
              <Button
                onClick={() => onOk()}
                type='primary'
                disabled={!agree}
                loading={loadings}
              >
                {' '}
                Next{' '}
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          <Descriptions bordered>
            <Descriptions.Item label='Date Of Birth' span={3}>
              {moment(dateOfBirth).format('DD-MMM-YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label='Gender' span={3}>
              {gender}
            </Descriptions.Item>
            <Descriptions.Item label='Address' span={3}>
              {address}
            </Descriptions.Item>
            <Descriptions.Item label='Name of father/spouse' span={3}>
              {fatherName}
            </Descriptions.Item>
            <Descriptions.Item label='Nationality' span={3}>
              {nationality}
            </Descriptions.Item>
            <Descriptions.Item
              label='Residential Status'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {residentialStatus?.toLowerCase().split('_').join(' ')}
            </Descriptions.Item>
            <Descriptions.Item
              label='Marital Status'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {maritalStatus?.toLowerCase()}
            </Descriptions.Item>
            <Descriptions.Item
              label='Source of Income'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {sourceOfIncome?.toLowerCase().split('_').join(' ')}
            </Descriptions.Item>
            <Descriptions.Item
              label='Politically Exposed'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {politicallyExposed?.toLowerCase()}
            </Descriptions.Item>
            <Descriptions.Item label='Country of tax' span={3}>
              {countryOfTax}
            </Descriptions.Item>
            <Descriptions.Item label='City' span={3}>
              {cities.filter((city) => city.id === cityId)[0]?.name}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} checked={agree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
        </Modal>
      ) : (
        <Modal
          style={{ display: 'none' }}
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          footer={
            <>
              <Button onClick={() => onCancel()}> Cancel </Button>
              <Button onClick={() => onOk()} type='primary' disabled={!agree}>
                {' '}
                Next{' '}
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          <Descriptions bordered>
            <Descriptions.Item label='Date Of Birth' span={3}>
              {moment(dateOfBirth).format('DD-MMM-YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label='Gender' span={3}>
              {gender}
            </Descriptions.Item>
            <Descriptions.Item label='Address' span={3}>
              {address}
            </Descriptions.Item>
            <Descriptions.Item label='Name of father/spouse' span={3}>
              {fatherName}
            </Descriptions.Item>
            <Descriptions.Item label='Nationality' span={3}>
              {nationality}
            </Descriptions.Item>
            <Descriptions.Item
              label='Residential Status'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {residentialStatus?.toLowerCase().split('_').join(' ')}
            </Descriptions.Item>
            <Descriptions.Item
              label='Marital Status'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {maritalStatus?.toLowerCase()}
            </Descriptions.Item>
            <Descriptions.Item
              label='Source of Income'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {sourceOfIncome?.toLowerCase().split('_').join(' ')}
            </Descriptions.Item>
            <Descriptions.Item
              label='Politically Exposed'
              span={3}
              style={{ textTransform: 'capitalize' }}
            >
              {politicallyExposed?.toLowerCase()}
            </Descriptions.Item>
            <Descriptions.Item label='Country of tax' span={3}>
              {countryOfTax}
            </Descriptions.Item>
            <Descriptions.Item label='City' span={3}>
              {cities.filter((city) => city.id === cityId)[0]?.name}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} checked={agree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
        </Modal>
      )}
      {otpModal && <VerificationModal visible={otpModal} onNext={onNext} />}
    </>
  )
}

const ClientProfileModal2 = (props) => {
  return (
    <div>
      <VerifyDetailsShow {...props} />
    </div>
  )
}

export default ClientProfileModal2
