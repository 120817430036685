import React from 'react'

import { Row, Col, Button, Collapse } from 'antd'
import {
  QuestionOutlined,
  PlusOutlined,
  MinusOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

import './pendingAdvisory.css'

const { Panel } = Collapse

const ScenarioPendingAdvisoryIndex = ({
  onFinish,
  loading,
  data,
  onReject,
  rejectLoading,
}) => {
  return (
    <>
      <Row gutter={16} className='scenario_advisory'>
        <Col span={24}>
          <Collapse
            defaultActiveKey='2'
            className='advisory_collapse'
            expandIcon={({ isActive }) =>
              isActive ? (
                <MinusOutlined className='faq-plus-icon' />
              ) : (
                <PlusOutlined className='faq-plus-icon' />
              )
            }
          >
            <Panel header='Current Open Position' key='1'>
              <div className='update_trade_table'>
                <Row
                  className='update_trade_table_row'
                  gutter={16}
                  style={{ fontWeight: 500 }}
                >
                  <Col
                    className='update_trade_table_script'
                    span={18}
                    style={{ borderRight: '1px solid #000' }}
                  >
                    Script
                  </Col>
                  <Col className='update_trade_table_script_total ' span={6}>
                    Total Quantity
                  </Col>
                </Row>
                {data?.currentPosition?.length &&
                  data?.currentPosition?.map((item, key) => {
                    const { Script, Quantity } = item
                    return (
                      <Row
                        className={
                          data?.currentPosition?.length - 1 === key
                            ? 'update_trade_table_row_new'
                            : 'update_trade_table_row'
                        }
                        gutter={16}
                      >
                        <Col
                          className='update_trade_table_script'
                          span={18}
                          style={{ borderRight: '1px solid #000' }}
                        >
                          {Script}
                        </Col>
                        <Col className='update_trade_table_col' span={6}>
                          {Quantity}
                        </Col>
                      </Row>
                    )
                  })}
              </div>
            </Panel>
            <Panel header='Current Advisory' key='2'>
              <div className='scenario_advisory_text_div'>
                <div className='scenario_advisory_text_text'>
                  <Row gutter={8}>
                    <Col>
                      <ArrowRightOutlined />
                    </Col>
                    <Col lg={4} md={14} sm={14} xs={14}>
                      Overall Cash Requirement
                    </Col>
                    <Col>:</Col>
                    <Col span={6}>{data?.totalCostValue}</Col>
                  </Row>
                </div>
                <div className='scenario_advisory_text_text'>
                  <Row gutter={8}>
                    <Col>
                      <ArrowRightOutlined />
                    </Col>
                    <Col lg={4} md={14} sm={14} xs={14}>
                      Futures and Options Cash flow
                    </Col>
                    <Col>:</Col>
                    <Col span={6} style={{ color: 'red' }}>
                      {data?.cashOutFlow}
                    </Col>
                  </Row>
                </div>
                <div className='scenario_advisory_text_text'>
                  <Row gutter={8}>
                    <Col>
                      <ArrowRightOutlined />
                    </Col>
                    <Col lg={4} md={14} sm={14} xs={14}>
                      SIP Amount
                    </Col>
                    <Col>:</Col>
                    <Col span={6}>{data?.sip ? data?.sip : 0}</Col>
                  </Row>
                </div>

                <div className='update_trade_table'>
                  <Row
                    className='update_trade_table_row'
                    gutter={16}
                    style={{ fontWeight: 500 }}
                  >
                    <Col
                      className='update_trade_table_script update_trade_table_col_script'
                      span={12}
                    >
                      Script
                    </Col>
                    <Col className='update_trade_table_col ' span={4}>
                      Trade
                    </Col>
                    <Col
                      className='update_trade_table_col update_trade_table_col_border'
                      span={4}
                    >
                      Quantity
                    </Col>

                    <Col className='update_trade_table_col' span={4}>
                      Trade Price
                    </Col>
                  </Row>
                  {data?.currentAdvisoryData?.length &&
                    data?.currentAdvisoryData?.map((item, key) => {
                      const { Script, Trade, Quantity, TradePrice } = item
                      return (
                        <Row
                          className={
                            data?.currentAdvisoryData?.length - 1 === key
                              ? 'update_trade_table_row_new'
                              : 'update_trade_table_row'
                          }
                          gutter={16}
                        >
                          <Col
                            className='update_trade_table_script update_trade_table_col_script'
                            span={12}
                          >
                            {Script}
                          </Col>
                          <Col className='update_trade_table_col' span={4}>
                            {Trade}
                          </Col>
                          <Col
                            className='update_trade_table_col update_trade_table_col_border'
                            span={4}
                          >
                            {Quantity}
                          </Col>
                          <Col className='update_trade_table_col' span={4}>
                            {TradePrice}
                          </Col>
                        </Row>
                      )
                    })}
                </div>
              </div>
            </Panel>
            <Panel header='Open Position after advisory Execution' key='3'>
              <div className='update_trade_table'>
                <Row
                  className='update_trade_table_row'
                  gutter={16}
                  style={{ fontWeight: 500 }}
                >
                  <Col
                    className='update_trade_table_script'
                    span={18}
                    style={{ borderRight: '1px solid #000' }}
                  >
                    Script
                  </Col>
                  <Col className='update_trade_table_col ' span={6}>
                    Total Quantity
                  </Col>
                </Row>
                {data?.afterExe?.length &&
                  data?.afterExe?.map((item, key) => {
                    const { Script, Quantity } = item
                    return (
                      <Row
                        className={
                          data?.afterExe?.length - 1 === key
                            ? 'update_trade_table_row_new'
                            : 'update_trade_table_row'
                        }
                        gutter={16}
                      >
                        <Col
                          className='update_trade_table_script'
                          span={18}
                          style={{ borderRight: '1px solid #000' }}
                        >
                          {Script}
                        </Col>
                        <Col className='update_trade_table_col' span={6}>
                          {Quantity}
                        </Col>
                      </Row>
                    )
                  })}
              </div>
            </Panel>
          </Collapse>
          <Row gutter={16} className='column_for_advisory_table'>
            <Col span={18}>
              <Button
                className='pending_advisory_accept_btn'
                onClick={onFinish}
                loading={loading}
              >
                Accept
              </Button>
              <Button type='primary' loading={rejectLoading} onClick={onReject}>
                Reject
              </Button>
            </Col>
            <Col span={6}>
              <div className='question_icon'>
                <div className='faqs-icon'>
                  <Link
                    to='/faqs'
                    className='nav-text'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <QuestionOutlined className='que_mark' />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
export default ScenarioPendingAdvisoryIndex
