import React from 'react'
import omit from 'lodash/omit'
import { Button, Form, Input } from 'antd'

import { RESET_PASSWORD } from './graphql/Mutations'
import openNotification from 'utils/Notification'
import Logo from 'assets/images/logo.png'
import client from 'apollo'
import './index.css'

const FormItem = Form.Item

export default function (props) {
  const { history, match: { params: { token } } } = props

  function onFinish(values) {
    let data = omit(values, ['confirm'])
    data.token = token
    client.mutate({ mutation: RESET_PASSWORD, variables: { data } })
      .then(() => {
        openNotification('success', 'Your password has been successfully reset')
        history.push('/login')
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className='gx-login-container reset-password-wrapper'>
      <div className='gx-login-content'>
        <div className='gx-login-header'>
          <img alt='Finideas' src={Logo} />
        </div>
        <div className='gx-mb-4'>
          <h2>Reset Password</h2>
          <p>Enter a new password for your account</p>
        </div>
        <Form
          initialValues={{ remember: true }}
          name='basic'
          onFinish={onFinish}
          className='gx-signin-form gx-form-row0'>
          <FormItem name='newPassword' rules={[{ required: true, message: 'Please input your password between 8 to 16 characters!', min: 8, max: 16 }]}>
            <Input className='gx-input-lineheight' type='password' placeholder='Password' />
          </FormItem>
          <FormItem
            name='confirm'
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('The two passwords that you entered do not match!')
                },
              }),
            ]}
          >
            <Input className='gx-input-lineheight' placeholder='Confirm New Password' type='password' />
          </FormItem>
          <FormItem>
            <Button type='primary' htmlType='submit'>Reset</Button>
          </FormItem>
        </Form>
      </div>
    </div>
  )
}
