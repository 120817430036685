import gql from 'graphql-tag'

export const CREATE_USERS_PDF = gql`
  mutation createUsersPdf($data: UserPdfInput!) {
    createUsersPdf(data: $data)
  }
`
export const DELETE_USERS_PDF = gql`
  mutation deleteUsersPdf($where: UsersPdfUniqueInput!) {
    deleteUsersPdf(where: $where)
  }
`
