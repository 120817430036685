import React from 'react'
import { Table } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_DIGIO_AUTO_DEBIT_SCHEDULE_REQUESTS } from './graphql/Queries'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import moment from 'moment'

const DigioAutoRequest = ({ id }) => {
  const { data, loading, error } = useQuery(
    GET_ALL_DIGIO_AUTO_DEBIT_SCHEDULE_REQUESTS,
    { variables: { where: { userId: id } } }
  )

  if (loading) return <Loader />
  if (error) return <Page404 />

  const columns = [
    {
      title: 'Request Id',
      dataIndex: 'requestId',
      render: (text, record) =>
        record?.res?.id ? record?.res?.id : record?.res?.details,
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      render: (text, record) => record?.req?.customer_name,
    },
    {
      title: 'UMRN Number',
      dataIndex: 'umrnNumber',
      render: (text, record) => record?.req?.umrn || '-',
    },
    {
      title: 'Schedule Date',
      dataIndex: 'scheduleDate',
      render: (text, record) =>
        record?.req?.settlement_date
          ? moment(record?.req?.settlement_date).format('DD-MMM-YYYY')
          : '-',
    },
    {
      title: 'Settlement Amount',
      dataIndex: 'settlementAmount',
      render: (text, record) =>
        record?.req?.amount ? record?.req?.amount : '-',
    },
    {
      title: 'Request Status',
      dataIndex: 'status',
      render: (text, record) =>
        record?.res?.id ? record?.res?.status : record?.res?.code,
    },
  ]
  return (
    <>
      <Table
        dataSource={
          data?.getDigioAutoScheduleRequestData
            ? data?.getDigioAutoScheduleRequestData
            : []
        }
        loading={loading}
        columns={columns}
      />
    </>
  )
}
export default DigioAutoRequest
