import { AppContext } from 'AppContext'
import React, { useContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_BOOKING_ADVISORY } from '../graphql/Queries'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { QuestionOutlined } from '@ant-design/icons'

import './BookAdvisory.css'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import { Button, DatePicker, Modal, Tooltip } from 'antd'
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons'
import { uniq } from 'lodash'
import client from 'apollo'
import { UPDATE_BOOK_ADVISORY } from '../graphql/Mutations'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import AutoRefresh from 'utils/AutoRefresh'

const BookedAdvisorySlot = ({ mandateId, amcAmount, dateData, userData }) => {
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext)

  const [deleteModal, setDeleteModal] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [isChangeDate, setIsChangeDate] = useState(false)
  const [date, setDate] = useState()
  const [totalAmount, setTotalAmount] = useState(amcAmount)
  const { data, loading, error } = useQuery(GET_BOOKING_ADVISORY, {
    variables: {
      where: {
        userId: parseInt(currentUser?.id),
        isClientLogin: false,
        isClientAcceptAdvisory: false,
        isAdvisoryBooked: true,
        isCancelAdvisory: false,
        advisoryType: 'New',
      },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data?.getBookAdvisory) {
      setDate(moment(data?.getBookAdvisory?.advisoryDate))

      if (data?.getBookAdvisory?.clientId?.eMandateAmcAmount) {
        setTotalAmount(data?.getBookAdvisory?.clientId?.eMandateAmcAmount)
      } else {
        if (currentUser?.stateId === 4030) {
          const cGst = data?.getBookAdvisory?.amcAmount * (9 / 100)
          const totalGst = Math.round(cGst) * 2
          setTotalAmount(data?.getBookAdvisory?.amcAmount + totalGst)
        } else {
          const totalGst = data?.getBookAdvisory?.amcAmount * (18 / 100)
          setTotalAmount(
            data?.getBookAdvisory?.amcAmount + Math.round(totalGst)
          )
        }
      }
    }
  }, [currentUser.stateId, data])
  let array = []
  const disabledDate = (current) => {
    const isWeekend = current.day() === 0 || current.day() === 6
    const holiday = dateData.some((date) => moment(date).isSame(current, 'day'))
    const isToday = current.isSame(moment(), 'day')
    const isSelected = current.isSame(
      moment(data?.getBookAdvisory?.advisoryDate),
      'day'
    )

    if (moment(current) >= moment() && array.length < 4) {
      if (!isWeekend && !holiday && !isToday && !isSelected) {
        array.push(moment(current).format('DD/MM'))
        array = uniq(array)
      }
    }

    return !array?.includes(moment(current).format('DD/MM'))
  }

  const handleDateChange = (e) => {
    setDate(moment(e))
  }

  const onFinish = (isDeleted) => {
    setBtnLoading(true)
    let updatedData = { userId: currentUser?.id }
    if (isDeleted) {
      updatedData = {
        ...updatedData,
        isCancelAdvisory: true,
        advisoryCancelBy: currentUser?.id,
      }
    } else {
      updatedData = { ...updatedData, advisoryDate: moment(date).format() }
    }

    client
      .mutate({
        mutation: UPDATE_BOOK_ADVISORY,
        variables: {
          data: { ...updatedData },
          where: { id: parseInt(data?.getBookAdvisory?.id) },
        },
      })
      .then((res) => {
        if (isDeleted) {
          let updatedData = { isBookingAdvisoryDateSelected: false }
          if (currentUser?.isNewAuaAdvisoryGenerate) {
            updatedData = {
              ...updatedData,
              newAuaAdvisoryCompletedSteps: 'ADVISORY_FEES',
            }
          } else {
            updatedData = { ...updatedData, completedSteps: 'E_MANDATE' }
          }
          client
            .mutate({
              mutation: UPDATE_USER,
              variables: {
                data: updatedData,
                id: currentUser?.id,
              },
            })
            .then((response) => {
              dispatch({
                type: 'CURRENT_USER',
                data: response?.data?.updateUser,
              })
              setBtnLoading(false)
              setDeleteModal(false)
              openNotification(
                'success',
                'Advisory slot cancelled successfully'
              )
            })
            .catch((err) => {
              console.log(err)
              setBtnLoading(false)
            })
        } else {
          setBtnLoading(false)
          setIsChangeDate(false)
          openNotification('success', 'Advisory slot updated successfully')
        }
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
      })
  }

  if (loading) return <Loader />
  if (error) return <Page404 />

  return (
    <>
      <div>
        <b className='book_advisory_slot_heading'>Your Booked Advisory Slot</b>
        <div className='book_advisory_slot_main_div'>
          <div className='booking_advisory_slot_row'>
            <div className='booking_advisory_slot_row_1'>
              <div className='booking_slot_client_code_div'>
                <b className='book_advisory_slot_sub_heading'>Client Code</b>
              </div>
              <div className='booking_slot_client_name'>
                <b className='book_advisory_slot_sub_heading'>Client Name</b>
              </div>
              <div className='booking_slot_advisory_date_div'>
                <b className='book_advisory_slot_sub_heading'>Advisory Date</b>
              </div>
              <div className='booking_slot_advisory_edit_div'>
                <b className='book_advisory_slot_sub_heading'>Edit</b>
              </div>
              <div className='booking_slot_advisory_delete_div'>
                <b className='book_advisory_slot_sub_heading'>Delete</b>
              </div>
            </div>
          </div>
          <div>
            <div className='booking_advisory_slot_row_1'>
              <div className='booking_slot_client_code_div'>
                <b className='book_advisory_slot_sub_heading_value'>
                  {currentUser?.clientCode}
                </b>
              </div>
              <div className='booking_slot_client_name'>
                <b className='book_advisory_slot_sub_heading_value'>
                  {currentUser?.firstName + ' ' + currentUser?.lastName}
                </b>
              </div>
              <div className='booking_slot_advisory_date_div'>
                <b className='book_advisory_slot_sub_heading_value'>
                  {moment(data?.getBookAdvisory?.advisoryDate).format(
                    'DD-MM-YYYY'
                  )}
                </b>
              </div>
              <div className='booking_slot_advisory_edit_div'>
                <Tooltip title='Edit Book Advisory Slot'>
                  <EditTwoTone
                    onClick={() => setIsChangeDate(true)}
                    className='book_advisory_slot_sub_heading_value'
                  />
                </Tooltip>
              </div>
              <div className='booking_slot_advisory_delete_div'>
                <Tooltip title='Cancel Book Advisory Slot'>
                  <DeleteOutlined
                    onClick={() => setDeleteModal(true)}
                    className='book_advisory_slot_sub_heading_value'
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        <div className='booking_advisory_slot_sub_heading'>
          <div className='booking_advisory_slot_sub_heading_div'>
            {(mandateId || currentUser?.umrnNumber) && (
              <div className='modal_note_text'>
                <p>
                  Note:- Kindly note your Finideas Advisory Fees Rs.
                  {Math.round(totalAmount)}/- will be debited within 48 hours
                  through the mandate advisory time.
                </p>
              </div>
            )}
            <p className='booking_slot_note_text'>
              Kindly login to the back office on your selected Advisory slot day
            </p>
            <p className='booking_slot_thanks_text'>!!! Thank You !!!</p>
          </div>

          <div>
            <div className='faqs-icon'>
              <Link
                to='/faqs'
                className='nav-text'
                target='_blank'
                rel='noopener noreferrer'
              >
                <QuestionOutlined className='font_icon' />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {!loading && deleteModal && (
        <Modal
          className='book_advisory_modal'
          visible={deleteModal}
          footer={null}
        >
          <div>
            <b className='modal_text'>Do you want cancel advisory slot?</b>
          </div>
          <div className='book_advisory_btn'>
            <Button
              type='primary'
              onClick={() => onFinish(true)}
              loading={btnLoading}
            >
              Yes
            </Button>
            <Button onClick={() => setDeleteModal(false)} disabled={btnLoading}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}

      {!loading && isChangeDate && (
        <Modal
          className='book_advisory_modal'
          visible={isChangeDate}
          footer={null}
        >
          <div className='edit_advisory_date_div'>
            <div>
              Would you like to change your book advisory slot? <br />
              Kindly select the slot for advisory
              <DatePicker
                className='advisory_select_date'
                style={{
                  borderRadius: '8px',
                  marginLeft: '20px',
                  marginTop: '5px',
                }}
                disabledDate={disabledDate}
                onChange={handleDateChange}
                format={'DD-MM-YYYY'}
                value={date}
                allowClear={false}
                dropdownClassName='custom-dropdown-class'
              />
            </div>
          </div>
          <div className='book_advisory_btn'>
            <Button
              type='primary'
              loading={btnLoading}
              onClick={() => onFinish(false)}
            >
              Confirm
            </Button>
            <Button
              onClick={() => setIsChangeDate(false)}
              disabled={btnLoading}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      )}

      <AutoRefresh />
    </>
  )
}
export default BookedAdvisorySlot
