import gql from 'graphql-tag'

export const GET_ALL_BOOKED_ADVISORY = gql`
  query getAllBookAdvisory(
    $where: whereBookAdvisoryGet
    $limit: Int
    $skip: Int
  ) {
    getAllBookAdvisory(where: $where, limit: $limit, skip: $skip) {
      bookingAdvisory {
        id
        userId
        advisoryDate
        isAdvisoryBooked
        isClientLogin
        isClientAcceptAdvisory
        isTermAndServiceAccepted
        advisorySendType
        advisoryType
        isPendingAdvisoryAccepted
        advisorySendDateAndTime
        advisoryAcceptDateAndTime
        createdAt
        paymentType
        amcAmount
        updatedAt
        isCancelAdvisory
        clientId {
          email
          firstName
          gender
          id
          userName
          lastName
          clientCode
        }
        advisorySubmittedUser {
          firstName
          lastName
          role
          email
          id
        }
        advisoryCancelByUser {
          firstName
          lastName
          role
          email
          id
        }
        advisoryUpdateByUser {
          firstName
          lastName
          role
          email
          id
        }
      }
      count
    }
  }
`
