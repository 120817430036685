import React, { useContext } from 'react'
import { Layout } from 'antd'

import Menu from './Menu'
import { version } from '../../package.json'
import Favicon from 'assets/images/favicon.png'
import LogoWhite from 'assets/images/finideas-logo-white-small-new.png'
import { AppContext } from 'AppContext'

const { Sider } = Layout

export default function ({ pathname, collapsed }) {
  const {
    state: { isClient, isVerificationDone, currentUser },
  } = useContext(AppContext)
  const logoSrc = collapsed ? Favicon : LogoWhite

  return (
    <Sider
      className={
        !isClient || (isVerificationDone && !currentUser?.isDueDateAmcPending)
          ? 'gx-app-sidebar gx-layout-sider-dark'
          : currentUser?.isNewAuaAdvisoryGenerate
          ? 'gx-app-sidebar gx-layout-sider-dark'
          : ' gx-app-sidebar gx-layout-sider-dark verification-sidebar'
      }
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className='logo'>
        <a
          href='https://www.finideas.com'
          target='_blank'
          className='nav-text'
          rel='noopener noreferrer'
        >
          <img alt='Finideas' src={logoSrc} />
        </a>
      </div>
      <div className='menu-wrapper'>
        <Menu pathname={pathname} />
      </div>
      <p className='version-print'>V {version}</p>
    </Sider>
  )
}
