import React from 'react'
import { Descriptions } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_MANDATE } from 'modules/Verification/graphql/Queries'
import Loader from 'components/loaders/Loader'
import { getName } from 'utils/User'
import moment from 'moment'

export default function UserEMandate({ userId }) {
  const { data, loading, error } = useQuery(GET_MANDATE, { variables: { where: { userId } }, fetchPolicy: 'network-only' })

  if (loading) return <Loader />
  if (error) return <p>Mandate Not Found!</p>
  const { getMandate = {} } = data
  const { user, user: { email, accountType, accountNumber, bank, phone, mandateId, exposure }, ifscCode, createdAt, mandateStatus, bankStatus } = getMandate
  return (
    <>
      {/* <p>E-mandate Report coming soon</p> */}
      <Descriptions bordered>
        <Descriptions.Item label='Mandate ID' span={3}>{mandateId}</Descriptions.Item>
        <Descriptions.Item label='Customer Name' span={3}>{getName(user)}</Descriptions.Item>
        <Descriptions.Item label='Mobile No' span={3}>{phone}</Descriptions.Item>
        <Descriptions.Item label='Email' span={3}>{email}</Descriptions.Item>
        <Descriptions.Item label='Account Type' span={3}>{accountType}</Descriptions.Item>
        <Descriptions.Item label='Account No' span={3}>{accountNumber}</Descriptions.Item>
        <Descriptions.Item label='Bank' span={3}>{bank}</Descriptions.Item>
        <Descriptions.Item label='IFSC Code' span={3}>{ifscCode}</Descriptions.Item>
        <Descriptions.Item label='exposure' span={3}>{exposure || 0}</Descriptions.Item>
        <Descriptions.Item label='Start Date(YYYY-MM-DD)' span={3}>{moment(createdAt).format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label='Mandate Status' span={3}>{mandateStatus}</Descriptions.Item>
        <Descriptions.Item label='Bank Status' span={3}>{bankStatus}</Descriptions.Item>
      </Descriptions>
    </>
  )
}

