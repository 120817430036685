import { useQuery } from '@apollo/react-hooks'
import React, { useContext, useState } from 'react'
import { GET_CLIENT_PROFILE2_PDF_LINK } from '../graphql/Queries'
import { Button, Checkbox, Modal } from 'antd'
import client from 'apollo'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
} from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import { AppContext } from 'AppContext'
import {
  COMPLETE_STEP,
  DISCLAIMER_MAIL,
  FETCH_CVL_STATUS,
} from '../graphql/Mutations'
import VerificationModal from './VerificationModal'
import { Document, Page, pdfjs } from 'react-pdf'

const ClientProfile2Non = ({ visible, onCancel, next, reEvaluate }) => {
  const [loadings, setLoadings] = useState(false)
  const [otpModal, setOtpModal] = useState(false)
  const [modalClose, setModalClose] = useState(true)
  const [agree, setAgree] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const { dispatch, state } = useContext(AppContext)
  const { id } = state.currentUser

  const { data, loading } = useQuery(GET_CLIENT_PROFILE2_PDF_LINK, {
    fetchPolicy: 'network-only',
  })

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  const onNext = () => {
    setLoadings(true)
    const data = {
      completedSteps: 'CLIENT_PROFILE_2',
    }

    client
      .mutate({ mutation: FETCH_CVL_STATUS })
      .then((res) => {
        client
          .mutate({ mutation: UPDATE_USER, variables: { id, data } })
          .then((res) => {
            client.mutate({ mutation: DISCLAIMER_MAIL })
            client.mutate({
              mutation: COMPLETE_STEP,
              variables: {
                data: {
                  step: 'CLIENT_PROFILE_2',
                  from: 'WEB',
                },
              },
            })
            dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
            openNotification('success', 'Client Profile Updated Successfully')
            next()
          })
          .catch((err) => console.log(err))
          .finally(() => setLoadings(false))
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadings(false))
  }

  const onOk = () => {
    setLoadings(true)
    if (reEvaluate) {
      setOtpModal(true)
      client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
        openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
        setLoadings(false)
      })
      setModalClose(false)
    } else {
      onNext()
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  return (
    <div>
      {modalClose ? (
        <Modal
          loading={loading}
          maskClosable={false}
          visible={visible}
          width={800}
          title={'Verify Your Details'}
          footer={
            <>
              <Button onClick={() => onCancel()}> Cancel </Button>
              <Button
                onClick={() => onOk()}
                type='primary'
                disabled={!agree}
                loading={loadings}
              >
                {' '}
                Next{' '}
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          <div className='pdfViewer'>
            <div className='pdf-viewer-button'>
              <div>
                <Button
                  className='buttons-viewer'
                  disabled={pageNumber === 1 && true}
                  onClick={() => setPageNumber(pageNumber - 1)}
                >
                  &lt;
                </Button>
                <Button
                  disabled={numPages === pageNumber && true}
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  &gt;
                </Button>
              </div>
              <span className='pdf-viewer-page-text'>
                Page {pageNumber} of {numPages}
              </span>
            </div>
            <Document
              onLoadSuccess={onDocumentLoadSuccess}
              file={data?.getClientProfile2PdfLink}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} />{' '}
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.{' '}
            </p>{' '}
          </div>
        </Modal>
      ) : (
        <Modal
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          width={800}
          footer={
            <>
              <Button onClick={() => onCancel()}> Cancel </Button>
              <Button
                onClick={() => onOk()}
                type='primary'
                disabled={!agree}
                loading={loadings}
              >
                {' '}
                Next{' '}
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          <div className='pdfViewer'>
            <Document
              onLoadSuccess={onDocumentLoadSuccess}
              file={data?.getClientProfile2PdfLink}
            >
              <Page pageNumber={1} />
            </Document>
          </div>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
        </Modal>
      )}
      {otpModal && <VerificationModal visible={otpModal} onNext={onNext} />}
    </div>
  )
}

export default ClientProfile2Non
