import React from 'react'
import { Table } from 'antd'

export default function ({ reportData, ClientCode, firstName, lastName }) {
  const columns = [
    {
      title: 'Average Index Report',
      className: 'report-title main-title',
      children: [
        {
          title: (
            <div className='hidden-client-data'>
              Client Code:{' '}
              <span className='hidden-client-code'>{ClientCode}</span>{' '}
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Entry Date',
              dataIndex: 'entrydate',
              key: 'entrydate',
              className: 'report-text average-index-column-align',
            },
            {
              title: 'Type',
              dataIndex: 'Type',
              key: 'Type',
              className: 'report-numeric interest-column-align',
              render: (text) => (text ? text : '-'),
            },
          ],
        },
        {
          title: (
            <div className='hidden-client-data'>
              Client Name:{' '}
              <span className='hidden-client-code'>
                {firstName + ' ' + lastName}
              </span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Index Level',
              dataIndex: 'ClosingIndexValue',
              key: 'ClosingIndexValue',
              className: 'report-numeric interest-column-align',
              render: (text) => (text ? text : '-'),
            },
            {
              title: 'Quantity',
              dataIndex: 'Qty',
              key: 'Qty',
              className: 'report-numeric interest-column-align',
              render: (text) => (text ? text : '-'),
            },
            {
              title: 'Value',
              dataIndex: 'Value',
              key: 'Value',
              className: 'report-numeric interest-column-align',
              render: (text) => (text ? text : '-'),
            },
          ],
        },
      ],
    },
  ]
  const data = reportData.map((report, key) => {
    const round = {
      ClosingIndexValue: report.ClosingIndexValue,
      Qty: report.Qty,
      Value: report.Value,
    }
    return { key, ...report, ...round }
  })

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      className='average-index-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}
