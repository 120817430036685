import React, { Fragment, useState, useContext } from 'react'
import { Table, Tag, Tooltip, Button, Switch, Input, Space } from 'antd'
import { EditTwoTone, SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'

import client from 'apollo'
import history from 'CustomHistory'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import openNotification from 'utils/Notification'
import { UPDATE_USER } from '../graphql/Mutations'
import { GET_CLIENT_USERS, GET_SYSTEM_USERS } from '../graphql/Queries'
import { getFirstName, getLastName, userRoles } from 'utils/User'

export default function ({ userId }) {
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const { state } = useContext(AppContext)
  const { currentReport } = state
  const [filters, setFilters] = useState({
    createdById: userId,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const userFilters = userRoles[state.currentUser.role]
  const [switchLoading, setSwitchLoading] = useState('')
  const role = ['CLIENT']

  const { data, loading, error } = useQuery(GET_SYSTEM_USERS, {
    variables: { where: { ...filters, role }, limit, skip, sort },
    fetchPolicy: 'network-only',
  })
  if (error) return <Page404 error={error} />

  let totalCount = 0
  if (!loading && get(data, 'getSystemUsers.users')) {
    totalCount = data?.getSystemUsers?.count
    tableData = data?.getSystemUsers?.users?.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
  }

  function handleSwitch(record) {
    setSwitchLoading(record.id)
    const queryVariables = {
      id: record.id,
      data: { isActive: !record.isActive },
    }
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: queryVariables,
        refetchQueries: [
          {
            query: GET_CLIENT_USERS,
            variables: { where: filters, limit, skip, sort },
          },
        ],
      })
      .then((res) => openNotification('success', 'User Updated Successfully'))
      .catch((err) => console.log(err))
      .finally(() => setSwitchLoading(''))
  }

  function renderAction(record) {
    const { id } = record
    return (
      <div className='action-icons'>
        <Tooltip title='Edit'>
          {currentReport.includes('Update Client') && (
            <EditTwoTone onClick={() => history.push(`/clients/${id}/edit`)} />
          )}
        </Tooltip>
        {currentReport.includes('Disable Client') && (
          <Switch
            size='small'
            checked={record.isActive}
            loading={switchLoading === id}
            disabled={state.currentUser.id === record.id}
            onClick={() => handleSwitch(record)}
            defaultChecked={record.isActive}
          />
        )}
      </div>
    )
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.role) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = filter.role
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = ''
      delete tempFilters.role
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => (
        <NavLink to={`/clients/${record.id}`}>{getFirstName(record)}</NavLink>
      ),
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, record) => (
        <NavLink to={`/clients/${record.id}`}>{getLastName(record)}</NavLink>
      ),
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      ...getColumnSearchProps('userName'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <a href={`mailto:${text}`}>{text}</a>,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => <a href={`tel:${text}`}>{text}</a>,
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (role) => <Tag className={role}>{role}</Tag>,
      filters: userFilters,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
    },
  ]

  return (
    <Fragment>
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        pagination={{
          pageSize: limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </Fragment>
  )
}
