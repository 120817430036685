import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import client from 'apollo'
import { get, uniqBy } from 'lodash'
import { SEND_DYNAMIC_EMAIL } from 'modules/Settings/graphql/Mutations'
import { USER_PUBLIC } from 'modules/Users/graphql/Queries'
import ReactQuill from 'react-quill'
import openNotification from 'utils/Notification'

const FormItem = Form.Item

const NotifyMail = ({ setModalForNotification, userDataForMail }) => {
  const [toMailOnChange, setToMailOnChange] = useState([userDataForMail.email])
  const [ccMail, setCcMail] = useState([])
  const [mailTemplate, setMailTemplate] = useState('')
  const [subjectForMail, setSubjectForMail] = useState('')

  const { data: userData, loading: userLoading } = useQuery(USER_PUBLIC, {
    fetchPolicy: 'network-only',
  })

  let userEmails = []

  if (!userLoading && get(userData, 'getUsersPublic.users')) {
    userData.getUsersPublic.users.forEach((item, key) => {
      userEmails.push({
        key,
        label: item?.email,
        value: item?.email,
      })
    })
  }

  const handleToMailChange = (e) => {
    setToMailOnChange(e)
  }

  const handleEditInputChange = (e) => {
    const array = e.map((email) => {
      return {
        email,
      }
    })

    setCcMail(array)
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],

      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],

      ['clean'],
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  const onFinish = (values) => {
    let data = {
      subject: values.subject,
      toMailId: toMailOnChange,
      ccMailIds: ccMail,
      mailBody: mailTemplate,
    }
    console.log(data)
    client
      .mutate({
        mutation: SEND_DYNAMIC_EMAIL,
        variables: { data },
      })
      .then((res) => {
        openNotification('success', 'Mail Send Successfully')
        setToMailOnChange([])
        setCcMail([])
        setMailTemplate('')
        setSubjectForMail('')
        setModalForNotification(false)
      })
      .catch((err) => openNotification('error', 'Something went wrong'))
  }

  return (
    <div>
      <>
        <Form onFinish={onFinish}>
          <Row>
            <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name='subject' label='Subject'>
                <Input
                  placeholder='Enter Subject'
                  onChange={(e) => setSubjectForMail(e.target.value)}
                  value={subjectForMail}
                />
              </Form.Item>
            </Col>
            <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
              <FormItem label='To Mail'>
                <Select
                  style={{ width: '100%' }}
                  mode='tags'
                  allowClear
                  placeholder='Please select'
                  defaultValue={[]}
                  onChange={(e) => handleToMailChange(e)}
                  options={uniqBy(userEmails, 'value')}
                  value={toMailOnChange}
                />
              </FormItem>
            </Col>
            <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label='CC Mail'>
                <Select
                  mode='tags'
                  allowClear
                  style={{ width: '100%' }}
                  defaultValue={[]}
                  placeholder='Please select'
                  onChange={(e) => handleEditInputChange(e)}
                  options={uniqBy(userEmails, 'value')}
                />
              </Form.Item>
            </Col>
          </Row>
          <ReactQuill
            theme='snow'
            onChange={(value) => {
              setMailTemplate(value)
            }}
            value={mailTemplate}
            modules={modules}
            formats={formats}
          />
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 24,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginTop: '20px' }}
              >
                Send Mail
              </Button>
            </div>
          </Form.Item>
        </Form>
      </>
    </div>
  )
}

export default NotifyMail
