import React, { useContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { Modal, Row, Col } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  GET_BOOKING_ADVISORY,
  GET_NEW_PENDING_ADVISORY,
  GET_PENDING_BOOKED_ADVISORY,
} from './graphql/Queries'
import { AppContext } from 'AppContext'
import moment from 'moment'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import TermsCondition from './PendingAdvisory/TermsCondition'
import client from 'apollo'
import {
  SEND_PENDING_ADVISORY_TERMS_MAIL,
  UPDATE_BOOK_ADVISORY,
} from './graphql/Mutations'
import PendingAdvisoryIndex from './PendingAdvisory/PendingAdvisoryIndex'
import openNotification from 'utils/Notification'
import AdvisoryTradeUpdate from './PendingAdvisory/AdvisoryTradeUpdate'
import { get, sortBy } from 'lodash'
import { UPDATE_ADVISORY_STATUS } from 'modules/PendingAdvisory/graphql/Mutation'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'

const { confirm } = Modal

const PendingBookedAdvisory = ({ back }) => {
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext)

  const [btnLoading, setBtnLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)
  const [graphData, setGraphData] = useState([])
  const [graphData1, setGraphData1] = useState([])
  const [newData, setNewData] = useState({})
  const [newLoading, setNewLoading] = useState(false)
  const [newError, setNewError] = useState()

  useEffect(() => {
    getNewAdvisory()
  }, [])

  const getNewAdvisory = () => {
    setNewLoading(true)
    client
      .query({
        query: GET_NEW_PENDING_ADVISORY,
        variables: { where: { advisoryDate: '' } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setNewData(res?.data)
        setNewLoading(false)
      })
      .catch((err) => {
        setNewError(err)
        setNewLoading(false)
      })
  }

  let type = ''

  const {
    data: bookedAdvisory,
    loading: bookedAdvisoryLoading,
    error: bookedAdvisoryError,
  } = useQuery(GET_BOOKING_ADVISORY, {
    variables: {
      where: {
        userId: parseInt(currentUser?.id),
        isClientAcceptAdvisory: false,
        isCancelAdvisory: false,
        isClientLogin: true,
      },
    },
    fetchPolicy: 'network-only',
  })

  const { data, loading, error } = useQuery(GET_PENDING_BOOKED_ADVISORY, {
    variables: {
      where: {
        exposure: currentUser?.initialExposure,
        planId: parseInt(currentUser?.plan?.id),
      },
    },
  })

  if (!newLoading && get(newData, 'getNewBookingAdvisory')) {
    type = newData?.getNewBookingAdvisory?.type
  }

  useEffect(() => {
    const data = []
    const mapData2 = []
    if (newData?.getNewBookingAdvisory && !newLoading) {
      const red =
        newData?.getNewBookingAdvisory?.mapScenario1 &&
        newData?.getNewBookingAdvisory?.mapScenario1[1] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario1[1])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario1[1][key]
          )

      const green =
        newData?.getNewBookingAdvisory?.mapScenario1 &&
        newData?.getNewBookingAdvisory?.mapScenario1[2] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario1[2])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario1[2][key]
          )

      const nifty =
        newData?.getNewBookingAdvisory?.mapScenario1 &&
        newData?.getNewBookingAdvisory?.mapScenario1[0] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario1[0])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario1[0][key]
          )

      for (let i = 0; i < red?.length; i++) {
        let d = {
          nifty: nifty[i],
          pl: red[i],
          nlv: green[i],
        }

        data.push(d)
      }
      setGraphData(sortBy(data, 'nifty'))

      const red1 =
        newData?.getNewBookingAdvisory?.mapScenario2[1] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario2[1])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario2[1][key]
          )

      const green1 =
        newData?.getNewBookingAdvisory?.mapScenario2[2] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario2[2])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario2[2][key]
          )

      const nifty1 =
        newData?.getNewBookingAdvisory?.mapScenario2[0] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario2[0])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario2[0][key]
          )

      for (let i = 0; i < red1?.length; i++) {
        let d = {
          nifty: nifty1[i],
          pl: red1[i],
          nlv: green1[i],
        }

        mapData2.push(d)
      }
      setGraphData1(sortBy(mapData2, 'nifty'))
    }
  }, [newLoading, newData])

  if (loading || bookedAdvisoryLoading || newLoading) return <Loader />
  if (error || bookedAdvisoryError || newError) return <Page404 />

  const sendTermsMail = () => {
    setBtnLoading(true)
    client
      .mutate({
        mutation: SEND_PENDING_ADVISORY_TERMS_MAIL,
        variables: {
          data: {
            advisoryId: parseInt(bookedAdvisory?.getBookAdvisory?.id),
            productRecommended:
              data?.getPendingBookingAdvisory?.productRecommended,
            productExposure: parseFloat(
              data?.getPendingBookingAdvisory?.productExposure
            ),
            productInitialInvestment: parseFloat(
              data?.getPendingBookingAdvisory?.productInitialInvestment
            ),
            productSIP: parseFloat(data?.getPendingBookingAdvisory?.productSIP),
            productHedging: parseFloat(
              data?.getPendingBookingAdvisory?.productHedging
            ),
            productInterest: parseFloat(
              data?.getPendingBookingAdvisory?.productInterest
            ),
            productAnnual: parseFloat(
              data?.getPendingBookingAdvisory?.productAnnual
            ),
            productAMC: parseFloat(data?.getPendingBookingAdvisory?.productAMC),
            productStock: parseFloat(
              data?.getPendingBookingAdvisory?.productStock
            ),
            saveInterest: parseFloat(
              data?.getPendingBookingAdvisory?.saveInterest
            ),
            grossTotal: parseFloat(data?.getPendingBookingAdvisory?.grossTotal),
            name: currentUser?.firstName + ' ' + currentUser?.lastName,
            email: currentUser?.email,
          },
        },
      })
      .then((res) => {
        client
          .query({
            query: GET_BOOKING_ADVISORY,
            variables: {
              where: {
                userId: parseInt(currentUser?.id),
                id: parseInt(bookedAdvisory?.getBookAdvisory?.id),
              },
            },
            fetchPolicy: 'network-only',
          })
          .then((response) => {
            setBtnLoading(false)
            openNotification(
              'success',
              res?.data?.sendTermsAndServiceMail?.message
            )
          })
          .catch((err) => {
            console.log(err)
            setBtnLoading(false)
          })
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
      })
  }

  const updatePendingAdvisory = () => {
    setBtnLoading(true)
    client
      .mutate({
        mutation: UPDATE_BOOK_ADVISORY,
        variables: {
          data: { isPendingAdvisoryAccepted: true, userId: currentUser?.id },
          where: { id: parseInt(bookedAdvisory?.getBookAdvisory?.id) },
        },
        refetchQueries: [
          {
            query: GET_BOOKING_ADVISORY,
            variables: {
              where: {
                userId: parseInt(currentUser?.id),
                id: parseInt(bookedAdvisory?.getBookAdvisory?.id),
                isClientLogin: true,
              },
            },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        console.log(res?.data)
        setBtnLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
      })
  }

  const onReject = () => {
    confirm({
      title: 'Do You Want To Discard The Received Advisory?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        setRejectLoading(true)
        const statusData =
          newData?.getNewBookingAdvisory?.currentAdvisoryData.map((d) => {
            return {
              advisorId: parseInt(d.AdvisorID),
              clientCode: d.ClientCode,
              status: 'Reject',
            }
          })
        client
          .mutate({
            mutation: UPDATE_ADVISORY_STATUS,
            variables: { data: statusData },
          })
          .then((res) => {
            client.mutate({
              mutation: UPDATE_BOOK_ADVISORY,
              variables: {
                data: {
                  isPendingAdvisoryAccepted: true,
                  isCancelAdvisory: true,
                  userId: currentUser?.id,
                  advisoryCancelBy: currentUser?.id,
                },
                where: { id: parseInt(bookedAdvisory?.getBookAdvisory?.id) },
              },
            })
            let userData = {}
            if (currentUser?.isNewAuaAdvisoryGenerate) {
              userData.newAuaAdvisoryCompletedSteps = 'ADVISORY_FEES'
              userData.isNewAuaAdvisoryGenerate = false
              userData.isBookingAdvisoryDateSelected = false
            } else {
              userData.completedSteps = 'E_MANDATE'
              userData.isBookingAdvisoryDateSelected = false
            }
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: { data: { ...userData }, id: currentUser?.id },
              })
              .then((response) => {
                dispatch({
                  type: 'CURRENT_USER',
                  data: response.data.updateUser,
                })
                setRejectLoading(false)
                res?.data?.updateAdvisoryStatus === true
                  ? openNotification('info', 'Rejected Successfully')
                  : openNotification('error', 'Something went wrong')
                back()
              })
              .catch((err) => {
                console.log(err)
                setRejectLoading(false)
              })
          })
          .catch((err) => {
            console.log(err)
            setRejectLoading(false)
          })
      },
    })
  }

  const Submit = () => {
    if (bookedAdvisory?.getBookAdvisory?.isTermAndServiceAccepted) {
      updatePendingAdvisory()
    } else {
      sendTermsMail()
    }
  }

  return (
    <>
      <div className='pending_booking_advisory_page'>
        <div className='pending_advisory_logo_and_title'>
          <div className='pending_advisory_title_div'>
            <b className='pending_advisory_title'>Index Long Term Strategy</b>
            <p className='pending_advisory_sub_title'>
              {bookedAdvisory?.getBookAdvisory?.isTermAndServiceAccepted &&
              bookedAdvisory?.getBookAdvisory?.isPendingAdvisoryAccepted
                ? 'Advisory Trade Update'
                : bookedAdvisory?.getBookAdvisory?.isTermAndServiceAccepted
                ? 'Pending Advisory'
                : 'Terms & Service'}
            </p>
          </div>
          <div>
            <img
              className='fin_logo_div'
              src='https://finideas-india.blr1.digitaloceanspaces.com/Finideas-black-png.png'
              alt='Finideas'
            />
          </div>
        </div>

        {/* client data */}
        <Row gutter={16}>
          <Col lg={18} md={18} sm={24} xs={23}>
            <div className='pending_advisory_client_details_div_1'>
              <div className='clients_details'>
                <div>
                  <div className='client_div_for_advisory_title'>
                    <b>Client Code</b>
                  </div>
                  <div className='client_div_for_advisory_sub_title'>
                    <b>Client Name</b>
                  </div>
                </div>
              </div>
              <div className='client_details_value'>
                <div>
                  <div className='client_code_value'>
                    <span>{currentUser?.clientCode}</span>
                  </div>
                  <div className='client_name_value'>
                    <span>
                      {currentUser?.firstName + ' ' + currentUser?.lastName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={24} xs={22} className='new_column_for_type'>
            <div className='new_pending_advisory_type'>
              <div className='advisory_date_and_type'>
                <div className='advisory_date_div'>
                  <div className='advisory_date_text'>
                    <b>Advisory Type</b>
                  </div>
                  <div className='advisory_date_value'>
                    <span>{type}</span>
                  </div>
                </div>
              </div>

              <div className='date_and_time_div'>
                <div className='advisory_date_div'>
                  <div className='advisory_date_text'>
                    <b>Date & Time</b>
                  </div>
                  <div className='advisory_date_value'>
                    <span>
                      {moment(newData?.getNewBookingAdvisory?.createdAt)
                        .subtract(330, 'minutes')
                        .format('DD-MM-YYYY HH:mm:ss')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {bookedAdvisory?.getBookAdvisory?.isTermAndServiceAccepted &&
        bookedAdvisory?.getBookAdvisory?.isPendingAdvisoryAccepted &&
        !bookedAdvisory?.getBookAdvisory?.isCancelAdvisory ? (
          <AdvisoryTradeUpdate
            type={type}
            advisoryDate={bookedAdvisory?.getBookAdvisory?.advisoryDate}
            advisoryId={bookedAdvisory?.getBookAdvisory?.id}
          />
        ) : bookedAdvisory?.getBookAdvisory?.isTermAndServiceAccepted ? (
          <PendingAdvisoryIndex
            type={type}
            advisoryDate={bookedAdvisory?.getBookAdvisory?.advisoryDate}
            loading={btnLoading}
            rejectLoading={rejectLoading}
            onFinish={Submit}
            onReject={onReject}
            advisoryData={newData?.getNewBookingAdvisory}
            data={graphData}
            graphData1={graphData1}
            userData={currentUser}
          />
        ) : (
          <TermsCondition data={data} loading={btnLoading} onFinish={Submit} />
        )}
      </div>
    </>
  )
}
export default PendingBookedAdvisory
