import { Form, Button, Select, Input, Table, Checkbox } from 'antd'
import {
  GET_SP_AGREEMENT_DATA,
  GET_SP_AUTO_ADVISE,
  NON_INDIVIDUAL_AGREEMENT_MAIL_SEND,
} from './graphql/Queries'
import React, { useContext, useEffect, useState } from 'react'
import { get } from 'lodash'

import './verification.css'
import { GET_PLANS_BASED_POINTS } from 'modules/Questionnaires/graphql/Query'
import { COMPLETE_STEP, INVESTMENT_PLANNING_MAIL } from './graphql/Mutations'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import { useQuery } from '@apollo/react-hooks'
import CustomMeta from 'components/CustomMeta'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import client from 'apollo'
import { GET_MAX_EXPOSURE, GET_NIFTY } from 'modules/Reports/graphql/Queries'
import { GENERATE_NON_INDIVIDUAL_REPORT } from 'modules/Settings/graphql/Queries'
import { convertNumberToCommaSeparate } from 'utils/utilities'

const FormItem = Form.Item
const { Option } = Select

export default function ({ next }) {
  const [form] = Form.useForm()
  const { dispatch, state } = useContext(AppContext)
  const {
    id,
    annualIncome,
    clientScore,
    initialExposure,
    initialInvestment,
    entityStatus,
    isPanCardIndividual,
    isNewAuaAdvisoryGenerate,
  } = state.currentUser
  const { niftyLotSize } = state.generalSetting
  const [reviewPlanData, setReviewPlanData] = useState(undefined)
  const [agree, setAgree] = useState(true)
  const [loader, setLoader] = useState(false)
  const [planLoader, setPlanLoader] = useState(false)
  const [exposureValue, setExposureValue] = useState(initialExposure)
  const [errorMessage, setErrorMessage] = useState()

  const { loading, error, data } = useQuery(GET_PLANS_BASED_POINTS, {
    variables: { points: clientScore },
    fetchPolicy: 'network-only',
  })
  const { data: niftyData, loading: niftyLoading } = useQuery(GET_NIFTY, {
    fetchPolicy: 'network-only',
  })

  let { data: maxExposure, loading: exposureLoading } = useQuery(
    GET_MAX_EXPOSURE,
    {
      fetchPolicy: 'network-only',
    }
  )

  if (error) return <Page404 error={error} />
  let allPlans =
    !loading &&
    !niftyLoading &&
    !exposureLoading &&
    get(data, 'fetchPlansBasedOnPoints')
      ? get(data, 'fetchPlansBasedOnPoints')
      : []

  if (
    !exposureLoading &&
    !niftyLoading &&
    !loading &&
    state?.currentUser?.isNewAuaAdvisoryGenerate
  ) {
    maxExposure.getMaxExposure.maxExposure =
      parseInt(state?.currentUser?.auaMaxLimit) - state?.currentUser?.exposure
  }
  const onFinish = (values) => {
    values = {
      ...values,
      exposure: exposureValue,
    }
    setLoader(true)
    client
      .query({
        query: GET_SP_AUTO_ADVISE,
        variables: {
          where: {
            dontBuyEquity: 'NO',
            exposure: parseInt(values.exposure),
            planId: parseInt(values.plan),
            udInvestment: values.udInvestment,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        entityStatus &&
          client.query({
            query: GENERATE_NON_INDIVIDUAL_REPORT,
            variables: {
              where: {
                dontBuyEquity: 'YES',
              },
            },
            fetchPolicy: 'network-only',
          })
        setReviewPlanData(res?.data?.spAutoAdvise)
      })

      .finally(() => setLoader(false))
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
      md: {
        span: 12,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
      md: {
        span: 16,
      },
    },
  }

  const Columns = [
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      className: 'report-text',
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      className: 'report-numeric review_plan',
      width: 200,
      render: (record) =>
        record?.includes('Plan')
          ? record
          : convertNumberToCommaSeparate(+record, false),
    },
  ]

  const newColumns = [
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      className: 'report-text',
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      className: 'report-numeric review_plan',
      render: (record) => convertNumberToCommaSeparate(+record, false),
    },
    {
      title: 'Annualised %',
      dataIndex: 'Per',
      key: 'Per',
      className: 'report-numeric review_plan',
      render: (text) => parseFloat(text).toFixed(2),
    },
  ]

  const planMail = () => {
    setPlanLoader(true)
    form.validateFields().then((values) => {
      values = {
        ...values,
        exposure: exposureValue,
      }
      client
        .mutate({
          initialInvestment: values.udInvestment,
          mutation: INVESTMENT_PLANNING_MAIL,
          variables: {
            where: {
              planId: parseInt(values.plan),
              dontBuyEquity: 'YES',
              exposure: parseInt(values.exposure),
              udInvestment: parseInt(values.udInvestment),
            },
          },
        })
        .then((res) => {
          client
            .query({
              query: GET_SP_AGREEMENT_DATA,
              variables: {
                where: {
                  dontBuyEquity: 'YES',
                },
              },
            })
            .then(() => {
              !isPanCardIndividual &&
                client.query({ query: NON_INDIVIDUAL_AGREEMENT_MAIL_SEND })
              let updateUserData = {
                planId: parseInt(values.plan),
                initialInvestment: values.udInvestment,
              }
              if (isNewAuaAdvisoryGenerate) {
                updateUserData.newAuaAdvisoryCompletedSteps =
                  'INVESTMENT_PLANNING'
              } else {
                updateUserData.annualIncome =
                  maxExposure.getMaxExposure.maxExposure.toString()
                updateUserData.completedSteps = 'INVESTMENT_PLANNING'
              }
              client
                .mutate({
                  mutation: UPDATE_USER,
                  variables: {
                    id: id,
                    data: { ...updateUserData },
                  },
                })
                .then((res) => {
                  if (!isNewAuaAdvisoryGenerate) {
                    client.mutate({
                      mutation: COMPLETE_STEP,
                      variables: {
                        data: {
                          step: 'INVESTMENT_PLANNING',
                          from: 'WEB',
                        },
                      },
                    })
                  }
                  setPlanLoader(false)
                  dispatch({
                    type: 'CURRENT_USER',
                    data: {
                      ...res.data.updateUser,
                      investmentAmount: values.udInvestment,
                      exposure: parseInt(values.exposure),
                    },
                  })
                  openNotification(
                    'success',
                    'Investment Planning Updated Successfully and Mail Sent'
                  )
                  next()
                })
            })
        })
        .catch((err) => {
          setLoader(false)
          console.log(err)
        })
    })
  }

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  let style = { marginBottom: '15rem' }
  if (reviewPlanData) {
    style = {}
  }

  useEffect(() => {
    const tempAnnualIncome = annualIncome || 0
    let minimumExposure =
      50000 * Math.round((niftyData?.getNifty50[0]?.CMP * niftyLotSize) / 50000)

    if (exposureValue && tempAnnualIncome) {
      if (maxExposure?.getMaxExposure?.maxExposure < exposureValue) {
        setErrorMessage(
          `Exposure value can't be more than ${convertNumberToCommaSeparate(
            +maxExposure?.getMaxExposure?.maxExposure
          )}`
        )
      } else if (minimumExposure > exposureValue) {
        setErrorMessage(
          `Exposure value can't be less than ${convertNumberToCommaSeparate(
            +minimumExposure
          )}`
        )
      } else {
        setErrorMessage('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exposureValue])

  if (!allPlans.length) return <h1>Plan not found based on your risk score</h1>

  return (
    <>
      <h1>Investment Planning</h1>
      <div className='payment_warning'>
        <span>
          Note:- Kindly fill in the data carefully to receive the according
          advisory you.
        </span>
      </div>
      <div
        className='gx-app-login-wrap-investment'
        style={{ overflowX: 'hidden' }}
      >
        <CustomMeta title='Investment Planning' />
        <div className='gx-app-login-content verification' style={style}>
          <Form
            {...formItemLayout}
            form={form}
            name='register'
            labelAlign='left'
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{
              plan: allPlans[0]?.id || undefined,
              udInvestment: initialInvestment,
              dontBuyEquity: 'YES',
            }}
          >
            <FormItem label='How much exposure value do you want to take?'>
              <Input
                value={convertNumberToCommaSeparate(exposureValue)}
                placeholder='e.g 10000000'
                autoFocus={true}
                style={{
                  width: '100%',
                  textAlign: 'right',
                  backgroundColor: 'transparent',
                }}
                onChange={(e) => {
                  setExposureValue(+e.target.value?.replaceAll(',', ''))
                }}
                autoComplete='off'
              />
              <span style={{ color: 'red' }}>{errorMessage}</span>
            </FormItem>

            <FormItem
              name='plan'
              label='Select your Plan?'
              rules={[
                {
                  required: true,
                  message: 'Please input your Plan !',
                },
              ]}
            >
              <Select
                placeholder='Plan'
                className='display-field'
                defaultValue={{ label: 'Select Dept', value: 0 }}
              >
                {allPlans.map((plan, key) => (
                  <Option key={plan.id} value={plan.id}>
                    {plan.name}
                  </Option>
                ))}
              </Select>
            </FormItem>

            {/* <FormItem name='udInvestment' label='User defined investment'>
              <InputNumber
                placeholder='e.g 10000000'
                style={{ width: '100%' }}
                autoComplete='off'
              />
            </FormItem> */}

            {/* <FormItem
              name="dontBuyEquity"
              label="Do you want to buy Equity"
            >
              <Select placeholder='select value' className='display-field' >
                <Option value="YES">Yes</Option>
                <Option value="NO">No</Option>
              </Select>
            </FormItem>
            */}
            <div style={{ marginBottom: '15px' }}>
              <Button
                disabled={errorMessage?.length || !exposureValue}
                style={{ margin: '0 8px' }}
                type='primary'
                htmlType='submit'
                loading={loader}
              >
                Review Plan
              </Button>
            </div>
          </Form>

          {reviewPlanData?.map((data) => {
            if (data.Description === 'Stocks/MF.')
              data.Description = 'Collateral'
            if (data.Description === 'SIP (Per Month)')
              data.Value = parseInt(data?.Value).toFixed(0)
            return <></>
          })}

          {reviewPlanData && (
            <>
              <Table
                dataSource={reviewPlanData
                  .filter(
                    (data) => data.Description !== '-------: Features :-------'
                  )
                  .slice(0, 6)}
                columns={Columns}
                bordered
                pagination={false}
              />
              <br />
              <Table
                dataSource={reviewPlanData.filter(
                  (data) => data.Per && data.Description !== 'Net Payment'
                )}
                columns={newColumns}
                bordered
                pagination={false}
              />
              <div>
                <div className='agree-wrapper'>
                  <Checkbox onChange={handleAgree} checked={agree} />
                  <p className='agree-content'>
                    I/We hereby declare that the details furnished above are
                    true and correct to the best of my/our knowledge and belief
                    and I/We undertake to inform you of any changes therein,
                    immediately. In case any of the above information is found
                    to be false or untrue or misleading or misrepresenting, I
                    am/We are aware that I/We may be held liable for it.
                  </p>
                </div>
                <Button
                  style={{ margin: '0 8px' }}
                  type='primary'
                  disabled={!agree}
                  loading={planLoader}
                  onClick={() => planMail()}
                >
                  Yes and Next
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
