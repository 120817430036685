import React from 'react'
import Widget from '../Widget'

export default function ({ title, desc = 'Something went wrong' }) {
  return (
    <Widget title={<h2 className='h4 gx-text-capitalize gx-mb-0'>{title}</h2>} styleName='gx-text-center'>
      <h5 className='h5 gx-text-capitalize gx-mt-3 gx-mb-3'>{desc}</h5>
    </Widget>
  )
}
