import React from 'react'
import { Table } from 'antd'

export default function ({
  data,
  columns,
  loading,
}) {
  return (
    <Table
      rowKey='Clientcode'
      loading={loading}
      dataSource={data}
      columns={columns}
      className='nlv-tracker-report'
      pagination={{
        pageSize: 5000,
        hideOnSinglePage: true,
        style: { display: 'none' },
      }}
      scroll={{ x: true, y: 'calc(100vh - 72px - 40px - 207px)' }}
    />
  )
}
