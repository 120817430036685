import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Col } from 'antd'
import { Chart } from "react-google-charts";

import { GET_DASHBOARD_STRATEGY_INDEX_CLIENT } from 'modules/Dashboard/graphql/Queries'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext';

export default function ({ clientCode }) {
  const { state: { currentUser: { clientCode: userCode }, } } = useContext(AppContext)
  const { data, loading } = useQuery(GET_DASHBOARD_STRATEGY_INDEX_CLIENT, { variables: { where: { clientCode: (clientCode || userCode) } }, fetchPolicy: 'network-only' })
  if (loading) return <></>

  return (
    <>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Widget>
          <h2 className='h4 gx-mb-3'>Net Liquidation Value (NLV)</h2>
          <Chart chartType="PieChart"
            data={data?.reportsGraph?.nlvGraph}
            options={{
              colors: ['#AF0000', '#000'],
            }}

          />
        </Widget>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Widget>
          <h2 className='h4 gx-mb-3'>strategy  Vs TotalExposure</h2>
          <Chart
            options={{
              colors: ['#AF0000', '#000']
            }}
            chartType="PieChart" data={data?.reportsGraph?.nlvVsTotalExposure} />
        </Widget>
      </Col>
    </>
  )
}
