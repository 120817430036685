import React from "react"
import _map from 'lodash/map'
import MailListItem from "./MailListItem"
import CustomScrollbars from 'utils/CustomScrollbars'

const MailList = ({ mails, onMailSelect }) => {
  return (
    <div className="gx-module-list gx-mail-list">
      <CustomScrollbars className="gx-module-content-scroll">
        {
          _map(mails, (mail, index) =>
            <MailListItem
              key={index}
              mail={mail}
              onMailSelect={onMailSelect}
            />
          )
            .reverse()
        }
      </CustomScrollbars>
    </div>
  )
}

export default MailList
