import gql from 'graphql-tag'

export const GET_USERS_FOR_SELECT = gql`
  query getUsers(
    $where: UserCreateWhereInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getUsers(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      users {
        id
        firstName
        lastName
        email
        userName
        clientCode
        performance {
          TotalExposure
          NIFTY
        }
      }
      count
    }
  }
`
export const GET_MEMBER_NAME = gql`
  query getMemberName {
    getMemberName
  }
`

export const USER_PUBLIC = gql`
  query {
    getUsersPublic {
      users {
        id
        firstName
        lastName
        role
        clientCode
        email
      }
      count
    }
  }
`

export const GET_USERS = gql`
  query getUsers(
    $where: UserCreateWhereInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getUsers(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      users {
        id
        firstName
        lastName
        email
        phone
        address
        registrationDate
        investmentDate
        cvlStatus
        cvlReportStatus
        riskSuitabilityReportStatus
        suitabilityReportStatus
        agreementReportStatus
        clientScore
        mandateId
        umrnNumber
        gstinNumber
        depositDate
        depositAmount
        currentAUA
        rationalStatus
        invoiceStatus
        cityId
        stateId
        countryId
        motherName
        fatherName
        dateOfBirth
        userName
        isActive
        role
        ratio
        keyId
        isAuto
        isAssociate
        exposure
        fundReceived
        initialExposure
        isKycDone
        completedSteps
        amcStartDate
        amcAmount
        sipStartDate
        sipAmount
        closedDate
        clientCode
        strategyCode
        token
        createdAt
        updatedAt
        annualIncome
        netAvailableExposure
        initialExposure
        hierarchy
        amcReceivedDate
        amcInPer
        amcInvoiceNo
        amcRecentAmount
        disableDate
        panCardNo
        remark
        collateralValue
        clientConsentTime
        sourceOfLead
        associateIntroducer
        invoiceStatus
        riskProfileType
        discountStatus
        initialExposure
        agreementDataMailSent
        amcRecentAmount
        amcInvoiceNo
        amcReceivedDate
        isPanCardIndividual
        isBookingAdvisoryDateSelected
        isNewAuaAdvisoryGenerate
        isReUploadKyc
        newAuaAdvisoryCompletedSteps
        currentCompletedSteps
        isShowAddAua
        auaMaxLimit
        eMandateAmcAmount
        plan {
          id
          name
          description
        }
        kyc {
          id
          incomeProof
          agreementData
          agreementForNonIndividual
        }
        createdBy {
          id
        }
        updatedBy {
          id
        }
        performance {
          TotalExposure
          NIFTY
        }
      }
      count
    }
  }
`

export const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(where: { id: $id }) {
      id
      firstName
      lastName
      email
      phone
      address
      cityId
      consentData
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      ratio
      keyId
      isAuto
      isAssociate
      exposure
      registrationDate
      investmentDate
      amcInPer
      sourceOfLead
      associateIntroducer
      invoiceStatus
      riskProfileType
      discountStatus
      familyGroup
      fundReceived
      initialExposure
      isKycDone
      completedSteps
      memberName
      amcStartDate
      amcAmount
      sipStartDate
      sipAmount
      closedDate
      clientCode
      strategyCode
      token
      disableDate
      isDisableDate
      createdAt
      annualIncome
      mandateId
      plan {
        id
        name
        description
      }
      remark
      collateralValue
      updatedAt
      kyc {
        id
        panCard
        aadharCard
        passportSize
        signature
        cancelledChequeSnap
        bankStatement
        associateAgreement
        syncOn
        eSignTransactionId
        agreementESignTransactionId
      }
      createdBy {
        id
      }
      updatedBy {
        id
      }
      commissionSelectUser
      otherMailIds
      bank
      bankAddress
      accountNumber
      ifscCode
      rationalStatus
      umrnNumber
      gstinNumber
      depositDate
      depositAmount
      panCardNo
      suitabilityReportStatus
      riskSuitabilityReportStatus
      agreementReportStatus
      cvlReportStatus
      currentAUA
      isBookingAdvisoryDateSelected
      isNewAuaAdvisoryGenerate
      isReUploadKyc
      newAuaAdvisoryCompletedSteps
      currentCompletedSteps
      isShowAddAua
      auaMaxLimit
      eMandateAmcAmount
    }
  }
`

export const GET_CLIENT_USERS = gql`
  query getClientUsers($where: UserWhereUniqueInput!, $skip: Int, $limit: Int) {
    getClientUsers(where: $where, skip: $skip, limit: $limit) {
      users {
        id
        firstName
        lastName
        email
        phone
        address
        cityId
        stateId
        countryId
        motherName
        fatherName
        dateOfBirth
        userName
        isActive
        role
        ratio
        keyId
        isAuto
        isAssociate
        exposure
        fundReceived
        initialExposure
        isKycDone
        completedSteps
        amcStartDate
        amcAmount
        sipStartDate
        sipAmount
        closedDate
        clientCode
        strategyCode
        token
        createdAt
        updatedAt
        commissionSelectUser
        # plan {
        #   id
        #   name
        #   description
        # }
        # createdBy {
        #   id
        # }
        # updatedBy {
        #   id
        # }
      }
      count
    }
  }
`

export const GET_CLIENT_USERS_FOR_STAFF = gql`
  query getClientUsersForStaff(
    $where: UserWhereUniqueInput!
    $skip: Int
    $limit: Int
  ) {
    getClientUsersForStaff(where: $where, skip: $skip, limit: $limit) {
      users {
        id
        firstName
        lastName
        email
        phone
        address
        cityId
        stateId
        countryId
        motherName
        fatherName
        dateOfBirth
        userName
        isActive
        role
        ratio
        keyId
        isAuto
        isAssociate
        exposure
        fundReceived
        initialExposure
        isKycDone
        completedSteps
        amcStartDate
        amcAmount
        sipStartDate
        sipAmount
        closedDate
        clientCode
        strategyCode
        token
        createdAt
        updatedAt
        commissionSelectUser
        # plan {
        #   id
        #   name
        #   description
        # }
        # createdBy {
        #   id
        # }
        # updatedBy {
        #   id
        # }
      }
      count
    }
  }
`

export const GET_SYSTEM_USERS = gql`
  query getSystemUsers($where: UserWhereUniqueInput!, $skip: Int, $limit: Int) {
    getSystemUsers(where: $where, skip: $skip, limit: $limit) {
      users {
        id
        firstName
        lastName
        email
        phone
        address
        cityId
        stateId
        countryId
        motherName
        fatherName
        dateOfBirth
        userName
        isActive
        ratio
        keyId
        isAuto
        isAssociate
        exposure
        fundReceived
        initialExposure
        isKycDone
        completedSteps
        amcStartDate
        amcAmount
        sipStartDate
        sipAmount
        closedDate
        clientCode
        strategyCode
        token
        createdAt
        updatedAt
        createdBy {
          id
        }
        updatedBy {
          id
        }
      }
      count
    }
  }
`

export const GET_REVENUE_INCOME = gql`
  query getRevenueIncome(
    $skip: Int
    $limit: Int
    $sort: SortInput
    $where: RevenueIncomeWhereUniqueInput
  ) {
    getRevenueIncome(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      RevenueIncome {
        id
        user {
          id
          firstName
          lastName
          role
        }
        client {
          id
          firstName
          lastName
          clientCode
        }
        amc {
          id
          amount
          paidAt
          taxInvoiceNumber
          invoice {
            amcAmount
            commissionAmount
            commissionInPer
          }
        }
        description
        commissionRatio
        commissionAmount
        userRevenueDetail {
          id
          userType
        }
        createdAt
        updatedAt
      }
      counter
      commissionAmountTotal
    }
  }
`

export const GET_ALL_AMC = gql`
  query getAllAmcs(
    $skip: Int
    $limit: Int
    $sort: SortInput
    $where: AmcWhereUniqueInput!
  ) {
    getAllAmcs(where: $where, skip: $skip, limit: $limit, sort: $sort)
    # {
    #   count
    #   amc {
    #     id
    #     status
    #     amount
    #     whichYear
    #     paymentType
    #     description
    #     amcQty
    #     amcPaidForPeriod
    #     nextDueDate
    #     paidAt
    #     startDate
    #     endDate
    #     createdAt
    #     updatedAt
    #     invoiceNo
    #     invoice {
    #       id
    #       dueDay
    #       assetUnderAdvisory
    #       discountAmount
    #       commissionAmount
    #       grossTotalAmount
    #       igst18InPer
    #       totalNetAmount
    #       quantity
    #       deduction
    #     }
    #     client {
    #       id
    #       firstName
    #       lastName
    #       role
    #       clientCode
    #       amcAmount
    #       email
    #       phone
    #       address
    #       exposure
    #       initialExposure
    #       amcInPer
    #       amcStartDate
    #       stateId
    #       createdBy {
    #         id
    #         firstName
    #         lastName
    #         email
    #         phone
    #         role
    #         createdBy {
    #           id
    #           firstName
    #           lastName
    #           email
    #           phone
    #           role
    #         }
    #       }
    #     }
    #   }
    #   total
    # }
  }
`

export const GET_AMC = gql`
  query getAllAmcs(
    $clientId: String
    $createdById: [String]
    $whichYear: Int
    $skip: Int
    $limit: Int
    $sort: SortInput
    $startDateFrom: DateTime
    $startDateTo: DateTime
    $paidAtFrom: DateTime
    $paidAtTo: DateTime
    $nextDueDateFrom: DateTime
    $nextDueDateTo: DateTime
    $clientCodeSearch: String
    $status: [Status]
  ) {
    getAllAmcs(
      where: {
        clientId: $clientId
        createdById: $createdById
        whichYear: $whichYear
        startDateFrom: $startDateFrom
        startDateTo: $startDateTo
        paidAtFrom: $paidAtFrom
        paidAtTo: $paidAtTo
        nextDueDateFrom: $nextDueDateFrom
        nextDueDateTo: $nextDueDateTo
        clientCodeSearch: $clientCodeSearch
        status: $status
      }
      skip: $skip
      limit: $limit
      sort: $sort
    )
    # {
    #   amc {
    #     id
    #     status
    #     amount
    #     whichYear
    #     paymentType
    #     description
    #     amcQty
    #     amcPaidForPeriod
    #     nextDueDate
    #     paidAt
    #     startDate
    #     endDate
    #     createdAt
    #     updatedAt
    #     invoiceNo
    #     client {
    #       id
    #       firstName
    #       lastName
    #       role
    #       clientCode
    #       amcAmount
    #       createdBy {
    #         id
    #         firstName
    #         lastName
    #         role
    #       }
    #     }
    #   }
    #   count
    #   total
    # }
  }
`

export const GET_DUE_AMC = gql`
  query getAllDueAmcs(
    $where: AmcDueWhereUniqueInput!
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getAllDueAmcs(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      amc {
        id
        startDate
        endDate
        status
        nextAmc
        invoice {
          id
          assetUnderAdvisory
          amcInPer
          discountInPer
          commissionInPer
          grossTotalAmount
          cgst9InPer
          igst18InPer
          sgst9InPer
          totalNetAmount
          niftySpot
          niftyLot
          state
          startDate
          endDate
          amcType
          invoicePdf
        }
        client {
          id
          firstName
          lastName
          clientCode
          email
          phone
          address
          stateId
          mandateId
          performance {
            TotalExposure
            NIFTY
          }
          amcInPer
          createdBy {
            id
            firstName
            lastName
            email
            phone
            role
            createdBy {
              id
              firstName
              lastName
              email
              phone
              role
              createdBy {
                id
                firstName
                lastName
                email
                phone
                role
                createdBy {
                  id
                  firstName
                  lastName
                  email
                  phone
                  role
                  createdBy {
                    id
                    firstName
                    lastName
                    email
                    phone
                    role
                    createdBy {
                      id
                      firstName
                      lastName
                      email
                      phone
                      role
                      createdBy {
                        id
                        firstName
                        lastName
                        email
                        phone
                        role
                        createdBy {
                          id
                          firstName
                          lastName
                          email
                          phone
                          role
                          createdBy {
                            id
                            firstName
                            lastName
                            email
                            phone
                            role
                            createdBy {
                              id
                              firstName
                              lastName
                              email
                              phone
                              role
                              createdBy {
                                id
                                firstName
                                lastName
                                email
                                phone
                                role
                                createdBy {
                                  id
                                  firstName
                                  lastName
                                  email
                                  phone
                                  role
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        amount
        whichYear
        paymentType
        description
        updatedBy {
          firstName
          lastName
        }
        createdAt
        updatedAt
        startDate
        endDate
        status
        amcQty
        amcPaidForPeriod
        nextDueDate
        paidAt
        invoiceNo
      }
      count
    }
  }
`

export const GET_SIP = gql`
  query getAllSips(
    $clientId: Int
    $whichMonth: String
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getAllSips(
      where: { clientId: $clientId, whichMonth: $whichMonth }
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      sip {
        id
        status
        amount
        whichMonth
        paymentType
        description
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
        }
      }
      count
    }
  }
`

export const GET_LEAD_USERS = gql`
  query getLeadUsers($userId: Int!) {
    getLeadUsers(where: { userId: $userId })
  }
`

export const GET_USER_REVENUE_BY_CLIENT = gql`
  query getUserRevenueByClient($clientId: Int!) {
    getUserRevenueByClient(where: { clientId: $clientId }) {
      id
      client {
        id
      }
      year
      ratio
      level
      chainMember {
        id
      }
      createdAt
      updatedAt
    }
  }
`
export const GET_USERS_FOR_CLIENT = gql`
  query getUsersForClient {
    getUsersForClient {
      count
      users {
        id
        firstName
        lastName
      }
    }
  }
`

export const KYC_FILE_UPLOAD = gql`
  query kycFileUpload {
    kycFileUpload
  }
`
export const AGREEMENT_DATA_FILE_UPLOAD = gql`
  query agreementDataFileUpload {
    agreementDataFileUpload
  }
`
export const E_MANDATE_BANK_DATA = gql`
  query eMandateBankData {
    eMandateBankData
  }
`
export const E_MANDATE = gql`
  query eMandate($data: EMandateInput) {
    eMandate(data: $data)
  }
`

export const CHECK_EXIST_CLIENT_CODE = gql`
  query checkExistClientCode($where: ClientCodeExistUniqueInput!) {
    checkExistClientCode(where: $where)
  }
`

export const GET_ALL_CLIENT = gql`
  query getClientUsers($where: UserWhereUniqueInput!, $skip: Int, $limit: Int) {
    getClientUsers(where: $where, skip: $skip, limit: $limit) {
      users {
        id
        firstName
        lastName
        clientCode
        email
      }
      count
    }
  }
`
