import { Col, Collapse, Row } from 'antd'
import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { DeleteOutlined } from '@ant-design/icons'
import client from 'apollo'
import { DELETE_USERS_PDF } from 'components/About/graphql/mutation'
import { GET_USER_PDF } from 'modules/Plans/graphql/Query'
import openNotification from 'utils/Notification'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import './index.css'

const { Panel } = Collapse

const RationalStatus = ({ pdf, key, id }) => {
  const [loader, setLoader] = useState(false)
  const handleDelete = (e) => {
    setLoader(true)
    client
      .mutate({
        mutation: DELETE_USERS_PDF,
        variables: { where: { id: parseInt(e) } },
      })
      .then((res) => {
        client
          .query({
            query: GET_USER_PDF,
            variables: { userId: parseInt(id) },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            setLoader(false)
            openNotification('success', 'Document Successfully Deleted')
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((error) => {
        openNotification('error', 'Something Went Wrong')
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Collapse key={key} style={{ width: '100%' }}>
        <Panel
          header={pdf?.pdfUrl
            ?.split('/')
            [pdf?.pdfUrl?.split('/')?.length - 1].replaceAll('%20', ' ')}
        >
          <div
            className='gx-mb-2'
            onClick={() => window.open(pdf?.pdfUrl, '_blank')}
          >
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className='pdfViewer'>
                  <Document file={pdf?.pdfUrl}>
                    <Page pageNumber={1} />
                  </Document>
                </div>
              </Col>
            </Row>
          </div>
        </Panel>
      </Collapse>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '20px',
          cursor: 'pointer',
        }}
        onClick={() => handleDelete(pdf?.id)}
      >
        {loader ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
        ) : (
          <DeleteOutlined />
        )}
      </div>
    </div>
  )
}

export default RationalStatus
