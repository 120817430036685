import React, { useContext } from 'react'
import { Modal, Button } from 'antd'

import './BookAdvisory.css'
import moment from 'moment'
import { AppContext } from 'AppContext'

const BookAdvisoryModal = ({
  isModalOpen,
  onCancel,
  isTimeUp,
  data,
  onCreate,
  loading,
  advisoryTime,
  amcAmount,
}) => {
  const {
    state: {
      currentUser: { mandateId, umrnNumber },
    },
  } = useContext(AppContext)

  return (
    <>
      {isTimeUp ? (
        <Modal
          className='book_advisory_modal'
          visible={isModalOpen}
          footer={null}
        >
          <div className='book_advisory_div'>
            <div className='book_advisory_sub_div'>
              <p className='book_advisory_text'>Dear {data?.name}</p>
              <p className='book_advisory_text'>Greetings from Finideas!!!</p>
              <p className='book_advisory_text'>
                Thank you for selecting {data?.date}. We will deliver your
                advisory during working hours from&nbsp;
                {moment(advisoryTime?.startTime, 'hh:mm A').format('LT')}{' '}
                to&nbsp;
                {moment(advisoryTime?.endTime, 'hh:mm A').format('LT')} on that
                day. Please remember to log in again on the specified date.
              </p>
            </div>
          </div>
          <div className='book_advisory_btn'>
            <Button loading={loading} onClick={onCreate} type='primary'>
              Confirm
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </div>
        </Modal>
      ) : (
        <Modal
          className='on_time_book_advisory_modal'
          visible={isModalOpen}
          footer={null}
        >
          <div>
            <b className='modal_text'>Do you want advisory now?</b>
          </div>

          {(mandateId || umrnNumber) && (
            <div className='modal_note_text'>
              <p>
                Note:- Kindly note your Finideas Advisory Fees Rs.
                {Math.round(amcAmount)}/- will be debited within 48 hours
                through the mandate advisory time.
              </p>
            </div>
          )}
          <div className='book_advisory_btn'>
            <Button loading={loading} onClick={onCreate} type='primary'>
              Yes
            </Button>
            <Button onClick={onCancel} disabled={loading}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}
export default BookAdvisoryModal
