import React, { useContext, useState } from 'react'
import { Button, Col, Form, Input, PageHeader, Row } from 'antd'
import { AppContext } from 'AppContext'
import client from 'apollo'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
  VERIFY_OTP_FOR_REEVALUATE,
} from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import { useHistory } from 'react-router-dom'

import './Reevalute.css'

const New = () => {
  const history = useHistory()
  const [loaded, setLoaded] = useState(false)
  const [loader, setLoader] = useState(false)
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext)
  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
  }

  const sentOtp = () => {
    setLoaded(true)
    client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
      setLoaded(false)
      openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
    })
  }

  const onFinish = (value) => {
    client
      .mutate({
        mutation: VERIFY_OTP_FOR_REEVALUATE,
        variables: { otp: value.otp },
      })
      .then((res) => {
        setLoader(true)
        if (res.data.verifyOtpForReEvaluate) {
          client
            .mutate({
              mutation: UPDATE_USER,
              variables: {
                data: {
                  reEvaluate: true,
                  completedSteps: 'LOGIN_CREATED',
                  isNewAuaAdvisoryGenerate: false,
                },
                id: parseInt(currentUser.id),
              },
            })
            .then((res) => {
              setLoader(true)
              dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
              dispatch({ type: 'IS_VERIFICATION_DONE', data: false })
              history.push('/verification')
              setLoader(false)
            })
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader(false))
  }

  return (
    <>
      <PageHeader className='box' title='Re-evaluate Risk Profile' />
      <Row>
        <Col xl={8} lg={8} md={6} sm={24} xs={24}></Col>
        <Col
          xl={8}
          lg={8}
          md={12}
          sm={24}
          xs={24}
          className='form-field'
          style={{ marginTop: '5%' }}
        >
          <Form
            {...formItemLayout}
            className='user-form'
            onFinish={onFinish}
            initialValues={{
              email: currentUser.email,
              phone: currentUser.phone,
            }}
          >
            <Form.Item
              name='email'
              label='Register Email ID'
              rules={[{ required: true }]}
            >
              <Input placeholder='Enter email' disabled />
            </Form.Item>
            <Form.Item
              name='phone'
              label='Register Mobile Number'
              rules={[{ required: true }]}
            >
              <Input type='number' placeholder='Enter Mobile Number' disabled />
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                onClick={sentOtp}
                loading={loaded}
                className='form-element'
              >
                Sent OTP
              </Button>
            </Form.Item>
            <Form.Item name='otp' label='OTP' rules={[{ required: true }]}>
              <Input placeholder='Enter OTP' type='number' />
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='form-element'
                loading={loader}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xl={8} lg={8} md={6} sm={24} xs={24}></Col>
      </Row>
    </>
  )
}

export default New
