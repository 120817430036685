import React, { useContext } from 'react'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import DownloadReport from '../DownloadReport'
import Loader from 'components/loaders/Loader'
import NlvTrackerTable from './NlvTrackerTable'
import { GET_NLV_TRACKER_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const { state: { currentUser: { clientCode } } } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_NLV_TRACKER_REPORT, { variables: { where: { clientCode } }, fetchPolicy: 'network-only' })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spNLVTracker')) return <NoData />

  const { spNLVTracker: [spNLVTracker] } = data

  return (
    <>
      <DownloadReport data={spNLVTracker.filter((report, key) => key !== 0)} fileName='NLV-Tracker-report' id='NLV-TRACKER-REPORT-DOWNLOAD-PDF' isLandscape={true} />
      <div className='report-table-wrapper' id='NLV-TRACKER-REPORT-PDF'>
        <NlvTrackerTable reportData={spNLVTracker.filter((report, key) => key !== 0)} id='NLV-TRACKER-REPORT-PDF' />
      </div>
      <div style={{ display: 'none' }} >
        <div className='report-table-wrapper' id='NLV-TRACKER-REPORT-DOWNLOAD-PDF'>
          <NlvTrackerTable reportData={spNLVTracker.filter((report, key) => key !== 0)} id='NLV-TRACKER-REPORT-DOWNLOAD-PDF' />
        </div>
      </div>
    </>
  )
}
