import React, { useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  InputNumber,
  Modal,
  Tag,
  Row,
  Col,
  Tooltip as AntTooltip,
} from 'antd'
import {
  QuestionOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import {
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import client from 'apollo'

import { cloneDeep, isEmpty, uniq } from 'lodash'
import {
  UPDATE_ADVISORY_STATUS,
  UPDATE_ADVISORY_VALUE,
} from './graphql/Mutation'
import openNotification from 'utils/Notification'
import moment from 'moment'
import PendingAdvisoryModel from './PendingAdvisoryModel'
import ScenarioPendingAdvisoryIndex from 'modules/Verification/PendingAdvisory/ScenarioPendingAdvisoryIndex'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import MoreDetails from 'modules/Verification/PendingAdvisory/MoreDetails'
import { ConfirmAdvisoryModal } from 'modules/Verification/PendingAdvisory/ConfirmAdvisoryModal'
import {
  SEND_PENDING_ADVISORY_TERMS_MAIL,
  UPDATE_BOOK_ADVISORY,
} from 'modules/Verification/graphql/Mutations'
import { useQuery } from '@apollo/react-hooks'
import { GET_BOOKING_ADVISORY } from 'modules/Verification/graphql/Queries'
import Loader from 'components/loaders/Loader'
import TermsCondition from 'modules/Verification/PendingAdvisory/TermsCondition'

export default function PendingAdvisoryTable({
  advisoryData,
  refetch,
  setDateForAdvisory,
  dateForAdvisory,
  allData,
  mapData,
  range,
  accept,
  setAccept,
  bookedAdvisoryId,
  userData,
  isPending,
  setOpenAdvisory,
}) {
  const data = cloneDeep(advisoryData)
  const { confirm } = Modal
  const [errorKeys, setErrorKeys] = useState([])
  const [openTableInModal, setOpenTableInModal] = useState(false)
  const [openMoreDetailsModal, setOpenMoreDetailsModal] = useState(false)
  const [newData, setNewData] = useState()
  const [confirmModal, setConfirmModal] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  const { data: getBookAdvisoryData } = useQuery(GET_BOOKING_ADVISORY, {
    variables: {
      where: { id: parseInt(bookedAdvisoryId), userId: parseInt(userData?.id) },
    },
  })

  useEffect(() => {
    if (data?.length) {
      data.forEach((d, key) => {
        if (newData?.length && newData[key]) {
          d.TradePrice = newData[key].TradePrice
          d.TradedQuantity = newData[key].TradedQuantity
        } else {
          d.TradedQuantity = +d.Quantity
        }
      })
    }
  }, [data, newData])

  if (isEmpty(getBookAdvisoryData)) return <Loader />

  const columns = [
    {
      title: 'Pending Advisory',
      className: 'report-title main-title main_div_title',
      children: [
        {
          title: (
            <div className='hidden-client-data'>
              {advisoryData?.length ? advisoryData[0]?.AdvisoryType : ''}
              <span className='hidden-client-code'></span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            // {
            //   title: 'Trade Type',
            //   dataIndex: 'TradeType',
            //   key: 'TradeType',
            //   className: 'report-text average-index-column-align',
            // },
            {
              title: 'Script',
              dataIndex: 'Script',
              key: 'Script',
              className: '',
            },
            {
              title: 'Trade',
              dataIndex: 'Trade',
              key: 'Trade',
              className: 'text_align',
            },
            {
              title: 'Quantity',
              dataIndex: 'Quantity',
              key: 'Quantity',
              className: 'text_align',
            },
          ],
        },
        {
          title: (
            <div className='hidden-client-data'>
              {advisoryData.length
                ? new Date(advisoryData[0]?.AdvisoryDate)
                    .toISOString()
                    .replace('Z', '')
                    .replace('T', ' ')
                : ''}
              <span className='hidden-client-code'></span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Traded Quantity',
              dataIndex: 'TradedQuantity',
              key: 'TradedQuantity',
              className: 'text_align',
            },
            {
              title: 'Price',
              dataIndex: 'TradePrice',
              key: 'TradePrice',
              className: 'text_align',
            },
          ],
        },
      ],
    },
  ]

  const showConfirm = () => {
    let flag = true
    data.forEach((data) => {
      if (
        data.TradedQuantity !== 0 &&
        (data.TradePrice <= 0 || typeof data?.TradePrice !== 'number')
      ) {
        flag = false
      }
      if (data.TradedQuantity === 0 || data?.TradedQuantity === null) {
        // data.TradePrice = 0
        flag = false
      }
    })

    flag
      ? setOpenTableInModal(true)
      : confirm({
          title: 'Traded Quantity and Trade Price must be greater then 0',
          icon: <ExclamationCircleOutlined />,
        })
  }

  const handleCancel = () => {
    setOpenTableInModal(false)
  }

  const handleOnOk = () => {
    if (data) {
      const statusData = data.map((d) => {
        return {
          advisorId: parseInt(d.AdvisorID),
          clientCode: d.ClientCode,
          status: 'Accept',
        }
      })
      client
        .mutate({
          mutation: UPDATE_ADVISORY_STATUS,
          variables: { data: statusData },
        })
        .then(() => {
          data.forEach((d) => {
            delete d.high
            delete d.low
            delete d.MktPrice
            // delete d.Symbol
            d.AdvisorID = parseInt(d.AdvisorID)
            if (!d.Strike) {
              d.Strike = ''
            } else {
              d.Strike = JSON.stringify(d.Strike)
            }
            if (d.AdvisoryType) {
              delete d.AdvisoryType
            }
            if (d.Price) {
              delete d.Price
            }
            if (d.AdvisoryDate) {
              delete d.AdvisoryDate
            }
            if (d.Quantity) {
              delete d.Quantity
            }
          })
          client
            .mutate({
              mutation: UPDATE_ADVISORY_VALUE,
              variables: { data },
            })
            .then((data) => {
              data?.data?.updateAdvisoryValue === true
                ? openNotification('success', 'Accepted Successfully')
                : openNotification('error', 'Something went wrong')

              client
                .mutate({
                  mutation: UPDATE_BOOK_ADVISORY,
                  variables: {
                    data: {
                      userId: userData?.id,
                      isClientAcceptAdvisory: true,
                      advisoryAcceptDateAndTime: moment().format(),
                    },
                    where: { id: parseInt(bookedAdvisoryId) },
                  },
                })
                .then((res) => {
                  setOpenTableInModal(false)
                  setConfirmModal(true)
                })
                .catch((err) => console.log(err))
            })
        })
    } else {
      setAccept(false)
      setOpenTableInModal(false)
    }
  }

  const handleAccept = (isReject = false) => {
    confirm({
      title: isReject
        ? 'Do You Want To Discard The Received Advisory?'
        : 'Kindly Update The Traded Quantity And Price?',
      icon: <ExclamationCircleOutlined />,
      okText: isReject ? 'Yes' : 'Accept',
      cancelText: isReject ? 'No' : 'Cancel',
      onOk() {
        if (isReject) {
          const statusData = data.map((d) => {
            return {
              advisorId: parseInt(d.AdvisorID),
              clientCode: d.ClientCode,
              status: 'Reject',
            }
          })
          client
            .mutate({
              mutation: UPDATE_ADVISORY_STATUS,
              variables: { data: statusData },
            })
            .then((res) => {
              res?.data?.updateAdvisoryStatus === true
                ? openNotification('info', 'Rejected Successfully')
                : openNotification('error', 'Something went wrong')

              client
                .mutate({
                  mutation: UPDATE_BOOK_ADVISORY,
                  variables: {
                    data: {
                      isPendingAdvisoryAccepted: true,
                      isCancelAdvisory: true,
                      userId: userData?.id,
                    },
                    where: { id: parseInt(bookedAdvisoryId) },
                  },
                })
                .then((res) => {
                  refetch(true, true)
                })
                .catch((err) => console.log(err))
            })
        } else {
          client
            .mutate({
              mutation: UPDATE_BOOK_ADVISORY,
              variables: {
                data: { isPendingAdvisoryAccepted: true, userId: userData?.id },
                where: { id: parseInt(bookedAdvisoryId) },
              },
            })
            .then((res) => {
              setAccept(true)
            })
            .catch((err) => console.log(err))
        }
      },
    })
  }

  const handleGetPendingAdvisory = (e) => {
    setDateForAdvisory(
      moment(e).format('DD-MMM-YYYY') !== 'Invalid date'
        ? moment(e).format('DD-MMM-YYYY')
        : ''
    )
  }

  const onFinish = () => {
    handleAccept()
  }
  const onReject = () => {
    handleAccept(true)
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <div className='custom-tooltip' style={{ background: '#fff' }}>
          <p style={{ color: 'orange' }}>{label}</p>
          <p style={{ color: 'red' }}>{`P&L: ${convertNumberToCommaSeparate(
            +payload[0].value
          )}`}</p>
          <p style={{ color: 'green' }}>{`NLV: ${convertNumberToCommaSeparate(
            +payload[1].value
          )}`}</p>
        </div>
      )
    }

    return null
  }

  const openModal = () => {
    setOpenMoreDetailsModal(!openMoreDetailsModal)
  }
  const closeModal = () => {
    setOpenMoreDetailsModal(false)
  }

  const confirmAdvisory = () => {
    setAccept(false)
    refetch(true, true)
  }

  const sendTermsMail = () => {
    setBtnLoading(true)
    const data = {
      ...allData?.termPara,
      name: userData?.firstName + ' ' + userData?.lastName,
      email: userData?.email,
      advisoryId: parseInt(bookedAdvisoryId),
    }

    client
      .mutate({
        mutation: SEND_PENDING_ADVISORY_TERMS_MAIL,
        variables: { data: { ...data } },
        refetchQueries: [
          {
            query: GET_BOOKING_ADVISORY,
            variables: {
              where: {
                userId: parseInt(userData?.id),
                id: parseInt(bookedAdvisoryId),
              },
            },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setBtnLoading(false)
      })
      .catch((err) => {
        setBtnLoading(false)
        console.log(err)
      })
  }
  return (
    <>
      {advisoryData?.length ? (
        <>
          <div className='pending_advisory_logo_and_title'>
            <div className='pending_advisory_title_div'>
              <b className='pending_advisory_title'>Index Long Term Strategy</b>
              <p
                className='pending_advisory_sub_title'
                style={{ textAlign: 'center' }}
              >
                Pending Advisory
              </p>
            </div>
            <div>
              <img
                className='fin_logo_div'
                src='https://finideas-india.blr1.digitaloceanspaces.com/Finideas-black-png.png'
                alt='Finideas'
              />
            </div>
          </div>
          <Row gutter={16}>
            <Col lg={16} md={16} sm={24} xs={23}>
              <div className='pending_advisory_client_details_div_1'>
                <div className='clients_details'>
                  <div>
                    <div className='client_div_for_advisory_title'>
                      <b>Client Code</b>
                    </div>
                    <div className='client_div_for_advisory_sub_title'>
                      <b>Client Name</b>
                    </div>
                  </div>
                </div>
                <div className='client_details_value'>
                  <div>
                    <div className='client_code_value'>
                      <b>{userData?.clientCode}</b>
                    </div>
                    <div className='client_name_value'>
                      <b>{userData?.firstName + ' ' + userData?.lastName}</b>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8} md={8} sm={24} xs={22} className='new_column_for_type'>
              <div>
                <div className='advisory_date_and_type'>
                  <div className='advisory_date_div'>
                    <div className='advisory_date_text_new'>
                      <b>Advisory Type</b>
                    </div>
                    <div className='advisory_date_value_new'>
                      <b>
                        {advisoryData?.length
                          ? advisoryData[0]?.AdvisoryType
                          : ''}
                      </b>
                    </div>
                  </div>
                </div>

                <div className='date_and_time_div'>
                  <div className='advisory_date_div'>
                    <div className='advisory_date_text_new'>
                      <b>Date & Time</b>
                    </div>
                    <div className='advisory_date_value_div'>
                      <b>
                        {advisoryData?.length
                          ? moment(allData?.createdAt)
                              .subtract(330, 'minute')
                              .format('DD-MM-YYYY HH:mm:ss')
                          : ''}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}

      {advisoryData?.length ? (
        <>
          {!accept ? (
            <>
              {advisoryData[0]?.AdvisoryType === 'Scenario' ||
              advisoryData[0]?.AdvisoryType === 'Shifting' ||
              advisoryData[0]?.AdvisoryType === 'Rollover' ? (
                <ScenarioPendingAdvisoryIndex
                  loading={false}
                  onFinish={onFinish}
                  data={allData}
                  onReject={onReject}
                  rejectLoading={false}
                />
              ) : advisoryData[0]?.AdvisoryType === 'New' ? (
                <>
                  {!getBookAdvisoryData?.getBookAdvisory
                    ?.isTermAndServiceAccepted ? (
                    <TermsCondition
                      data={{ getPendingBookingAdvisory: allData?.termPara }}
                      loading={btnLoading}
                      onFinish={sendTermsMail}
                    />
                  ) : (
                    <Row
                      gutter={16}
                      className='pending_advisory_div_column_new'
                    >
                      <Col
                        lg={16}
                        md={16}
                        sm={24}
                        xs={24}
                        className='pending_advisory_div_column'
                      >
                        <div className='update_trade_table'>
                          <Row
                            className='update_trade_table_row'
                            gutter={16}
                            style={{ fontWeight: 500 }}
                          >
                            <Col
                              className='update_trade_table_script'
                              span={12}
                            >
                              Script
                            </Col>
                            <Col
                              className='update_trade_table_col update_trade_table_col_border'
                              span={4}
                            >
                              Trade
                            </Col>
                            <Col
                              className='update_trade_table_col'
                              span={4}
                              style={{ borderRight: '1px solid #000' }}
                            >
                              Quantity
                            </Col>
                            <Col className='update_trade_table_col' span={4}>
                              Price
                            </Col>
                          </Row>
                          {data?.length &&
                            data?.map((item, key) => {
                              const { Script, Trade, Quantity, TradePrice } =
                                item
                              return (
                                <Row
                                  className={
                                    advisoryData?.length - 1 === key
                                      ? 'update_trade_table_row_new'
                                      : 'update_trade_table_row'
                                  }
                                  gutter={16}
                                >
                                  <Col
                                    className='update_trade_table_script'
                                    span={12}
                                  >
                                    {Script}
                                  </Col>
                                  <Col
                                    className='update_trade_table_col update_trade_table_col_border'
                                    span={4}
                                  >
                                    {Trade}
                                  </Col>
                                  <Col
                                    className='update_trade_table_col'
                                    span={4}
                                    style={{ borderRight: '1px solid #000' }}
                                  >
                                    {Quantity}
                                  </Col>
                                  <Col
                                    className='update_trade_table_col'
                                    span={4}
                                  >
                                    {TradePrice}
                                  </Col>
                                </Row>
                              )
                            })}
                        </div>
                        <Row
                          gutter={16}
                          className='column_for_advisory_table_new'
                        >
                          <Col span={18} className='action_btn'>
                            <Button
                              className='pending_advisory_accept_btn'
                              onClick={onFinish}
                              loading={false}
                            >
                              Accept
                            </Button>
                            <Button
                              type='primary'
                              loading={false}
                              onClick={onReject}
                            >
                              Reject
                            </Button>
                          </Col>
                          <Col span={6}>
                            <div className='question_icon'>
                              <div className='faqs-icon'>
                                <Link
                                  to='/faqs'
                                  className='nav-text'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <QuestionOutlined className='que_mark' />
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        lg={8}
                        md={8}
                        sm={24}
                        xs={24}
                        className='scenario_analysis_div'
                      >
                        <div className='new_advisory_text'>
                          <b className='pending_advisory_row_col_2'>
                            Scenario Analysis
                          </b>
                        </div>
                        <p className='pending_advisory_row_col_2_text'>
                          As Warren Buffet says,"The best Investment you can
                          make is an Investment in yourself.. The more you
                          learn, the more you earn".
                        </p>
                        <p className='pending_advisory_row_col_2_para'>
                          So let's understand the scenario analysis of your
                          position. The below given attachment will show your
                          indicative Profit and Loss and your fund value at
                          different levels of market. Please note that the below
                          data may change time to time. Feel free to ask us if
                          you have any questions on this.
                        </p>

                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <ResponsiveContainer width='100%' height={300}>
                            <LineChart
                              height={300}
                              data={mapData?.mapData1}
                              margin={{
                                top: 5,
                                right: 20,
                                bottom: 5,
                                left: 20,
                              }}
                            >
                              <Tooltip content={<CustomTooltip />} />
                              <Legend
                                verticalAlign='top'
                                align='center'
                                height={36}
                                content={(props) => (
                                  <div className='chart_legend'>
                                    <div
                                      className='legend1'
                                      style={{ color: 'red' }}
                                    >
                                      <div
                                        className='legend_dot'
                                        style={{ background: 'red' }}
                                      ></div>
                                      <span style={{ marginLeft: '7px' }}>
                                        Expected P&L at Trading Member
                                      </span>
                                    </div>
                                    <div
                                      className='legend1'
                                      style={{
                                        color: 'green',
                                        marginLeft: '7px',
                                      }}
                                    >
                                      <div
                                        className='legend_dot'
                                        style={{ background: 'green' }}
                                      ></div>
                                      <span style={{ marginLeft: '7px' }}>
                                        Expected NLV
                                      </span>
                                    </div>
                                  </div>
                                )}
                              />
                              <Line
                                type='monotone'
                                dataKey='pl'
                                stroke='red'
                                dot={{
                                  stroke: 'red',
                                  strokeWidth: 2,
                                  fill: 'white',
                                }}
                                activeDot={{ r: 8 }}
                              />
                              <Line
                                type='monotone'
                                dataKey='nlv'
                                stroke='green'
                                dot={{
                                  stroke: 'green',
                                  strokeWidth: 2,
                                  fill: 'white',
                                }}
                                activeDot={{ r: 8 }}
                              />
                              <XAxis dataKey='nifty'>
                                <Label
                                  value='Nifty Levels'
                                  position='insideBottom'
                                  offset={-2}
                                />
                              </XAxis>
                              <YAxis domain={range?.range1}>
                                <Label
                                  value='Expected P&L and NLV'
                                  position='left'
                                  angle={-90}
                                  offset={15}
                                  dy={-55}
                                />
                              </YAxis>
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                        <Button
                          className='more_details_btn'
                          onClick={openModal}
                        >
                          More Details
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <div className='update_trade_table'>
                      <Row
                        className='update_trade_table_row'
                        gutter={16}
                        style={{ fontWeight: 500 }}
                      >
                        <Col className='update_trade_table_script' span={12}>
                          Script
                        </Col>
                        <Col
                          className='update_trade_table_col update_trade_table_col_border'
                          span={4}
                        >
                          Trade
                        </Col>
                        <Col
                          className='update_trade_table_col'
                          span={4}
                          style={{ borderRight: '1px solid #000' }}
                        >
                          Quantity
                        </Col>
                        <Col className='update_trade_table_col' span={4}>
                          Price
                        </Col>
                      </Row>
                      {data?.length &&
                        data?.map((item, key) => {
                          const { Script, Trade, Quantity, TradePrice, Price } =
                            item
                          return (
                            <Row
                              className={
                                advisoryData?.length - 1 === key
                                  ? 'update_trade_table_row_new'
                                  : 'update_trade_table_row'
                              }
                              gutter={16}
                            >
                              <Col
                                className='update_trade_table_script'
                                span={12}
                              >
                                {Script}
                              </Col>
                              <Col
                                className='update_trade_table_col update_trade_table_col_border'
                                span={4}
                              >
                                {Trade}
                              </Col>
                              <Col
                                className='update_trade_table_col'
                                span={4}
                                style={{ borderRight: '1px solid #000' }}
                              >
                                {Quantity}
                              </Col>
                              <Col className='update_trade_table_col' span={4}>
                                {TradePrice ? TradePrice : Price}
                              </Col>
                            </Row>
                          )
                        })}
                    </div>
                    <Row gutter={16} className='column_for_advisory_table'>
                      <Col span={18}>
                        <Button
                          className='pending_advisory_accept_btn'
                          onClick={onFinish}
                          loading={false}
                        >
                          Accept
                        </Button>
                        <Button
                          type='primary'
                          loading={false}
                          onClick={onReject}
                        >
                          Reject
                        </Button>
                      </Col>
                      <Col span={6}>
                        <div className='question_icon'>
                          <div className='faqs-icon'>
                            <Link
                              to='/faqs'
                              className='nav-text'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <QuestionOutlined className='que_mark' />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className='update_trade_date'>
                <div className='date_div'>
                  <div className='advisory_date_div_1'>
                    <div className='advisory_date_text_new'>
                      <b>Select Trade Date</b>
                    </div>
                    <div className='advisory_date_value'>
                      <DatePicker
                        value={dateForAdvisory ? moment(dateForAdvisory) : ''}
                        format={'DD-MM-YYYY'}
                        className='date_picker'
                        onChange={(e) => handleGetPendingAdvisory(e)}
                      />
                      {(!dateForAdvisory ||
                        dateForAdvisory === 'Invalid date') && (
                        <Tag
                          color='red'
                          style={{
                            marginTop: '15px',
                            display: 'flex',
                            justifyContent: 'center',
                            marginRight: 0,
                          }}
                        >
                          Please Select Date
                        </Tag>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='update_trade_table'>
                <Row
                  className='update_trade_table_row'
                  gutter={16}
                  style={{ fontWeight: 500 }}
                >
                  <Col
                    className='update_trade_table_col'
                    lg={8}
                    md={8}
                    sm={7}
                    xs={7}
                  >
                    Script
                  </Col>
                  <Col
                    className='update_trade_table_col update_trade_table_col_border'
                    lg={4}
                    md={4}
                    sm={3}
                    xs={3}
                  >
                    Trade
                  </Col>
                  <Col className='update_trade_table_col' span={4}>
                    Quantity
                  </Col>
                  <Col
                    className='update_trade_table_col update_trade_table_col_border'
                    lg={4}
                    md={4}
                    sm={5}
                    xs={5}
                  >
                    Traded Quantity
                  </Col>
                  <Col className='update_trade_table_col' span={4}>
                    Trade Price
                  </Col>
                </Row>
                {advisoryData?.length &&
                  advisoryData?.map((item, key) => {
                    const { Script, Trade, Quantity, TradePrice } = item
                    return (
                      <Row
                        className={
                          advisoryData?.length - 1 === key
                            ? 'update_trade_table_row_new'
                            : 'update_trade_table_row'
                        }
                        gutter={16}
                      >
                        <Col
                          className='update_trade_table_col'
                          lg={8}
                          md={8}
                          sm={7}
                          xs={7}
                        >
                          {Script}
                        </Col>
                        <Col
                          className='update_trade_table_col update_trade_table_col_border'
                          lg={4}
                          md={4}
                          sm={3}
                          xs={3}
                        >
                          {Trade}
                        </Col>
                        <Col className='update_trade_table_col' span={4}>
                          {Quantity}
                        </Col>
                        <Col
                          className='update_trade_table_col update_trade_table_col_border'
                          lg={4}
                          md={4}
                          sm={5}
                          xs={5}
                        >
                          {!accept ? (
                            <>
                              <span>{Quantity}</span>
                            </>
                          ) : (
                            <InputNumber
                              defaultValue={accept && Quantity}
                              onChange={(value) => {
                                data[key].TradedQuantity = value
                                setNewData(data)
                              }}
                            />
                          )}
                        </Col>
                        <Col
                          className='update_trade_table_col advisory_value_input'
                          span={4}
                        >
                          {dateForAdvisory &&
                          dateForAdvisory !== 'Invalid date' &&
                          accept ? (
                            <div className='advisory_input'>
                              <InputNumber
                                type='number'
                                defaultValue={
                                  newData?.length &&
                                  newData[key] &&
                                  newData[key].TradePrice > 0
                                    ? newData[key].TradePrice
                                    : undefined
                                }
                                onChange={(value) => {
                                  if (
                                    !isNaN(data[key]?.low) &&
                                    !isNaN(data[key]?.high)
                                  ) {
                                    if (
                                      value < data[key].low ||
                                      value > data[key].high
                                    ) {
                                      setErrorKeys(uniq([...errorKeys, key]))
                                    } else {
                                      setErrorKeys(
                                        errorKeys?.filter(
                                          (item) => item !== key
                                        )
                                      )
                                    }
                                  }
                                  data[key].TradePrice = value
                                  setNewData(data)
                                }}
                              />
                              <div>
                                {errorKeys?.includes(key) &&
                                  !isNaN(data[key].low) &&
                                  !isNaN(data[key].high) && (
                                    <>
                                      <div className='advisory_error'>
                                        Price range is {data[key].low} -{' '}
                                        {data[key].high}
                                      </div>

                                      <div style={{ marginTop: '10px' }}>
                                        <AntTooltip
                                          placement='left'
                                          visible={
                                            errorKeys?.includes(key) &&
                                            !isNaN(data[key].low) &&
                                            !isNaN(data[key].high)
                                          }
                                          title={`Price range is ${data[key].low} - ${data[key].high}`}
                                        >
                                          <InfoCircleOutlined className='error_info_icon' />
                                        </AntTooltip>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          ) : (
                            <>
                              <span>{TradePrice}</span>
                            </>
                          )}
                        </Col>
                      </Row>
                    )
                  })}
              </div>
              <Row gutter={16} className='scenario_advisory'>
                <Col span={18}>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type='primary'
                      disabled={
                        !dateForAdvisory ||
                        dateForAdvisory === 'Invalid date' ||
                        dateForAdvisory === undefined ||
                        errorKeys?.length
                          ? true
                          : false
                      }
                      onClick={showConfirm}
                    >
                      {'Submit'}
                    </Button>
                    <Button onClick={() => setAccept(false)}>{'Cancel'}</Button>
                  </div>
                </Col>

                <Col span={6}>
                  <div
                    className='question_icon'
                    style={{ marginRight: '20px' }}
                  >
                    <div className='faqs-icon' style={{ paddingLeft: '12px' }}>
                      <Link
                        to='/faqs'
                        className='nav-text'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <QuestionOutlined className='que_mark' />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <div>
          {isPending && (
            <div
              onClick={() => setOpenAdvisory(false)}
              className='back_icon_for_advisory'
            >
              <ArrowLeftOutlined />
              <div style={{ marginLeft: '10px' }}>Back to Advisory List</div>
            </div>
          )}
          <div
            style={{
              color: '#000',
              marginTop: '300px',
              fontSize: '25px',
              fontWeight: '500',
              textAlign: 'center',
            }}
          >
            No Pending Advisory Found
          </div>
        </div>
      )}

      {openTableInModal && !isEmpty(data) && (
        <PendingAdvisoryModel
          visible={setOpenTableInModal}
          onCancel={() => handleCancel()}
          onOk={() => handleOnOk()}
          columns={columns}
          data={data}
        />
      )}

      {openMoreDetailsModal && (
        <MoreDetails
          isOpenModal={openMoreDetailsModal}
          closeModal={closeModal}
          bookedAdvisory={allData}
          advisoryDate={allData?.createdAt}
          mapData1={mapData?.mapData1}
          mapData2={mapData?.mapData2}
          range1={range?.range1}
          range2={range?.range2}
          userData={userData}
        />
      )}

      {confirmModal && (
        <ConfirmAdvisoryModal
          visible={confirmModal}
          onOk={confirmAdvisory}
          confirmLoading={false}
        />
      )}
    </>
  )
}
