import gql from 'graphql-tag'

export const CREATE_HOLIDAY = gql`
  mutation CreateHoliday($data: HolidayCreateInput) {
    createHoliday(data: $data) {
      id
      date
      title
    }
  }
`

export const UPDATE_HOLIDAY = gql`
  mutation UpdateHoliday(
    $where: HolidayWhereInput!
    $data: HolidayCreateInput
  ) {
    updateHoliday(where: $where, data: $data) {
      id
      date
      title
    }
  }
`

export const DELETE_HOLIDAY = gql`
  mutation DeleteHoliday($where: HolidayWhereInput!) {
    deleteHoliday(where: $where)
  }
`
