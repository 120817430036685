import map from 'lodash/map'

export function numDifferentiation(value) {
  if (!value) return '-'
  var val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr'
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac'
  }
  return val
}

export function generateUserPerformanceData(data) {
  return map(data, (item, key) => {
    switch (item.Description) {
      case 'New Clients':
        return {
          cardColor: 'teal',
          icon: 'profile2',
          title: item.Description,
          subTitle: item.Value
        }
      case 'Redim AUM':
      case 'Redeem AUM':
        return {
          cardColor: 'cyan',
          icon: 'revenue-new',
          title: 'Redeem AUM',
          subTitle: item.Value
        }
      case 'AMC collection':
        return {
          cardColor: 'orange',
          icon: 'revenue-new',
          title: item.Description,
          subTitle: item.Value
        }
      case 'SIP collection':
        return {
          cardColor: 'indigo',
          icon: 'revenue-new',
          title: item.Description,
          subTitle: item.Value
        }
      case 'Redim Client':
      case 'Redeem Client':
        return {
          cardColor: 'sepia',
          icon: 'profile2',
          title: 'Redeem Client',
          subTitle: item.Value
        }
      case 'New AUM':
        return {
          cardColor: 'purple',
          icon: 'revenue-new',
          title: item.Description,
          subTitle: item.Value
        }
      default:
        break
    }
  })
}

export function generateDashboardData(data) {
  return map(data, (item, key) => {
    switch (key) {
      case 'totalFund':
        return {
          cardColor: 'cyan',
          icon: 'revenue-new',
          title: numDifferentiation(item),
          subTitle: 'Total Fund'
        }
      case 'totalClients':
        return {
          cardColor: 'orange',
          icon: 'profile2',
          title: item || '-',
          subTitle: 'Clients'
        }
      case 'totalTickets':
        return {
          cardColor: 'teal',
          icon: 'tag-o',
          title: item || '-',
          subTitle: 'Open Tickets'
        }
      case 'totalProfitLoss':
        return {
          cardColor: 'purple',
          icon: 'growth',
          title: item ? `${item}%` : '-',
          subTitle: 'Total Profit Loss'
        }
      case 'totalGroups':
        return {
          cardColor: 'purple',
          icon: 'team',
          title: item || '-',
          subTitle: 'Groups'
        }
      default:
        break
    }
  })
}

export function generateDashboardSummary(data) {
  return map(data, (item, key) => {
    switch (key) {
      case 'TotalFund':
        return {
          cardColor: 'cyan',
          icon: 'revenue-new',
          title: item || '-',
          subTitle: 'Total Fund'
        }
      case 'Clients':
        return {
          cardColor: 'orange',
          icon: 'profile2',
          title: !!item ? item : item === 0 ? 0 : '-',
          subTitle: 'Clients'
        }
      case 'Tickets':
        return {
          cardColor: 'teal',
          icon: 'tag-o',
          title: item || '-',
          subTitle: 'Open Tickets'
        }
      case 'Pnlper':
        return {
          cardColor: 'purple',
          icon: 'growth',
          title: item || '-',
          subTitle: 'Total Profit Loss'
        }
      case 'totalGroups':
        return {
          cardColor: 'purple',
          icon: 'team',
          title: item || '-',
          subTitle: 'Groups'
        }
      default:
        break
    }
  })
}
