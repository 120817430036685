import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'
import React, { useState } from 'react'
import { Table, PageHeader, Button, Modal, Tooltip, Tag } from 'antd'
import { EditTwoTone, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons'

import client from 'apollo'
import Page404 from 'components/Page404'
import { GET_FLAGS } from './graphql/Query'
import CreateFlagModal from './CreateFlagModal'
import { CREATE_FLAG, UPDATE_FLAG, DELETE_FLAG } from './graphql/Mutation'
import openNotification from 'utils/Notification'

const { confirm } = Modal
export default function () {
  const [showFormModal, setShowFormModal] = useState(false)
  const [selectedFlag, setSelectedFlag] = useState(undefined)

  const { loading, error, data } = useQuery(GET_FLAGS, { fetchPolicy: 'network-only' })

  let allFlags
  if (error) return <Page404 error={error} />
  if (!loading && get(data, 'getFlags')) {
    allFlags = data.getFlags.map((flag, key) => ({ key: key.toString(), ...flag }))
  }

  function handleUpdateFlag(values, resetForm) {
    const data = values
    const { id } = selectedFlag
    client.mutate({
      mutation: UPDATE_FLAG, variables: { data, where: { id: parseInt(id) } },
      refetchQueries: [{ query: GET_FLAGS }]
    })
      .then((res) => {
        openNotification('success', 'Flags Updated Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleHideFormModal() {
    setShowFormModal(false)
    setSelectedFlag(undefined)
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this flag?`,
      content: `When clicked the OK button, this flag will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_FLAG, variables: { where: { id } },
            refetchQueries: [{ query: GET_FLAGS }]
          })
          .catch((err) => console.log(err))
        openNotification('success', 'Plan Deleted Successfully')
      }
    })
  }

  function renderAction(record) {
    return <div className='action-icons'>
      <Tooltip title='Edit'>
        <EditTwoTone
          onClick={() => {
            setShowFormModal(true)
            setSelectedFlag(record)
          }} />
      </Tooltip>
      <Tooltip title='Delete Plan'>
        <DeleteOutlined onClick={() => showConfirm(record.id)} />
      </Tooltip>
    </div>
  }

  function handleCreateFlag(values, resetForm) {
    const data = values
    client.mutate({ mutation: CREATE_FLAG, variables: { data }, refetchQueries: [{ query: GET_FLAGS }] })
      .then((res) => {
        openNotification('success', 'Flag Added Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => <Tag>{text ? 'true' : 'false'}</Tag>
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
    }
  ]

  return (
    <>
      <PageHeader
        className='box'
        title='Flags'
        extra={[
          <Button
            key='1'
            type='primary'
            onClick={() => setShowFormModal(true)}
          >
            Add Flag
          </Button>
        ]}
      />
      {showFormModal &&
        <CreateFlagModal
          visible={showFormModal}
          onCreate={handleCreateFlag}
          onUpdate={handleUpdateFlag}
          selectedFlag={selectedFlag}
          isEdit={selectedFlag !== undefined}
          onCancel={() => handleHideFormModal()}
        />
      }
      <Table columns={columns} dataSource={allFlags} />
    </>
  )
}
