export default [
  {
    'id': 'INBOX',
    'handle': 'inbox',
    'title': 'Inbox',
    'icon': 'inbox'
  },
  {
    'id': 'SENTBOX',
    'handle': 'sent',
    'title': 'Sent',
    'icon': 'sent'
  },
]
