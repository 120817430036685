import gql from "graphql-tag"

export const GET_FLAGS = gql`
  query getFlags {
    getFlags {
    id
    name
    value
    message
  }
}
`
