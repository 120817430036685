import React, { useState } from 'react'
import { Button, notification, Collapse } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_FAQUESTION } from '../graphql/Queries'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'

import '../verification.css'
import CreateTicketModal from 'modules/Tickets/CreateTicketModal'
import client from 'apollo'
import { CREATE_TICKET } from 'modules/Tickets/graphql/Mutations'

const { Panel } = Collapse

const FAQsModel = () => {
  const [openCreateTicketModel, setOpenCreateATicketModel] = useState(false)
  const [ticketLoading, setTicketLoading] = useState(false)
  const { data, loading, error } = useQuery(GET_ALL_FAQUESTION, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 />

  const openTicketModel = () => {
    setOpenCreateATicketModel(!openCreateTicketModel)
  }

  const handleCreateTicket = (values, resetForm) => {
    setTicketLoading(true)
    let files = []
    if (values?.fileUrl) {
      values.fileUrl.forEach((file) => {
        files.push(file.originFileObj)
      })
      values.fileUrl = files
    } else {
      delete values.fileUrl
    }

    client
      .mutate({
        mutation: CREATE_TICKET,
        variables: { ...values },
      })
      .then((res) => {
        notification.success({
          duration: 2.5,
          message: (
            <>
              Ticket created Successfully
              <br />
              {`Note Your Ticket Number.:${res?.data?.createTicket?.ticketNumber}`}
            </>
          ),
        })
        setOpenCreateATicketModel(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setTicketLoading(false))
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div className='faqs_page'>
              <div className='faqs_title'>
                Read some of the top queries asked by our customers.
              </div>
              <div>
                <img
                  src='https://finideas-india.blr1.digitaloceanspaces.com/Finideas-black-png.png'
                  alt='Finideas'
                  width={150}
                  height={50}
                />
              </div>
            </div>
            <div className='faqs-questions-div'>
              <div>
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <MinusOutlined className='faq-plus-icon' />
                    ) : (
                      <PlusOutlined className='faq-plus-icon' />
                    )
                  }
                >
                  {data?.getAllFAQuestions &&
                    data?.getAllFAQuestions?.map((item, key) => (
                      <Panel
                        header={
                          <span className='faq_questions_answers'>
                            {item?.question}
                          </span>
                        }
                        key={key}
                      >
                        <p className='faq_answer'>{item?.answer}</p>
                      </Panel>
                    ))}
                </Collapse>
              </div>
              <div className='create_ticket_div'>
                <p className='create_ticket_title'>Still need help?</p>
                <Button type='primary' onClick={openTicketModel}>
                  Create ticket
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {openCreateTicketModel && (
        <CreateTicketModal
          visible={openCreateTicketModel}
          loading={ticketLoading}
          onCreate={handleCreateTicket}
          onCancel={() => setOpenCreateATicketModel(false)}
          fromFaq={true}
        />
      )}
    </>
  )
}
export default FAQsModel
