import { useQuery } from '@apollo/react-hooks'
import { Descriptions } from 'antd'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import { GET_ALL_USERS_ANSWERS } from 'modules/Verification/graphql/Queries'
import React from 'react'

export default function UserQuestionnaire({ planId, userId, annualIncome }) {
  const {
    data: userAnswers,
    loading: userAnswersLoading,
    error: userAnswersError,
  } = useQuery(
    GET_ALL_USERS_ANSWERS,
    { variables: { where: { userId: userId } } },
    { fetchPolicy: 'network-only' }
  )

  if (userAnswersLoading) return <Loader />
  if (userAnswersError) return <Page404 error={userAnswersError} />
  return (
    <Descriptions bordered>
      {/* <Descriptions.Item label={'1. What is your annual Income?'} span={3}>{annualIncome}</Descriptions.Item> */}
      {userAnswers?.getAllUsersAnswers?.map((data, key) => {
        if (data?.question) {
          let answer
          if (JSON.parse(data?.selectedOptions)) {
            JSON.parse(data?.selectedOptions).map((option) => {
              return (answer = option?.option)
            })
          }

          return (
            <Descriptions.Item label={`${data?.question?.question}`} span={3}>
              {answer}
            </Descriptions.Item>
          )
        }
        return true
      })}
    </Descriptions>
  )
}
