import React, { useState, useEffect, useContext } from 'react'
import {
  Radio,
  Form,
  Input,
  Button,
  Upload,
  Row,
  Col,
  Select,
  PageHeader,
  DatePicker,
  Space,
  Switch,
} from 'antd'
import {
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import '../../../modules/Clients/clients.css'
import moment from 'moment'
import { GET_INVOICE_SETTING } from '../graphql/Queries'
import { UPDATE_INVOICE_SETTING } from '../graphql/Mutations'
import client from 'apollo'
import InvoiceTable from './invoiceTable'
import { AppContext } from 'AppContext'
import openNotification from 'utils/Notification'
import CustomMeta from 'components/CustomMeta'
import { isEmpty } from 'lodash'
import Loader from 'components/loaders/Loader'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const Invoice = () => {
  const { dispatch } = useContext(AppContext)

  const [getData, setGetData] = useState({})
  const [invoiceDateValue, setInvoiceDate] = useState('MANUALLY')
  const [fileList, setFileList] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [invoice, setInvoice] = useState(moment().format())
  const [uploadDataLoader, setUploadDataLoader] = useState(false)
  const [highLow, setHighLow] = useState(false)

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_INVOICE_SETTING,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        dispatch({
          type: 'INVOICE_SETTING',
          data: res.data.getInvoiceSetting,
        })
        setInvoice(res.data.getInvoiceSetting.invoiceDate)
        setGetData(res.data.getInvoiceSetting)
        setInvoiceDate(res.data.getInvoiceSetting.invoiceDateType)
        setHighLow(
          res.data.getInvoiceSetting.selectAmcPercentage === 'high'
            ? true
            : false
        )
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }, [dispatch])

  useEffect(() => {
    let array = []
    if (!isEmpty(getData)) {
      Promise.all(
        getData?.amcDates.map(async (item, index) => {
          array.push({
            ...item,
            invoiceDate: moment(invoice).format('DD-MMM-YYYY'),
            key: index + 1,
          })
        })
      )
      setData(array)
    }
  }, [invoice, getData])

  if (isEmpty(getData)) return <Loader />

  const dateInvoice = (e) => {
    if (e.target.value === 'AUTO') {
      setInvoice(moment().format('DD-MMM-YYYY'))
    } else {
      setInvoice(getData?.invoiceDate)
    }
    setInvoiceDate(e.target.value)
  }

  const onFinish = (values) => {
    setUploadDataLoader(true)
    // if (fileList === undefined) {
    //   delete values.qrImage
    // }

    values = {
      ...values,
      qrImage: values.qrImage[0].originFileObj,
      amcDates: data,
      autoGenerateInvoiceStartNumber: +values.autoGenerateInvoiceStartNumber,
      invoiceDate:
        invoiceDateValue === 'AUTO'
          ? moment().format('YYYY-MM-DD')
          : moment(invoice).format('YYYY-MM-DD'),
      invoiceDateType: invoiceDateValue,
      selectAmcPercentage: highLow ? 'high' : 'low',
    }

    client
      .mutate({
        mutation: UPDATE_INVOICE_SETTING,
        variables: { data: values },
      })
      .then((res) => {
        dispatch({
          type: 'INVOICE_SETTING',
          data: res.data.updateInvoiceSetting,
        })
        // setGetData(res.data.updateInvoiceSetting)
        setData(JSON.parse(res?.data?.updateInvoiceSetting?.amcDates))
        setUploadDataLoader(false)
        openNotification('success', 'Update Invoice Setting Successfully')
      })
      .catch((err) => {
        console.log(err)
        setUploadDataLoader(false)
      })
      .finally(() => setUploadDataLoader(false))
  }

  const handleDateChanges = (value) => {
    setInvoice(value)
  }

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  const options = [
    {
      value: '2018-19',
      label: '2018-19',
    },
    {
      value: '2019-20',
      label: '2019-20',
    },
    {
      value: '2020-21',
      label: '2020-21',
    },
    {
      value: '2021-22',
      label: '2021-22',
    },
    {
      value: '2022-23',
      label: '2022-23',
    },
    {
      value: '2023-24',
      label: '2023-24',
    },
    {
      value: '2024-25',
      label: '2024-25',
    },
    {
      value: '2025-26',
      label: '2025-26',
    },
    {
      value: '2026-27',
      label: '2026-27',
    },
    {
      value: '2027-28',
      label: '2027-28',
    },
    {
      value: '2028-29',
      label: '2028-29',
    },
    {
      value: '2029-30',
      label: '2029-30',
    },
    {
      value: '2030-31',
      label: '2030-31',
    },
    {
      value: '2031-32',
      label: '2031-32',
    },
    {
      value: '2032-33',
      label: '2032-33',
    },
    {
      value: '2033-34',
      label: '2033-34',
    },
    {
      value: '2034-35',
      label: '2034-35',
    },
    {
      value: '2035-36',
      label: '2035-36',
    },
    {
      value: '2036-37',
      label: '2036-37',
    },
    {
      value: '2037-38',
      label: '2037-38',
    },
    {
      value: '2038-39',
      label: '2038-39',
    },
    {
      value: '2039-40',
      label: '2039-40',
    },
  ]

  return loading ? (
    <Loader />
  ) : (
    <div>
      <CustomMeta title={'Invoice'} description='' />
      <PageHeader className='box' title='Invoice Setting' />
      <Form
        {...formItemLayout}
        name='register'
        onFinish={onFinish}
        loading={loading}
        autoComplete='off'
        scrollToFirstError
        className='client-form'
        initialValues={{
          accountDetails: getData?.accountDetails,
          address: getData?.address,
          autoGenerateInvoiceStartNumber:
            getData?.autoGenerateInvoiceStartNumber,
          bankName: getData?.bankName,
          billTypeParticular: getData?.billTypeParticular,
          branchName: getData?.branchName,
          companyName: getData?.companyName,
          gstinNumber: getData?.gstinNumber,
          ifscCode: getData?.ifscCode,
          invoiceDateType: getData?.invoiceDateType,
          invoiceDate: moment(getData?.invoiceDate),
          invoiceNumber: getData?.invoiceNumber,
          invoiceYear: getData?.invoiceYear,
          panNumber: getData?.panNumber,
          upiId: getData?.upiId,
          type: getData?.type,
          webEmail: getData?.webEmail,
          otherMailId: getData?.otherMailId,
          qrImage: [
            {
              uid: `qrImage ${getData?.id}`,
              name: 'qrImage.png',
              url: getData?.qrImage,
            },
          ],
          amcPercentage: getData?.amcPercentage || [],
        }}
      >
        <FormItem
          name='type'
          label='Default Select'
          rules={[
            {
              required: true,
              message: 'Answer is require',
            },
          ]}
        >
          <Input disabled />
        </FormItem>
        <Row gutter={16}>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Company Name'
              name='companyName'
              rules={[
                {
                  required: true,
                  message: 'Please input your Company Name!',
                },
              ]}
            >
              <Input placeholder='Enter Company' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Website & Email id'
              name='webEmail'
              rules={[
                {
                  required: true,
                  message: 'Please input your Website & Email id!',
                },
              ]}
            >
              <Input placeholder='Enter Website & Email id' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Address'
              name='address'
              rules={[
                {
                  required: true,
                  message: 'Please input your Address!',
                },
              ]}
            >
              <Input placeholder='Please Enter your Address' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Invoice No'
              name='invoiceNumber'
              rules={[
                {
                  required: true,
                  message: 'Please input your Invoice No!',
                },
              ]}
            >
              <Input placeholder='Please Enter your Invoice No' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Account Details'
              name='accountDetails'
              rules={[
                {
                  required: true,
                  message: 'Please input your Account Details!',
                },
              ]}
            >
              <Input placeholder='Please Enter your Account Details' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Bank Name'
              name='bankName'
              rules={[
                {
                  required: true,
                  message: 'Please input your Bank Name!',
                },
              ]}
            >
              <Input placeholder='Please Enter your Bank Name' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Branch Name'
              name='branchName'
              rules={[
                {
                  required: true,
                  message: 'Please input your Branch Name!',
                },
              ]}
            >
              <Input placeholder='Please Enter your Branch Name' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='IFSC Code'
              name='ifscCode'
              rules={[
                {
                  required: true,
                  message: 'Please input your IFSC Code!',
                  pattern: new RegExp('^[A-Z]{4}0[A-Z0-9]{6}$'),
                },
              ]}
            >
              <Input placeholder='Please Enter your IFSC Code' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='PAN Number'
              name='panNumber'
              rules={[
                {
                  required: true,
                  message: 'Please input Valid PAN Number!',
                  pattern: new RegExp(
                    /[A-Za-z]{3}[ABCFGHLJPTFabcfghjpltf]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}/g
                  ),
                },
              ]}
            >
              <Input
                placeholder='Please Enter your PAN Number'
                style={{ textTransform: 'uppercase' }}
                maxLength='10'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              type='number'
              label='Invoice Start Number'
              name='autoGenerateInvoiceStartNumber'
              rules={[
                {
                  required: true,
                  message: 'Please input Invoice Start Number!',
                },
              ]}
            >
              <Input placeholder='Please Enter your Invoice Start Number' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Invoice Date'
              name='invoiceDateType'
              rules={[
                {
                  // required: true,
                  message: 'Please input Invoice Date!',
                },
              ]}
            >
              <Radio.Group onChange={dateInvoice} value={invoiceDateValue}>
                <Radio value={'AUTO'}>Auto</Radio>
                <Radio value={'MANUALLY'}>Manual</Radio>
              </Radio.Group>

              {invoiceDateValue !== 'AUTO' && (
                <FormItem
                  name='invoiceDate'
                  rules={[
                    {
                      required: true,
                      message: 'Invoice Date is required!',
                    },
                  ]}
                >
                  <DatePicker
                    style={{ marginLeft: '20px' }}
                    value={
                      invoiceDateValue === 'AUTO'
                        ? moment().format('YYYY-MM-DD')
                        : ''
                    }
                    onChange={handleDateChanges}
                    format='DD-MMM-YYYY'
                  />
                </FormItem>
              )}
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='GST Number'
              name='gstinNumber'
              rules={[
                {
                  required: true,
                  message: 'Please input GST Number!',
                },
              ]}
            >
              <Input placeholder='Please Enter GST Number' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='UPI Id'
              name='upiId'
              rules={[
                {
                  required: true,
                  message: 'Please input UPI Id!',
                },
              ]}
            >
              <Input placeholder='Please Enter UPI Id' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='qrImage'
              label='QR image'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='qr-file'
                listType='picture'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Invoice FY'
              name='invoiceYear'
              rules={[
                {
                  required: true,
                  message: 'Please Select Invoice FY!',
                },
              ]}
            >
              <Select
                showSearch
                placeholder='Select a Finance Year'
                optionFilterProp='children'
                options={options}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Bill Type Particular'
              name='billTypeParticular'
              rules={[
                {
                  required: true,
                  message: 'Please input Bill Type Particular!',
                },
              ]}
            >
              <Input placeholder='Please Enter Bill Type Particular' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Other Mail Id'
              name='otherMailId'
              rules={[
                {
                  required: true,
                  message: 'Please input Other Mail Id!',
                },
              ]}
            >
              <Input placeholder='Please Enter Other Mail Id' />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label='Amc Percentage High or Low' name='highLow'>
              <Switch checked={highLow} onChange={(e) => setHighLow(e)} />
              <span style={{ marginLeft: '10px' }}>
                Select {highLow ? 'High' : 'Low'} Amc Percentage
              </span>
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label='AMC Percentages'>
              <Form.List name='amcPercentage'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          marginBottom: 8,
                        }}
                        align='baseline'
                      >
                        <Col
                          className='gutter-row'
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          xl={8}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'minExposure']}
                            fieldKey={[fieldKey, 'minExposure']}
                            key={key}
                            style={{ width: '170px' }}
                          >
                            <Input type='number' placeholder='Min Exposure' />
                          </Form.Item>
                        </Col>
                        <Col
                          className='gutter-row'
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          xl={8}
                        >
                          <Form.Item
                            style={{ width: '170px' }}
                            {...restField}
                            name={[name, 'maxExposure']}
                            fieldKey={[fieldKey, 'maxExposure']}
                            key={key}
                          >
                            <Input type='number' placeholder='Max Exposure' />
                          </Form.Item>
                        </Col>
                        <Col
                          className='gutter-row'
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          xl={8}
                        >
                          <Form.Item
                            style={{ width: '170px' }}
                            {...restField}
                            name={[name, 'percentage']}
                            fieldKey={[fieldKey, 'percentage']}
                            key={key}
                          >
                            <Input placeholder='percentage' />
                          </Form.Item>
                        </Col>
                        {fields.length > 1 && (
                          <MinusCircleOutlined
                            style={{ marginLeft: '20px' }}
                            onClick={() => remove(name)}
                          />
                        )}
                      </Space>
                    ))}
                    <Col
                      className='gutter-row'
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      xl={8}
                    >
                      <Form.Item>
                        <Button
                          type='dashed'
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add AMC Percentage
                        </Button>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ width: '100%' }}
          >
            <Form.Item
              label='Amc Dates'
              rules={[
                {
                  required: true,
                  message: 'Please input Bill Type Particular!',
                },
              ]}
            >
              <InvoiceTable setData={setData} data={data} />
            </Form.Item>
          </Col>
        </Row>
        {/* // )} */}
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit' loading={uploadDataLoader}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Invoice
