import React, { useContext, useEffect, useState } from 'react'

import { GET_MULTIPLE_ADVISORY } from './graphql/Queries'
import PendingAdvisoryTable from './PendingAdvisoryTable'
import client from 'apollo'
import { sortBy } from 'lodash'
import { ArrowLeftOutlined } from '@ant-design/icons'
import Loader from 'components/loaders/Loader'
import { AppContext } from 'AppContext'
import { CREATE_BOOK_ADVISORY } from 'modules/Verification/graphql/Mutations'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

export default function PendingAdvisory({
  clientData,
  setOpenAdvisory,
  getAllPendingAdvisory,
}) {
  const [dateForAdvisory, setDateForAdvisory] = useState('')
  const [advisoryData, setAdvisoryData] = useState([])
  const [data, setData] = useState({})
  const [mapData, setMapData] = useState({})
  const [range, setRange] = useState({})
  const [loading, setLoading] = useState(false)
  const [accept, setAccept] = useState(false)
  const [bookedAdvisoryId, setBookAdvisoryId] = useState('')
  const {
    state: { currentUser },
  } = useContext(AppContext)

  const userData = clientData ? clientData : currentUser

  const [typeValue, setTypeValue] = useState()
  const [allTypes, setAllTypes] = useState([])
  const [typeIndex, setTypeIndex] = useState(0)
  const refetch = (isRefetch = false, isRedirect = false) => {
    setLoading(true)
    if (isRefetch && !isEmpty(clientData)) {
      getAllPendingAdvisory()
    }
    let mapData1 = []
    let mapData2 = []
    const range1 = []
    const range2 = []
    client
      .query({
        query: GET_MULTIPLE_ADVISORY,
        variables: {
          where: {
            clientCode: userData?.clientCode,
            id: userData?.id,
            advisoryDate: dateForAdvisory,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (isRefetch && isRedirect) {
          if (currentUser?.id !== userData?.id) {
            window.location.reload()
            window.location.replace('/pending-advisory-for-associate')
          } else {
            window.location.reload()
            window.location.replace('/pending-advisory')
          }
        }
        const newAdvisory = res?.data?.getMultipleAdvisoryData.find(
          (item) => item?.type === typeValue
        )
        setAdvisoryData(newAdvisory?.currentAdvisoryData || [])
        setData(newAdvisory || {})

        if (newAdvisory?.currentAdvisoryData?.length) {
          if (newAdvisory?.mapScenario1) {
            const red1 =
              newAdvisory?.mapScenario1[1] &&
              Object.keys(newAdvisory?.mapScenario1[1])
                .filter((key) => key !== 'Description')
                .map((key, index) => newAdvisory?.mapScenario1[1][key])

            const green1 =
              newAdvisory?.mapScenario1[2] &&
              Object.keys(newAdvisory?.mapScenario1[2])
                .filter((key) => key !== 'Description')
                .map((key, index) => newAdvisory?.mapScenario1[2][key])

            const nifty1 =
              newAdvisory?.mapScenario1[0] &&
              Object.keys(newAdvisory?.mapScenario1[0])
                .filter((key) => key !== 'Description')
                .map((key, index) => newAdvisory?.mapScenario1[0][key])

            for (let i = 0; i < red1?.length; i++) {
              let d = {
                nifty: nifty1[i],
                pl: red1[i],
                nlv: green1[i],
              }

              mapData1.push(d)
            }

            const red2 =
              newAdvisory?.mapScenario2[1] &&
              Object.keys(newAdvisory?.mapScenario2[1])
                .filter((key) => key !== 'Description')
                .map((key, index) => newAdvisory?.mapScenario2[1][key])

            const green2 =
              newAdvisory?.mapScenario2[2] &&
              Object.keys(newAdvisory?.mapScenario2[2])
                .filter((key) => key !== 'Description')
                .map((key, index) => newAdvisory?.mapScenario2[2][key])

            const nifty2 =
              newAdvisory?.mapScenario2[0] &&
              Object.keys(newAdvisory?.mapScenario2[0])
                .filter((key) => key !== 'Description')
                .map((key, index) => newAdvisory?.mapScenario2[0][key])
            for (let i = 0; i < red1?.length; i++) {
              let d = {
                nifty: nifty2[i],
                pl: red2[i],
                nlv: green2[i],
              }

              mapData2.push(d)
            }
            for (const d of mapData1) {
              range1.push(+d.pl, +d.nlv)
            }

            for (const d of mapData2) {
              range2.push(+d.pl, +d.nlv)
            }

            let mapObject = {
              mapData1: sortBy(mapData1, 'nifty'),
              mapData2: sortBy(mapData2, 'nifty'),
            }
            let rangeObject = {
              range1: [
                Math.min(...range1) - 10000,
                Math.max(...range1) + 10000,
              ],
              range2: [
                Math.min(...range2) - 10000,
                Math.max(...range2) + 10000,
              ],
            }
            if (dateForAdvisory !== '') {
              setAccept(true)
            }
            setMapData(mapObject)
            setRange(rangeObject)
          }
          if (!isRefetch && !dateForAdvisory) {
            client
              .mutate({
                mutation: CREATE_BOOK_ADVISORY,
                variables: {
                  data: {
                    userId: userData?.id,
                    advisoryDate: moment().format(),
                    isAdvisoryBooked: true,
                    isClientLogin: true,
                    isClientAcceptAdvisory: false,
                    isTimeUp: false,
                    isPendingAdvisory: true,
                    isTermAndServiceAccepted:
                      !isEmpty(newAdvisory) && newAdvisory?.type === 'New'
                        ? false
                        : true,
                    isPendingAdvisoryAccepted: false,
                    advisoryType: !isEmpty(newAdvisory)
                      ? newAdvisory?.type
                      : null,
                    advisorySendDateAndTime: moment(
                      res?.data?.getAdvisoryData?.createdDate
                    ).format(),
                  },
                },
              })
              .then((res) => {
                setBookAdvisoryId(res?.data?.createBookAdvisory?.id)
                setAccept(
                  res?.data?.createdBookAdvisory?.isPendingAdvisoryAccepted
                )
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
              })
          }
        }

        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_MULTIPLE_ADVISORY,
        variables: {
          where: { clientCode: userData?.clientCode, id: userData?.id },
        },
      })
      .then((res) => {
        setAllTypes(
          res?.data?.getMultipleAdvisoryData?.map((item) => item?.type)
        )
        setTypeIndex(0)
        setTypeValue(res?.data?.getMultipleAdvisoryData[0]?.type)
      })
  }, [currentUser, userData])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForAdvisory, typeValue])

  // const { data, loading, error, refetch } = useQuery(GET_ADVISORY_DATA, {
  //   // variables: { where: { advisoryData: dateForAdvisory } },
  //   fetchPolicy: 'network-only',
  // })

  if (loading) return <Loader />
  // if (error) return <Page404 error={error} />
  // if (!get(data, 'getAdvisoryData')) return <NoData />

  return (
    <div>
      {allTypes?.length ? (
        <div className='multiple_advisory'>
          {clientData ? (
            <div
              style={{
                display: 'flex',
                margin: '9px 10px 0px 0px',
                cursor: 'pointer',
              }}
              onClick={() => setOpenAdvisory(false)}
            >
              <div>
                <ArrowLeftOutlined />
              </div>
              <div style={{ marginLeft: '10px' }}>Back to Advisory List</div>
            </div>
          ) : (
            ''
          )}
          {allTypes?.length
            ? allTypes?.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      setTypeValue(item)
                      setTypeIndex(index)
                    }}
                    className={
                      typeIndex === index
                        ? 'pending_advisory_types_active'
                        : 'pending_advisory_types'
                    }
                  >
                    <span>{item}</span>
                  </div>
                )
              })
            : ''}
        </div>
      ) : (
        ''
      )}
      {bookedAdvisoryId !== '' ? (
        <PendingAdvisoryTable
          // advisoryData={data?.getAdvisoryData}
          advisoryData={advisoryData}
          refetch={refetch}
          setDateForAdvisory={setDateForAdvisory}
          dateForAdvisory={dateForAdvisory}
          allData={data}
          mapData={mapData}
          range={range}
          accept={accept}
          setAccept={setAccept}
          bookedAdvisoryId={bookedAdvisoryId}
          userData={userData}
          isPending={clientData ? true : false}
          setOpenAdvisory={setOpenAdvisory}
        />
      ) : (
        <div>
          {clientData && (
            <div
              onClick={() => setOpenAdvisory(false)}
              className='back_icon_for_advisory'
            >
              <ArrowLeftOutlined />
              <div style={{ marginLeft: '10px' }}>Back to Advisory List</div>
            </div>
          )}
          <div
            style={{
              color: '#000',
              marginTop: '300px',
              fontSize: '25px',
              fontWeight: '500',
              textAlign: 'center',
            }}
          >
            No Pending Advisory Found
          </div>
        </div>
      )}
    </div>
  )
}
