import React, { Fragment, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import get from 'lodash/get'
import { NavLink } from 'react-router-dom'
import { useJsonToCsv } from 'react-json-csv'
import { useQuery } from '@apollo/react-hooks'
import { UploadOutlined } from '@ant-design/icons'
import {
  PageHeader,
  Table,
  Button,
  Input,
  Space,
  Tooltip,
  Modal,
  DatePicker,
  Upload,
  Form,
} from 'antd'
import {
  SearchOutlined,
  EditTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
  NotificationOutlined,
} from '@ant-design/icons'

import {
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  SEND_GROUP_NOTIFICATION,
} from './graphql/Mutations'
import NotificationGroupModal from './NotificationGroupModal'
import { IMPORT_GROUP } from '../Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import CreateGroupModal from './CreateGroupModal'
import CustomMeta from 'components/CustomMeta'
import { GET_GROUPS } from './graphql/Queries'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import client from 'apollo'
import _isEmpty from 'lodash/isEmpty'

const { confirm } = Modal
const { RangePicker } = DatePicker
const { saveAsCsv } = useJsonToCsv()

export default function () {
  let tableData = []
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [sortingOrder, setSortingOrder] = useState('DESC')
  const [showFormModal, setShowFormModal] = useState(false)
  const {
    state: { currentReport },
  } = useContext(AppContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(undefined)
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const variables = {
    where: filters,
    limit,
    skip,
    sort: { sortingOrder, field: 'createdAt' },
  }
  const { data, loading, error } = useQuery(GET_GROUPS, {
    variables,
    fetchPolicy: 'network-only',
  })
  const [csv, setCsv] = useState(undefined)
  const [isCsvDownload, setIsCsvDownload] = useState(false)

  if (error) return <Page404 error={error} />

  let totalCount = 0

  if (!loading && get(data, 'getGroups.groups')) {
    totalCount = data.getGroups.count
    tableData = data.getGroups.groups.map((group, key) => ({
      key: key.toString(),
      ...group,
    }))
  }
  useEffect(() => {
    if (skip === 0 && limit === 500 && !loading && isCsvDownload) {
      const newData = data.getGroups.groups
      const fields = {
        id: 'id',
        name: 'name',
        userIds: 'userIds',
      }
      saveAsCsv({ data: newData, fields, filename: 'Groups' })
      setIsCsvDownload(false)
    }
  }, [skip, limit, loading, data, isCsvDownload])

  function handleCsvDownload() {
    setIsCsvDownload(true)
    setLimit(500)
    setSkip(0)
  }
  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this group?`,
      content: `When clicked the OK button, this group will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_GROUP,
            variables: { id },
            refetchQueries: [{ query: GET_GROUPS, variables }],
          })
          .catch((err) => console.log(err))
      },
    })
  }

  function handleHideFormModal() {
    setShowFormModal(false)
    setSelectedGroup(undefined)
  }

  function handleHideNotificationModal() {
    setShowNotificationModal(false)
    setSelectedGroup(undefined)
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        {currentReport.includes('Update Group') && (
          <Tooltip title='Edit'>
            <EditTwoTone
              onClick={() => {
                setShowFormModal(true)
                setSelectedGroup(record)
              }}
            />
          </Tooltip>
        )}
        <Tooltip title='Send Notification'>
          <NotificationOutlined
            onClick={() => {
              setShowNotificationModal(true)
              setSelectedGroup(record)
            }}
          />
        </Tooltip>
        <Tooltip title='Delete Group'>
          <DeleteOutlined onClick={() => showConfirm(record.id)} />
        </Tooltip>
      </div>
    )
  }

  function handleNotificationModal(values, resetForm) {
    if (values.file) {
      values.file = values.file[0].originFileObj
    }
    const getUserPhoneEmails =
      values.sendVia === 'EMAIL' || values.sendVia === 'TEMPLATE'
        ? 'email'
        : 'phone'
    const userPhoneEmails = selectedGroup.users.map(
      (user) => user[getUserPhoneEmails]
    )
    const data = { ...values, userPhoneEmails, userIds: selectedGroup?.userIds }
    const { id } = selectedGroup
    client
      .mutate({ mutation: SEND_GROUP_NOTIFICATION, variables: { data, id } })
      .then((res) => {
        openNotification('success', 'Notification Sent')
        handleHideNotificationModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleCreateGroup(values, resetForm) {
    const data = values
    client
      .mutate({
        mutation: CREATE_GROUP,
        variables: { data },
        refetchQueries: [{ query: GET_GROUPS, variables }],
      })
      .then((res) => {
        openNotification('success', 'Group Added Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleUpdateGroup(values, resetForm) {
    const data = values
    const { id } = selectedGroup
    client
      .mutate({
        mutation: UPDATE_GROUP,
        variables: { data, id },
        refetchQueries: [{ query: GET_GROUPS, variables }],
      })
      .then((res) => {
        openNotification('success', 'Group Updated Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleTableChange(pagination, filter, sorter) {
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (filter.status) {
      tempFilters['status'] = filter.status
      setFilters(tempFilters)
    } else if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSortingOrder('DESC')
      } else {
        setSortingOrder('ASC')
      }
    } else {
      tempFilters['status'] = ''
      delete tempFilters.status
      setFilters(tempFilters)
    }
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  const showModal = () => {
    setIsModalVisible(true)
  }

  const [form] = Form.useForm()

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        client
          .mutate({
            mutation: IMPORT_GROUP,
            variables: { data: { csv: values.csv[0].originFileObj } },
          })
          .then(async (res) => {
            // if (get(res, 'data.importUsers.errorUsers') && get(res, 'data.importUsers.importUsersCount') && get(res, 'data.importUsers.errorUsersCount')) {
            openNotification('success', `${res.data.importGroup}`)
            const fields = {
              address: 'address',
              cityId: 'cityId',
              clientCode: 'clientCode',
              countryId: 'countryId',
              createdById: 'createdById',
              dateOfBirth: 'dateOfBirth',
              email: 'email',
              firstName: 'firstName',
              isActive: 'isActive',
              lastName: 'lastName',
              password: 'password',
              phone: 'phone',
              role: 'role',
              stateId: 'stateId',
              strategyCode: 'strategyCode',
              userName: 'userName',
            }

            // converting json to csv
            saveAsCsv({
              data: res.data.importGroup.errorUsers,
              fields,
              filename: 'Error Users',
            })
            // }
          })
          .catch((err) => console.log(err))
      })
      .catch((info) => console.log('Validate Failed:', info))

    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    if (e && e.fileList && e.fileList.length > 1 && e.file) {
      setCsv([e.file])
      return e && e.fileList
    }
    setCsv(e.fileList)
    return e && e.fileList
  }

  const customCsv = {
    multiple: false,
    onRemove: (file) => setCsv([]),
    beforeUpload: (file) => {
      setCsv(file)
      return false
    },
    csv,
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <NavLink to={`/groups/${record.id}`}>{text}</NavLink>
      ),
      ...getColumnSearchProps('name'),
      width: '50%',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('Do MMMM YYYY'),
      sorter: (a, b) => a.name.length - b.name.length,
      width: '40%',
      ...getColumnDateProps('createdAt'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: '10%',
    },
  ]

  return (
    <Fragment>
      <CustomMeta title='Groups' />
      <PageHeader
        className='box'
        title='Groups'
        extra={[
          currentReport.includes('Create Group') && (
            <Button key='1' type='primary' onClick={handleCsvDownload}>
              Export Groups
            </Button>
          ),
          <Button key='2' type='primary' onClick={showModal}>
            Import Groups
          </Button>,
          <Button key='3' type='primary' onClick={() => setShowFormModal(true)}>
            Add group
          </Button>,
        ]}
      />
      {isModalVisible && (
        <Modal
          title='Import Groups'
          visible
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button
              key='link'
              href='https://finideas-india.blr1.digitaloceanspaces.com/importClients/importClients/importClients.csv'
              type='secondary'
              loading={loading}
              style={{ marginRight: '35%' }}
            >
              Download demo CSV
            </Button>,
            <Button key='back' onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key='submit'
              type='primary'
              loading={loading}
              onClick={handleOk}
            >
              Ok
            </Button>,
          ]}
        >
          <Form form={form} layout='vertical'>
            <Form.Item
              name='csv'
              label='Please select CSV file'
              valuePropName='csv'
              getValueFromEvent={normFile}
            >
              <Upload
                name='csv-file'
                listType='text'
                {...customCsv}
                accept='.csv'
                multiple={false}
                fileList={csv}
              >
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
                <u>
                  <p>Instructions</p>
                </u>
                <ul>
                  <li>Use Demo CSV to import Group.</li>
                  <li>
                    Please mention userId by coma separate (Not client code).
                  </li>
                </ul>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {showNotificationModal && selectedGroup && (
        <NotificationGroupModal
          visible={showNotificationModal}
          onSend={handleNotificationModal}
          onCancel={() => handleHideNotificationModal()}
        />
      )}
      {showFormModal && (
        <CreateGroupModal
          visible={showFormModal}
          onCreate={handleCreateGroup}
          onUpdate={handleUpdateGroup}
          selectedGroup={selectedGroup}
          isEdit={selectedGroup !== undefined}
          onCancel={() => handleHideFormModal()}
        />
      )}
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        pagination={{
          pageSize: limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </Fragment>
  )
}
