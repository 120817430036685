import gql from 'graphql-tag'

export const GET_SECTIONS = gql`
  query getSections($where: GetSectionBasedOnPlanInput) {
    getSections(where: $where) {
      id
      section
      index
      questions {
        id
        question
        index
        type
        isRequired
        point
        options {
          id
          option
          point
          isDefault
        }
      }
    }
  }
`

export const GET_KYC = gql`
  query kyc($userId: Int) {
    kyc(where: { userId: $userId }) {
      id
      kycForm
      agreementData
      agreementForNonIndividual
    }
  }
`
export const GET_SP_AUTO_ADVISE = gql`
  query spAutoAdvise($where: spAutoAdviseInput) {
    spAutoAdvise(where: $where)
  }
`
export const GET_SP_AGREEMENT_DATA = gql`
  query spAgreementData($where: spAgreementInput) {
    spAgreementData(where: $where)
  }
`

export const GET_USER_ANSWERS = gql`
  query getUserAnswers($where: WherePlanInput) {
    getUserAnswers(where: $where) {
      id
      user {
        id
      }
      question {
        id
        type
      }
      selectedOptions
    }
  }
`

export const GET_ALL_USERS_ANSWERS = gql`
  query getAllUsersAnswers($where: AnswerUniqueInput) {
    getAllUsersAnswers(where: $where) {
      id
      user {
        id
      }
      question {
        id
        type
        question
      }
      selectedOptions
    }
  }
`

export const GET_MANDATE = gql`
  query getMandate($where: MandateWhereUniqueInput!) {
    getMandate(where: $where) {
      id
      user {
        id
        bank
        accountType
        accountNumber
        email
        phone
        firstName
        lastName
        mandateId
      }
      ifscCode
      mandateStatus
      bankStatus
      createdAt
    }
  }
`
export const PARTNERS_DETAILS = gql`
  query partnerDetails($where: PartnerDetailsWhereUniqueInput!) {
    partnerDetails(where: $where) {
      id
      pan
      name
      uidOrDin
      address
      relationshipWithApplicant
      politicallyExposed
      user {
        id
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_CLIENT_PROFILE2_PDF_LINK = gql`
  query getClientProfile2PdfLink {
    getClientProfile2PdfLink
  }
`

export const GET_CLIENT_PROFILE1_PDF_LINK = gql`
  query getClientProfile1PdfLink {
    getClientProfile1PdfLink
  }
`

export const NON_INDIVIDUAL_AGREEMENT_MAIL_SEND = gql`
  query nonIndividualAgreementMailSend {
    nonIndividualAgreementMailSend
  }
`

export const GET_ALL_FAQUESTION = gql`
  query GetAllFAQuestions {
    getAllFAQuestions {
      answer
      id
      question
      createdAt
      updatedAt
    }
  }
`

export const GET_BOOKING_ADVISORY = gql`
  query GetBookAdvisory($where: BookAdvisoryWhereInput) {
    getBookAdvisory(where: $where) {
      id
      userId
      advisoryDate
      isAdvisoryBooked
      isClientLogin
      isClientAcceptAdvisory
      advisorySendDateAndTime
      createdAt
      updatedAt
      advisoryAcceptDateAndTime
      isTermAndServiceAccepted
      advisorySendType
      isCancelAdvisory
      amcAmount
      paymentType
      advisoryType
      isPendingAdvisoryAccepted
      clientId {
        firstName
        lastName
        id
        clientCode
        email
        eMandateAmcAmount
      }
      advisorySubmittedUser {
        firstName
        lastName
        role
        email
        id
      }
      advisoryCancelByUser {
        firstName
        lastName
        role
        email
        id
      }
      advisoryUpdateByUser {
        firstName
        lastName
        role
        email
        id
      }
    }
  }
`

export const GET_PENDING_BOOKED_ADVISORY = gql`
  query Query($where: GetPendingBookingAdvisoryInput) {
    getPendingBookingAdvisory(where: $where)
  }
`

export const GET_NEW_PENDING_ADVISORY = gql`
  query Query($where: AdvisoryDataReport) {
    getNewBookingAdvisory(where: $where)
  }
`
