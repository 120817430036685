import gql from 'graphql-tag'

export const GET_FAQ_QUESTION = gql`
  query GetFAQuestion($where: whereFAQuestionUpdate) {
    getFAQuestion(where: $where) {
      answer
      createdAt
      id
      question
      updatedAt
    }
  }
`
