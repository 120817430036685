import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'

import {
  LOGIN_WITH_OTP,
  RESEND_OTP,
  SEND_SIGNUP_OTP,
} from './graphql/Mutations'
import Logo from 'assets/images/logo-tagline.png'
import CustomMeta from 'components/CustomMeta'
import './index.css'
import client from 'apollo'
import openNotification from 'utils/Notification'
import { loginWithOtp } from 'auth'

const FormItem = Form.Item

const LoginWithEmail = () => {
  const [form] = Form.useForm()
  const [selectUser, setSelectUser] = useState(false)
  const [emailValues, setEmailValues] = useState()

  function handleLoginWithEmail(values) {
    const email = values.email
    setEmailValues(email)
    client
      .mutate({ mutation: SEND_SIGNUP_OTP, variables: { where: values } })
      .then((res) => {
        setSelectUser(true)
        openNotification('success', res.data.sendSignUpOtp)
      })
      .catch((err) => {
        const userName = err.message.split(':')[1].split(' ')[1]
        if (
          err.message
            .split(':')[1]
            .toString()
            .includes('Already in progress, Please sign in to continue')
        ) {
          window.location.href = `/login?userName=${userName}#nosignup`
        }
        console.log({ err })
      })
  }

  function handleLoginWithOtp(values) {
    client
      .mutate({ mutation: LOGIN_WITH_OTP, variables: { otp: values.number } })
      .then((res) => {
        openNotification('success', 'Login successfully')
        loginWithOtp(res)
      })
      .catch((err) => console.log(err))
  }

  function reSendOtp() {
    client
      .mutate({
        mutation: RESEND_OTP,
        variables: { where: { email: emailValues } },
      })
      .then((res) => {
        setSelectUser(true)
        openNotification('success', res.data.resendOtp)
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className='gx-app-login-wrap'>
      <CustomMeta title='Login' />
      <div className='gx-app-login-container'>
        <div className='gx-app-login-main-content'>
          <div className='gx-app-logo-content'>
            <div className='gx-app-logo-content-bg'>
              {/* <img src='https://via.placeholder.com/272x395' alt='Nature' /> */}
            </div>
            <div className='gx-app-logo-wid'>
              <h1>Sign Up</h1>
              <p>By Sign Up, you can avail full features of our services.</p>
            </div>
            <div className='gx-app-logo'>
              <img alt='Finideas' src={Logo} />
            </div>
          </div>
          <div className='gx-app-login-content'>
            {selectUser ? (
              <Form
                form={form}
                onFinish={handleLoginWithOtp}
                className='gx-signin-form gx-form-row0'
              >
                <p className='otp-form-text'>
                  {' '}
                  We've sent a verification code on your email id-{
                    emailValues
                  }{' '}
                </p>
                <FormItem
                  name='number'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your valid otp!',
                      pattern: new RegExp(/^\d{6}(,\d{6})*$/g),
                    },
                  ]}
                >
                  <Input
                    placeholder='Enter verification code'
                    autoFocus={true}
                  />
                </FormItem>
                <FormItem>
                  <Button type='primary' className='gx-mb-0' htmlType='submit'>
                    Sign In
                  </Button>
                  <Button
                    type='primary'
                    className='gx-mb-0'
                    onClick={reSendOtp}
                  >
                    Resend OTP
                  </Button>
                </FormItem>
              </Form>
            ) : (
              <Form
                form={form}
                onFinish={handleLoginWithEmail}
                className='gx-signin-form gx-form-row0'
              >
                <FormItem
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your valid email!',
                      pattern: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/g),
                    },
                  ]}
                >
                  <Input placeholder='Email' autoFocus={true} />
                </FormItem>
                <FormItem>
                  <Button type='primary' className='gx-mb-0' htmlType='submit'>
                    Send OTP
                  </Button>
                </FormItem>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginWithEmail
