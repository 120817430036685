import React, { useContext, useEffect, useState } from 'react'
import { Progress, Steps } from 'antd'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import client from 'apollo'

import './verification.css'
import { AppContext } from 'AppContext'
import InvestmentPlanning from './InvestmentPlanning'
import EMandateRegistration from './EMandateRegistration'
import Congratulations from './Congratulations'
import PendingBookedAdvisory from './PendingBookedAdvisory'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'

const { Step } = Steps

const AuaNewSteps = () => {
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext)
  const { state } = useContext(AppContext)
  const { newAuaAdvisoryCompletedSteps } = state?.currentUser

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [header, setHeader] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    window.location.hash = 'no-back-button'
    window.location.hash = 'Again-No-back-button'
    window.onhashchange = function () {
      window.location.hash = 'no-back-button'
    }
  }, [])

  let tempCurrent = 0
  switch (newAuaAdvisoryCompletedSteps) {
    case 'INVESTMENT_PLANNING':
      tempCurrent = 1
      break
    case 'ADVISORY_FEES':
      tempCurrent = 2
      break
    case 'CONGRATULATION':
      tempCurrent = 3
      break
    case 'DONE':
      tempCurrent = 4
      break
    default:
      break
  }
  const [current, setCurrent] = useState(tempCurrent)

  const next = (nextStep = 1) => {
    if (
      newAuaAdvisoryCompletedSteps === 'KYC_FORM' &&
      currentUser?.umrnNumber !== null
    ) {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            data: { newAuaAdvisoryCompletedSteps: 'ADVISORY_FEES' },
            id: currentUser?.id,
          },
        })
        .then((res) => {
          console.log(res?.data)
          setCurrent(2)
          dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      setCurrent(current + nextStep)
    }
  }
  const back = () => {
    if (newAuaAdvisoryCompletedSteps === 'CONGRATULATION') {
      setCurrent(0)
    }
  }

  const steps = [
    {
      title: 'Investment Planning',
      content: (
        <InvestmentPlanning next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Advisory Fees',
      content: (
        <EMandateRegistration next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Congratulations',
      content: (
        <Congratulations next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Pending Advisory',
      content: (
        <PendingBookedAdvisory
          next={next}
          back={back}
          reEvaluate={currentUser.reEvaluate}
        />
      ),
    },
  ]

  return (
    <>
      <div className='.gx-app-login-content.content-wrapper'>
        {header && windowWidth <= 480 ? (
          <div className='steps_collapse'>
            <Steps current={true} size='small'>
              <Step key={steps[current].title} title={steps[current].title} />
            </Steps>
            <div className='steps_icon' onClick={() => setHeader(!header)}>
              <DownCircleOutlined className='steps_icon_span' />
            </div>
          </div>
        ) : (
          <div>
            <div className='steps_icon' onClick={() => setHeader(!header)}>
              <UpCircleOutlined
                className='steps_icon_span'
                style={windowWidth <= 480 ? {} : { display: 'none' }}
              />
            </div>
            <Steps size='small' current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
        )}
        <div className='progress'>
          <Progress
            percent={Math.round((100 / 5) * (tempCurrent + 1))}
            format={(p) => `${p}% Progress`}
          />
        </div>
        <div className='steps-content'>{steps[current]?.content}</div>
      </div>
    </>
  )
}

export default AuaNewSteps
