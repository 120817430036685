import gql from 'graphql-tag'

export const FETCH_EMAIL_DATA = gql`
query fetchEmail($emailFilter: String!, $fetchFrom: fetchFromEnum!){
    fetchEmail(data: { start:1, end:20, emailFilter: $emailFilter, fetchFrom: $fetchFrom }){
      result
    }
  }
`

export const FETCH_EMAIL_BY_ID = gql`
  query fetchEmailById($messageId:Int!, $fetchFrom: fetchFromEnum!){
    fetchEmailById(data: { messageId: $messageId, fetchFrom: $fetchFrom }){
      result
    }
  }
`
