import { Table } from 'antd'
import React from 'react'
// import { Table } from 'antd'
import { NavLink } from 'react-router-dom'
import { convertNumberToCommaSeparate } from 'utils/utilities'

export default function ({ reportData, clientData }) {
  const { ClientCode, ClientName } = clientData
  // const [exportDataCsvFO, setExportDataCsvFO] = useState([])
  // const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   setLoading(true)
  //   client
  //     .query({
  //       query: GET_TRADER_FO_REPORT,
  //       variables: { where: { clientCode: ClientCode?.split('ILTS')[0] } },
  //       fetchPolicy: 'network-only',
  //     })
  //     .then((res) => {
  //       setExportDataCsvFO(res?.data?.spTradeReportFO)
  //       setLoading(false)
  //     })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // const dataForExportCSVFO = exportDataCsvFO?.map((item) => {
  //   return {
  //     script: item?.Script,
  //     qty: item?.qty,
  //     rate: item?.rate,
  //     entrydate: item?.entrydate,
  //     ClientCode: item?.ClientCode,
  //   }
  // })
  const columns = [
    {
      title: (
        <NavLink
          to='/reports/position-fo'
          className='reports-position-name'
          style={{
            color: '#000',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '18px',
          }}
        >
          Futures &amp; Options Report
        </NavLink>
      ),
      className: 'report-title main-title',

      children: [
        {
          title: (
            <div className='hidden-client-data'>
              Client Code:{' '}
              <span className='hidden-client-data'>{ClientCode}</span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Script',
              dataIndex: 'Script',
              key: 'Script',
              className: 'report-text',
              width: 300,
            },
            {
              title: 'Quantity',
              dataIndex: 'TotalQty',
              key: 'TotalQty',
              className: 'report-numeric',
              // render: (record) => record.toLocaleString('en - IN'),
              width: 80,
            },
          ],
        },
        {
          title: (
            <div className='hidden-client-data'>
              Client Name:{' '}
              <span className='hidden-client-data'>{ClientName}</span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Trade Rate',
              dataIndex: 'TradedRate',
              key: 'TradedRate',
              className: 'report-numeric',
              render: (record) => convertNumberToCommaSeparate(record, true),
              width: 80,
            },
            {
              title: 'CMP',
              dataIndex: 'CMP',
              key: 'CMP',
              className: 'report-numeric',
              render: (record) => convertNumberToCommaSeparate(record, true),
              width: 80,
            },
            {
              title: 'Value',
              dataIndex: 'Value',
              key: 'Value',
              className: 'report-numeric',
              render: (record) => convertNumberToCommaSeparate(record),
              width: 80,
            },
            {
              title: 'Profit / Loss',
              dataIndex: 'Pnl',
              key: 'Pnl',
              className: 'report-numeric',
              render: (record) => convertNumberToCommaSeparate(record),
              width: 100,
            },
          ],
        },
      ],
    },
  ]

  let data = reportData.map((report, key) => {
    return { key, ...report }
  })

  return (
    <Table
      className='futures-options'
      dataSource={data}
      bordered
      columns={columns}
      // scroll={{ x: true, y: 240 }}
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    />
    // <>
    //   <div
    //     style={{ border: '2px solid #535353', borderRadius: '5px', padding: 6 }}
    //   >
    //     <NavLink
    //       to='/reports/position-fo'
    //       className='reports-position-name'
    //       style={{
    //         display: 'flex',
    //         justifyContent: 'center',
    //         fontSize: '18px',
    //       }}
    //     >
    //       <b>Futures &amp; Options Report</b>
    //     </NavLink>
    //   </div>
    //   &nbsp;
    //   <div style={{ display: 'flex', justifyContent: 'start', gap: '50px' }}>
    //     <div
    //       style={{
    //         display: 'flex',
    //         border: '2px solid #535353',
    //         width: '50%',
    //         borderRadius: '5px',
    //       }}
    //     >
    //       <div
    //         style={{
    //           borderRight: '2px solid #535353',
    //           padding: '10px',
    //           fontWeight: 'bold',
    //           fontSize: '16px',
    //         }}
    //       >
    //         Client Code
    //       </div>
    //       <div
    //         style={{ padding: '10px', fontWeight: 'bold', fontSize: '16px' }}
    //       >
    //         {ClientCode}
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         display: 'flex',
    //         border: '2px solid #535353',
    //         width: '50%',
    //         borderRadius: '5px',
    //         fontWeight: 'bold',
    //       }}
    //     >
    //       <div
    //         style={{
    //           borderRight: '2px solid #535353',
    //           padding: '10px',
    //           fontWeight: 'bold',
    //           fontSize: '16px',
    //         }}
    //       >
    //         Client Name
    //       </div>
    //       <div
    //         style={{ padding: '10px', fontSize: '16px', fontWeight: 'bold' }}
    //       >
    //         {ClientName}
    //       </div>
    //     </div>
    //   </div>
    //   &nbsp;
    //   <div class='main_stock'>
    //     <div class='stock_script'>
    //       <div style={{ width: '35%' }} class='stock_right'>
    //         Script
    //       </div>
    //       <div style={{ width: '13%', textAlign: 'right' }} class='stock_right'>
    //         Quantity
    //       </div>
    //       <div style={{ width: '13%', textAlign: 'right' }} class='stock_right'>
    //         Trade Rate
    //       </div>
    //       <div style={{ width: '13%', textAlign: 'right' }} class='stock_right'>
    //         CMP
    //       </div>
    //       <div style={{ width: '13%', textAlign: 'right' }} class='stock_right'>
    //         Value
    //       </div>
    //       <div
    //         style={{ width: '13%', textAlign: 'right' }}
    //         class='stock_right_one'
    //       >
    //         Profit/Loss
    //       </div>
    //     </div>
    //     <div class='border_bottom'></div>

    //     {data?.slice(0, -1)?.map((item, key) => {
    //       return (
    //         <>
    //           <div class='stock_script'>
    //             <div style={{ width: '35%' }} class='stock_right_second'>
    //               {item.Script}
    //             </div>
    //             <div
    //               style={{ width: '13%', textAlign: 'right' }}
    //               class='stock_right_second'
    //             >
    //               {item.TotalQty}
    //             </div>
    //             <div
    //               style={{ width: '13%', textAlign: 'right' }}
    //               class='stock_right_second'
    //             >
    //               {item.TradedRate}
    //             </div>
    //             <div
    //               style={{ width: '13%', textAlign: 'right' }}
    //               class='stock_right_second'
    //             >
    //               {item.CMP}
    //             </div>
    //             <div
    //               style={{ width: '13%', textAlign: 'right' }}
    //               class='stock_right_second'
    //             >
    //               {item.Value}
    //             </div>
    //             <div
    //               style={{ width: '13%', textAlign: 'right' }}
    //               class='stock_right_one_new'
    //             >
    //               {item.Pnl}
    //             </div>
    //           </div>
    //           {data.length - 2 !== key ? (
    //             <div class='border_bottom' key={-1}></div>
    //           ) : (
    //             ''
    //           )}
    //         </>
    //       )
    //     })}
    //   </div>
    //   <br />
    //   <div class='main_stock'>
    //     <div class='stock_script'>
    //       <div style={{ width: '35%' }} class='stock_right_second'>
    //         <b>{data[data?.length - 1].Script}</b>
    //       </div>
    //       <div
    //         style={{ width: '13%', textAlign: 'right' }}
    //         class='stock_right_second'
    //       >
    //         <b>{data[data?.length - 1].TotalQty}</b>
    //       </div>
    //       <div
    //         style={{ width: '13%', textAlign: 'right' }}
    //         class='stock_right_second'
    //       >
    //         <b>{data[data?.length - 1].TradedRate}</b>
    //       </div>
    //       <div
    //         style={{ width: '13%', textAlign: 'right' }}
    //         class='stock_right_second'
    //       >
    //         <b>{data[data?.length - 1].CMP}</b>
    //       </div>
    //       <div
    //         style={{ width: '13%', textAlign: 'right' }}
    //         class='stock_right_second'
    //       >
    //         <b>{data[data?.length - 1].Value}</b>
    //       </div>
    //       <div
    //         style={{ width: '13%', textAlign: 'right' }}
    //         class='stock_right_one_new'
    //       >
    //         <b>{data[data?.length - 1].Pnl}</b>
    //       </div>
    //     </div>
    //   </div>
    // </>
  )
}
