import React, { useContext, useEffect, useState } from 'react'
import {
  Row,
  Col,
  InputNumber,
  Button,
  Modal,
  DatePicker,
  Tag,
  Tooltip,
} from 'antd'
import {
  QuestionOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { cloneDeep, isEmpty, uniq } from 'lodash'
import { Link } from 'react-router-dom'
import PendingAdvisoryModel from 'modules/PendingAdvisory/PendingAdvisoryModel'
import moment from 'moment'
import client from 'apollo'
import { COMPLETE_STEP, UPDATE_BOOK_ADVISORY } from '../graphql/Mutations'
import { AppContext } from 'AppContext'
import {
  GET_BOOKING_ADVISORY,
  GET_NEW_PENDING_ADVISORY,
} from '../graphql/Queries'
import {
  UPDATE_ADVISORY_STATUS,
  UPDATE_ADVISORY_VALUE,
} from 'modules/PendingAdvisory/graphql/Mutation'
import openNotification from 'utils/Notification'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import history from 'CustomHistory'
import './pendingAdvisory.css'
import { ConfirmAdvisoryModal } from './ConfirmAdvisoryModal'
import Loader from 'components/loaders/Loader'
const { confirm } = Modal

const AdvisoryTradeUpdate = ({ type, advisoryDate, advisoryId }) => {
  const [advisoryData, setAdvisoryData] = useState([])
  const data = cloneDeep(advisoryData)
  const [newData, setNewData] = useState()
  const [openTableInModal, setOpenTableInModal] = useState(false)
  const [accept, setAccept] = useState(true)
  const [dateForAdvisory, setDateForAdvisory] = useState('')
  const [errorKeys, setErrorKeys] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext)

  useEffect(() => {
    data.forEach((d, key) => {
      if (newData?.length && newData[key]) {
        d.TradePrice = newData[key].TradePrice
        d.TradedQuantity = newData[key].TradedQuantity
      } else {
        d.TradedQuantity = +d.Quantity
      }
    })
  }, [data, newData])

  const handleCancel = () => {
    setOpenTableInModal(false)
  }

  const refetch = () => {
    setLoading(true)
    client
      .query({
        query: GET_NEW_PENDING_ADVISORY,
        variables: { where: { advisoryDate: dateForAdvisory } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setAdvisoryData(res?.data?.getNewBookingAdvisory?.currentAdvisoryData)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForAdvisory])

  const reject = () => {
    client
      .mutate({
        mutation: UPDATE_BOOK_ADVISORY,
        variables: {
          data: { isPendingAdvisoryAccepted: false, userId: currentUser?.id },
          where: { id: parseInt(advisoryId) },
        },
        refetchQueries: [
          {
            query: GET_BOOKING_ADVISORY,
            variables: {
              userId: currentUser?.id,
              id: parseInt(advisoryId),
              isClientLogin: true,
            },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setAccept(false)
        console.log(res?.data)
      })
      .catch((err) => {
        setAccept(false)
        console.log(err)
      })
  }

  const onFinish = () => {
    if (data) {
      const statusData = data.map((d) => {
        return {
          advisorId: parseInt(d.AdvisorID),
          clientCode: d.ClientCode,
          status: 'Accept',
        }
      })
      client
        .mutate({
          mutation: UPDATE_ADVISORY_STATUS,
          variables: { data: statusData },
        })
        .then(() => {
          data.forEach((d) => {
            // d.TradeType = d.Trade
            delete d.high
            delete d.low
            delete d.MktPrice
            // delete d.Symbol
            d.AdvisorID = parseInt(d.AdvisorID)
            if (!d.Strike) {
              d.Strike = ''
            } else {
              d.Strike = JSON.stringify(d.Strike)
            }
            // d.Type = d.Trade
            if (d.AdvisoryType) {
              delete d.AdvisoryType
            }
            if (d.AdvisoryDate) {
              delete d.AdvisoryDate
            }
            if (d.Quantity) {
              delete d.Quantity
            }
          })
          client
            .mutate({
              mutation: UPDATE_ADVISORY_VALUE,
              variables: { data },
            })
            .then((data) => {
              client
                .mutate({
                  mutation: UPDATE_BOOK_ADVISORY,
                  variables: {
                    data: {
                      isClientAcceptAdvisory: true,
                      userId: currentUser?.id,
                      advisoryAcceptDateAndTime: moment().format(),
                    },
                    where: { id: parseInt(advisoryId) },
                  },
                  refetchQueries: [
                    {
                      query: GET_BOOKING_ADVISORY,
                      variables: {
                        userId: currentUser?.id,
                        id: parseInt(advisoryId),
                        isClientLogin: true,
                      },
                      fetchPolicy: 'network-only',
                    },
                  ],
                })
                .then((res) => {
                  setConfirmModal(true)
                  data?.data?.updateAdvisoryValue === true
                    ? openNotification('success', 'Accepted Successfully')
                    : openNotification('error', 'Something went wrong')
                  setOpenTableInModal(false)
                })
                .catch((err) => {
                  setAccept(false)
                  console.log(err)
                })
            })
            .catch((err) => {
              setAccept(false)
              console.log(err)
            })
        })
        .catch((err) => {
          setAccept(false)
          console.log(err)
        })
    }
  }

  const columns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
      className: 'report-text average-index-column-align',
      render: (text, record) => record?.Script,
      align: 'center',
    },
    {
      title: 'Trade',
      dataIndex: 'Trade',
      key: 'Trade',
      className: 'report-text average-index-column-align text_align',
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      className: 'report-text average-index-column-align text_align',
    },
    {
      title: 'Traded Quantity',
      dataIndex: 'TradedQuantity',
      key: 'TradedQuantity',
      className: 'report-text average-index-column-align text_align',
    },
    {
      title: 'Trade Price',
      dataIndex: 'TradePrice',
      key: 'TradePrice',
      className: 'report-text average-index-column-align text_align',
    },
  ]

  const showConfirm = () => {
    let flag = true
    data.forEach((data) => {
      if (
        data.TradedQuantity !== 0 &&
        (data.TradePrice <= 0 || typeof data?.TradePrice !== 'number')
      ) {
        flag = false
      }
      if (data.TradedQuantity === 0 || data?.TradedQuantity === null) {
        // data.TradePrice = 0
        flag = false
      }
    })

    flag
      ? setOpenTableInModal(true)
      : confirm({
          title: 'Traded Quantity and Trade Price must be greater then 0',
          icon: <ExclamationCircleOutlined />,
        })
  }

  const handleGetPendingAdvisory = (e) => {
    setDateForAdvisory(
      moment(e).format('DD-MMM-YYYY') !== 'Invalid date'
        ? moment(e).format('DD-MMM-YYYY')
        : ''
    )
  }

  const confirmAdvisory = () => {
    setConfirmLoading(true)
    let userData = {
      isNewAuaAdvisoryGenerate: false,
      isBookingAdvisoryDateSelected: false,
    }
    if (currentUser?.isNewAuaAdvisoryGenerate) {
      userData.newAuaAdvisoryCompletedSteps = 'DONE'
    } else {
      userData.completedSteps = 'DONE'
    }
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          data: { ...userData },
          id: currentUser?.id,
        },
      })
      .then((res) => {
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'DONE',
              from: 'WEB',
            },
          },
        })
        setConfirmModal(false)
        setConfirmLoading(false)
        dispatch({
          type: 'CURRENT_USER',
          data: res.data.updateUser,
        })
        dispatch({
          type: 'IS_VERIFICATION_DONE',
          data: true,
        })
        history.push('/')
      })
      .catch((err) => {
        setConfirmModal(false)
        setConfirmLoading(false)
        console.log(err)
      })
  }
  if (loading) return <Loader />

  return (
    <>
      {accept ? (
        <div className='update_trade_date'>
          <div className='date_div'>
            <div className='advisory_date_div_1'>
              <div className='advisory_date_text'>
                <b>Select Trade Date</b>
              </div>
              <div className='advisory_date_value'>
                <DatePicker
                  className='date_picker'
                  onChange={(e) => handleGetPendingAdvisory(e)}
                  value={dateForAdvisory ? moment(dateForAdvisory) : ''}
                  format={'DD-MM-YYYY'}
                />
                {(!dateForAdvisory || dateForAdvisory === 'Invalid date') && (
                  <div>
                    <Tag
                      color='red'
                      style={{
                        marginTop: '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        marginRight: 0,
                      }}
                    >
                      Please Select Date
                    </Tag>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className='update_trade_table'>
        <Row
          className='update_trade_table_row'
          gutter={16}
          style={{ fontWeight: 500 }}
        >
          <Col className='update_trade_table_col' span={8}>
            Script
          </Col>
          <Col
            className='update_trade_table_col update_trade_table_col_border'
            lg={4}
            md={4}
            sm={3}
            xs={3}
          >
            Trade
          </Col>
          <Col className='update_trade_table_col' span={4}>
            Quantity
          </Col>
          <Col
            className='update_trade_table_col update_trade_table_col_border'
            span={4}
          >
            Traded Quantity
          </Col>
          <Col className='update_trade_table_col' lg={4} md={4} sm={5} xs={5}>
            Trade Price
          </Col>
        </Row>
        {advisoryData?.length &&
          advisoryData?.map((item, key) => {
            const { Script, Trade, TradedQuantity, Quantity } = item
            return (
              <Row
                className={
                  advisoryData?.length - 1 === key
                    ? 'update_trade_table_row_new'
                    : 'update_trade_table_row'
                }
                gutter={16}
              >
                <Col className='update_trade_table_col' span={8}>
                  {Script}
                </Col>
                <Col
                  className='update_trade_table_col update_trade_table_col_border'
                  lg={4}
                  md={4}
                  sm={3}
                  xs={3}
                >
                  {Trade}
                </Col>
                <Col className='update_trade_table_col' span={4}>
                  {Quantity}
                </Col>
                <Col
                  className='update_trade_table_col update_trade_table_col_border'
                  span={4}
                >
                  {!accept ? (
                    <>
                      <span>{TradedQuantity}</span>
                    </>
                  ) : (
                    <InputNumber
                      defaultValue={accept && Quantity}
                      onChange={(value) => {
                        data[key].TradedQuantity = value
                        setNewData(data)
                      }}
                    />
                  )}
                </Col>
                <Col
                  className='update_trade_table_col'
                  lg={4}
                  md={4}
                  sm={5}
                  xs={5}
                >
                  {dateForAdvisory && dateForAdvisory !== 'Invalid date' && (
                    <div className='advisory_input'>
                      <InputNumber
                        type='number'
                        defaultValue={
                          newData?.length &&
                          newData[key] &&
                          newData[key].TradePrice > 0
                            ? newData[key].TradePrice
                            : undefined
                        }
                        onChange={(value) => {
                          if (
                            !isNaN(data[key]?.low) &&
                            !isNaN(data[key]?.high)
                          ) {
                            if (
                              value < data[key].low ||
                              value > data[key].high
                            ) {
                              setErrorKeys(uniq([...errorKeys, key]))
                            } else {
                              setErrorKeys(
                                errorKeys?.filter((item) => item !== key)
                              )
                            }
                          }
                          data[key].TradePrice = value
                          setNewData(data)
                        }}
                      />
                      <div>
                        {errorKeys?.includes(key) &&
                          !isNaN(data[key].low) &&
                          !isNaN(data[key].high) && (
                            <>
                              <div className='advisory_error'>
                                Price range is {data[key].low} -{' '}
                                {data[key].high}
                              </div>

                              <div style={{ marginTop: '10px' }}>
                                <Tooltip
                                  placement='left'
                                  visible={
                                    errorKeys?.includes(key) &&
                                    !isNaN(data[key].low) &&
                                    !isNaN(data[key].high)
                                  }
                                  title={`Price range is ${data[key].low} - ${data[key].high}`}
                                >
                                  <InfoCircleOutlined className='error_info_icon' />
                                </Tooltip>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            )
          })}
      </div>
      <Row gutter={16} className='scenario_advisory'>
        <Col span={18}>
          <Button
            className='pending_advisory_accept_btn'
            onClick={showConfirm}
            disabled={
              !dateForAdvisory ||
              dateForAdvisory === 'Invalid date' ||
              dateForAdvisory === undefined ||
              errorKeys?.length
                ? true
                : false
            }
          >
            Submit
          </Button>
          <Button type='primary' onClick={reject}>
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <div className='question_icon'>
            <div className='faqs-icon'>
              <Link
                to='/faqs'
                className='nav-text'
                target='_blank'
                rel='noopener noreferrer'
              >
                <QuestionOutlined className='que_mark' />
              </Link>
            </div>
          </div>
        </Col>
      </Row>

      {openTableInModal && !isEmpty(data) && (
        <PendingAdvisoryModel
          visible={setOpenTableInModal}
          onCancel={() => handleCancel()}
          onOk={() => onFinish()}
          columns={columns}
          data={data}
        />
      )}

      {confirmModal && (
        <ConfirmAdvisoryModal
          visible={confirmModal}
          onOk={confirmAdvisory}
          confirmLoading={confirmLoading}
        />
      )}
    </>
  )
}
export default AdvisoryTradeUpdate
