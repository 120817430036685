import gql from 'graphql-tag'

export const GET_REMINDERS = gql`
  query GetReminders {
    getReminders {
      id
      name
      days
      scheduleType
      isWhatsAppSend
      status
      createdAt
      mandateTemplate
      nonMandateTemplate
      isDiscontinue
      amcDueDate
      amcUserSelectMember
      amcUserSelectedType
    }
  }
`

export const GET_REMINDER_USERS = gql`
  query getReminderUsers($data: ReminderWhereUniqueInput!) {
    getReminderUsers(data: $data) {
      id
      startDate
      endDate
      status
      invoice {
        id
        assetUnderAdvisory
        amcInPer
        discountInPer
        commissionInPer
        grossTotalAmount
        cgst9InPer
        igst18InPer
        sgst9InPer
        totalNetAmount
        invoicePdf
        niftySpot
        niftyLot
        state
        startDate
        endDate
        amcType
        deduction
        dueDate
      }
      client {
        id
        firstName
        lastName
        clientCode
        email
        phone
        address
        stateId
        mandateId
        completedSteps
        umrnNumber
        amcInPer
      }
      amount
      whichYear
      paymentType
      description
      createdAt
      updatedAt
      startDate
      endDate
      status
      amcQty
      amcPaidForPeriod
      nextDueDate
      paidAt
      invoiceNo
    }
  }
`
