import React, { useContext } from 'react'
import { PageHeader, Col, Row } from 'antd'

import { AppContext } from 'AppContext'
import Counts from './Components/Counts'
import CustomMeta from 'components/CustomMeta'
import SipAmcCounts from './Components/SipAmcCounts'
import NLVChartReport from './Components/NLVChartReport'
// import NiftyCandleChart from './Components/NiftyCandleChart'
import SipAmcCountsClient from './Components/SipAmcCountsClient'
// import RevenueIncomeCount from './Components/RevenueIncomeCount'
// import CommonChartReport from './Components/CommonChartReport'
import NLVIndividualReport from './Components/NLVIndividualReport'
import UserPerformanceCounts from './Components/UserPerformanceCounts'
import ProfitLossChartReport from './Components/ProfitLossChartReport'
import { useHistory } from 'react-router'
import { getName } from 'utils/User'
// import NiftyCandleChartRS from './Components/NiftyCandleChartRS'
import StrategyIndexAbsoluteClient from './Components/StrategyIndexAbsoluteClient'
import StockReportScriptValue from './Components/StockReportScriptValue'
import NetLiquidationValueClient from './Components/NetLiquidationValueClient'


export default function Dashboard(props) {
  const {
    state: { isClient },
  } = useContext(AppContext)
  let {
    location: { clientDetails = {} },
    match: {
      params: { clientId: clientIdInPath },
    },
  } = props
  const { id: clientId, clientCode } = clientDetails
  const history = useHistory()
  if (clientIdInPath && !(clientId || clientCode)) {
    history.push('/clients')
  }
  return (
    <div>
      <CustomMeta title='Dashboard' />
      {clientId && clientCode && (
        <PageHeader
          className='box'
          onBack={() => history.goBack()}
          title={getName(clientDetails)}
        />
      )}
      <Row style={{display:'flex',justifyContent:'center'}}>
        {!isClient && !clientId && <Counts />}

        {/* {!isClient && <RevenueIncomeCount />} */}

        {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <NiftyCandleChartRS />
        </Col> */}

        {(isClient || clientCode) &&
          <StrategyIndexAbsoluteClient clientCode={clientCode} />
        }
        {(isClient || clientCode) &&
          <StockReportScriptValue clientCode={clientCode} />
        }
        {(isClient || clientCode) &&
          <NetLiquidationValueClient clientCode={clientCode} />
        }

        {
          isClient || clientId ?
            <SipAmcCountsClient clientId={clientId} />
            :
            <SipAmcCounts />
        }

        {!isClient && !clientId && <UserPerformanceCounts />}

        {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <NiftyCandleChart />
        </Col> */}
        {/* {
          (isClient || clientId) && */}
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <NLVChartReport clientId={clientId} clientCode={clientCode} />
        </Col>
        {/* } */}
        {!isClient && !clientId && (
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <NLVIndividualReport />
          </Col>
        )}
        {/* {
          isClient &&
          <Col xl={6} lg={12} md={12} sm={12} xs={24}>
            <CommonChartReport />
          </Col>
        } */}
      </Row>
        {!isClient && !clientId && (
          <Col xl={6} lg={12} md={12} sm={12} xs={24} flex={true}>
            <ProfitLossChartReport />
          </Col>
        )}
    </div >
  )
}
