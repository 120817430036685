/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Input, PageHeader, Row, Select } from 'antd'
import client from 'apollo'
import moment from 'moment'
import { get, uniqBy } from 'lodash'
import { AppContext } from 'AppContext'
import { SEND_DYNAMIC_EMAIL, UPDATE_INVOICE } from '../graphql/Mutations'
import openNotification from 'utils/Notification'
import ReactQuill from 'react-quill'
import { useQuery } from '@apollo/react-hooks'
import { GET_INVOICES, GET_TEMPLATE_DATA } from '../graphql/Queries'
import { USER_PUBLIC } from 'modules/Users/graphql/Queries'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const MandateEmail = ({
  setOpenReactQuill,
  invoiceCreateData,
  setInvoiceExplanation,
  setInvoiceExplanationValue,
  isSkip,
}) => {
  const {
    state: { invoiceSetting },
  } = useContext(AppContext)

  const {
    id,
    discountInPer,
    commissionInPer,
    discountAmcInPer,
    amcAmount,
    discountAmount,
    commissionAmount,
    totalNetAmount,
    quantity,
    niftySpot,
    dueDay,
    amcInPer,
    grossTotalAmount,
    assetUnderAdvisory,
    igst18InPer,
    deduction,
    invoicePdf,
    clientName,
    startDate,
    amcType,
    clientCode,
    emailId,
    // otherMailId,
    ccMailIds,
  } = invoiceCreateData

  const [onMailSend, setOnMailSend] = useState(false)
  const [receiveMailTemplate, setReceiveMailTemplate] = useState()
  const [toMailOnChange, setToMailOnChange] = useState(
    emailId ? (typeof emailId === 'object' ? emailId : [emailId]) : []
  )
  const [ccMail, setCcMail] = useState(
    ccMailIds
      ? ccMailIds?.map((item) => {
          return {
            email: item,
          }
        })
      : []
  )

  let userEmails = []

  const {
    data: mailTemplate,
    loading: mailTemplateLoading,
    error: mailTemplateError,
  } = useQuery(GET_TEMPLATE_DATA, { variables: { type: deduction } })

  const { data: userData, loading: userLoading } = useQuery(USER_PUBLIC, {
    fetchPolicy: 'network-only',
  })

  if (!userLoading && get(userData, 'getUsersPublic.users')) {
    userData?.getUsersPublic?.users.forEach((item, key) => {
      userEmails.push({
        key,
        label: item?.email,
        value: item?.email,
      })
    })
  }

  useEffect(() => {
    if (!mailTemplateLoading && !mailTemplateError) {
      let modifyTemplate = mailTemplate?.getTemplateData?.htmlData
      modifyTemplate = modifyTemplate?.replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${ writeClientName ? writeClientName : clientDetailsGet?.clientName }',
        clientName
      )

      modifyTemplate = modifyTemplate?.replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${Math.round( totalNetAmountWithGst )}',
        Math.round(totalNetAmount ? totalNetAmount : 0)
      )

      modifyTemplate = modifyTemplate?.replace(
        // eslint-disable-next-line no-template-curly-in-string
        "${moment( invoiceSetting?.amcDates.filter( (item) =&gt; item.amcType?.toString().toLowerCase() === '' )[0]?.amcFinalDueDate ) .add(1, 'day') .format( 'DD-MMM-YY' )}",
        amcType === 'NEW'
          ? moment(startDate).add('days', 1).format('DD-MMM-YYYY')
          : moment(startDate)
              .add('days', invoiceSetting.amcDates[1].day)
              .format('DD-MMM-YYYY')
      )

      setReceiveMailTemplate(modifyTemplate)
    }
  }, [
    mailTemplateLoading,
    mailTemplateError,
    mailTemplate,
    clientName,
    totalNetAmount,
    amcType,
    startDate,
    invoiceSetting,
  ])

  // useEffect(() => {
  //   setCcMail((curr) => {
  //     return [...curr, { email: invoiceSetting?.otherMailId }]
  //   })
  // }, [invoiceSetting])

  const InvoiceExplanation = `
  <div style:"line-height: normal">Dear <b>${clientName}</b></div>

  <div style:"line-height: normal">Greetings from FinIdeas!!!!</div>
  <div style:"line-height: normal">We are happy to serve you...</div>

  <div style:"line-height: normal">Calculation of the Exposure will be as mentioned below,</div>

  <div style:"line-height: normal">Nifty 50 x Quantity of Nifty in ILTS</div>
  <div style:"line-height: normal">In your case the calculation is like,</div>
  <div style:"line-height: normal">Index of Nifty 50 on <b>${moment(
    invoiceSetting?.invoiceDate
  ).format('DD-MMM-YYYY')} = ${Math.round(+niftySpot)} </b></div>
  <div style:"line-height: normal">Quantity of Nifty in ILTS = 50</div>

  <div style:"line-height: normal">AMC  = <b>${amcInPer}% </b> of Exposure</div>
  <div style="line-height: normal">Discount AMC% = <b>${
    discountAmcInPer > 0 ? discountAmcInPer : 0
  }%</b></div>
  <div style:"line-height: normal">Options Exposure = <b>${+niftySpot} x ${Math.round(
    quantity
  )}= ${Math.round(assetUnderAdvisory)}</b></div>

  <div style:"line-height: normal">Total AUA = <b>${assetUnderAdvisory}</b></div>
  <div style:"line-height: normal">Amc period day = <b>${dueDay} </b></div>

  <div style:"line-height: normal">AMC = <b>${assetUnderAdvisory} x (${amcInPer}% - ${
    discountAmcInPer > 0 ? discountAmcInPer : 0
  }%) x (${dueDay}/365) = ${Math.round(amcAmount)}</b></div>
  <div style:"line-height: normal">Discount <b>${discountInPer}% = ${discountAmount?.toFixed(
    2
  )}</b></div>
  <div style:"line-height: normal">Commission <b>${commissionInPer}% = ${commissionAmount?.toFixed(
    2
  )}</b></div>

  <div style:"line-height: normal">Final  Amount</div>
  <div style:"line-height: normal">Total Gross Amount = <b>${Math.round(
    grossTotalAmount
  )} = ${Math.round(amcAmount)} - ${Math.round(
    discountAmount
  )} - ${commissionAmount?.toFixed(2)}</b></div>
  <div style:"line-height: normal">GST = <b>${Math.round(
    grossTotalAmount
  )}</b> x 18% = <b>${Math.round(igst18InPer)}</b></div>
  <div style:"line-height: normal">Total = <b>${Math.round(
    grossTotalAmount
  )} + ${Math.round(igst18InPer)}  = ${Math.round(totalNetAmount)}</b></div>
  <div style:"line-height: normal">In case of queries please contact your RM for further clarification</div>
  <div style:"line-height: normal">Thanks and Regards,</div>
  <div style:"line-height: normal">Team FinIdeas</div>
  `

  const handleMailSend = () => {
    setOnMailSend(true)

    let data = {
      subject: `Finideas Advisory Fees (${clientName})`,
      toMailId: toMailOnChange,
      mailBody: receiveMailTemplate,
      attachedPdf: invoicePdf,
      pdfName: clientName + invoiceSetting?.invoiceNumber,
    }

    if (ccMail.length) {
      data.ccMailIds = ccMail
    }

    ccMail?.length &&
      client
        .mutate({
          mutation: SEND_DYNAMIC_EMAIL,
          variables: { data },
        })
        .then((res) => {
          openNotification('success', 'Mail Send Successfully')
          client
            .mutate({
              mutation: UPDATE_INVOICE,
              variables: {
                where: { id },
                data: {
                  mailBody: receiveMailTemplate,
                  subject: clientCode,
                  ccMailIds: ccMail
                    ?.filter((data) => data !== '')
                    .map((item) => item?.email),
                  otherMailId: toMailOnChange,
                },
              },
              refetchQueries: [
                {
                  query: GET_INVOICES,
                  variables: { where: {} },
                  fetchPolicy: 'network-only',
                },
              ],
            })
            .then((res) => {
              setOpenReactQuill(false)
              if (isSkip) {
                setInvoiceExplanationValue(InvoiceExplanation)
                setInvoiceExplanation(true)
              }
              setOnMailSend(false)
            })
          setOpenReactQuill(true)
        })
        .catch((err) => setOnMailSend(false))
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],

      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],

      ['clean'],
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  const handleEditInputChange = (e) => {
    const array = e.map((email) => {
      return {
        email,
      }
    })

    console.log(array)
    setCcMail(array)
  }

  const handleToMailChange = (e) => {
    setToMailOnChange(e)
  }

  const handleInvoiceExplanation = () => {
    setInvoiceExplanationValue(InvoiceExplanation)
    setInvoiceExplanation(true)
    setOpenReactQuill(false)
  }

  return (
    <>
      <PageHeader className='box' title='Mail Body' />
      <Form
        {...formItemLayout}
        layout='vertical'
        initialValues={{
          emailId: toMailOnChange,
          ccMails: ccMail
            .filter((item) => item !== '')
            ?.map((email) => email?.email),
        }}
      >
        <Row>
          <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item label='Subject'>
              <Input
                value={`Finideas Advisory Fees (${clientName})`}
                placeholder='Enter Subject'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={10} lg={10} xl={10}>
            <FormItem label='To Mail' name='emailId'>
              <Select
                mode='tags'
                allowClear
                style={{ width: '100%' }}
                placeholder='Please select'
                defaultValue={[]}
                onChange={(e) => handleToMailChange(e)}
                options={uniqBy(userEmails, 'value')}
              />
            </FormItem>
          </Col>
          <Col className='gutter-row' xs={24} sm={24} md={10} lg={10} xl={10}>
            <Form.Item label='CC Mail' name='ccMails'>
              <Select
                mode='tags'
                allowClear
                style={{ width: '100%' }}
                placeholder='Please select'
                onChange={(e) => handleEditInputChange(e)}
                options={uniqBy(userEmails, 'value')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {receiveMailTemplate && !mailTemplateLoading && !mailTemplateError && (
        <ReactQuill
          theme='snow'
          value={receiveMailTemplate}
          onChange={(value) => {
            setReceiveMailTemplate(value)
          }}
          modules={modules}
          formats={formats}
        />
      )}
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button
          type='primary'
          onClick={emailId || toMailOnChange ? handleMailSend : ''}
          style={{ marginTop: '20px' }}
          loading={onMailSend}
          disabled={ccMail?.length === 0 || toMailOnChange?.length === 0}
        >
          Send Mail
        </Button>
        {isSkip && (
          <Button
            type='primary'
            onClick={() => handleInvoiceExplanation()}
            style={{ marginTop: '20px' }}
          >
            Skip
          </Button>
        )}
      </Form.Item>
    </>
  )
}

export default MandateEmail
