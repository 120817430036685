import React, { useState, useContext, useEffect } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { Select, DatePicker } from 'antd'
import get from 'lodash/get'
import moment from 'moment'

import history from 'CustomHistory'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext'
import { exportGraphAsImage } from 'utils/Graph'
import ChartError from 'components/Page404/ChartError'
import ChartLoader from 'components/loaders/ChartLoader'
import { GET_NLV_TRACKER_REPORT } from '../../Reports/graphql/Queries'
import client from 'apollo'
import { GET_CLIENT_USERS } from 'modules/Users/graphql/Queries'

const Option = Select.Option

export default function ({ clientId, clientCode }) {
  const [selectedMonths, setSelectedMonths] = useState(60)
  const [selectedDate, setSelectedDate] = useState(undefined)
  const {
    state: {
      currentUser: { role },
    },
  } = useContext(AppContext)

  const [data, setData] = useState([])
  const [loadingClient, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [selectClientDropdown, setSelectClient] = useState([])
  const [selectClientByOption, setSelectClientByOption] = useState()

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_CLIENT_USERS,
        variables: { where: {} },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: GET_NLV_TRACKER_REPORT,
            variables: {
              where: {
                clientCode:
                  role !== 'ADMIN'
                    ? selectClientByOption
                      ? selectClientByOption
                      : res?.data?.getClientUsers?.users[0]?.clientCode
                    : null,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            setData(res?.data)
            setLoading(false)
          })
          .catch((error) => {
            setError(true)
            setLoading(false)
          })
        setSelectClient(res?.data?.getClientUsers?.users)
      })
      .catch((error) => setLoading(false))
  }, [role, selectClientByOption])

  if (loadingClient)
    return <ChartLoader title='Comparison of Fund with Index' />
  if (error) return <ChartError title='Comparison of Fund with Index' />
  if (!get(data, 'spNLVTracker.length'))
    return <ChartError title='Comparison of Fund with Index' desc='No Data' />

  const handleChangeClient = (e) => {
    setSelectClientByOption(e)
  }

  const {
    spNLVTracker: [spNLVTracker],
  } = data

  function handleChange(value) {
    setSelectedMonths(value === 'all' ? 60 : value)
    setSelectedDate(undefined)
  }

  const divId = 'export-graph-NLV-Chart'

  function handleExport(value) {
    exportGraphAsImage('NLV-Chart-Report', value, divId)
  }

  const renderData = selectedDate
    ? spNLVTracker
        .map((reportData) => ({
          name: reportData.ReportDate,
          Index: reportData['NIFTY%'],
          NLV: reportData['TotNLV%'],
        }))
        .filter((reportData) =>
          moment(reportData.name).isSameOrAfter(selectedDate)
        )
    : spNLVTracker
        .map((reportData) => ({
          name: reportData.ReportDate,
          Index: reportData['NIFTY%'],
          NLV: reportData['TotNLV%'],
        }))
        .filter((reportData) =>
          moment(reportData.name).isSameOrAfter(
            moment().subtract(selectedMonths, 'months'),
            'months'
          )
        )

  function disabledDate(current) {
    return current && current > moment().endOf('day')
  }

  return (
    <Widget
      title={
        <>
          <h2 className='h4 gx-text-capitalize gx-mb-0'>
            Comparison of Fund with Index
          </h2>
        </>
      }
      extra={
        <>
          <p
            className='gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block'
            onClick={() => {
              if (clientId) {
                history.push(`/clients/${clientId}/position/${clientCode}`)
              } else {
                history.push('/reports/performance')
              }
            }}
          >
            View Performance
          </p>
        </>
      }
      styleName='gx-text-center'
    >
      <div className='ant-row-flex gx-pr-4 gx-pt-4'>
        <div className='gx-ml-auto'>
          {role !== 'ADMIN' && (
            <Select
              placeholder='Select Client'
              showSearch
              allowClear
              style={{ width: '200px' }}
              defaultValue={selectClientDropdown[0]?.clientCode}
              optionFilterProp='children'
              onChange={(e) => handleChangeClient(e)}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {!loadingClient &&
                !error &&
                selectClientDropdown?.map((ele, index) => {
                  return (
                    <Option value={ele.clientCode} key={index}>
                      {ele.clientCode}
                    </Option>
                  )
                })}
            </Select>
          )}
          &nbsp;
          <DatePicker
            format={'DD-MMM-YYYY'}
            disabledDate={disabledDate}
            value={selectedDate}
            showToday={false}
            onChange={(e) => {
              if (e) {
                setSelectedDate(e)
                setSelectedMonths('')
              } else {
                setSelectedDate(undefined)
                setSelectedMonths(3)
              }
            }}
          />
          &nbsp;
          <Select
            className='gx-mb-2 gx-select-sm'
            defaultValue='3'
            onChange={handleChange}
            value={
              selectedMonths
                ? selectedMonths === 60
                  ? 'View All Records'
                  : `Last ${selectedMonths} months`
                : selectedMonths
            }
          >
            <Option value='' disabled>
              Select Months
            </Option>
            <Option value='3'>Last 3 months</Option>
            <Option value='6'>Last 6 months</Option>
            <Option value='12'>Last 12 months</Option>
            <Option value='all'>View All Records</Option>
          </Select>
          &nbsp;
          <Select
            className='gx-mb-2 gx-select-sm'
            value='1'
            onChange={handleExport}
          >
            <Option value='1' disabled>
              Export As
            </Option>
            <Option value='png'>PNG</Option>
            <Option value='jpeg'>JPEG</Option>
          </Select>
        </div>
      </div>
      <ResponsiveContainer width='100%' height={250} id={divId}>
        <LineChart
          data={renderData}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' hide={true} />
          <YAxis />
          <Tooltip />
          <Legend />
          {/* activeDot={{ r: 8 }} */}
          <Line
            type='monotone'
            dataKey='Index'
            dot={false}
            strokeWidth={2.5}
            stroke='#AF0000'
          />
          <Line
            type='monotone'
            dataKey='NLV'
            dot={false}
            strokeWidth={2.5}
            stroke='#000'
          />
        </LineChart>
      </ResponsiveContainer>
    </Widget>
  )
}
