import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, Select } from 'antd'
import history from 'CustomHistory'
import _isEmpty from 'lodash/isEmpty'
import sumBy from 'lodash/sumBy'
import first from 'lodash/first'
import _map from 'lodash/map'
import _get from 'lodash/get'

import client from 'apollo'
import ILTS from './Tables/ILTS'
import Stock from './Tables/Stock'
import Ledger from './Tables/Ledger'
import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import DownloadReport from '../DownloadReport'
import Loader from 'components/loaders/Loader'
import OpenPosition from './Tables/OpenPosition'
import {
  GET_ILTS_P_REPORT,
  GET_ILTS_REPORT,
  GET_TRADER_EQ_REPORT,
  GET_TRADER_FO_REPORT,
  SEND_ME_PDF_V2,
} from '../graphql/Queries'
import { GET_STRATEGY } from 'modules/Settings/graphql/Queries'
import '../index.css'
import { CSVLink } from 'react-csv'
import mailImage from '../../../assets/images/gmail-icon-logo-svgrepo-com.svg'
import openNotification from 'utils/Notification'

const { Option } = Select

export default function () {
  const [strategy, setStrategy] = useState('1005')
  const [sendMeLoading, setSendMeLoading] = useState(false)
  const [strategyList, setStrategyList] = useState(undefined)
  const [exportDataCsv, setExportDataCsv] = useState([])
  const [exportDataCsvFO, setExportDataCsvFO] = useState([])
  const [loadingExportData, setLoading] = useState(false)
  const [loadingFO, setLoadingFO] = useState(false)
  const {
    state: {
      currentReport,
      currentUser: { clientCode, id },
    },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_ILTS_REPORT, {
    variables: { where: { clientCode, date: null, strategy } },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    client
      .query({
        query: GET_STRATEGY,
        variables: { where: { clientCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (_get(res, 'data.getStrategy')) {
          setStrategyList(_get(res, 'data.getStrategy'))
          if (res.data.getStrategy.length === 1) {
            setStrategy(res.data.getStrategy[0].StrategyCode)
          }
        }
      })
      .catch(() => setStrategyList([]))
  }, [clientCode])

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_TRADER_EQ_REPORT,
        variables: { where: { clientCode: clientCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoading(false)
        setExportDataCsv(res?.data?.spTradeReportEQ)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLoadingFO(true)
    client
      .query({
        query: GET_TRADER_FO_REPORT,
        variables: { where: { clientCode: clientCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoadingFO(false)
        setExportDataCsvFO(res?.data?.spTradeReportFO)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataForExportCSV = exportDataCsv?.map((item) => {
    return {
      script: item?.script,
      qty: item?.qty,
      rate: item?.rate,
      entrydate: item?.entrydate,
      ClientCode: item?.ClientCode,
    }
  })

  const dataForExportCSVFO = exportDataCsvFO?.map((item) => {
    return {
      script: item?.Script,
      qty: item?.qty,
      rate: item?.rate,
      entrydate: item?.entrydate,
      ClientCode: item?.ClientCode,
    }
  })

  let spIltsreport
  let futuresOptionsReport = {}
  let stockReport = {}
  let ledgerReport = {}

  if (!loading && _get(data, 'spIltsreport')) {
    spIltsreport = data.spIltsreport[0]
    const reportFutures = spIltsreport.filter(
      (report, key) => report.Script && !report.Script.endsWith(' EQ')
    )
    futuresOptionsReport = reportFutures.map((reportFuture) => {
      return {
        ...reportFuture,
        TotalQty: Math.round(reportFuture.TotalQty),
        TradedRate: Math.round(reportFuture.TradedRate),
        CMP: Math.round(reportFuture.CMP),
        Value: Math.round(reportFuture.Value),
        Pnl: Math.round(reportFuture.Pnl),
      }
    })
    futuresOptionsReport = [
      ...futuresOptionsReport,
      {
        Script: 'Total',
        TotalQty: sumBy(futuresOptionsReport, 'TotalQty'),
        Value: sumBy(futuresOptionsReport, 'Value'),
        Pnl: sumBy(futuresOptionsReport, 'Pnl'),
      },
    ]

    const reportStocks = spIltsreport.filter(
      (report, key) => report.Script && report.Script.endsWith(' EQ')
    )
    stockReport = reportStocks.map((reportStock) => {
      return {
        ...reportStock,
        TotalQty: Math.round(reportStock.TotalQty),
        TradedRate: Math.round(reportStock.TradedRate),
        CMP: Math.round(reportStock.CMP),
        Value: Math.round(reportStock.Value),
        Pnl: Math.round(reportStock.Pnl),
      }
    })
    stockReport = [
      ...stockReport,
      {
        Script: 'Total',
        TotalQty: sumBy(stockReport, 'TotalQty'),
        Value: sumBy(stockReport, 'Value'),
        Pnl: sumBy(stockReport, 'Pnl'),
      },
    ]

    const ledgers = spIltsreport.filter(
      (report, key) => key !== 0 && key !== 1 && key !== 2 && !report.Script
    )
    ledgerReport = ledgers.map((reportLedger) => {
      return {
        ...reportLedger,
        DR_Amount: Math.round(reportLedger.DR_Amount),
        CR_Amount: Math.round(reportLedger.CR_Amount),
      }
    })
    ledgerReport = [
      ...ledgerReport,
      {
        Entrydate: 'Total',
        DR_Amount: sumBy(ledgerReport, 'DR_Amount'),
        CR_Amount: sumBy(ledgerReport, 'CR_Amount'),
        CumAmount:
          sumBy(ledgerReport, 'CR_Amount') - sumBy(ledgerReport, 'DR_Amount'),
        remark: '',
      },
      // {
      //   Entrydate: 'Net Value',
      //   DR_Amount:
      //     sumBy(ledgerReport, 'CR_Amount') - sumBy(ledgerReport, 'DR_Amount'),
      //   CR_Amount: '',
      //   remark: '',
      // },
    ]
  }

  const spILTSReportData =
    spIltsreport &&
    _map(spIltsreport, (report, key) => {
      if (key === 0) return report
      if (report.ClientCode) delete report.ClientCode
      return report
    })

  return (
    <>
      <div className='send-me-wrapper'>
        <Button
          type='primary'
          // loading={sendMeLoading}
          onClick={() =>
            history.push(
              `/reports/performance/${id}/comparison-of-fund/${clientCode}`
            )
          }
        >
          View comparison
        </Button>
        {/* <Button
          type='primary'
          loading={sendMeLoading}
          onClick={() => {
            setSendMeLoading(true)
            sendMePdf('ILTS-PDF').then(() => setSendMeLoading(false))
          }}
        >
          Send Me
        </Button> */}
        <Select
          defaultValue='1005'
          style={{ width: 80 }}
          className='gx-mr-3 gx-select-sm'
          onChange={(e) => setStrategy(e)}
          loading={!strategyList}
        >
          {_map(strategyList, (strategy, key) => {
            const { StrategyName, StrategyCode } = strategy
            return (
              <Option value={StrategyCode} key={key}>
                {StrategyName}
              </Option>
            )
          })}
        </Select>
        <img
          src={mailImage}
          alt='mailImage'
          width={25}
          height={30}
          style={{
            cursor: `${sendMeLoading ? 'not-allowed' : 'pointer'}`,
            marginRight: '10px',
          }}
          onClick={() => {
            setSendMeLoading(true)
            client
              .query({
                query: GET_ILTS_P_REPORT,
                variables: { where: { id: parseInt(id) } },
                fetchPolicy: 'network-only',
              })
              .then((res) => {
                client
                  .query({
                    query: SEND_ME_PDF_V2,
                    variables: {
                      data: {
                        url: res?.data?.getIltspreport,
                        useSendInBlue: true,
                      },
                    },
                    fetchPolicy: 'network-only',
                  })
                  .then(() =>
                    openNotification('success', 'Mail has been sent!')
                  )
              })
              .catch((err) => console.log(err))
          }}
        />
        <DownloadReport
          data={spILTSReportData}
          fileName={`ILTS-PERFORMANCE-REPORT-${clientCode}`}
          id='ILTS-REPORT-PDF'
          isILTSNewReport={true}
          clientId={id}
          newPdfIcon={true}
        />
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Page404 />
      ) : !_get(data, 'spIltsreport') ||
        _isEmpty(_get(data, 'spIltsreport[0]')) ? (
        <NoData />
      ) : (
        <div className='report-table-wrapper ilts-report-client' id='ILTS-PDF'>
          <div className='report-ILTS' id='ILTS-PDF-Client'>
            {/* <DownloadReport data={first(spIltsreport)} fileName='ILTS' id='ILTS-PDF-Client' /> */}
            <ILTS
              reportData={first(spIltsreport)}
              ledgerReport={ledgerReport}
            />
          </div>
          <br />
          <br />
          <div
            className='report-ILTS'
            id='ILTS-PDF-Stock'
            style={{ pageBreakAfter: 'always', margin: '0 20px' }}
            // onClick={() => history.push(`/reports/trade-eq`)}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {currentReport.includes('Stock Report All Export As Button') && (
                <CSVLink
                  data={dataForExportCSV}
                  className='ExportAS'
                  style={{ marginRight: '10px' }}
                  filename={`Stock Holding Report ${clientCode}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    loading={loadingExportData}
                    type='primary'
                    style={{
                      height: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Export Trades
                  </Button>
                </CSVLink>
              )}

              <DownloadReport
                className='ExportAS'
                data={stockReport}
                fileName={`Stock Holding Report ${clientCode}`}
                id='ILTS-PDF-Stock'
                isStockReport={true}
                newPdfIcon={true}
                clientId={id}
              />
            </div>
            <Stock reportData={stockReport} clientData={first(spIltsreport)} />
          </div>
          <br />
          <br />
          <div
            className='report-ILTS'
            id='ILTS-PDF-Futures'
            style={{ pageBreakAfter: 'always', margin: '0 20px' }}
            // onClick={() => history.push(`/reports/trade-fo`)}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {currentReport.includes('FO Report All Export As Button') && (
                <CSVLink
                  data={dataForExportCSVFO}
                  className='ExportAS'
                  style={{ marginRight: '10px' }}
                  filename={`Trade F&O Report ${clientCode}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    type='primary'
                    style={{
                      height: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    loading={loadingFO}
                  >
                    Export Trades
                  </Button>
                </CSVLink>
              )}
              <DownloadReport
                className='ExportAS'
                data={futuresOptionsReport}
                fileName={`Trade F&O Report ${clientCode}`}
                id='ILTS-PDF-Futures'
                isFutureAndOptionReport={true}
                newPdfIcon={true}
                clientId={id}
              />
            </div>
            <OpenPosition
              reportData={futuresOptionsReport}
              clientData={first(spIltsreport)}
            />
          </div>
          <br />
          <br />
          <div className='report-ILTS' id='ILTS-PDF-Ledger'>
            <DownloadReport
              className='ExportAS'
              data={ledgerReport}
              fileName={`Ledger Report ${clientCode}`}
              id='ILTS-PDF-Ledger'
              isLedgerReport={true}
              newPdfIcon={true}
              clientId={id}
            />
            <Ledger
              reportData={ledgerReport}
              clientData={first(spIltsreport)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '13px',
            }}
          >
            This is an indicative report. Please check your back-office for
            final report
          </div>
        </div>
      )}
    </>
  )
}
