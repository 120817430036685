import React from 'react'
import { Table } from 'antd'

export default function ({ reportData, role }) {
  const data = reportData.map((report, key) => {
    // delete report.ID
    // delete report.Branch
    // delete report.LedgerJSC
    // delete report.LedgerJCPL
    // delete report.LedgerJFS
    // delete report.entrydate
    if (key + 1 === reportData.length)
      return { key, ...report, Mnth: report.Type }
    return { key, ...report }
  })

  const child = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
      width: '10%',
      className: 'report-text',
    },
    {
      title: 'Branch',
      dataIndex: 'Branch',
      key: 'Branch',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      width: '20%',
      className: 'report-text',
    },
    {
      title: 'LedgerJSC',
      dataIndex: 'LedgerJSC',
      key: 'LedgerJSC',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'LedgerJCPL',
      dataIndex: 'LedgerJCPL',
      key: 'LedgerJCPL',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'LedgerJFS',
      dataIndex: 'LedgerJFS',
      key: 'LedgerJFS',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'LedgerAll',
      dataIndex: 'LedgerAll',
      key: 'LedgerAll',
      width: '10%',
      className: 'report-numeric',
    },
    {
      title: 'EntryDate',
      dataIndex: 'EntryDate',
      key: 'EntryDate',
      width: '20%',
      className: 'report-numeric',
    },
  ]

  const columns = [
    {
      title: 'J Balance Report',
      className: 'report-title main-title',
      children:
        role !== 'ADMIN'
          ? child
          : child?.filter(
              (item) =>
                item?.title !== 'ID' &&
                item?.title !== 'Branch' &&
                item?.title !== 'LedgerJSC' &&
                item?.title !== 'LedgerJCPL' &&
                item?.title !== 'LedgerJFS'
            ),
    },
  ]

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Table
        columns={columns}
        dataSource={data}
        className='interest-report'
        style={{ width: '60%' }}
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
        // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
      />
    </div>
  )
}
