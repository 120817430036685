import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import { Col } from 'antd'

import { AppContext } from 'AppContext'
import { generateDashboardSummary } from 'utils/misc'
import IconWithTextCard from '../Components/IconWithTextCard'
import { GET_DASHBOARD_SUMMARY, GET_REVENUE_INCOME_SUMMARY } from '../graphql/Queries'

export default function () {
  let dashboardData = []
  let dashboardSpliceData = []
  const { state: { selectedGroup, selectedStrategy, currentUser: { id } } } = useContext(AppContext)
  const { data, loading } = useQuery(GET_DASHBOARD_SUMMARY, { variables: { groupId: selectedGroup, strategyCode: selectedStrategy }, fetchPolicy: 'network-only' })

  const { data: revenueData } = useQuery(GET_REVENUE_INCOME_SUMMARY, { variables: { where: { userId: id } }, fetchPolicy: 'network-only' })
  if (get(revenueData, 'getTotalRevenueIncome')) {
    dashboardData = [
      ...dashboardData,
      {
        cardColor: 'light-blue',
        icon: 'timeline-with-icons',
        title: get(revenueData, 'getTotalRevenueIncome').toFixed(2) || '-',
        subTitle: 'Revenue Income'
      }
    ]
  }

  if (loading || !get(data, 'dashboardSummary[0]')) return <></>

  if (get(data, 'dashboardSummary[0]')) {
    dashboardData = [
      ...dashboardData,
      ...generateDashboardSummary(get(data, 'dashboardSummary[0]'))
    ]
    dashboardSpliceData = [...dashboardData.splice(3, 5)]
  }

  return (
    get(dashboardData, 'length') ?
      <>
        {
          dashboardData.map((data, key) => {
            return (
              <Col key={key} xl={4} lg={6} md={6} sm={6} xs={24}>
                <IconWithTextCard {...data} />
              </Col>
            )
          })
        }
        {
          dashboardSpliceData.map((data, key) => {
            return (
              <Col key={key} xl={4} lg={6} md={6} sm={6} xs={24}>
                <IconWithTextCard {...data} />
              </Col>
            )
          })
        }
      </>
      :
      <></>
  )
}
