import { Button, Col, Form, Input, Modal, Row } from 'antd'
import client from 'apollo'
import { AppContext } from 'AppContext'
import { VERIFY_OTP_FOR_REEVALUATE } from 'modules/Users/graphql/Mutations'
import React, { useContext, useState } from 'react'

const VerificationModal = ({ visible, onNext }) => {

  const { state: { currentUser } } = useContext(AppContext)
  const [loadings, setLoadings] = useState()
  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 } }
  }

  const onFinish = (value) => {
    setLoadings(true)
    const otp = value.otp
    client.mutate({ mutation: VERIFY_OTP_FOR_REEVALUATE, variables: { otp: otp } })
      .then(res => {
        onNext()
        setLoadings(false)
      })
      .catch(err => console.log(err))
      .finally(() => setLoadings(false))
  }

  return (
    <>
      <Modal
        maskClosable={false}
        visible={visible}
        title={'OTP verification'}
        footer={null}
        mask={false}
        closable={false}
      >
        <Form
          {...formItemLayout}
          className='user-form'
          onFinish={onFinish}
          initialValues={{
            email: currentUser.email,
            phone: currentUser.phone
          }}
        >
          <Row gutter={16}>
            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name='email'
                label='Register Email ID'
                rules={[{ required: true }]}
              >
                <Input placeholder='Enter email' disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name='phone'
                label='Register Mobile Number'
                rules={[{ required: true }]}
              >
                <Input type='number' style={{ width: '100%' }} placeholder='Enter Mobile Number' disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name='otp'
                label='OTP'
                rules={[{ required: true }]}
              >
                <Input placeholder='Enter OTP' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item>
                <Button type='primary' htmlType='submit' loading={loadings} >Submit</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default VerificationModal
